import { useQuery } from '@apollo/client';
import React from 'react';
import {
  convertEnumString,
  formatDateTime,
  OverflowContainer,
  PageError,
  PageLoading,
  Table,
  Td,
  Th,
} from '../../../../';
import { getAuditGql } from './gql';

const AuditList = ({ id, auditGqlQuery, auditGqlPath = 'audits' }) => {
  const { data, error } = useQuery(getAuditGql(auditGqlQuery, auditGqlPath), {
    variables: { id },
  });
  if (error) {
    return <PageError error={error} />;
  }

  if (data) {
    const audits = data[auditGqlQuery][auditGqlPath];
    return (
      <OverflowContainer>
        <Table style={{ tableLayout: 'fixed' }}>
          <colgroup>
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="35%" />
          </colgroup>
          <thead>
            <tr>
              <Th noHover>Timestamp</Th>
              <Th noHover>Event Type</Th>
              <Th noHover>Username</Th>
              <Th noHover>Changes</Th>
            </tr>
          </thead>
          <tbody>
            {audits.map((audit) => {
              return (
                <tr key={audit.id}>
                  <Td>{formatDateTime(audit.timestamp)}</Td>
                  <Td>{convertEnumString(audit.eventType)}</Td>
                  <Td>{audit.username}</Td>
                  <Td>
                    <div
                      style={{ overflow: 'auto' }}
                      dangerouslySetInnerHTML={{
                        __html: audit.formattedChangeLog,
                      }}
                    ></div>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </OverflowContainer>
    );
  }

  return <PageLoading />;
};

export default AuditList;
