import validationSchema from '../validationSchema';

const formikSchema = {
  initialValues: {
    orgId: '',
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    comsEvents: [{ event: '', email: false, sms: false }],
  },
  validationSchema,
};

export default formikSchema;
