import { gql } from '@apollo/client';

const UPDATE_ADN = gql`
  mutation updateADN($input: ADNUpdateInput!) {
    updateADN(input: $input) {
      id
    }
  }
`;

const GET_DATA = gql`
  query getData($id: ID!) {
    getADN(id: $id) {
      type
      id
      carrierId
      orgId
      principalId
      event
      subject
      from
      emailTemplate
      smsTemplate
      bannerImage
      isActive
    }
  }
`;

export { UPDATE_ADN, GET_DATA };
