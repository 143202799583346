import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAlerts } from '../../hooks';
import { Container, DropInput, FileIcon, Input, UploadBtn } from './styles';

const FileUploadComponentSimple = ({
  onForm,
  allowedFileType = '',
  accept = '',
  labelText = 'Drop File Here',
  field: { name },
}) => {
  const [file, setFile] = useState();
  const { handleChange } = useFormikContext();
  const { alertError } = useAlerts();

  useEffect(() => {
    if (file && onForm) {
      handleChange({
        target: {
          name,
          value: file,
        },
      });
    }
  }, [file, handleChange, onForm, name]);
  const acceptPayload =
    allowedFileType || accept
      ? {
          accept: allowedFileType ? `.${allowedFileType}` : accept,
        }
      : {};

  const handleFileChange = (fileToChange) => {
    if (fileToChange && !fileToChange.type) {
      alertError('Please do not upload a folder!');
      return;
    }
    setFile(fileToChange);
  };

  return (
    <Container noFile={!file}>
      <DropInput
        type="file"
        {...acceptPayload}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleFileChange(e.dataTransfer.files[0]);
        }}
        onDragEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
      <FileIcon>
        {file
          ? `${file.name.substring(0, 9)}... | ${(file.size / 1000).toFixed(
              2,
            )}kb`
          : labelText}
      </FileIcon>
      <UploadBtn type="button">
        <Input
          onChange={(e) => {
            if (e.target.files.length > 0) {
              handleFileChange(e.target.files[0]);
            }
          }}
          type="file"
          {...acceptPayload}
        />
        Upload
      </UploadBtn>
    </Container>
  );
};
export default FileUploadComponentSimple;
