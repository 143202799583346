import { GoogleMap, Marker } from '@react-google-maps/api';
import React from 'react';
import {
  googleMapsOptions,
  PageError,
  PageLoading,
  useGoogleMapsLoader,
} from '../../../../../';

const PointViewModal = ({ value }) => {
  const { isLoaded, loadError } = useGoogleMapsLoader();

  if (loadError) {
    return <PageError error={loadError} />;
  }

  const point = { lat: value.latitude, lng: value.longitude };
  if (isLoaded) {
    return (
      <GoogleMap
        options={googleMapsOptions}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        zoom={17}
        tilt={0}
        center={point}
      >
        <Marker
          icon={
            'https://tm-cloud-assets.s3-eu-west-1.amazonaws.com/tm-marker.png'
          }
          position={point}
        />
      </GoogleMap>
    );
  }

  return <PageLoading />;
};

export default PointViewModal;
