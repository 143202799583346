import styled from 'styled-components';

const FormContainer = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: ${({ gridStyling }) => gridStyling || 'auto 1fr'};
  width: 800px;
`;

export default FormContainer;
