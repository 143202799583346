import { gql } from '@apollo/client';

const ASSERT_MFA = gql`
  query assertMFA($id: String!, $otp: String!) {
    assertMFA(id: $id, otp: $otp) {
      token
    }
  }
`;

export { ASSERT_MFA };
