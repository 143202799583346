import { get, noop } from 'lodash';
import React, { Fragment, useContext } from 'react';
import {
  addDurationStringToDate,
  convertEnumString,
  formatDateTime,
  formatDuration,
  formatNumber,
  ModalContext,
  Table,
  Th,
  theme,
} from '../../../';
import { getStopActivities } from '../functions';
import LoadInfoModal from './LoadInfoModal';
import RepressedView from './RepressedView';
import {
  AbsoluteActivityType,
  ActivityType,
  ActivityTypeDot,
  ActivityTypeLine,
  CameraIcon,
  ChatIcon,
  ExecutionFlow,
  InfoBox,
  InfoIcon,
  LoadInfo,
  OrderIndicator,
  RepressIcon,
  StopExecution,
  StopExecutionDetails,
  StopExecutionFlow,
} from './styles';

const ExecutionFlowView = ({
  load,
  canRepress,
  isRepressed,
  height = '80vh',
  onRepressClick = noop,
  onChatClick = noop,
  onCamClick = noop,
  hasChat = false,
}) => {
  const {
    status,
    travelPlan: { stops: ls },
    allocation,
    carrier,
    execution,
    plannedStart: ps,
    plannedEnd: pe,
    reference,
    isRoute,
  } = load;

  const stops = ls.filter((_, index) =>
    isRoute ? index !== 0 && index !== ls.length - 1 : true,
  );

  const hasCam =
    carrier?.surfsightId &&
    allocation?.vehicle?.asset?.devices?.some((d) => d.type === 'DASH_CAM');
  const { toggleModal, setModalContent, clearModal } = useContext(ModalContext);
  if (isRepressed) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <RepressedView onRepressClick={onRepressClick} />
        {hasCam && <CameraIcon onClick={onCamClick} />}
        {hasChat && <ChatIcon onClick={onChatClick} />}
      </div>
    );
  }
  const plannedStart = formatDateTime(ps);
  const plannedEnd = formatDateTime(pe);

  return (
    <ExecutionFlow height={height}>
      <LoadInfo>
        <InfoBox
          status={status}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: theme.colors.black,
          }}
        >
          <p>{reference}</p>
          <p>{convertEnumString(status)}</p>
        </InfoBox>
        <InfoIcon
          onClick={() => {
            toggleModal();
            setModalContent(
              <LoadInfoModal
                load={load}
                close={clearModal}
                setModalContent={setModalContent}
              />,
            );
          }}
        />
        {hasCam && <CameraIcon onClick={onCamClick} />}
        {canRepress && <RepressIcon onClick={onRepressClick} />}
        {hasChat && <ChatIcon onClick={onChatClick} />}
      </LoadInfo>
      <InfoBox type="success">Start at {plannedStart}</InfoBox>
      <StopExecutionFlow>
        {stops.map((stop, stopIndex) => {
          const { location, path } = stop;
          const activities = getStopActivities(stop, execution);
          const order = stopIndex + 1;
          const stopArrival = formatDateTime(stop.plannedStart);
          const stopDeparture = formatDateTime(stop.plannedEnd);
          const stopDuration = formatDuration(
            stop.plannedStart,
            stop.plannedEnd,
            true,
          );
          return (
            <Fragment key={stop.id}>
              {path && (
                <InfoBox
                  style={{
                    color: theme.colors.black,
                    fontWeight: 'normal',
                    padding: '0.3rem 0.5rem',
                    paddingLeft: '2.5rem',
                    gridColumn: 'first 2',
                    marginBottom: '0.5rem',
                    zIndex: 3,
                  }}
                >
                  Travel:{' '}
                  <strong>
                    {formatNumber(path.distance.toFixed(0))} km for{' '}
                    {path.estimatedTravelTime
                      .split(':')
                      .filter((_, idx) => idx <= 1)
                      .join(':')}
                  </strong>
                </InfoBox>
              )}
              <StopExecution>
                <OrderIndicator>{order}</OrderIndicator>
                <h3>{location.data.name}</h3>
              </StopExecution>
              <StopExecutionDetails>
                <div />
                <Table>
                  <thead>
                    <tr>
                      <Th>
                        <ActivityType />
                      </Th>
                      <Th>Arrival</Th>
                      <Th>Departure</Th>
                      <Th>Duration</Th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ fontWeight: 'bold' }}>
                        <p>Planned</p>
                      </td>
                      <td>
                        <p>{stopArrival}</p>
                      </td>
                      <td>
                        <p>{stopDeparture}</p>
                      </td>
                      <td>
                        <p>{stopDuration}</p>
                      </td>
                    </tr>
                    {execution?.stopVisits
                      ?.filter((v) => v.stopId === stop.id)
                      .map((visit, vIndex) => {
                        const visitArrival = formatDateTime(visit.arrivedAt);
                        const visitDeparture = formatDateTime(visit.departedAt);
                        const visitDuration = formatDuration(
                          visit.arrivedAt,
                          visit.departedAt,
                          true,
                        );
                        return (
                          <tr key={vIndex + visit.arrivedAt}>
                            <td style={{ fontWeight: 'bold' }}>
                              <p>Actual</p>
                            </td>
                            <td>
                              <p>{visitArrival}</p>
                            </td>
                            <td>
                              <p>{visitDeparture}</p>
                            </td>
                            <td>
                              <p>{visitDuration}</p>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </StopExecutionDetails>
              {activities.map((activity) => {
                const activityStart = formatDateTime(activity.dueTime);
                const activityEnd = formatDateTime(
                  addDurationStringToDate(activity.dueTime, activity.duration),
                );
                const hasExecution = activity.execution.length > 0;
                const actualStart = get(activity, 'execution[0].started');
                const actualEnd = get(activity, 'execution[0].ended');
                const didStartLate =
                  hasExecution &&
                  formatDuration(activityStart, actualStart, true, true)
                    .valueInSeconds > 0;
                const didEndLate =
                  hasExecution &&
                  formatDuration(activityEnd, actualEnd, true, true)
                    .valueInSeconds > 0;
                const pDuration = formatDuration(
                  activityStart,
                  activityEnd,
                  true,
                  true,
                );
                const aDuration = formatDuration(
                  actualStart,
                  actualEnd || new Date().toISOString(),
                  true,
                  true,
                );
                const isOverDuration =
                  hasExecution &&
                  pDuration.valueInSeconds < aDuration.valueInSeconds;
                const buildStyle = (indicator) =>
                  indicator ? { color: theme.colors.danger } : {};
                return (
                  <StopExecutionDetails key={activity.id}>
                    <Table>
                      <thead>
                        <tr>
                          <Th
                            rowSpan={activity.execution.length > 0 ? '3' : '2'}
                            style={{
                              position: 'relative',
                              textAlign: 'center',
                              padding: 0,
                            }}
                          >
                            <ActivityType />
                            <AbsoluteActivityType type={activity.direction}>
                              {convertEnumString(activity.direction)}
                            </AbsoluteActivityType>
                            <ActivityTypeDot type={activity.direction} />
                            <ActivityTypeLine type={activity.direction} />
                          </Th>
                          <Th>Start</Th>
                          <Th>End</Th>
                          <Th>Duration</Th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>
                            <p>Planned</p>
                          </td>
                          <td>
                            <p>{activityStart}</p>
                          </td>
                          <td>
                            <p>{activityEnd}</p>
                          </td>
                          <td>
                            <p>{pDuration.format}</p>
                          </td>
                        </tr>
                        {hasExecution && (
                          <tr>
                            <td style={{ fontWeight: 'bold' }}>
                              <p>Actual</p>
                            </td>
                            <td>
                              <p style={buildStyle(didStartLate)}>
                                {formatDateTime(actualStart)}
                              </p>
                            </td>
                            <td>
                              <p style={buildStyle(didEndLate)}>
                                {formatDateTime(actualEnd)}
                              </p>
                            </td>
                            <td>
                              <p style={buildStyle(isOverDuration)}>
                                {aDuration.format}
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </StopExecutionDetails>
                );
              })}
            </Fragment>
          );
        })}
      </StopExecutionFlow>
      <InfoBox type="danger">End at {plannedEnd}</InfoBox>
    </ExecutionFlow>
  );
};

export default ExecutionFlowView;
