import { gql } from '@apollo/client';

const GET_ORG_CONFIG = gql`
  query getOrgConfig($id: ID!) {
    getOrg: getOrg(id: $id) {
      loadConfiguration {
        orgId
        entityDefaultMstInMinutes
        loadDeclineReasons
        loadRequestDeclineReasons
        loadCancellationReasons
        loadReinstantiationReasons
        loadRejectionReasons
        loadForceCloseReasons
        loadFailureReasons
        loadAbortReasons
        loadTemplateTypes
        loadReferenceFormat
        actionManagementFailureReasons
        useEntityDefaultMst
        allowEditingActiveLoad
        allowEditingPersistedTravelPlan
        activityCountUpEnabled
        useSiteIdForIdentitySeeding
        pbxEnabled
        chatEnabled
        odometerCaptureEnabled
        enableRouteCheck
        notifyNextEnabled
        collectionsEnabled
        selfScheduleEnabled
        routeCheckRejectReasons
        attemptOrCancelPickup
        logEntryLevel
        dropOffHandlingUnitConfiguration {
          failureReasons
          rejectReasons
          endorsementReasons
          endorsementNoDiscrepancyReasons
          endorsementType
        }
        pickupHandlingUnitConfiguration {
          failureReasons
          rejectReasons
          endorsementReasons
          endorsementNoDiscrepancyReasons
          endorsementType
        }
        routingViolations {
          unknownStop {
            isRequired
            durationInSeconds
          }
          excessStop {
            isRequired
            durationInSeconds
          }
          unplannedStop {
            isRequired
            durationInSeconds
          }
          unattended {
            isRequired
            durationInSeconds
          }
        }
        trackingViolations {
          maxSpeed
          idleDurationInSeconds
        }
      }
    }
    debugLevel: __type(name: "LogEntriesDebugLevel") {
      enumValues {
        name
      }
    }
    templateTypes: __type(name: "LoadWorkflowTypeEnums") {
      enumValues {
        name
      }
    }
    endorsementTypes: __type(name: "LoadConfigurationHuConfigurationEnum") {
      enumValues {
        name
      }
    }
  }
`;

const UPDATE_ORG_CONFIG = gql`
  mutation updateLoadConfiguration($input: LoadConfigurationInput!) {
    updateLoadConfiguration(input: $input) {
      id
    }
  }
`;

export { GET_ORG_CONFIG, UPDATE_ORG_CONFIG };
