import { Location20, Satellite20, Select_0132 } from '@carbon/icons-react';
import styled from 'styled-components';

const GoogleMapCustomMenu = styled.div<{ small?: boolean }>`
  position: absolute;
  z-index: 1000;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  margin: 10px;
  left: ${({ small }) => (small ? 0 : 200)}px;
  align-items: center;
`;

const GoogleMapCustomMenuIconWrapper = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  cursor: pointer;
  background: ${({ theme, isActive }) => isActive && theme.colors.primary};
  :hover {
    background: ${({ theme, isActive }) => !isActive && theme.colors.lightGrey};
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GoogleMapSelectIcon = styled(Select_0132)`
  cursor: pointer;
`;

const GoogleMapShapeDisplayIcon = styled(Location20)`
  cursor: pointer;
`;

const GoogleMapSatelliteIcon = styled(Satellite20)`
  cursor: pointer;
`;

const ShapeDisplayInfo = styled.div`
  padding: 0.3rem;
  color: ${({ theme }) => theme.colors.primary};
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
`;

export {
  GoogleMapCustomMenu,
  GoogleMapCustomMenuIconWrapper,
  GoogleMapSelectIcon,
  ShapeDisplayInfo,
  GoogleMapShapeDisplayIcon,
  GoogleMapSatelliteIcon,
};
