import { Checkmark24 } from '@carbon/icons-react';
import React from 'react';
import styled from 'styled-components';

const Label = styled.div`
  font-size: 0.75rem;
  margin-left: 0.9rem;
`;

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.5rem;
`;

const StyledCheckbox = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  width: 2rem;
  height: 2rem;
  background: ${({ checked, theme, disabled }) =>
    disabled
      ? theme.colors.lightGrey
      : checked
      ? theme.colors.primary
      : theme.colors.white};
  border: 1px solid
    ${({ theme, checked, disabled }) =>
      theme.colors[
        disabled ? 'mediumGrey' : checked ? 'primary' : 'mediumGrey'
      ]};
  border-radius: 3px;
  transition: all 150ms;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const CheckBoxFormField = ({
  field,
  labelText,
  className,
  checked,
  onChange,
  disabled,
}) => {
  const component = field ? (
    <StyledCheckbox
      disabled={disabled}
      checked={field.value}
      onClick={() => {
        !disabled &&
          field.onChange({ target: { value: !field.value, name: field.name } });
        onChange && !disabled && onChange(field, !field.value);
      }}
    >
      {field.value && <Checkmark24 />}
    </StyledCheckbox>
  ) : (
    <StyledCheckbox
      disabled={disabled}
      checked={checked}
      onClick={() => !disabled && onChange()}
    >
      {checked && <Checkmark24 />}
    </StyledCheckbox>
  );
  return (
    <Container>
      <CheckboxContainer className={className}>{component}</CheckboxContainer>
      <Label>{labelText}</Label>
    </Container>
  );
};

export default CheckBoxFormField;
