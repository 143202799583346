import {
  Circle,
  DrawingManager,
  OverlayView,
  Polygon,
  useJsApiLoader,
} from '@react-google-maps/api';
import { noop } from 'lodash';
import Tooltip from 'rc-tooltip';
import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { googleMapsOptions, googleMapsStyles } from '../../config';
import { ContextMenuContainer, theme } from '../../styles';
import {
  GoogleMapCustomMenu,
  GoogleMapCustomMenuIconWrapper,
  GoogleMapSatelliteIcon,
  GoogleMapSelectIcon,
  GoogleMapShapeDisplayIcon,
  ShapeDisplayInfo,
} from './styles';

type GoogleMapShape = google.maps.Rectangle | google.maps.Circle;

const GoogleMapsLoaderContext = createContext<{
  isLoaded: boolean;
  loadError?: Error;
}>({
  isLoaded: false,
  loadError: undefined,
});

const GoogleMapsLoaderProvider: React.FC = ({ children }) => {
  const values = useMemo<{
    googleMapsApiKey: string;
    libraries: (
      | 'geometry'
      | 'drawing'
      | 'places'
      | 'localContext'
      | 'visualization'
    )[];
    region: string;
  }>(() => {
    return {
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API || '',
      libraries: ['geometry', 'drawing', 'places'],
      region: 'za',
    };
  }, []);
  const { isLoaded, loadError } = useJsApiLoader(values);
  const value = useMemo(() => ({ isLoaded, loadError }), [isLoaded, loadError]);
  return (
    <GoogleMapsLoaderContext.Provider value={value}>
      {children}
    </GoogleMapsLoaderContext.Provider>
  );
};

const useGoogleMapsLoader = () => useContext(GoogleMapsLoaderContext);

const useBoundMap = (map?: google.maps.Map, points = []) => {
  const pointJSON = JSON.stringify(points);

  const fitBounds = useCallback(() => {
    const pointArray = JSON.parse(pointJSON);
    if (map && pointArray.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      for (const point of pointArray) {
        bounds.extend(point);
      }
      map.fitBounds(bounds);
    }
  }, [map, pointJSON]);

  useEffect(fitBounds, [fitBounds]);

  return fitBounds;
};

const useGoogleMapsDrawingManager = ({
  drawingMode = google.maps.drawing.OverlayType.RECTANGLE,
  onBoundsChanged = noop,
  onClick = noop,
  onContextMenu = noop,
  onCreateShape = noop,
  onDrag = noop,
  onDragEnd = noop,
  onDragStart = noop,
  onReset = noop,
  shouldReset = false,
  tooltip = 'Selection Mode',
} = {}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [poly, setPoly] = useState<GoogleMapShape | undefined>();
  const [drawingManager, setDrawingManager] = useState<
    google.maps.drawing.DrawingManager | undefined
  >();

  useEffect(() => {
    if (shouldReset) {
      poly?.setVisible(false);
      onReset();
      setPoly(undefined);
      setIsDrawing(false);
    }
  }, [shouldReset, onReset, poly]);

  const onFinishDrawing = (p: GoogleMapShape) => {
    drawingManager?.setDrawingMode(null);
    p.addListener('bounds_changed', () => onBoundsChanged(p));
    p.addListener('click', () => onClick(p));
    p.addListener('contextmenu', (m: google.maps.MapMouseEvent) =>
      onContextMenu(m, p),
    );
    p.addListener('drag', () => onDrag(p));
    p.addListener('dragend', () => onDragEnd(p));
    p.addListener('dragstart', () => onDragStart(p));
    onCreateShape(p);
    setPoly(p);
  };

  const dmIcon = (
    <Tooltip
      destroyTooltipOnHide
      overlay={<div>{tooltip}</div>}
      placement="bottom"
    >
      <GoogleMapCustomMenuIconWrapper
        isActive={isDrawing}
        onClick={() => {
          poly?.setVisible(false);
          onReset();
          setPoly(undefined);
          setIsDrawing((prev) => !prev);
        }}
      >
        <GoogleMapSelectIcon />
      </GoogleMapCustomMenuIconWrapper>
    </Tooltip>
  );

  const dmComponent = isDrawing && !poly && (
    <DrawingManager
      onLoad={(dm) => !drawingManager && setDrawingManager(dm)}
      options={{
        drawingMode,
        drawingControl: false,
        rectangleOptions: {
          draggable: true,
          editable: true,
          fillColor: theme.colors.lightGrey,
          strokeColor: theme.colors.primary,
          strokeWeight: 1,
          zIndex: 100000,
        },
      }}
      onRectangleComplete={onFinishDrawing}
      onCircleComplete={onFinishDrawing}
    />
  );
  return { poly, dmComponent, dmIcon };
};

interface Point {
  lat: number;
  lng: number;
}

interface MapShapeData {
  markers?: Point[];
  center?: Point;
  radius?: number;
}

interface MapShapeShape {
  type: 'RADIUS' | 'POLYGON';
  data: MapShapeData;
}
interface MapShape {
  color?: string;
  id?: string;
  name?: string;
  reference?: string;
  entrance?: Point;
  shape: MapShapeShape;
}

const useGoogleMapsDisplayShapes = (
  shapeArray: MapShape[] = [],
  tooltip = 'Display Shapes',
  defaultDisplay = false,
) => {
  const [displayShapes, setDisplayShapes] = useState(defaultDisplay);
  const shapesComponent =
    displayShapes &&
    shapeArray.map((shape) => {
      const reference = shape.reference ? ` (${shape.reference})` : '';
      const infoDisplay = (
        <ShapeDisplayInfo>
          {shape.name || ''}
          {reference}
        </ShapeDisplayInfo>
      );
      const options = {
        fillColor: shape.color || theme.colors.success,
        strokeColor: shape.color || theme.colors.secondary,
      };
      if (shape.shape.type === 'POLYGON') {
        return (
          <Fragment key={shape.id}>
            <Polygon paths={shape.shape.data.markers} options={options} />
            <OverlayView
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              position={shape.entrance || shape.shape.data.markers![0]}
            >
              {infoDisplay}
            </OverlayView>
          </Fragment>
        );
      }
      return (
        <Fragment key={shape.id}>
          <Circle
            center={shape.shape.data.center!}
            radius={shape.shape.data.radius!}
            options={options}
          />
          <OverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={shape.shape.data.center}
          >
            {infoDisplay}
          </OverlayView>
        </Fragment>
      );
    });

  const shapeDisplayIcon = (
    <Tooltip
      destroyTooltipOnHide
      overlay={<div>{tooltip}</div>}
      placement="bottom"
    >
      <GoogleMapCustomMenuIconWrapper
        isActive={displayShapes}
        onClick={() => {
          setDisplayShapes((prev) => !prev);
        }}
      >
        <GoogleMapShapeDisplayIcon />
      </GoogleMapCustomMenuIconWrapper>
    </Tooltip>
  );
  return { setDisplayShapes, displayShapes, shapesComponent, shapeDisplayIcon };
};

const GoogleMapSatelliteSelect: React.FC<{ map: google.maps.Map }> = ({
  map,
}) => {
  const [isSatellite, setIsSatellite] = useState(false);
  return (
    <Tooltip
      destroyTooltipOnHide
      overlay={<div>Satellite View</div>}
      placement="bottom"
    >
      <GoogleMapCustomMenuIconWrapper
        isActive={isSatellite}
        onClick={() => {
          setIsSatellite((p) => {
            map.setMapTypeId(
              google.maps.MapTypeId[p ? 'ROADMAP' : 'SATELLITE'],
            );
            return !p;
          });
        }}
      >
        <GoogleMapSatelliteIcon />
      </GoogleMapCustomMenuIconWrapper>
    </Tooltip>
  );
};
const getMapBounds = (map?: google.maps.Map) => {
  const latLng = map?.getBounds();
  if (latLng) {
    const neLatLng = latLng.getNorthEast();
    const swLatLng = latLng.getSouthWest();

    const ne = {
      lat: neLatLng.lat(),
      lng: neLatLng.lng(),
    };
    const sw = {
      lat: swLatLng.lat(),
      lng: swLatLng.lng(),
    };
    const se = {
      lat: sw.lat,
      lng: ne.lng,
    };
    const nw = {
      lat: ne.lat,
      lng: sw.lng,
    };

    return [ne, se, sw, nw];
  }
  return [];
};

const getPathFromPolygon = (poly: google.maps.Polygon) =>
  poly
    ? poly
        .getPath()
        .getArray()
        .map((latlng) => latlng.toJSON())
    : [];

const getDefaultGoogleMapsContextOptions = (position?: {
  lat: number;
  lng: number;
}) => (
  <Fragment>
    <div>
      <a
        href={`https://www.google.com/maps?q&layer=c&cbll=${
          position?.lat || 0
        },${position?.lng || 0}&cbp=8,0,0,0,0`}
        target="_blank"
        rel="noreferrer"
      >
        Street View
      </a>
    </div>
  </Fragment>
);

const GoogleMapsContextMenu: React.FC<
  React.PropsWithChildren<{
    position: { lat: number; lng: number };
    onContextMenuClick: () => void;
  }>
> = ({ position, onContextMenuClick, children }) => {
  if (position) {
    return (
      <OverlayView
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        position={position}
      >
        <ContextMenuContainer onClick={onContextMenuClick}>
          {children}
          {getDefaultGoogleMapsContextOptions(position)}
        </ContextMenuContainer>
      </OverlayView>
    );
  }
  return null;
};

const getGoogleMapsDefaultOptions = (center: { lat: number; lng: number }) => ({
  options: { ...googleMapsOptions, styles: googleMapsStyles.grayscale },
  mapContainerStyle: {
    width: '100%',
    height: '100%',
    position: 'relative',
    zIndex: '0',
  },
  zoom: 16,
  center,
  tilt: 0,
});

export {
  getDefaultGoogleMapsContextOptions,
  getGoogleMapsDefaultOptions,
  getMapBounds,
  getPathFromPolygon,
  GoogleMapCustomMenu,
  GoogleMapsContextMenu,
  GoogleMapsLoaderContext,
  GoogleMapsLoaderProvider,
  useBoundMap,
  useGoogleMapsDisplayShapes,
  useGoogleMapsDrawingManager,
  useGoogleMapsLoader,
  GoogleMapSatelliteSelect,
};
