import { gql } from '@apollo/client';

const UPDATE_LOAD_CONTACT = gql`
  mutation updateLoadContact($input: LoadContactUpdateInput!, $token: String) {
    updateLoadContact(input: $input, token: $token) {
      id
    }
  }
`;

const GET_CONTACT = gql`
  query getContact($id: ID!, $token: String) {
    getLoadContact(id: $id, token: $token) {
      id
      firstName
      lastName
      email
      mobile
      comsEvents {
        event
        email
        sms
      }
      orgId
      position
    }
  }
`;

export { UPDATE_LOAD_CONTACT, GET_CONTACT };
