import { gql } from '@apollo/client';

const GET_CARRIER = gql`
  query getCarrier($id: ID!) {
    getCarrier(id: $id) {
      id
      name
      vatNumber
      masterCarrier {
        id
      }
      isMasterCarrierLinkEnabled
    }
  }
`;

const GET_MASTER_CARRIERS = gql`
  query getOrgsPaged {
    getOrgsPaged(
      pagination: { take: 1000000, skip: 0 }
      query: { hasCarrierPortal: true }
      order: [["name", "ASC"]]
    ) {
      data {
        id
        name
      }
    }
  }
`;

const LINK_MASTER_CARRIER = gql`
  mutation linkMasterCarrier($input: CarrierMasterLinkInput!) {
    linkMasterCarrier(input: $input)
  }
`;

export { GET_CARRIER, LINK_MASTER_CARRIER, GET_MASTER_CARRIERS };
