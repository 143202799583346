import { useMutation, useQuery } from '@apollo/client';
import { Form as FormikForm, Formik } from 'formik';
import React, { useContext } from 'react';
import {
  ActionButtons,
  appsArray,
  Dropdown,
  FormArea,
  FormWrapper,
  listSortAndFormat,
  LoadingContext,
  MultiSelect,
  PageError,
  PageLoading,
  TableDynamicInput,
} from '../../../../../../../';
import { mapValuesForHandling } from './functions';
import {
  GET_ALL_SITES,
  MANAGE_OPERATOR_SITES,
  MANAGE_OPERATOR_SITE_APPS,
} from './gql';
import validationSchema from './validationSchema';

const Admin = ({
  outcomeObject,
  data: { sites, id },
  setCurrentFocusIndex,
  childIndex,
}) => {
  const { setLoading } = useContext(LoadingContext);

  const { data, error } = useQuery(GET_ALL_SITES);
  const [manageOperatorsApps] = useMutation(MANAGE_OPERATOR_SITE_APPS);
  const [manageOperatorSites] = useMutation(MANAGE_OPERATOR_SITES);

  const handleSubmit = async (mutationData) => {
    const { appsHaveChanged, sitesToAdd, sitesToRemove } = mutationData;
    if (sitesToAdd.length || sitesToRemove.length) {
      const add = sitesToAdd.length
        ? sitesToAdd.map(({ site, apps }) => ({
            apps,
            siteId: site,
          }))
        : [];
      const remove = sitesToRemove;
      await manageOperatorSites({
        variables: {
          input: {
            id,
            add,
            remove,
          },
        },
      });
    }
    if (appsHaveChanged.length) {
      const promises = appsHaveChanged.map(({ site, apps }) =>
        manageOperatorsApps({
          variables: { input: { operatorId: id, siteId: site, apps } },
        }),
      );
      await Promise.all(promises);
    }
  };

  if (error) {
    return <PageError error={error} />;
  }

  if (data) {
    const { getOrgs: orgs, getSites: siteList } = data;
    const allSites = listSortAndFormat(
      siteList,
      (s) =>
        `${orgs.find((o) => o.id === s.orgId)?.name || 'Site'} - ${s.name}`,
      'id',
    );
    const initialValues = {
      sites: sites.map(({ site, apps }) => ({ site: site.id, apps })),
    };
    return (
      <Formik
        validateOnMount
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          const mutationData = mapValuesForHandling(initialValues, values);

          setLoading(true);
          handleSubmit(mutationData)
            .then(outcomeObject.onCompleted)
            .catch(outcomeObject.onError)
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {({ values }) => {
          return (
            <FormWrapper>
              <FormikForm>
                <FormArea>
                  <TableDynamicInput
                    newRow={{
                      site: '',
                      apps: [],
                    }}
                    name="sites"
                    rows={values.sites}
                    columns={[
                      {
                        key: 'site',
                        header: 'Site',
                        Component: Dropdown,
                        componentProps: {
                          options: allSites,
                          searchOnly: true,
                          light: true,
                          id: 'id',
                          display: 'name',
                          noErrors: true,
                        },
                        dependsOnSelf: true,
                        dependsOnFunction: (_, thisValue) => {
                          return {
                            options: allSites.filter(
                              (e) =>
                                !values.sites
                                  .map((s) => s.site)
                                  .includes(e.value) || e.value === thisValue,
                            ),
                          };
                        },
                      },
                      {
                        key: 'apps',
                        width: '40%',
                        center: true,
                        header: 'Permissions',
                        Component: MultiSelect,
                        componentProps: {
                          options: appsArray,
                          placeholder: 'Select Permissions',
                          noMoreItemsText: 'No More Permissions',
                          label: 'Permissions',
                        },
                      },
                    ]}
                    actionButtonText="Add Permission"
                    headerText="Permissions"
                  />
                </FormArea>
              </FormikForm>
              <ActionButtons type="finalStep" primaryName="Save Changes" />
            </FormWrapper>
          );
        }}
      </Formik>
    );
  }

  return <PageLoading />;
};

export default Admin;
