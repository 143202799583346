import { useContext } from 'react';
import { LoadingContext } from '../context';
import { getS3Object } from '../utils';
import useAlerts from './useAlerts';

const useDownloadS3File = (
  filePath,
  fileName,
  isDirectLink = false,
  bucket = undefined,
) => {
  const { setLoading } = useContext(LoadingContext);
  const { alertError } = useAlerts();
  const downloadS3File = async (name, path) => {
    await setLoading(true);
    const a = document.createElement('a');
    try {
      if (!isDirectLink) {
        const file = await getS3Object(path || filePath, false, bucket);
        await setLoading(false);
        const { Body, ContentType } = file;
        a.setAttribute(
          'download',
          `${name || fileName}.${
            ContentType ? ContentType.split('/')[1] : 'pdf'
          }`,
        );
        a.setAttribute('href', URL.createObjectURL(new Blob([Body])));
      } else {
        a.setAttribute('href', path || filePath);
      }
      a.click();
    } catch (e) {
      alertError(e.message);
    } finally {
      setLoading(false);
      a.remove();
    }
  };
  return downloadS3File;
};

export default useDownloadS3File;
