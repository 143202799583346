import React from 'react';
import styled from 'styled-components';
import { formatPercent, formatTimeFromSeconds } from '../../functions';
import {
  Cab,
  Line1,
  Line2,
  Line3,
  OverflowContainer,
  Road,
  RoadLine,
  Truck,
  TruckContainer,
  Wheel1,
  Wheel2,
  Smoke,
  ProgressBarContainer,
  ProgressBarDetails,
  ProgressBarMax,
  ProgressBar as ProgressBarProgress,
} from './styles';

const Overlay = styled.div`
  background: rgba(255, 255, 255, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000000000;
`;

const BG = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ fillScreen }) => (fillScreen ? '100vw' : '100%')};
  height: ${({ fillScreen }) => (fillScreen ? '100vh' : '100%')};
`;

const TruckMobile = () => (
  <TruckContainer>
    <OverflowContainer>
      <Smoke />
      <Line1 />
      <Line2 />
      <Line3 />
      <Truck>
        <Wheel1 />
        <Wheel2 />
      </Truck>
      <Cab />
      <Road />
      <RoadLine />
    </OverflowContainer>
  </TruckContainer>
);

const ProgressBar = ({
  progress = {
    items: 0,
    totalItems: 0,
    timeRemainingInSeconds: 0,
    startup: false,
  },
}) => {
  const { items, totalItems, timeRemainingInSeconds, startup } = progress;
  const perc = formatPercent(items, totalItems);
  const time = formatTimeFromSeconds(timeRemainingInSeconds, false, true, true);
  return (
    <ProgressBarContainer>
      {startup ? (
        'Initialising...'
      ) : (
        <ProgressBarDetails>
          <div>{`${items} / ${totalItems} (${perc}%)`}</div>
          <div>{`${time} remaining`}</div>
        </ProgressBarDetails>
      )}
      <ProgressBarMax>
        <ProgressBarProgress width={perc} startup={startup} />
      </ProgressBarMax>
    </ProgressBarContainer>
  );
};

const PageLoading = ({
  overlay = false,
  fillScreen = false,
  progress = null,
}) => {
  const component = progress ? (
    <ProgressBar progress={progress} />
  ) : (
    <TruckMobile />
  );
  return overlay ? (
    <Overlay>{component}</Overlay>
  ) : (
    <BG fillScreen={fillScreen}>{component}</BG>
  );
};

export default PageLoading;
