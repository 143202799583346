import * as yup from 'yup';

const formikSchema = {
  initialValues: {
    otp: '',
  },
  validationSchema: yup.object({
    otp: yup.string().length(6).required(),
  }),
};

export default formikSchema;
