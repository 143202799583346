import { lighten } from 'polished';

const theme = {
  dashboardColors: [
    '#00AFF0',
    '#E9343C',
    '#3957D0',
    '#141B4D',
    '#0CD371',
    '#600C62',
    '#8773CD',
    '#F75295',
    '#FF8218',
    '#FFDB40',
    '#31C0C5',
  ],
  colors: {
    modules: {
      admin: '#00AFF0',
      crm: '#d9effc',
      gatekeeper: '#33D342',
      fuel: '#D4145A',
      link: '#FF871A',
      link_retail: lighten(0.25, '#FF871A'),
      live: lighten(0.25, '#802E53'),
      fleet: '#00A99D',
      loads: '#FAC70E',
      routes: '#852e8d',
      vortex: '#AAA',
    },
    teamJobStatuses: {
      Loaded: '#31bfc6',
      Ready: '#f65296',
      Scheduled: '#8f2793',
      Closed: '#9a9a9a',
      Complete: '#62bf41',
    },
    avtStatuses: {
      Processed: '#0CD371',
      CTC: '#600C62',
      Failed: '#E9343C',
      New: '#FFDB40',
      Created: '#00AFF0',
      Validated: '#3957D0',
    },
    linkOutboundStatuses: {
      PLANNED: '#00AFF0',
      ACTIVE: '#31C0C5',
      WAITING: '#FF8218',
      LOADING: '#F75295',
      LOADED: '#902693',
      COMPLETE: '#0CD371',
      CLOSED: '#141B4D',
      CANCELLED: '#9A9A9A',
      FLUSHED: '#E62700',
      NEW: '#9A9A9A',
      IN_STAGING: '#333333',
      OUT_STAGING: '#333333',
    },
    linkInboundStatuses: {
      // will need to decide on the below three colors...just added for the meantime
      WAITING_RETURNS: '#141B4D',
      WAITING_EXECUTING: '#FFDB40',
      WAITING_SORTING: '#FF8218',
      PLANNED: '#00AFF0',
      ON_SITE: '#FFDB40',
      SORTING: '#600C62',
      EXECUTING: '#F75295',
      RETURNS: '#31C0C5',
      WAITING: '#FF8218',
      COMPLETED: '#0CD371',
      CLOSED: '#141B4D',
      FLUSHED: '#E62700',
      CANCELLED: '#9A9A9A',
      MISSED: '#9A9A9A',
      IN_STAGING: '#333333',
      OUT_STAGING: '#333333',
    },
    loads: {
      PICKUP: '#FF8218',
      DROPOFF: '#63BF42',
      violations: {
        ROUTING_EXCESS_STOP: '#E62700',
        ROUTING_UNATTENDED_STOP: '#FF8218',
        ROUTING_UNKNOWN_STOP: '#F9E17A',
        ROUTING_UNPLANNED_STOP: '#27ace3',
        SPEEDING: 'red',
        EXCESS_IDLE: 'red',
      },
      statuses: {
        NEW: '#9A9A9A',
        REQUESTED: '#00AFF0',
        CONFIRMED: '#31C0C5',
        DECLINED: '#9A9A9A',
        ASSIGNED: '#FFDB40',
        SCHEDULED: '#FFDB40',
        ACCEPTED: '#FFDB40',
        ACTIVE: '#F75295',
        FORCE_COMPLETED: '#0CD371',
        COMPLETED: '#0CD371',
        CLOSED: '#141B4D',
        CANCELLED: '#9A9A9A',
        REJECTED: '#9A9A9A',
      },
    },
    customerPortalStatuses: {
      Drops: '#FFFFFF',
      Allocated: '#00AFF0',
      Unallocated: '#525252',
      Unactioned: '#FF8218',
      Scheduled: '#feda50',
      Active: '#8f2b91',
      Complete: '#a7e7ad',
      Failed: '#E62700',
    },
    secondary: '#1C1D4C',
    primary: '#00AFF0',
    tmSecondary: '#1C1D4C',
    tmPrimary: '#00AFF0',
    buttonSecondary: '#393939',
    success: '#63BF42',
    danger: '#E62700',
    warning: '#FF8218',
    broken: '#D8D8D8',
    inputBottom: '#8D8D8D',
    white: '#FFFFFF',
    disabled: '#D8D8D8',
    black: '#000000',
    darkGrey: '#525252',
    mediumGrey: '#8D8D8D',
    lightGrey: '#D8D8D8',
    lighterGrey: '#F5F5F5',
    inputText: '#161616',
    purple: '#802E53',
    yellow: '#F9E17A',
    lightPink: '#feddea',
  },
  formColumnWidth: '24rem',
  headerHeight: '48px',
  dashboardHeaderHeight: '24px',
};

export default theme;
