import React from 'react';
import styled from 'styled-components';
import { marker } from '../../../';

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
`;

const Subtext = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.1rem;
  margin: 26px 0 0 4px;
  font-weight: 200;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 2rem;
  margin: 26px 0 0 4px;
  font-weight: 500;
`;

const Banner = ({ text, subtext }) => (
  <Container>
    <Logo src={marker} />
    <Text>{text}</Text>
    {subtext && <Subtext>{subtext}</Subtext>}
  </Container>
);

export default Banner;
