import { noop } from 'lodash';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  InlineNotification,
  localStorageKeys,
  setCustomerPortalAuthToken,
} from '../../../';
import ForgotPasswordForm from './ForgotPasswordForm';
import { loggedIn } from './functions';
import LoginForm from './LoginForm';
import MFAForm from './MFAForm';
import SetPasswordForm from './SetPasswordForm';
import { Container, LoginContainer, POPI } from './styles';

const LoginProcess = withRouter(
  ({ children, location, onLogin = noop, portal }) => {
    const sessionExpireNotification = (
      <InlineNotification
        kind="error"
        title="Session Expired"
        subtitle="Please Login Again"
      />
    );
    const urlParams = new URLSearchParams(
      location.search?.replace(/\+/, '%2B'),
    );
    const [notify, setNotify] = useState();

    const alertEndSession = () => setNotify(sessionExpireNotification);

    const [resetPassword, updateResetPassword] = useState(false);
    const [assertMFA, setAssertMFA] = useState(false);
    const [session, updateSession] = useState(null);
    const [refuseEntry, considerRefuseEntry] = useState(
      !loggedIn(alertEndSession),
    );
    const [username, updateUsername] = useState(urlParams?.get('email') || '');
    const yardToken =
      location.search && new URLSearchParams(location.search).get('yardToken');
    const redirect = (idToken) => {
      localStorage.setItem(localStorageKeys.idToken, idToken);
      setCustomerPortalAuthToken(idToken);
      considerRefuseEntry(false);
      onLogin(idToken);
    };
    if (notify) {
      setTimeout(() => {
        setNotify(null);
      }, 3000);
    }
    setInterval(() => {
      considerRefuseEntry(!loggedIn(alertEndSession));
    }, 10000);
    if (refuseEntry) {
      return (
        <Container>
          <POPI>
            Trackmatic endeavours to comply with the requirements of the POPIA
            act (s11.1.a and 11.2.a). Copies of both the Trackmatic Privacy
            Policy and PAIA Manual are available on request.
          </POPI>
          {notify}
          <LoginContainer>
            {resetPassword ? (
              <ForgotPasswordForm
                username={username}
                updateResetPassword={updateResetPassword}
                setNotify={setNotify}
              />
            ) : assertMFA ? (
              <MFAForm
                username={username}
                setNotify={setNotify}
                closeModalAndRedirect={redirect}
              />
            ) : !session ? (
              <LoginForm
                setAssertMFA={setAssertMFA}
                updateSession={updateSession}
                updateUsername={updateUsername}
                closeModalAndRedirect={redirect}
                updateResetPassword={updateResetPassword}
                setNotify={setNotify}
                yardToken={yardToken}
                username={username}
                portal={portal}
              />
            ) : (
              <SetPasswordForm
                session={session}
                username={username}
                closeModalAndRedirect={redirect}
                setNotify={setNotify}
                setAssertMFA={setAssertMFA}
              />
            )}
          </LoginContainer>
        </Container>
      );
    }
    return <div>{children}</div>;
  },
);

export default LoginProcess;
