import React, { useEffect, useRef } from 'react';

const HtmlPreview = ({ html }) => {
  const iframeRef = useRef();

  useEffect(() => {
    const iframeDoc =
      iframeRef.current?.contentDocument ||
      iframeRef.current?.contentWindow?.document;
    iframeDoc.body.innerHTML = html;
  }, [html]);

  return (
    <iframe
      style={{ width: '100%', height: '100%' }}
      ref={iframeRef}
      title="HTML EDITOR"
    />
  );
};

export default HtmlPreview;
