import TrashCan from '@carbon/icons-react/lib/trash-can/24';
import { Field, FieldArray, Form, useFormikContext } from 'formik';
import { noop } from 'lodash';
import React, { Fragment } from 'react';
import { Table, Th } from '../../styles';
import { ActionButton, ActionRow, Delete } from './styles';

const TableDynamicInput = ({
  noDelete,
  noAdd,
  name,
  columns,
  rows,
  newRow,
  actionButtonText,
  headerText,
  style,
  onClick,
  disableNewRow = false,
  initRows = [],
  noDeleteRow = noop,
}) => {
  const cannotDelete = noDelete || (noAdd && rows && rows.length === 1);
  const { getFieldMeta } = useFormikContext();
  return (
    <Form style={{ ...style, height: '100%' }}>
      <FieldArray
        name={name}
        render={({ push, remove }) => (
          <Fragment>
            <ActionRow>
              <p>{headerText}</p>
              {!noAdd && (
                <ActionButton
                  type="button"
                  onClick={onClick || (() => push(newRow))}
                  disabled={disableNewRow}
                >
                  {actionButtonText}
                </ActionButton>
              )}
            </ActionRow>
            <Table>
              <colgroup>
                {columns.map(({ key, width }) => (
                  <col key={key} width={width ? `${width}%` : 'auto'} />
                ))}
                {!cannotDelete && <col width="5%" />}
              </colgroup>
              <thead>
                <tr>
                  {columns.map(({ key, header }) => (
                    <Th noHover key={key}>
                      {header}
                    </Th>
                  ))}
                  {!cannotDelete && <Th noHover />}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, idx) => {
                  const cannotDeleteRow = noDeleteRow(row, initRows[idx]);
                  return (
                    <tr key={idx}>
                      {columns.map(
                        ({
                          key,
                          Component,
                          componentProps = {},
                          dependsOn,
                          dependsOnSelf,
                          primaryTo,
                          dependantDisableFunction,
                          primaryDisableFunction,
                          dependsOnFunction,
                          generalDisableFunction,
                          width,
                        }) => {
                          const fieldName = componentProps.fieldName
                            ? componentProps.fieldName(idx)
                            : `${name}[${idx}].${key}`;

                          const dependantValue =
                            primaryTo &&
                            (Array.isArray(primaryTo)
                              ? primaryTo.map(
                                  (v) =>
                                    getFieldMeta(`${name}[${idx}].${v}`).value,
                                )
                              : getFieldMeta(`${name}[${idx}].${primaryTo}`)
                                  .value);
                          const primaryValue =
                            dependsOn &&
                            getFieldMeta(`${name}[${idx}].${dependsOn}`).value;
                          const thisValue = getFieldMeta(
                            `${name}[${idx}].${key}`,
                          ).value;
                          return (
                            <td key={fieldName} style={{ width }}>
                              <Field
                                name={fieldName}
                                component={Component}
                                noBorders
                                {...componentProps}
                                dataRowFromTable={{ ...row, idx }}
                                {...((primaryValue || dependsOnSelf) &&
                                  dependsOnFunction(primaryValue, thisValue))}
                                disabled={
                                  dependantValue
                                    ? primaryDisableFunction(dependantValue)
                                    : dependantDisableFunction
                                    ? dependantDisableFunction(primaryValue)
                                    : generalDisableFunction
                                    ? generalDisableFunction(
                                        getFieldMeta(fieldName).value,
                                      )
                                    : componentProps.disabled
                                }
                              />
                            </td>
                          );
                        },
                      )}
                      {!cannotDelete && (
                        <td>
                          {!cannotDeleteRow && (
                            <Delete onClick={() => remove(idx)}>
                              &nbsp;
                              <TrashCan /> &nbsp;
                            </Delete>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Fragment>
        )}
      />
    </Form>
  );
};

export default TableDynamicInput;
