import { theme } from '..';

const formatNumber = (value) => {
  if (typeof value === 'string') {
    const number = parseFloat(value);
    if (typeof number === 'number' && number) {
      return Intl.NumberFormat('en-US').format(number);
    }
    return value;
  }
  if (typeof value === 'number') {
    return Intl.NumberFormat('en-US').format(value);
  }
  return value;
};

const formatPercent = (numerator, denominator) =>
  denominator === 0 ? 0 : Math.round((numerator / denominator) * 100, 0);

const getPercentageColor = (percent) =>
  theme.colors[percent < 40 ? 'danger' : percent < 80 ? 'warning' : 'success'];

const formatCurrency = (value) => {
  if (typeof value === 'string') {
    const number = parseFloat(value);
    if (typeof number === 'number' && number) {
      return new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
      }).format(number);
    }
    return value;
  }
  if (typeof value === 'number') {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
    }).format(value);
  }
  return value;
};

export { formatNumber, formatPercent, formatCurrency, getPercentageColor };
