/* eslint-disable no-param-reassign */
import { useMutation, useQuery } from '@apollo/client';
import { dissoc } from 'ramda';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
  getURLParam,
  PageError,
  PageHeading,
  PageLoading,
  Tab,
  Tabs,
  TopBar,
  useAlerts,
  useLoading,
} from '../../../../';
import AssetDetails from './AssetDetails';
import AssetLicences from './AssetLicences';
import AssetPermits from './AssetPermits';
import AssignedAssetGroups from './AssignedAssetGroups';
import AssignedSites from './AssignedSites';
import Consumption from './Consumption';
import Dimension from './Dimension';
import {
  ALLOCATE_ASSET_GROUPS,
  ALLOCATE_SITES,
  GET_ASSET,
  UPDATE_ASSET,
} from './gql';
import TPSEventStreamList from './TPSEventSteamList';
import TyreTransactions from './TyreTransactions';

const EditAsset = withRouter(
  ({
    match,
    currentEntity = {},
    linkParams = {},
    tyreTransactionRoute = '',
    currentUser = {},
  }) => {
    const assetId = getURLParam(match, 'assetId');
    const { alertSuccess, onError } = useAlerts();
    const {
      data,
      error,
      refetch: refetchAsset,
    } = useQuery(GET_ASSET, {
      variables: { id: assetId },
    });
    const onCompleted = () => {
      alertSuccess('Your asset has successfully been updated');
    };

    const [
      editAssetMutation,
      { loading: assetLoading, data: updatedAssetData },
    ] = useMutation(UPDATE_ASSET, {
      onCompleted,
      onError,
    });
    const [
      manageSitesMutation,
      { loading: sitesLoading, data: updatedAssetSiteData },
    ] = useMutation(ALLOCATE_SITES, {
      onCompleted,
      onError,
    });
    const [
      manageAssetGroupsMutation,
      { loading: assetGroupsLoading, data: updatedAssetGroupData },
    ] = useMutation(ALLOCATE_ASSET_GROUPS, {
      onCompleted,
      onError,
    });
    useLoading(sitesLoading || assetLoading || assetGroupsLoading);
    if (error) {
      return <PageError error={error} />;
    }
    if (data) {
      const assetData = dissoc('org', data.getAsset);
      return (
        <Fragment>
          <TopBar>
            <PageHeading
              heading={`${currentEntity.name} Assets`}
              description={`Edit Asset`}
            />
          </TopBar>
          <Tabs style={{ marginBottom: '0.5rem' }}>
            <Tab label="Details">
              <AssetDetails
                availableAssetClasses={data.getAsset.org.assetClasses}
                assetId={assetId}
                editAssetMutation={editAssetMutation}
                data={assetData}
                updatedData={updatedAssetData}
                currentUser={currentUser}
              />
            </Tab>
            <Tab label="Dimensions">
              <Dimension
                updatedData={updatedAssetData}
                assetId={assetId}
                availableAssetClasses={data.getAsset.org.assetClasses}
                editAssetMutation={editAssetMutation}
                data={assetData}
              />
            </Tab>
            {currentEntity.type !== 'site' && (
              <Tab label="Sites">
                <AssignedSites
                  id={assetId}
                  manageSitesMutation={manageSitesMutation}
                  allSites={data.getAsset.org.sites}
                  chosenSites={
                    updatedAssetSiteData
                      ? updatedAssetSiteData.manageAssetSites.sites
                      : data.getAsset.sites.filter(Boolean)
                  }
                />
              </Tab>
            )}
            <Tab label="Licences">
              <AssetLicences data={data.getAsset.licences} />
            </Tab>
            <Tab label="Permits">
              <AssetPermits
                data={data.getAsset.fleetPermits.data}
                refetchAsset={refetchAsset}
              />
            </Tab>
            <Tab label="Assigned Asset Groups">
              <AssignedAssetGroups
                id={assetId}
                manageAssetGroupsMutation={manageAssetGroupsMutation}
                allAssetGroups={data.getAsset.org.assetGroupsPaged.data}
                chosenAssetGroups={
                  updatedAssetGroupData
                    ? updatedAssetGroupData.manageAssetGroups.assetGroups
                    : data.getAsset.assetGroups
                }
              />
            </Tab>
            <Tab label="Consumption">
              <Consumption
                updatedData={updatedAssetData}
                assetId={assetId}
                editAssetMutation={editAssetMutation}
                data={assetData}
              />
            </Tab>
            <Tab label="Tyres">
              <TyreTransactions
                data={data.getAsset.tyreTransactions}
                route={tyreTransactionRoute}
                linkParams={linkParams}
              />
            </Tab>
            <Tab label="TPS">
              <TPSEventStreamList id={assetId} />
            </Tab>
            {/* <Tab label="Assigned Access Groups">
            <AssignedAccessGroups
              editAssetMutation={editAssetMutation}
              data={data.getAsset}
            />
          </Tab>
          <Tab label="Assigned Tags">
            <AssignedTags
              editAssetMutation={editAssetMutation}
              data={data.getAsset}
            />
          </Tab> */}
          </Tabs>
        </Fragment>
      );
    }
    return <PageLoading />;
  },
);

export { EditAsset as default, TyreTransactions };
