import { useLazyQuery, useMutation } from '@apollo/client';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Field, Form, Formik } from 'formik';
import React, { Fragment, useContext, useState } from 'react';
import {
  ActionButtons,
  cognito,
  FormItem,
  InlineNotification,
  LoadingContext,
  SecondaryButton,
  TextFormField,
} from '../../../../';
import Banner from '../Banner';
import { testPasswordStrength } from '../functions';
import { ForgotPassword, FormArea } from '../LoginForm/styles';
import PasswordForm from '../PasswordForm';
import { CHECK_IF_CAN_RESET, SET_NEW_RESET_DATE } from './gql';
import { step1, step2 } from './initialValues';

const SetPasswordForm = ({ username, updateResetPassword, setNotify }) => {
  const reload = () => {
    setTimeout(() => {
      window.location.href = '/';
    }, 2000);
  };

  const onFailure = (err) => {
    setLoading(false);
    setNotify(
      <InlineNotification kind="error" title="Error" subtitle={err.message} />,
    );
  };

  const [checkIfCanReset] = useLazyQuery(CHECK_IF_CAN_RESET);
  const [setNewResetDate] = useMutation(SET_NEW_RESET_DATE, {
    onCompleted: reload,
    onError: onFailure,
  });
  const { setLoading } = useContext(LoadingContext);
  const [auth, updateAuth] = useState('');
  const [user, setUser] = useState(username);
  const [step, updateStep] = useState(1);
  const [cognitoUser, setCognitoUser] = useState();

  const sendPIN = (usr) => {
    usr.forgotPassword({
      onSuccess: () => {
        setLoading(false);
        updateStep(2);
      },
      onFailure,
    });
  };

  const resetPassword = (passwordInput) => {
    setLoading(true);
    try {
      testPasswordStrength(user, passwordInput);
      cognitoUser.confirmPassword(auth, passwordInput, {
        onSuccess: () => {
          setNotify(
            <InlineNotification
              kind="success"
              title="Password Changed"
              subtitle="Your password has been updated"
            />,
          );
          setNewResetDate({ variables: { email: user } });
        },
        onFailure,
      });
    } catch (e) {
      onFailure(e);
    }
  };

  const resetForm = () => {
    setLoading(false);
    updateResetPassword(false);
  };

  const stepSubtext = () =>
    step === 1
      ? 'Enter in your email address to receive a reset code'
      : step === 2
      ? 'Enter in the reset code you received'
      : 'Enter in your new password';

  return (
    <Fragment>
      <Banner text="Password Reset" subtext={stepSubtext()} />
      <FormArea columns="1" fitWidth>
        {step === 1 && (
          <Formik
            initialValues={{ email: user }}
            validationSchema={step1.validationSchema}
            onSubmit={({ email }) => {
              setLoading(true);
              checkIfCanReset({ variables: { email } })
                .then(() => {
                  setUser(email);
                  const cgUser = new CognitoUser({
                    Username: email,
                    Pool: cognito(),
                  });
                  setCognitoUser(cgUser);
                  sendPIN(cgUser);
                })
                .catch((e) => {
                  onFailure(e);
                  setLoading(false);
                });
            }}
          >
            {({ isValid }) => (
              <Fragment>
                <Form>
                  <FormItem>
                    <Field
                      labelText="Email Address"
                      name="email"
                      type="text"
                      component={TextFormField}
                      light
                    />
                  </FormItem>
                  <div style={{ marginBottom: '150px' }} />
                </Form>
                <ActionButtons
                  ignoreDirty
                  disabled={!isValid}
                  fillWidth
                  primaryName="Send Reset Code"
                />
              </Fragment>
            )}
          </Formik>
        )}
        {step === 2 && (
          <Formik
            initialValues={step2.initialValues}
            validationSchema={step2.validationSchema}
            onSubmit={({ code }) => {
              updateAuth(code);
              updateStep(3);
            }}
          >
            <Fragment>
              <Form>
                <FormItem>
                  <Field
                    placeholder="One-time PIN"
                    name="code"
                    type="text"
                    component={TextFormField}
                    light
                  />
                </FormItem>
                <div style={{ marginBottom: '150px' }} />
              </Form>
              <ActionButtons fillWidth primaryName="Next" />
            </Fragment>
          </Formik>
        )}
        {step === 3 && <PasswordForm onSubmit={resetPassword} />}
        <SecondaryButton fillWidth onClick={resetForm}>
          Cancel
        </SecondaryButton>
        {step === 2 && (
          <ForgotPassword
            onClick={() => {
              setLoading(true);
              sendPIN(cognitoUser);
            }}
          >
            Resend One-Time PIN
          </ForgotPassword>
        )}
      </FormArea>
    </Fragment>
  );
};

export default SetPasswordForm;
