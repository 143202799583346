import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  generateLinkWithParams,
  LoadingContext,
  NotificationsContext,
  SelectRemoveColumns,
} from '../../../../..';
import { ADD_OPERATOR } from './gql';

const AssignPermissions = withRouter(
  ({
    history,
    operatorId,
    returnRoute = '',
    currentEntity = {},
    linkParams = {},
    permissionsList = [],
  }) => {
    const { addInlineAlert } = useContext(NotificationsContext);
    const { setLoading } = useContext(LoadingContext);

    const [assignOperatorToMasterCarrier, { loading }] = useMutation(
      ADD_OPERATOR,
      {
        onCompleted: () => {
          addInlineAlert(
            'success',
            'Operator Assigned Successfully',
            'Your operator has successfully been assigned to this carrier',
          );
          history.push(generateLinkWithParams(returnRoute, linkParams));
        },
        onError: (err) => addInlineAlert('error', 'Error', err.message),
      },
    );

    useEffect(() => {
      setLoading(loading);
    }, [loading, setLoading]);

    return (
      <SelectRemoveColumns
        leftItems={permissionsList}
        rightItems={[]}
        instruction="Assign Permissions"
        leftHeading="Available Permissions"
        rightHeading="Assigned Permissions"
        actionHandler={(items) => {
          const variables = {
            input: {
              operatorId: operatorId,
              orgId: currentEntity.id,
              apps: items.map((i) => i.value),
            },
          };
          assignOperatorToMasterCarrier({ variables });
        }}
      />
    );
  },
);

export default AssignPermissions;
