import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const POPI = styled.div`
  position: fixed;
  text-align: center;
  left: 50%;
  bottom: 5px;
  width: 35%;
  min-width: 300px;
  max-width: 450px;
  transform: translate(-50%, 0);
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.mediumGrey};
`;

const LoginContainer = styled.div`
  padding: 1.5rem;
  width: 35%;
  min-width: 300px;
  max-width: 450px;
  box-shadow: 0 0 5px 0 ${({ theme }) => theme.colors.mediumGrey};
`;

export { Container, LoginContainer, POPI };
