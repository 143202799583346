import { useMutation } from '@apollo/client';
import React from 'react';
import { useAlerts } from '../../../hooks';
import ConfirmModal from '../../ConfirmModal';

const Delete = ({
  row,
  deleteRow,
  close,
  refetch,
  deleteHeader = 'DELETE ROW',
  deleteDescription = 'Are you Sure?',
}) => {
  const { alertSuccess, onError } = useAlerts();
  const [deleteRowMutation, { loading }] = useMutation(deleteRow, {
    onCompleted: () => {
      alertSuccess(`Row has been deleted.`);
      refetch();
      close();
    },
    onError: (err) => {
      onError(err);
      close();
    },
  });
  return (
    <ConfirmModal
      danger
      title={
        typeof deleteHeader === 'function' ? deleteHeader(row) : deleteHeader
      }
      subtitle={deleteDescription}
      onCancel={close}
      onConfirm={() => deleteRowMutation({ variables: { id: row.id } })}
      loading={loading}
    />
  );
};

export default Delete;
