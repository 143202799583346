import * as yup from 'yup';

const step1 = {
  validationSchema: yup.object({
    email: yup
      .string()
      .email()
      .required(),
  }),
};
const step2 = {
  initialValues: {
    code: '',
  },
  validationSchema: yup.object({
    code: yup.string().required(),
  }),
};
const step3 = {
  initialValues: {
    pw: '',
  },
  validationSchema: yup.object({
    pw: yup.string().required(),
  }),
};

export { step1, step2, step3 };
