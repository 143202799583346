import { gql } from '@apollo/client';

const GET_CARRIER = gql`
  query getCarrier($id: ID!) {
    getCarrier(id: $id) {
      id
      name
      vatNumber
      reference
      isFuelEnabled
      isDedicated
      sharesAssets
      sharedAssetTypes
    }
  }
`;
const EDIT_CARRIER = gql`
  mutation updateCarrier($input: CarrierUpdateInput!) {
    updateCarrier(input: $input) {
      id
    }
  }
`;
export { GET_CARRIER, EDIT_CARRIER };
