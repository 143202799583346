import {
  AddAlt16,
  CaretLeft16,
  CaretRight16,
  Crop16,
  SubtractAlt16,
} from '@carbon/icons-react';
import {
  axisBottom,
  brushX,
  pointer,
  scaleTime,
  select,
  zoom,
  zoomTransform,
} from 'd3';
import { addMinutes, max, min } from 'date-fns';
import { noop } from 'lodash';
import { curry, flatten } from 'ramda';
import TooltipWrapper from 'rc-tooltip';
import React, { useEffect, useRef, useState } from 'react';
import {
  addDurationStringToDate,
  convertEnumString,
  formatDateTime,
  formatDuration,
  formatNumber,
  formatPercent,
  formatTimeFromSeconds,
  theme,
} from '../../../';
import {
  DOMAIN_INDICATOR_OFFSET,
  MARGIN_BOTTOM,
  MARGIN_TOP,
  ROUND_RECT,
  TOP_BAR,
} from '../config';
import { getStopActivities } from '../functions';
import { Tooltip } from '../Tooltip';
import { getYForTimelineComponent } from './functions';
import {
  TimelineContainer,
  TimelineOption,
  TimelineOptions,
  TimelineTooltipPoint,
} from './styles';

const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState();
  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      setDimensions(entries[0].contentRect);
    });
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);
  return dimensions;
};

const TimelineView = ({
  load,
  setSelectedTrips = noop,
  selectedTrips = [],
  setSelectedActivity = noop,
}) => {
  const svgRef = useRef();
  const getSvg = () => select(svgRef.current);
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);

  const [currentZoomState, setCurrentZoomState] = useState();
  const [tooltipContent, setTooltipContent] = useState();

  const [isBrushMode, setBrushMode] = useState(false);
  const [brushSelection, setBrushSelection] = useState();

  const removeTooltip = () => {
    setTooltipContent();
  };

  const zoomBehaviour = zoom()
    .scaleExtent([0.005, 10])
    .on('zoom', () => {
      if (!isBrushMode) {
        const svg = getSvg();
        const zoomState = zoomTransform(svg.node());
        setCurrentZoomState(zoomState);
      }
    });

  const scaleZoom = (scale) => {
    const svg = getSvg();
    svg.transition().call(zoomBehaviour.scaleBy, scale);
  };

  const handleTripSelect = (isNext = true) => {
    const { trips = [] } = load;
    const tripCount = trips.length;
    const selectedTripCount = selectedTrips.length;
    if (tripCount > 0) {
      if (selectedTripCount !== 1) {
        setSelectedTrips([trips[isNext ? 0 : tripCount - 1]]);
      } else {
        const selectedTripIndex = trips.findIndex(
          (t) => t.id === selectedTrips[0].id,
        );
        const newTripIndex = isNext
          ? selectedTripIndex === tripCount - 1
            ? 0
            : selectedTripIndex + 1
          : selectedTripIndex === 0
          ? tripCount - 1
          : selectedTripIndex - 1;
        setSelectedTrips([trips[newTripIndex]]);
      }
    }
  };

  useEffect(() => {
    const svg = select(svgRef.current);
    if (dimensions) {
      const {
        plannedStart,
        plannedEnd,
        travelPlan: { stops, loading: loadingActivity },
        trips = [],
        assetEvents = [],
        execution,
      } = load;
      const hasExecution = !!execution;
      const hasExecutionEnd = hasExecution && execution.ended;
      const [pStart, pEnd] = [new Date(plannedStart), new Date(plannedEnd)];
      const [aStart, aEnd] = [
        hasExecution && new Date(execution.started),
        hasExecution && new Date(execution.ended || new Date().toISOString()),
      ];
      const scaleStart = min([pStart, aStart].filter(Boolean));
      const scaleEnd = max([pEnd, aEnd].filter(Boolean));
      const { width, height } = dimensions;
      const getY = curry(getYForTimelineComponent)(height);
      const xScale = scaleTime()
        .domain([addMinutes(scaleStart, -30), addMinutes(scaleEnd, 30)])
        .range([0, width]);

      if (currentZoomState) {
        const newXScale = currentZoomState.rescaleX(xScale);
        xScale.domain(newXScale.domain());
      }

      const xAxis = axisBottom(xScale).tickSize(-(height - MARGIN_TOP));

      svg
        .select('.x-axis')
        .style('transform', `translateY(${height - MARGIN_BOTTOM}px)`)
        .call(xAxis)
        .call((g) => {
          g.select('.domain').remove();
        });

      //zoom
      svg.call(zoomBehaviour);

      svg
        .selectAll('.x-axis .tick line')
        .attr('stroke', theme.colors.lightGrey);

      const plannedTimelineData = [{ pStart, pEnd }];
      const actualTimelineData = [{ aStart, aEnd }];

      //domain start and end

      const currentDomain = xScale.domain();

      svg
        .selectAll('.current-domain')
        .data(currentDomain)
        .join('text')
        .attr('class', 'current-domain')
        .attr(
          'x',
          (d, dIndex) => xScale(d) + (dIndex ? -DOMAIN_INDICATOR_OFFSET : 10),
        )
        .attr('y', 15)
        .attr('fill', theme.colors.white)
        .attr('font-weight', 'bold')
        .text((d) => formatDateTime(d));

      //current mouse point domain

      svg.on('mousemove', function (mouseEvent) {
        const [xOffset] = pointer(mouseEvent, this);
        const mouseDate = xScale.invert(xOffset);
        svg
          .selectAll('.mouse-date-bg')
          .data([mouseDate])
          .join('rect')
          .attr('class', 'mouse-date-bg')
          .attr(
            'x',
            Math.max(
              0,
              Math.min(
                width - DOMAIN_INDICATOR_OFFSET,
                xOffset - DOMAIN_INDICATOR_OFFSET / 2,
              ),
            ),
          )
          .attr('y', 0)
          .attr('height', TOP_BAR)
          .attr('width', DOMAIN_INDICATOR_OFFSET + 10)
          .attr('fill', theme.colors.tmSecondary);

        svg
          .selectAll('.mouse-date')
          .data([mouseDate])
          .join('text')
          .attr('class', 'mouse-date')
          .attr(
            'x',
            Math.max(
              10 + DOMAIN_INDICATOR_OFFSET / 2,
              Math.min(width - DOMAIN_INDICATOR_OFFSET / 2, xOffset),
            ),
          )
          .attr('y', 15)
          .attr('fill', theme.colors.white)
          .attr('font-weight', 'bold')
          .style('text-anchor', 'middle')
          .text((d) => formatDateTime(d));

        svg
          .selectAll('.mouse-date-line')
          .data([mouseDate])
          .join('line')
          .attr('class', 'mouse-date-line')
          .attr('x', xOffset)
          .attr('x1', xOffset)
          .attr('x2', xOffset)
          .attr('y1', TOP_BAR)
          .attr('y2', height)
          .attr('stroke', theme.colors.mediumGrey)
          .lower();
      });

      svg.on('mouseleave', () => {
        svg.selectAll('.mouse-date-bg').remove();
        svg.selectAll('.mouse-date-line').remove();
        svg.selectAll('.mouse-date').remove();
      });

      //assetEvents

      const handleAssetEventTooltip = function (mouseEvent, assetEvent) {
        const [xOffset, yOffset] = pointer(mouseEvent, this);
        const values = [
          {
            header: 'Asset',
            value: assetEvent.assetLicenceNo,
          },
          {
            header: 'At',
            value: formatDateTime(assetEvent.timeCreated),
          },
          {
            header: 'Source',
            value: assetEvent.source,
          },
        ];
        setTooltipContent({
          header: convertEnumString(assetEvent.eventType),
          xOffset,
          yOffset,
          values,
        });
      };

      svg
        .selectAll('.asset-event-indicator-circle')
        .data(assetEvents)
        .join('circle')
        .attr('class', 'asset-event-indicator-circle')
        .attr('cx', (event) => xScale(new Date(event.timeCreated)))
        .attr('r', 2)
        .attr('cy', getY(2))
        .attr('fill', theme.colors.danger)
        .raise()
        .on('mousemove', handleAssetEventTooltip)
        .on('mouseleave', removeTooltip);

      //purple timeline for planned
      svg
        .selectAll('.planned-timeline')
        .data(plannedTimelineData)
        .join('rect')
        .attr('class', 'planned-timeline')
        .attr('x', (x) => xScale(x.pStart))
        .attr('width', (x) => xScale(x.pEnd) - xScale(x.pStart))
        .attr('height', 4)
        .attr('y', getY(1))
        .attr('rx', ROUND_RECT)
        .attr('ry', ROUND_RECT)
        .attr('fill', theme.colors.purple);

      //planned start / end indicators

      const startEndData = [
        [pStart, 'Start'],
        [pEnd, 'End'],
      ];

      const handleStartEndTooltip = function (mouseEvent, se) {
        const [xOffset, yOffset] = pointer(mouseEvent, this);
        setTooltipContent({
          header: `${se[1]} at ${formatDateTime(se[0])}`,
          xOffset,
          yOffset,
        });
      };

      svg
        .selectAll('.start-end-indicator-circle')
        .data(startEndData)
        .join('circle')
        .attr('class', 'start-end-indicator-circle')
        .attr('cx', (d) => xScale(d[0]))
        .attr('r', 8)
        .attr('cy', getY(1))
        .attr('fill', theme.colors.purple)
        .on('mousemove', handleStartEndTooltip)
        .on('mouseleave', removeTooltip);

      svg
        .selectAll('.start-end-indicator-text')
        .data(startEndData)
        .join('text')
        .attr('class', 'start-end-indicator-text')
        .attr('x', (d) => xScale(d[0]))
        .attr('y', getY(1))
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('fill', theme.colors.white)
        .attr('font-weight', 'bold')
        .text((_, stopIndex) => (stopIndex ? 'E' : 'S'))
        .on('mousemove', handleStartEndTooltip)
        .on('mouseleave', removeTooltip);

      //blue timeline for actual
      if (hasExecution) {
        svg
          .selectAll('.actual-timeline')
          .data(actualTimelineData)
          .join('rect')
          .attr('class', 'actual-timeline')
          .attr('x', (x) => xScale(x.aStart))
          .attr('width', (x) => xScale(x.aEnd) - xScale(x.aStart))
          .attr('height', 4)
          .attr('y', getY(7))
          .attr('rx', ROUND_RECT)
          .attr('ry', ROUND_RECT)
          .attr('fill', theme.colors.tmPrimary);

        //actual start / end indicators

        const actualStartEndData = [
          [aStart, 'Start'],
          ...(hasExecutionEnd ? [[aEnd, 'End']] : []),
        ];

        svg
          .selectAll('.actual-start-end-indicator-circle')
          .data(actualStartEndData)
          .join('circle')
          .attr('class', 'actual-start-end-indicator-circle')
          .attr('cx', (d) => xScale(d[0]))
          .attr('r', 8)
          .attr('cy', getY(7))
          .attr('fill', theme.colors.tmPrimary)
          .on('mousemove', handleStartEndTooltip)
          .on('mouseleave', removeTooltip);

        svg
          .selectAll('.actual-start-end-indicator-text')
          .data(actualStartEndData)
          .join('text')
          .attr('class', 'actual-start-end-indicator-text')
          .attr('x', (d) => xScale(d[0]))
          .attr('y', getY(7))
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .attr('fill', theme.colors.white)
          .attr('font-weight', 'bold')
          .text((_, stopIndex) => (stopIndex ? 'E' : 'S'))
          .on('mousemove', handleStartEndTooltip)
          .on('mouseleave', removeTooltip);
      }

      //trips

      const handleTripTooltip = function (mouseEvent, trip) {
        const {
          computedValues: {
            totalDistance,
            drivingTime,
            totalTime,
            idleTime,
            averageSpeed,
            maxSpeed,
          },
          startEvent: { dateTime: start, lat: sLat, lng: sLng },
          endEvent: { dateTime: end, lat: eLat, lng: eLng },
          id,
        } = trip;
        const [driving, idle, total] = [drivingTime, idleTime, totalTime].map(
          (t) => formatTimeFromSeconds(t / 1000),
        );
        const [drivingPercent, idlePercent] = [drivingTime, idleTime].map((t) =>
          formatPercent(t, totalTime),
        );
        const [avgSpeed, topSpeed] = [averageSpeed, maxSpeed].map(
          (s) => `${s.toFixed()} km/h`,
        );

        const [xOffset, yOffset] = pointer(mouseEvent, this);
        const values = [
          {
            header: 'Trip Id',
            value: id,
          },
          {
            header: 'Duration',
            value: total,
          },
          {
            header: 'Distance',
            value: `${formatNumber((totalDistance / 1000).toFixed())} km`,
          },
          {
            header: 'Driving Time',
            value: `${driving} (${drivingPercent}%)`,
          },
          {
            header: 'Idle Time',
            value: `${idle} (${idlePercent}%)`,
          },
          {
            header: 'Average Speed',
            value: avgSpeed,
          },
          {
            header: 'Top Speed',
            value: topSpeed,
          },
          {
            header: 'Start Date',
            value: formatDateTime(start),
          },
          {
            header: 'End Date',
            value: formatDateTime(end),
          },
        ];
        setTooltipContent({
          xOffset,
          yOffset: yOffset - 30,
          values,
          addresses: [
            { lat: sLat, lng: sLng },
            { lat: eLat, lng: eLng },
          ],
        });
      };

      const handleTripClick = function (_, trip) {
        setSelectedTrips((prev) => {
          if (prev.find((t) => t.id === trip.id)) {
            return prev.filter((t) => t.id !== trip.id);
          }
          return [...prev, trip];
        });
      };

      svg
        .selectAll('.trip')
        .data(trips)
        .join('rect')
        .attr('class', 'trip')
        .attr('x', (x) => xScale(new Date(x.startEvent.dateTime)))
        .attr(
          'width',
          (x) =>
            xScale(new Date(x.endEvent.dateTime)) -
            xScale(new Date(x.startEvent.dateTime)),
        )
        .attr('height', 30)
        .attr('y', getY(5))
        .attr('rx', ROUND_RECT)
        .attr('ry', ROUND_RECT)
        .attr(
          'fill',
          (trip) =>
            theme.colors[
              selectedTrips.find((t) => t.id === trip.id)
                ? 'mediumGrey'
                : 'lightGrey'
            ],
        )
        .attr('stroke', theme.colors.mediumGrey)
        .on('mousemove', handleTripTooltip)
        .on('mouseleave', removeTooltip)
        .on('click', handleTripClick);

      //stops

      const handleStopTooltip = function (mouseEvent, stop) {
        const stopActivities = getStopActivities(stop);
        const [xOffset, yOffset] = pointer(mouseEvent, this);
        const {
          location: {
            data: { name: header },
          },
        } = stop;
        const values = [
          {
            header: 'Start',
            value: formatDateTime(stop.plannedStart),
          },
          {
            header: 'End',
            value: formatDateTime(stop.plannedEnd),
          },
          {
            header: 'Duration',
            value: formatDuration(
              stop.plannedStart,
              stop.plannedEnd,
              true,
              false,
              true,
            ),
          },
          {
            header: 'Activities',
            value: stopActivities.length,
          },
        ];
        setTooltipContent({
          header,
          xOffset,
          yOffset,
          values,
        });
      };

      svg
        .selectAll('.stop-indicator-duration')
        .data(stops)
        .join('line')
        .attr('class', 'stop-indicator-duration')
        .attr('x1', (stop) => xScale(new Date(stop.plannedStart)))
        .attr('x2', (stop) => xScale(new Date(stop.plannedEnd)))
        .attr('stroke-width', 4)
        .attr('y1', getY(0.9))
        .attr('y2', getY(0.9))
        .attr('stroke', theme.colors.white)
        .attr('stroke-dasharray', 6)
        .on('mousemove', handleStopTooltip)
        .on('mouseleave', removeTooltip);

      svg
        .selectAll('.stop-indicator-circle')
        .data(stops)
        .join('circle')
        .attr('class', 'stop-indicator-circle')
        .attr('cx', (stop) => xScale(new Date(stop.plannedStart)))
        .attr('r', 8)
        .attr('cy', getY(1))
        .attr('fill', theme.colors.purple)
        .raise()
        .on('mousemove', handleStopTooltip)
        .on('mouseleave', removeTooltip);

      svg
        .selectAll('.stop-indicator-text')
        .data(stops)
        .join('text')
        .attr('class', 'stop-indicator-text')
        .attr('x', (stop) => xScale(new Date(stop.plannedStart)))
        .attr('y', getY(1))
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('fill', theme.colors.white)
        .attr('font-weight', 'bold')
        .text((_, stopIndex) => stopIndex + 1)
        .raise()
        .on('mousemove', handleStopTooltip)
        .on('mouseleave', removeTooltip);

      //stop execution
      if (hasExecution) {
        const stopVisits =
          execution.stopVisits?.map((visit) => {
            const stopIndex = stops.findIndex((s) => s.id === visit.stopId);
            const locationName = stops[stopIndex]?.location?.data?.name;
            return { ...visit, stopIndex, locationName };
          }) || [];

        const handleStopArrivalTooltip = function (mouseEvent, stop) {
          const [xOffset, yOffset] = pointer(mouseEvent, this);
          const { locationName: header } = stop;
          const values = [
            {
              header: 'Start',
              value: formatDateTime(stop.arrivedAt),
            },
            {
              header: 'End',
              value: formatDateTime(stop.departedAt),
            },
            {
              header: 'Duration',
              value: formatDuration(
                stop.arrivedAt,
                stop.departedAt,
                true,
                false,
                true,
              ),
            },
          ];
          setTooltipContent({
            header,
            xOffset,
            yOffset,
            values,
          });
        };

        svg
          .selectAll('.stop-arrival-indicator-duration')
          .data(stopVisits.filter((s) => s.arrivedAt && s.departedAt))
          .join('line')
          .attr('class', 'stop-arrival-indicator-duration')
          .attr('x1', (stop) => xScale(new Date(stop.arrivedAt)))
          .attr('x2', (stop) => xScale(new Date(stop.departedAt)))
          .attr('stroke-width', 4)
          .attr('y1', getY(6.9))
          .attr('y2', getY(6.9))
          .attr('stroke', theme.colors.white)
          .attr('stroke-dasharray', 6)
          .on('mousemove', handleStopArrivalTooltip)
          .on('mouseleave', removeTooltip);

        svg
          .selectAll('.stop-arrival-indicator-circle')
          .data(stopVisits)
          .join('circle')
          .attr('class', 'stop-arrival-indicator-circle')
          .attr('cx', (stop) => xScale(new Date(stop.arrivedAt)))
          .attr('r', 8)
          .attr('cy', getY(7))
          .attr('fill', theme.colors.primary)
          .raise()
          .on('mousemove', handleStopArrivalTooltip)
          .on('mouseleave', removeTooltip);

        svg
          .selectAll('.stop-arrival-indicator-text')
          .data(stopVisits)
          .join('text')
          .attr('class', 'stop-arrival-indicator-text')
          .attr('x', (stop) => xScale(new Date(stop.arrivedAt)))
          .attr('y', getY(7))
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .attr('fill', theme.colors.white)
          .attr('font-weight', 'bold')
          .text((stop) => stop.stopIndex + 1)
          .raise()
          .on('mousemove', handleStopArrivalTooltip)
          .on('mouseleave', removeTooltip);
      }

      if (loadingActivity) {
        const handleLoadingActivityToolTip = function (mouseEvent, activity) {
          const [xOffset, yOffset] = pointer(mouseEvent, this);
          const { start, end } = activity;
          const values = [
            {
              header: 'Start',
              value: formatDateTime(start),
            },
            {
              header: 'End',
              value: formatDateTime(end),
            },
            {
              header: 'Duration',
              value: formatDuration(
                activity.start,
                activity.end,
                true,
                false,
                true,
              ),
            },
          ];
          setTooltipContent({
            header: 'Loading',
            values,
            xOffset,
            yOffset,
          });
        };

        svg
          .selectAll('.loading-duration')
          .data([loadingActivity])
          .join('rect')
          .attr('class', 'loading-duration')
          .attr('x', (activity) => xScale(new Date(activity.start)))
          .attr(
            'width',
            (activity) =>
              xScale(new Date(activity.end)) - xScale(new Date(activity.start)),
          )
          .attr('height', 4)
          .attr('y', getY(6))
          .attr('rx', ROUND_RECT)
          .attr('ry', ROUND_RECT)
          .attr('fill', theme.colors.loads.PICKUP)
          .on('mousemove', handleLoadingActivityToolTip)
          .on('mouseleave', removeTooltip);
      }

      //activity execution
      if (hasExecution) {
        const activities = flatten(
          flatten(stops.map((stop) => getStopActivities(stop))).map(
            (activity) => {
              return (execution.activities || [])
                .filter((a) => a.activityId === activity.id)
                .map((exec) => {
                  return {
                    ...activity,
                    dueTime: new Date(activity.dueTime),
                    dueEnd: addDurationStringToDate(
                      activity.dueTime,
                      activity.duration,
                    ),
                    ...exec,
                    started: new Date(exec.started),
                    ended: new Date(exec.ended || new Date().toISOString()),
                  };
                });
            },
          ),
        );

        const handleActivityTooltip = function (mouseEvent, activity) {
          const [xOffset, yOffset] = pointer(mouseEvent, this);
          const {
            status,
            direction,
            stop: {
              location: {
                data: { name: location },
              },
            },
          } = activity;
          const isActivitySuccessful = status === 'COMPLETE';
          const { workflowExecution } = activity;
          const { requirementsOutcomes, workflowOutcome } =
            workflowExecution || {};
          const signature = requirementsOutcomes?.find(
            (ro) => ro.type === 'SIGNATURE',
          )?.data;
          const signaturePayload =
            isActivitySuccessful && signature
              ? [
                  {
                    header: 'Signature',
                    value: (
                      <img
                        src={`${process.env.REACT_APP_BLOB_STORAGE_URL}/${load.carrierId}/${signature.imageUrl}`}
                        alt="Signature"
                        height={40}
                      />
                    ),
                  },
                  { header: 'Signed By', value: signature.name },
                  { header: 'Comment', value: signature.comment },
                ]
              : [];
          const failurePayload = !isActivitySuccessful
            ? [
                {
                  header: 'Reason',
                  value: workflowOutcome?.data?.failReason,
                },
                {
                  header: 'Comment',
                  value: workflowOutcome?.data?.failComment,
                },
              ]
            : [];
          const consignmentOutcomes =
            workflowOutcome?.data?.consignmentOutcomes || [];
          let huSuccessCount = 0;
          const huCount = consignmentOutcomes.reduce((acc, consignment) => {
            for (const hu of consignment.handlingUnitOutcomes || []) {
              acc++;
              huSuccessCount += hu.status === 'SUCCESSFUL' ? 1 : 0;
            }
            return acc;
          }, 0);
          const workflowType = workflowOutcome?.type;
          const huPayload =
            workflowType === 'PARCEL_MANAGEMENT' && isActivitySuccessful
              ? [
                  {
                    header: 'Total Completed (Units)',
                    value: formatNumber(huSuccessCount),
                  },
                  {
                    header: 'Total Failed (Units)',
                    value: formatNumber(huCount - huSuccessCount),
                  },
                ]
              : [];
          const header = `${convertEnumString(direction)} at ${location}`;
          const entity = activity.stop?.entities?.find((e) =>
            e.activities.some((a) => a.id === activity.id),
          );
          const sellTo = entity?.name || '';
          const shipTo = location || '';
          const values = [
            {
              header: 'Start',
              value: formatDateTime(activity.started),
            },
            {
              header: 'End',
              value: formatDateTime(activity.ended),
            },
            {
              header: 'Duration',
              value: formatDuration(
                activity.started,
                activity.ended,
                true,
                false,
                true,
              ),
            },
            {
              header: 'Outcome',
              value: convertEnumString(activity.status),
            },
            ...failurePayload,
            {
              header: 'Ship To',
              value: shipTo,
            },
            {
              header: 'Sell To',
              value: sellTo,
            },
            ...huPayload,
          ];
          const valuesColumn2 = [...signaturePayload];
          setTooltipContent({
            header,
            values,
            valuesColumn2,
            xOffset,
            yOffset,
          });
        };

        const handleActivityClick = function (_, activity) {
          setSelectedActivity((prev) => {
            if (!prev || prev.id !== activity.id) {
              return activity;
            }
          });
        };

        svg
          .selectAll('.activity-duration')
          .data(activities)
          .join('rect')
          .attr('class', 'activity-duration')
          .attr('x', (activity) => xScale(activity.started))
          .attr(
            'width',
            (activity) => xScale(activity.ended) - xScale(activity.started),
          )
          .attr('height', 4)
          .attr('y', getY(6))
          .attr('rx', ROUND_RECT)
          .attr('ry', ROUND_RECT)
          .attr('fill', (activity) => theme.colors.loads[activity.direction])
          .on('mousemove', handleActivityTooltip)
          .on('mouseleave', removeTooltip)
          .on('click', handleActivityClick);
      }

      //brush
      const brush = brushX()
        .extent([
          [0, 0],
          [width, height],
        ])
        .on('end', (e) => {
          if (trips.length > 0 && e.sourceEvent) {
            const selection = e.selection || [];
            const [brushStart, brushEnd] = selection.map(xScale.invert);
            const brushedTrips = trips.filter(
              ({
                startEvent: { dateTime: ts },
                endEvent: { dateTime: te },
              }) => {
                const [tripStart, tripEnd] = [ts, te].map((d) => new Date(d));
                const isBrushed =
                  brushStart <= tripStart && brushEnd >= tripEnd;
                return isBrushed;
              },
            );
            setSelectedTrips(brushedTrips);
            setBrushSelection(selection);
          }
        });
      svg.select('.brush').call(brush).call(brush.move, brushSelection);
    }
  }, [
    brushSelection,
    currentZoomState,
    dimensions,
    load,
    selectedTrips,
    setSelectedTrips,
    zoomBehaviour,
    setSelectedActivity,
  ]);

  return (
    <TimelineContainer>
      {tooltipContent && (
        <TooltipWrapper
          placement="top"
          visible
          overlay={<Tooltip tooltipContent={tooltipContent} />}
        >
          <TimelineTooltipPoint
            top={tooltipContent.yOffset}
            left={tooltipContent.xOffset}
          />
        </TooltipWrapper>
      )}
      <div ref={wrapperRef}>
        <svg ref={svgRef}>
          <rect
            height={`${TOP_BAR}px`}
            fill={theme.colors.tmPrimary}
            width="100%"
          />
          <g className="x-axis" />
          {isBrushMode && <g className="brush" />}
        </svg>
      </div>
      <TimelineOptions>
        <TimelineOption onClick={() => scaleZoom(0.5)}>
          <SubtractAlt16 />
          Zoom Out
        </TimelineOption>
        <TimelineOption
          onClick={() => {
            scaleZoom(2);
          }}
        >
          <AddAlt16 />
          Zoom In
        </TimelineOption>
        <TimelineOption
          isActive={isBrushMode}
          onClick={() => {
            setBrushMode((prev) => !prev);
          }}
        >
          <Crop16 />
          Trip Selector
        </TimelineOption>
        <TimelineOption onClick={() => handleTripSelect(false)}>
          <CaretLeft16 />
          Previous Trip
        </TimelineOption>
        <TimelineOption onClick={() => handleTripSelect()}>
          <CaretRight16 />
          Next Trip
        </TimelineOption>
      </TimelineOptions>
    </TimelineContainer>
  );
};

export default TimelineView;
