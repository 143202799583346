import styled, { keyframes } from 'styled-components';

const fly1 = keyframes`
0%{
    right:0;
    width:0;
}
30%{
    right:50%;
    width:40%;
}
100%{
    right:100%;
    width:0;
}
`;

const fly2 = keyframes`
0%{
    right:0;
    width:0;
}
60%{
    right:50%;
    width:25%;
}
100%{
    right:100%;
    width:0;
}
`;

const fly3 = keyframes`
0%{
    right:0;
    width:0;
}
60%{
    right:20%;
    width:55%;
}
100%{
    right:100%;
    width:0;
}
`;

const flyRoad = keyframes`
0%{
    right:-50%;
}
100%{
    right:150%;
}
`;

const bounceTruck = keyframes`
0%{
    height:40%;
}
50%{
    height:36%;
}
100%{
    height:40%;
}
`;

const bounceCab = keyframes`
0%{
    height:20%;
}
50%{
    height:18.5%;
}
100%{
    height:20%;
}
`;

const smokey = keyframes`
0%{
    height:5px;
    width:5px;
    left:10%;
    bottom:30%;
    opacity:1;
}
50%{
  height:7px;
    width:7px;
    left:-2%;
    bottom:40%;
    opacity:0.4;
}
100%{
  height:15px;
    width:15px;
    left:0%;
    bottom:50%;
    opacity:0;
}
`;

const Truck = styled.div`
  width: 100%;
  height: 40%;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  position: relative;
  animation: ${bounceTruck} 500ms ease infinite;
`;

const Wheel1 = styled.div`
  position: absolute;
  content: '';
  left: 0.5rem;
  bottom: -10px;
  width: 17px;
  height: 17px;
  border: 2px solid white;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.secondary};
`;

const Wheel2 = styled.div`
  position: absolute;
  content: '';
  right: 0.5rem;
  bottom: -10px;
  width: 17px;
  height: 17px;
  border: 2px solid white;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.secondary};
`;

const TruckContainer = styled.div`
  width: 7.5rem;
  height: 6.5rem;
`;

const OverflowContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 78% 22%;
  padding: 0 1rem;
  grid-gap: 0.2rem;
  overflow: none;
`;

const BaseLine = styled.div`
  z-index: 100;
  position: absolute;
  border-radius: 20%;
  background: ${({ theme }) => theme.colors.secondary};
`;

const Line1 = styled(BaseLine)`
  top: 25%;
  right: -100%;
  width: 30%;
  height: 1px;
  animation: ${fly1} 500ms ease-out infinite;
`;

const Line2 = styled(BaseLine)`
  top: 45%;
  right: 20%;
  width: 35%;
  height: 2px;
  animation: ${fly2} 500ms ease-out infinite;
`;

const Line3 = styled(BaseLine)`
  top: 55%;
  right: 10%;
  width: 55%;
  height: 1px;
  animation: ${fly3} 500ms ease-out infinite;
`;

const Smoke = styled.div`
  position: absolute;
  width: 5px;
  height: 5px;
  background: ${({ theme }) => theme.colors.darkGrey};
  left: 10%;
  z-index: 5;
  bottom: 30%;
  border-radius: 100%;
  animation: ${smokey} 1000ms linear infinite;
`;

const Road = styled(BaseLine)`
  top: 74%;
  z-index: -1;
  width: 100%;
  height: 22%;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 0 0 10px 10px;
`;

const RoadLine = styled(BaseLine)`
  top: 80%;
  right: 25%;
  width: 50%;
  height: 3px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0;
  animation: ${flyRoad} 500ms ease-out infinite;
`;

const Cab = styled.div`
  width: 80%;
  height: 20%;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 0 70% 0 0;
  position: relative;
  animation: ${bounceCab} 500ms ease infinite;
  ::before {
    content: '';
    position: absolute;
    width: 150%;
    height: 100%;
    bottom: -50%;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 0 20% 5px 0;
  }
  ::after {
    position: absolute;
    content: '';
    bottom: -95%;
    left: 10%;
    width: 13px;
    height: 13px;
    border: 2px solid white;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.secondary};
  }
`;

const ProgressBarContainer = styled.div`
  width: 400px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 0.5rem;
  font-size: 0.75rem;
`;

const ProgressBarDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 100%;
`;

const ProgressBarMax = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.darkGrey};
  width: 100%;
  height: 2rem;
`;

const animatedProgress = keyframes`
   0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
`;

const ProgressBar = styled.div`
  background: linear-gradient(
    270deg,
    ${({ theme, startup }) => theme.colors[startup ? 'lightGrey' : 'success']},
    ${({ startup }) => (startup ? '#eee' : '#047d08')},
    ${({ theme, startup }) => theme.colors[startup ? 'lightGrey' : 'success']},
    ${({ startup }) => (startup ? '#eee' : '#047d08')},
    ${({ theme, startup }) => theme.colors[startup ? 'lightGrey' : 'success']},
    ${({ startup }) => (startup ? '#eee' : '#047d08')}
  );
  background-size: 400% 400%;
  animation: ${animatedProgress} 2s ease infinite;

  width: ${({ width }) => `${width}%`};
  height: 100%;
  transition: all 0.5s ease;
`;

export {
  Cab,
  Line1,
  Line2,
  Line3,
  OverflowContainer,
  Road,
  RoadLine,
  Truck,
  TruckContainer,
  Wheel1,
  Wheel2,
  Smoke,
  ProgressBarContainer,
  ProgressBarDetails,
  ProgressBarMax,
  ProgressBar,
};
