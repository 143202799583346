import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup.string().required(),
  vatNumber: yup.string().required(),
  reference: yup.string().required(),
  isFuelEnabled: yup.boolean().nullable(),
  isDedicated: yup.boolean().nullable(),
  sharesAssets: yup.boolean().nullable(),
  sharedAssetTypes: yup.array().when('sharesAssets', {
    is: (sa) => !!sa,
    then: yup
      .array()
      .of(yup.string())
      .min(1, 'Please Select at Least 1 Type')
      .required(),
    otherwise: yup.array().of(yup.string()).required(),
  }),
});

export default validationSchema;
