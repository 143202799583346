import { gql } from '@apollo/client';

const GET_ALLOCATIONS = gql`
  query getOrg($id: ID!) {
    getOrg(id: $id) {
      assets {
        id
        licenceNumber
        fleetNumber
        type
        make
        model
        year
        assetClassId
      }
      assetClasses {
        id
        name
        isLink
      }
      operatorsOnOrgLevel(pagination: { take: 100000, skip: 0 }) {
        data {
          operator {
            id
            firstName
            lastName
            mobile
          }
          apps
        }
        pagination {
          skip
          take
          total
        }
      }
    }
  }
`;

const GET_CARRIERS = gql`
  query getOrg($id: ID!) {
    org: getOrg(id: $id) {
      carriers {
        id
        name
        masterCarrierId
      }
    }
  }
`;

const DISPATCH_LOAD = gql`
  mutation dispatchLoad($input: LoadDispatchInput!) {
    dispatchLoad(input: $input) {
      id
    }
  }
`;

const FLIP_LOAD = gql`
  mutation flipLoad($input: LoadFlipInput!) {
    flipLoad(input: $input) {
      id
      status
    }
  }
`;

const GENERATE_TRAVEL_PLAN = gql`
  mutation generateLoadTravelPlan($id: ID!) {
    generateLoadTravelPlan(id: $id) {
      id
    }
  }
`;

export {
  DISPATCH_LOAD,
  GENERATE_TRAVEL_PLAN,
  GET_ALLOCATIONS,
  GET_CARRIERS,
  FLIP_LOAD,
};
