import { darken, lighten } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

const TableBodyContainer = styled.div`
  height: 74vh;
  width: 100%;
  overflow: auto;
`;
const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  width: 100%;
  & thead tr {
    background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
  }
  & tbody {
    overflow: hidden;
  }
  & td {
    text-align: left;
    border-bottom: 1px solid;
    border-right: 1px solid;
    :first-child {
      border-left: 1px solid;
      border-color: ${({ theme }) => theme.colors.lightGrey};
    }
    border-color: ${({ theme }) => theme.colors.lightGrey};
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colors.darkGrey};
  }
  & td > * {
    padding: 0.2rem 0.5rem;
    width: 100%;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }
  & tr {
    height: 0.8rem;
  }
  & .bx--dropdown,
  & .bx--list-box,
  & .bx--combo-box,
  & .bx--text-input {
    border-bottom: none;
  }
  & .bx--text-input {
    border-bottom: none !important;
  }
`;

const Th = styled.th`
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
  color: ${({ theme }) => theme.colors.darkGrey};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  border-bottom: 2px solid;
  border-right: 2px solid;
  border-top: 2px solid;
  border-color: ${({ theme }) => theme.colors.lightGrey};
  :first-child {
    border-left: 2px solid;
    border-color: ${({ theme }) => theme.colors.lightGrey};
  }
  height: 100%;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 800;
  font-size: 0.75rem;
  padding: 0.75rem 0.5rem;
  ${({ noHover, theme }) =>
    !noHover &&
    css`
      :hover {
        background: ${darken(0.2, theme.colors.lightGrey)};
      }
    `}
`;

const Td = ({ children, ...props }) => (
  <td {...props}>
    <p
      style={{
        justifyContent: props.center && 'center',
        ...(props.style || {}),
      }}
    >
      {children}
    </p>
  </td>
);

export { Table as default, Th, TableBodyContainer, Td };
