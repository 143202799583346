import { useFormikContext } from 'formik';
import { lighten } from 'polished';
import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';
import { NotificationsContext } from '../../context';
import { Link, theme } from '../../styles';
import { PrimaryButton } from '../Buttons/index';
import Loading from '../Spinner';

let ActionButtons = ({
  fillWidth,
  primaryName,
  onClickPrimary,
  type,
  to,
  disabled,
  className,
  loading,
  customValidation,
  ignoreDirty,
}) => {
  const { submitForm, isValid, dirty, errors } = useFormikContext();
  const { addNotification } = useContext(NotificationsContext);

  const clickHandler = () => {
    const errorList = Object.values(errors);
    if (errorList.length > 0) {
      addNotification(
        'error',
        `Invalid (${errorList.length} error${
          errorList.length === 1 ? '' : 's'
        })`,
        errorList.map((e) => JSON.stringify(e)).join(', '),
      );
    }
    submitForm();
  };
  const isDirty = ignoreDirty ? false : !dirty;
  const isDisabled = disabled !== undefined ? disabled : isDirty;
  const style = {
    background: isDisabled
      ? theme.colors.disabled
      : !isValid
      ? lighten(0.2, theme.colors.primary)
      : theme.colors.primary,
  };
  switch (type) {
    case 'finalStep':
      return (
        <Fragment>
          <PrimaryButton
            type="button"
            fillWidth={fillWidth}
            className={className}
            style={style}
            disabled={isDisabled}
            onClick={onClickPrimary || clickHandler}
          >
            {loading ? (
              <Loading small withOverlay={false} />
            ) : (
              primaryName || 'Save Changes'
            )}
          </PrimaryButton>
        </Fragment>
      );
    case 'finalFormButton':
      return (
        <PrimaryButton
          type="submit"
          fillWidth={fillWidth}
          className={className}
          oneButton
          onClick={submitForm}
          disabled={disabled !== undefined ? disabled : !isValid || isDirty}
        >
          {loading ? (
            <Loading small withOverlay={false} />
          ) : (
            primaryName || 'Save Changes'
          )}
        </PrimaryButton>
      );
    case 'steps':
      return (
        <PrimaryButton
          fillWidth={fillWidth}
          className={className}
          oneButton
          disabled={disabled}
          onClick={onClickPrimary}
          type="button"
        >
          {loading ? (
            <Loading small withOverlay={false} />
          ) : disabled ? (
            'Next'
          ) : (
            <Link to={to}>{primaryName || 'Next'}</Link>
          )}
        </PrimaryButton>
      );
    default:
      return (
        <PrimaryButton
          fillWidth={fillWidth}
          className={className}
          oneButton
          disabled={
            typeof customValidation === 'boolean'
              ? !customValidation
              : !isValid || isDirty
          }
          onClick={onClickPrimary || (() => submitForm())}
        >
          {loading ? (
            <Loading small withOverlay={false} />
          ) : (
            primaryName || 'Save Changes'
          )}
        </PrimaryButton>
      );
  }
};

ActionButtons = styled(ActionButtons)`
  display: flex;
  margin-top: ${({ noMargin }) => (noMargin ? '0' : '2rem')};
`;

export default ActionButtons;
