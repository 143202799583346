import { gql } from '@apollo/client';

const CREATE_ASSET_CLASS = gql`
  mutation createAssetClass($input: AssetClassCreateInput!) {
    createAssetClass(input: $input) {
      id
    }
  }
`;

const GET_ORG = gql`
  query getOrg($id: ID!) {
    getOrg(id: $id) {
      sites {
        id
        name
      }
    }
  }
`;

export { CREATE_ASSET_CLASS, GET_ORG };
