import { userTypes } from '../../../';
const { tmUser, groupUser, orgUser, siteUser } = userTypes;

const determineUserType = (isTMUser, isGroupUser, orgs) => {
  if (isTMUser) {
    return tmUser;
  }
  if (isGroupUser) {
    return groupUser;
  }
  if (orgs.length > 0) {
    return orgUser;
  }
  return siteUser;
};

export { determineUserType };
