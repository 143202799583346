import styled from 'styled-components';

const ContextMenuContainer = styled.div`
  transform: translate(0, -50%);
  max-width: 16rem;
  max-height: 8rem;
  overflow: auto;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-top: 2rem;
  > div {
    cursor: pointer;
    padding: 0.3rem;
    :not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
    }
    font-size: 0.6rem;
    :hover {
      background: ${({ theme }) => theme.colors.lightGrey};
    }
  }
  & a {
    color: ${({ theme }) => theme.colors.black};
    text-decoration: none;
  }
`;

export { ContextMenuContainer };
