import React, { useContext } from 'react';
import {
  formatDateTime,
  ModalContext,
  PageContainer,
  RawPayloadModal,
  Table,
  Th,
  theme,
} from '../../../../../';
import {
  FleetPermitEditModalCommonModule,
  FleetPermitViewModalCommonModule,
} from '../../../Permits';
import { CodeIcon, DivWrapper, EditIcon, ViewIcon } from '../styles';

const Permits = ({ data, refetchAsset }) => {
  const { setModalContent, toggleModal, clearModal } = useContext(ModalContext);
  return (
    <PageContainer height={'70vh'} align="end">
      <Table>
        <thead>
          <tr>
            <Th noHover>Permit Number</Th>
            <Th noHover>Country</Th>
            <Th noHover>Valid From</Th>
            <Th noHover>Expiry Date</Th>
            <Th noHover>Captured By</Th>
            <Th noHover>Date Captured</Th>
            <Th noHover>Status</Th>
            <Th noHover>JSON</Th>
            <Th noHover>Edit</Th>
            <Th noHover>View</Th>
          </tr>
        </thead>
        <tbody>
          {data.map((d) => {
            const isExpired = new Date(d.expiryDate) <= new Date();
            return (
              <tr key={d.id}>
                <td>
                  <p>{d.permitNumber}</p>
                </td>
                <td>
                  <p>{d.country}</p>
                </td>
                <td>
                  <p>{formatDateTime(d.validFrom, null, false)}</p>
                </td>
                <td>
                  <p>{formatDateTime(d.expiryDate, null, false)}</p>
                </td>
                <td>
                  <p>
                    {d.createdBy &&
                      `${d.createdBy.firstName} ${d.createdBy.lastName}`}
                  </p>
                </td>
                <td>
                  <p>{formatDateTime(d.createdOn, null, false)}</p>
                </td>
                <td>
                  <p style={{ color: isExpired && theme.colors.danger }}>
                    {isExpired ? 'EXPIRED' : 'ACTIVE'}
                  </p>
                </td>
                <td style={{ width: '20px' }}>
                  <DivWrapper
                    onClick={() => {
                      toggleModal();
                      setModalContent(<RawPayloadModal data={d.rawData} />);
                    }}
                  >
                    <CodeIcon />
                  </DivWrapper>
                </td>
                <td style={{ width: '20px' }}>
                  <DivWrapper
                    onClick={() => {
                      toggleModal();
                      setModalContent(
                        <FleetPermitEditModalCommonModule
                          refetchAsset={refetchAsset}
                          clearModal={clearModal}
                          data={d}
                        />,
                      );
                    }}
                  >
                    <EditIcon />
                  </DivWrapper>
                </td>
                <td style={{ width: '20px' }}>
                  <DivWrapper
                    onClick={() => {
                      toggleModal();
                      setModalContent(
                        <FleetPermitViewModalCommonModule docs={d.documents} />,
                      );
                    }}
                  >
                    <ViewIcon />
                  </DivWrapper>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </PageContainer>
  );
};

export default Permits;
