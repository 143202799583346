import config from '../config';
import setup from './setup';

const getS3Object = async (Key, isPublic = true, bucket = undefined) => {
  const s3 = setup();
  const Bucket = bucket || config.s3(isPublic).bucketName;
  try {
    const result = await s3
      .getObject({
        Bucket,
        Key,
      })
      .promise();
    return result;
  } catch (e) {
    throw new Error(e);
  }
};

export default getS3Object;
