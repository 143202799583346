import { CaretUp20, CaretUp32 } from '@carbon/icons-react';
import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import { theme as mainTheme } from '../../../';

const MapContainer = styled.div`
  grid-area: map;
  height: 100%;
  width: 100%;
`;

const Marker = styled(FaMapMarkerAlt)`
  transform: translate(-50%, -100%);
  background: rgba(255, 255, 255, 0.6);
  border-radius: 100px;
  padding: 0.1rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  & path {
    fill: ${({ theme }) => theme.colors.primary};
    :hover {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const OrderIndicator = styled.div`
  z-index: 2;
  cursor: pointer;
  border: 2px solid
    ${({ theme, isLoading }) =>
      theme.colors[isLoading ? 'purple' : 'tmPrimary']};
  color: ${({ theme, isLoading }) =>
    theme.colors[isLoading ? 'purple' : 'tmPrimary']};
  background: ${({ theme }) => theme.colors.white};
  :hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme, isLoading }) =>
      theme.colors[isLoading ? 'purple' : 'tmPrimary']};
  }
  font-weight: bold;
  width: 1.7rem;
  height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  align-items: center;
  justify-items: center;
  ${({ onMap }) =>
    onMap &&
    css`
      transform: translate(-50%, -50%);
    `}
`;

const TripSnapshot = styled(CaretUp20)`
  transform: translate(-10px, -10px)
    rotate(${({ direction }) => `${direction}deg`});
  & path {
    fill: ${({ theme }) => theme.colors.success};
    stroke: ${({ theme }) => theme.colors.darkGrey};
  }
`;

const CurrentPositionMoving = styled(CaretUp32)`
  transform: translate(-50%, -50%)
    rotate(${({ direction }) => `${direction}deg`});
  & path {
    fill: ${({ theme }) => theme.colors.success};
    stroke: ${({ theme }) => theme.colors.darkGrey};
  }
`;

const CurrentPositionIdle = (props) => {
  return (
    <CurrentPositionOuter {...props}>
      <CurrentPositionIdleInner {...props} />
    </CurrentPositionOuter>
  );
};

const CurrentPositionParked = (props) => {
  return (
    <CurrentPositionOuter {...props}>
      <CurrentPositionParkedInner {...props} />
    </CurrentPositionOuter>
  );
};

const CurrentPositionOuter = styled.div`
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 100px;
  border: 2px solid ${({ theme }) => theme.colors.tmPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CurrentPositionIdleInner = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background: ${({ theme }) => theme.colors.yellow};
  border: 1px solid ${({ theme }) => theme.colors.darkGrey};
`;

const CurrentPositionParkedInner = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background: ${({ theme }) => theme.colors.danger};
  border: 1px solid ${({ theme }) => theme.colors.darkGrey};
`;

const CurrentPosition = (props) => {
  const Component =
    props.status === 'IDLE'
      ? CurrentPositionIdle
      : props.status === 'PARKED'
      ? CurrentPositionParked
      : CurrentPositionMoving;
  return <Component {...props} />;
};

const Arrow = ({ direction, ...rest }) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      xmlnsSerif="http://www.serif.com/"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokLinejoin: 'round',
        strokeMiterlimit: 2,
        transform: `translate(-50%,-50%) rotate(${-90 + +direction}deg)`,
      }}
      {...rest}
    >
      <g transform="matrix(1.29079,0,0,1.39844,-5.56375,-3.63669)">
        <path
          d="M5.311,12.808L5.311,6.695L12.194,6.695L12.194,4.333L19.278,9.751L12.194,15.17L12.194,12.808L5.311,12.808Z"
          style={{
            fill: mainTheme.colors.success,
            stroke: mainTheme.colors.darkGrey,
          }}
        />
      </g>
    </svg>
  );
};

const TripStartEndEvent = styled.div`
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 100px;
  transform: translate(-50%, -50%);
  background: ${({ isStart, theme }) =>
    theme.colors[isStart ? 'success' : 'danger']};
  border: 1px solid ${({ theme }) => theme.colors.darkGrey};
`;

const ActivityTypeBubble = styled.div`
  padding: 0.3rem 0.7rem;
  background: ${({ theme, type }) => theme.colors.loads[type]};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
`;

export {
  ActivityTypeBubble,
  Arrow,
  CurrentPosition,
  MapContainer,
  Marker,
  OrderIndicator,
  TripSnapshot,
  TripStartEndEvent,
};
