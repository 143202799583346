import * as yup from 'yup';

const addressValidation = yup.object({
  streetName: yup.string().notRequired().nullable(),
  suburb: yup.string().notRequired().nullable(),
  city: yup.string().notRequired().nullable(),
  streetNo: yup.string().notRequired().nullable(),
  postalCode: yup.string().notRequired().nullable(),
  province: yup.string().notRequired().nullable(),
});

export default addressValidation;
