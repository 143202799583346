import { gql } from '@apollo/client';

const getAuditGql = (gqlQuery, gqlPath = 'audits') => gql`
query getAuditGql($id:ID!){
${gqlQuery}(id:$id){
    ${gqlPath}{
        id
        userId
        username
        formattedChangeLog
        timestamp
        eventType
    }
}
}
`;

export { getAuditGql };
