import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  ActionButtons,
  FormArea,
  FormWrapper,
  SelectRemoveColumns,
  useLoading,
} from '../../../../../../';
import { ASSIGN_APPS } from './gql';
import validationSchema from './validationSchema';

const Details = withRouter(({ operator, outcomeObject, permissionsList }) => {
  const [manageApps, { loading }] = useMutation(ASSIGN_APPS, outcomeObject);

  useLoading(loading);

  return (
    <Formik
      initialValues={operator}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(input) => {
        manageApps({
          variables: { input },
        });
      }}
    >
      {({ values, handleChange }) => (
        <FormWrapper>
          <Form>
            <FormArea columns={2}>
              <SelectRemoveColumns
                leftItems={permissionsList.filter(
                  (pl) => !values.apps.includes(pl.value),
                )}
                rightItems={permissionsList.filter((pl) =>
                  values.apps.includes(pl.value),
                )}
                instruction="Assign Permissions"
                leftHeading="Available Permissions"
                rightHeading="Assigned Permissions"
                actionHandler={(value) => {
                  handleChange({
                    target: {
                      name: 'apps',
                      value: value.map((v) => v.value),
                    },
                  });
                }}
                withinForm
              />
            </FormArea>
          </Form>
          <ActionButtons type={'finalStep'} primaryName="Save Changes" />
        </FormWrapper>
      )}
    </Formik>
  );
});

export default Details;
