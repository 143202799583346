import styled, { css } from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
`;

const Label = styled.div`
  display: flex;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.darkGrey};
  margin: 1px 1px 0.5rem 0;
`;

const Choices = styled.div``;

const ChoiceContainer = styled.div`
  padding: 0.5rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  & div:first-child {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    border-radius: 100px;
    border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
    ${({ selected, theme, disabled }) =>
      selected &&
      css`
        background: ${theme.colors[disabled ? 'lightGrey' : 'primary']};
        border: none;
      `}
  }
  & div:last-child {
    color: ${({ selected, theme, disabled }) =>
      selected && theme.colors[disabled ? 'lightGrey' : 'primary']};
  }
`;

export { Container, Label, Choices, ChoiceContainer };
