import * as yup from 'yup';

const formikSchema = {
  initialValues: {
    assetId1: '',
    assetId2: '',
    assetId3: '',
    driverId: '',
    crewIds: [],
  },
  validationSchema: yup.object({
    assetId1: yup.string().required(),
    assetId2: yup.string().notRequired().nullable(),
    assetId3: yup.string().notRequired().nullable(),
    driverId: yup.string().required(),
    crewIds: yup
      .array()
      .of(yup.string().notRequired().nullable())
      .notRequired()
      .nullable(),
  }),
};

export default formikSchema;
