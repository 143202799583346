import { get } from 'lodash';
import React from 'react';
import {
  convertEnumString,
  formatDateTime,
  formatDuration,
  getOperatorName,
} from '../../../';
import { Tooltip as TooltipDisplay } from '../Tooltip';

const CurrentPositionTooltip = ({
  load,
  assetCurrentPosition,
  vehicleDisplay,
}) => {
  const timestamp = assetCurrentPosition.gps.dateTime;
  const extraHeaders =
    assetCurrentPosition.status === 'IDLE'
      ? [
          {
            header: 'Idle Since',
            value: formatDateTime(timestamp),
          },
          {
            header: 'Idle For',
            value: formatDuration(timestamp, new Date(), true, false, true),
          },
        ]
      : [];
  return (
    <TooltipDisplay
      tooltipContent={{
        addresses: [
          {
            lat: assetCurrentPosition.gps.lat,
            lng: assetCurrentPosition.gps.lng,
          },
        ],
        header: vehicleDisplay,
        values: [
          {
            header: 'Status',
            value: convertEnumString(assetCurrentPosition.status),
          },
          {
            header: 'Vehicle',
            value: vehicleDisplay,
          },
          {
            header: 'Driver',
            value: getOperatorName(get(load, 'allocation.driver')),
          },
          {
            header: 'Speed',
            value: `${assetCurrentPosition.gps.speed.toFixed()} kph`,
          },
          {
            header: 'GPS Coords',
            value: `${assetCurrentPosition.gps.lat.toFixed(
              3,
            )}, ${assetCurrentPosition.gps.lng.toFixed(3)}`,
          },
          {
            header: 'Date',
            value: formatDateTime(timestamp),
          },
          ...extraHeaders,
          {
            header: 'GPS Accuracy',
            value: assetCurrentPosition.gps.accuracy,
          },
          {
            header: 'Battery',
            value: `${assetCurrentPosition.batteryLevel}% ${
              assetCurrentPosition.batteryCharging ? ' (charging)' : ''
            }`,
          },
        ],
        noAbsolute: true,
      }}
    />
  );
};

export default CurrentPositionTooltip;
