import { gql } from '@apollo/client';

const GET_ADNS = gql`
  query getADNs(
    $take: Int!
    $skip: Int!
    $query: QueryADNs
    $order: [[String]]
  ) {
    getADNs(
      pagination: { take: $take, skip: $skip }
      query: $query
      order: $order
    ) {
      data {
        id
        carrierId
        orgId
        principalId
        subject
        event
        type
      }
      pagination {
        take
        skip
        total
      }
    }
  }
`;

export { GET_ADNS };
