import { curry } from 'ramda';
import { convertListToIdObject } from '../../../../functions';

const shapeData = curry((assetClasses, data) => {
  const assetClassObject = convertListToIdObject(assetClasses);
  return data.assetsPaged.data.map(
    ({
      id,
      licenceNumber,
      fleetNumber,
      type,
      vin,
      status,
      assetClassId,
      qrCode,
      masterAsset,
      siteCount,
      isRearTrailer,
      assetGroupCount,
    }) => {
      const { name: assetClass, isLink } = assetClassObject[assetClassId] || {};
      const position = isLink ? (isRearTrailer ? 'Rear' : 'Front') : '';
      return {
        position,
        id,
        licenceNumber,
        fleetNumber,
        type,
        class: assetClass,
        isVerified: !!masterAsset,
        assetGroupCount,
        vin: !masterAsset ? vin : masterAsset.vin,
        isActive: status === 'ACTIVE',
        siteCount,
        qrCode,
      };
    },
  );
});

export { shapeData };
