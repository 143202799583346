import * as Sentry from '@sentry/react';
import React, { Fragment } from 'react';
import PageError from '../PageError';

const SentryErrorBoundary = ({ children }) => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    return (
      <Sentry.ErrorBoundary
        fallback={({ error }) => <PageError error={error} />}
        showDialog
      >
        {children}
      </Sentry.ErrorBoundary>
    );
  }
  return <Fragment>{children}</Fragment>;
};

export default SentryErrorBoundary;
