import * as yup from 'yup';

const addressValidation = yup.object({
  streetName: yup.string().required(),
  suburb: yup.string().required(),
  city: yup.string().required(),
  streetNo: yup.string().required(),
  postalCode: yup.string().optional(),
  province: yup.string().optional(),
});

export default addressValidation;
