import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { appsArray, linkPlaceholders, TableView } from '../../../../';
import { listSortAndFormat } from '../../../../functions';
import { getCurrentContext, shapeOperatorData } from './functions';
import { GET_ORG_SITES, GET_SITES } from './gql';

const OperatorsList = withRouter(
  ({
    match,
    create,
    navLinks,
    linkParams,
    currentEntity,
    currentEntityType,
    isOrgLevel,
  }) => {
    const currentContext = getCurrentContext({
      currentEntity,
      linkParams,
      match,
      create,
      navLinks,
      currentEntityType,
      isOrgLevel,
    });

    const { data } = useQuery(GET_SITES, {
      variables: { id: currentEntity?.id },
    });

    const { data: adminData } = useQuery(GET_ORG_SITES, {});

    const filterOptions = [
      { name: 'search', type: 'input', placeholder: 'Name or ID' },
      {
        name: 'permissions',
        type: 'multiselect',
        placeholder: 'Permissions',
        options: appsArray.filter(({ value }) =>
          isOrgLevel ? ['driver', 'crew', 'fleet'].includes(value) : true,
        ),
      },
      {
        name: 'status',
        type: 'dropdown',
        placeholder: 'Status',
        options: [
          { value: 'ACTIVE', label: 'Active' },
          { value: 'INACTIVE', label: 'Inactive' },
        ],
      },
      ...(isOrgLevel
        ? [
            {
              name: 'siteId',
              type: 'dropdown',
              placeholder: 'Site',
              options: listSortAndFormat(
                data ? data.getOrg.sites : [],
                'name',
                'id',
              ),
            },
          ]
        : []),
      ...(!currentEntity?.id
        ? [
            {
              name: 'orgId',
              type: 'dropdown',
              placeholder: 'Organisation',
              searchOnly: true,
              options: listSortAndFormat(adminData?.orgs || [], 'name', 'id'),
            },
            {
              name: 'siteId',
              type: 'dropdown',
              placeholder: 'Site',
              searchOnly: true,
              options: listSortAndFormat(
                adminData?.sites?.map((s) => ({
                  ...s,
                  name: `${
                    adminData?.orgs?.find((o) => o.id === s.orgId)?.name
                  } - ${s.name}`,
                })) || [],
                'name',
                'id',
              ),
            },
          ]
        : []),
    ];

    return (
      <TableView
        initialLinkParamObject={linkParams}
        additionalLinkParam={linkPlaceholders.operatorId}
        filterOptions={filterOptions}
        heading={`${get(currentEntity, 'name', '')} Operators`}
        description={`View, edit or create operator`}
        initialOrder={[['firstName', 'ASC']]}
        actionLinkName="Create Operator"
        placeHolderText="Search Operator"
        {...currentContext}
        {...(isOrgLevel
          ? { dataShapeFunction: (d) => shapeOperatorData(d, data) }
          : {})}
        fixedQueries={
          isOrgLevel && { permissions: ['driver', 'crew', 'fleet'] }
        }
      />
    );
  },
);

export default OperatorsList;
