import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  generateLinkWithParams,
  NotificationsContext,
  PageContainer,
  PageHeading,
  useLoading,
} from '../../../../';
import ContactForm from '../ContactForm';
import formikSchema from './formikSchema';
import { CREATE_LOAD_CONTACT } from './gql';

const CreateLoadContact = ({
  currentEntity,
  linkParams,
  returnRoute,
  type = 'ORG',
  onSubmit,
  isRoutes = false,
  token,
}) => {
  const display = isRoutes ? 'Routes' : 'Loads';
  const history = useHistory();
  const { addInlineAlert } = useContext(NotificationsContext);

  const [createLoadContactMutation, { loading }] = useMutation(
    CREATE_LOAD_CONTACT,
    {
      onCompleted: (d) => {
        addInlineAlert(
          'success',
          `${display} Contact Created Successfully`,
          `${display} Contact has been successfully created`,
        );
        onSubmit
          ? onSubmit(d)
          : history.push(generateLinkWithParams(returnRoute, linkParams));
      },
      onError: (err) => {
        addInlineAlert('error', 'Error', err.message);
      },
    },
  );

  useLoading(loading);

  return (
    <PageContainer>
      <PageHeading
        heading={`${currentEntity.name} ${display} Contacts`}
        description={`Create ${display} Contact`}
      />
      <Formik
        validateOnMount
        {...formikSchema}
        onSubmit={(values) => {
          createLoadContactMutation({
            variables: {
              input: {
                ...values,
                carrierId: type === 'CARRIER' ? currentEntity.id : null,
                orgId: type === 'ORG' ? currentEntity.id : values.orgId,
              },
              token,
            },
          });
        }}
      >
        <ContactForm
          currentEntity={currentEntity}
          type={type}
          isRoutes={isRoutes}
        />
      </Formik>
    </PageContainer>
  );
};

export default CreateLoadContact;
