/* eslint-disable no-param-reassign */
import { useMutation, useQuery } from '@apollo/client';
import { omit } from 'lodash';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
  getURLParam,
  PageError,
  PageHeading,
  PageLoading,
  Tab,
  Tabs,
  TopBar,
  useLoading,
} from '../../../../../';
import { useOutcomeObject } from '../../common';
import Licences from '../../Licences';
import Apps from './Apps';
import { GET_OPERATOR, UPDATE_OPERATOR } from './gql';
import OperatorDetails from './OperatorDetails';

const EditOperator = withRouter(
  ({
    match,
    history,
    redirect,
    appsArray,
    linkParams,
    currentEntity,
    isTMOperator,
  }) => {
    const [siteId, operatorId] = ['siteId', 'operatorId'].map((a) =>
      getURLParam(match, a),
    );
    const { data, error } = useQuery(GET_OPERATOR, {
      variables: {
        id: operatorId,
      },
    });

    const outcomeObject = useOutcomeObject({ history, redirect, linkParams });

    const [editOperatorMutation, { loading: opMutationLoading }] = useMutation(
      UPDATE_OPERATOR,
      outcomeObject,
    );
    useLoading(opMutationLoading);
    if (error) {
      return <PageError error={error} />;
    }
    if (data) {
      const { getOperator } = data;
      return (
        <Fragment>
          <TopBar>
            <PageHeading
              heading={`${
                currentEntity?.id ? currentEntity.name : 'All'
              } Operators`}
              description={`Edit Operator`}
            />
          </TopBar>
          <Tabs style={{ marginBottom: '1rem' }}>
            <Tab label="Operator Details">
              <OperatorDetails
                editOperatorMutation={editOperatorMutation}
                data={omit(getOperator, ['sites', 'licences'])}
              />
            </Tab>
            {!isTMOperator && (
              <Tab label="Permissions">
                <Apps
                  outcomeObject={outcomeObject}
                  data={getOperator}
                  appsArray={appsArray}
                  siteId={siteId}
                />
              </Tab>
            )}
            <Tab label="Licences">
              <Licences licences={getOperator.licences} />
            </Tab>
          </Tabs>
        </Fragment>
      );
    }
    return <PageLoading />;
  },
);

export default EditOperator;
