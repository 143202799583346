import { ControlledEditor as Editor } from '@monaco-editor/react';
import React, { useState } from 'react';
import {
  FormItemSplitter,
  HtmlPreview,
  OverflowContainer,
  PrimaryButton,
  SecondaryButton,
  ToggleBtn,
} from '../..';
import { Container, Left, Right } from './styles';

const HtmlEdit = ({ html, onSave, onCancel, heading = 'HTML' }) => {
  const [tempHtml, setTempHtml] = useState(html);
  const [isLightMode, setIsLightMode] = useState(false);

  return (
    <Container>
      <Left>
        <div>{heading}</div>
        <OverflowContainer style={{ overflow: 'hidden' }}>
          <Editor
            language="html"
            value={tempHtml}
            onChange={(_, value) => {
              setTempHtml(value);
            }}
            theme={isLightMode ? 'light' : 'vs-dark'}
          />
        </OverflowContainer>
        <FormItemSplitter style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}>
          <SecondaryButton fillWidth onClick={onCancel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            fillWidth
            onClick={() => onSave(tempHtml)}
            disabled={html === tempHtml}
          >
            Save
          </PrimaryButton>
          <ToggleBtn
            labelA="Dark"
            labelB="Light"
            defaultToggled={isLightMode}
            onToggle={() => setIsLightMode((p) => !p)}
          />
        </FormItemSplitter>
      </Left>
      <Right>
        <HtmlPreview html={tempHtml} />
      </Right>
    </Container>
  );
};

export default HtmlEdit;
