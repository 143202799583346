import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-items: center;
  padding: 30vh 30vw;
  & h2 {
    font-size: 3rem;
  }
`;

export { Container };
