import styled from 'styled-components';

const Container = styled.div`
  width: 250px;
  height: 100%;
  display: grid;
  grid-template-rows: 140px 140px 1fr;
  border-left: 5px solid ${({ theme }) => theme.colors.secondary};
  z-index: 3;
  grid-area: cam;
`;

const DeviceOffline = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.warning};
  font-weight: bold;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.darkGrey};
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGrey};
`;

const AssetPosition = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: ${({ theme, isOnline }) =>
    theme.colors[isOnline ? 'success' : 'warning']};
  transform: translate(-50%, -50%);
  border: 2px solid ${({ theme }) => theme.colors.white};
`;

export { Container, DeviceOffline, AssetPosition };
