import { fromUnixTime, isFuture } from 'date-fns';
import jwt from 'jsonwebtoken';
import { commonPasswords, PasswordStrength } from 'tai-password-strength';
import { clearAllLocalStorageKeys, localStorageKeys } from '../../../';

const testPasswordStrength = (username, password) => {
  if (password.toLowerCase().includes(username.toLowerCase())) {
    throw new Error('Username cannot be present in the password');
  }
  const tester = new PasswordStrength();
  tester.addCommonPasswords(commonPasswords);
  const { commonPassword } = tester.check(password);
  if (commonPassword) {
    throw new Error('Password cannot be a commonly used pattern');
  }
};

const loggedIn = (sendAlert) => {
  const idToken = localStorage.getItem(localStorageKeys.idToken);
  if (!idToken || idToken === undefined) {
    clearAllLocalStorageKeys();
    return false;
  }
  const decoded = jwt.decode(idToken);
  if (!decoded || (decoded && !decoded.payload)) {
    clearAllLocalStorageKeys();
    return false;
  }
  const { exp } = decoded.payload;
  const hasExpired = !isFuture(fromUnixTime(exp));
  if (hasExpired) {
    sendAlert();
    clearAllLocalStorageKeys();
    return false;
  }
  if (localStorage.getItem(localStorageKeys.rememberMe) === 'true') {
    return true;
  }
  return true;
};

export { loggedIn, testPasswordStrength };
