import { gql } from '@apollo/client';

const UPDATE_ASSET_CLASS = gql`
  mutation updateAssetClass($input: AssetClassUpdateInput!) {
    updateAssetClass(input: $input) {
      id
    }
  }
`;

const GET_ASSET_CLASS = gql`
  query getAssetClass($id: ID!) {
    getAssetClass(id: $id) {
      id
      type
      name
      isLink
      weight
      volumetricMass
      pieces
      pallets
      loadedTransportCost
      loadedTyresCost
      loadedFuelCost
      loadedFridgeCost
      emptyTransportCost
      emptyTyresCost
      emptyFuelCost
      emptyFridgeCost
      org {
        sites {
          id
          name
        }
      }
      siteAllocations {
        site {
          id
          name
        }
        allocation
      }
    }
  }
`;

export { UPDATE_ASSET_CLASS, GET_ASSET_CLASS };
