import { omit } from 'lodash';

const initialValues = (data, updatedData) => {
  const keysToOmit = [
    'fleetPermits',
    'masterAsset',
    'assetGroups',
    'sites',
    'licences',
    'tyreTransactions',
    data.masterAsset ? 'engineNo' : 'engineNumber',
  ];
  const masterAssetData = data.masterAsset || {};
  const assetData = updatedData?.updateAsset || data;
  return omit(
    {
      ...assetData,
      ...masterAssetData,
      licenceNumber: assetData.licenceNumber,
    },
    keysToOmit,
  );
};

export default initialValues;
