import styled, { css } from 'styled-components';

const DashboardFlash = styled.div`
  font-size: 0.75rem;
  color: ${({ theme, overTime, color }) =>
    color || (overTime && theme.colors.danger)};
  ${({ theme, flash, overTime }) =>
    (flash || overTime) &&
    css`
      animation-name: fade;
      animation-duration: 1.5s;

      @keyframes fade {
        from {
          background: ${theme.colors[overTime ? 'danger' : 'primary']};
          color: ${theme.colors.white};
        }
        to {
          background: none;
          color: ${theme.colors[overTime ? 'danger' : 'darkGrey']};
        }
      }
    `};
`;

const DashboardFlashTd = styled.td`
  font-size: 0.75rem;
  color: ${({ theme, overTime, color }) =>
    color || (overTime && theme.colors.danger)};
  ${({ theme, flash, overTime }) =>
    (flash || overTime) &&
    css`
      animation-name: fade;
      animation-duration: 1.5s;

      @keyframes fade {
        from {
          background: ${theme.colors[overTime ? 'danger' : 'primary']};
          color: ${theme.colors.white};
        }
        to {
          background: none;
          color: ${theme.colors[overTime ? 'danger' : 'darkGrey']};
        }
      }
    `};
`;

export { DashboardFlash, DashboardFlashTd };
