import { gql } from '@apollo/client';

const ACTIVATE_ROUTE = gql`
  mutation activateRoute($id: ID!) {
    activateRoute(id: $id) {
      id
    }
  }
`;

const CLOSE_LOAD = gql`
  mutation closeLoad($id: ID!) {
    closeLoad(id: $id) {
      id
    }
  }
`;

const CANCEL_LOAD = gql`
  mutation cancelLoad($input: LoadReasonCommentInput!) {
    cancelLoad(input: $input) {
      id
    }
  }
`;

export { CLOSE_LOAD, CANCEL_LOAD, ACTIVATE_ROUTE };
