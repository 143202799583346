import validationSchema from '../validationSchema';
const formikSchema = {
  initialValues: {
    name: '',
    vatNumber: '',
    reference: '',
    isFuelEnabled: false,
    isDedicated: false,
    sharesAssets: false,
    sharedAssetTypes: [],
  },
  validationSchema,
};

export default formikSchema;
