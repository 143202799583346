import { CognitoUser } from 'amazon-cognito-identity-js';
import { cognito } from '../../../../';

const setNewPassword = (session, password, username) =>
  new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: cognito(),
    });
    cognitoUser.Session = session;
    cognitoUser.completeNewPasswordChallenge(
      password,
      { name: 'name' },
      {
        onSuccess: (response) => {
          resolve(response);
        },
        onFailure: (error) => {
          if ('message' in error) {
            reject(error.message);
          }
          reject(error);
        },
      },
    );
  });

export { setNewPassword };
