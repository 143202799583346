import * as yup from 'yup';

const documentCreateSchema = yup.object({
  name: yup.string().required(),
  file: yup.mixed().label('File').required(),
});

const documentUpdateSchema = yup.object({
  name: yup.string().required(),
  path: yup.string().notRequired().nullable(),
  file: yup
    .mixed()
    .label('File')
    .when('path', {
      is: (v) => !v,
      then: yup.mixed().required(),
      otherwise: yup.mixed().notRequired().nullable(),
    }),
});

export { documentCreateSchema, documentUpdateSchema };
