const DENOMINATOR = 10;
const MARGIN_TOP = 30;
const MARGIN_BOTTOM = 10;
const ROUND_RECT = 1;
const DOMAIN_INDICATOR_OFFSET = 115;
const TOP_BAR = 20;

const loadWebsocketEvents = {
  WORKFLOW_EXECUTION: 'workflow_execution',
  LOAD_EXECUTION: 'load_execution',
  STATUS_UPDATE: 'load_status_updated_event',
  TRIP_SNAPSHOT: 'trip_snapshot',
  TRIP_SUMMARY: 'trip_summary',
  EXTRAPOLATION: 'extrapolation',
};

export {
  DENOMINATOR,
  MARGIN_BOTTOM,
  MARGIN_TOP,
  ROUND_RECT,
  DOMAIN_INDICATOR_OFFSET,
  TOP_BAR,
  loadWebsocketEvents,
};
