import styled from 'styled-components';
import { Table as TableStyle } from '../../styles';
import { lighten } from 'polished';

const Table = styled(TableStyle)`
  & tbody {
    cursor: pointer;
    & p {
      user-select: none;
    }
  }
`;

const Td = styled.td`
  background: ${({ isSelected, theme, disabled }) =>
    disabled
      ? lighten(0.1, theme.colors.lightGrey)
      : isSelected && theme.colors.primary};
  & p {
    color: ${({ isSelected, theme }) => isSelected && theme.colors.white};
    font-weight: ${({ isSelected }) => isSelected && 'bold'};
  }
`;

const MenuBar = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
`;

const SelectDeselect = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  padding: 0.75rem 0.5rem;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.colors.trackmaticblue};
  }
`;

const SelectedInfo = styled.div`
  padding: 0.3rem 1rem;
`;

export { Table, Td, MenuBar, SelectDeselect, SelectedInfo };
