import React, { Fragment } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../Buttons';

let AuthError = ({ className }) => (
  <Fragment>
    <div className={className}>
      <h1>You are not authorised to view this page</h1>
    </div>
    <PrimaryButton onClick={() => window.history.back()}>Go Back</PrimaryButton>
  </Fragment>
);

AuthError = styled(AuthError)`
  & h1 {
    margin-bottom: 20px;
  }
  color: ${({ theme }) => theme.colors.danger};
`;

export default AuthError;
