import { useLazyQuery } from '@apollo/client';
import React, { Fragment, useContext } from 'react';
import { InlineNotification, LoadingContext } from '../../../../';
import Banner from '../Banner';
import { testPasswordStrength } from '../functions';
import { LOGIN } from '../LoginForm/gql';
import PasswordForm from '../PasswordForm';
import { setNewPassword } from './functions';

const SetPasswordForm = ({
  session,
  username,
  closeModalAndRedirect,
  setNotify,
  setAssertMFA,
}) => {
  const BadNotification = ({ message }) => (
    <InlineNotification kind="error" title="Error" subtitle={message} />
  );
  const { setLoading } = useContext(LoadingContext);
  const [login] = useLazyQuery(LOGIN, {
    onError: (e) => {
      setNotify(<BadNotification message={e.message} />);
    },
    onCompleted: (data) => {
      if (data) {
        if (data.login.assertMFA) {
          setAssertMFA(true);
        } else {
          closeModalAndRedirect(data.login.token);
        }
      }
    },
  });

  const setPassword = (password) => {
    try {
      testPasswordStrength(username, password);
      setLoading(true);
      setNewPassword(session, password, username)
        .then(() => {
          setNotify(
            <InlineNotification
              kind="success"
              title="Password set"
              subtitle="Your password has been set"
            />,
          );
          login({ variables: { username, password } });
        })
        .catch(() => {
          setNotify(
            <BadNotification message="Please ensure password meets requirements" />,
          );
        })
        .finally(() => setLoading(false));
    } catch (e) {
      setLoading(false);
      setNotify(<BadNotification message={e.message} />);
    }
  };

  return (
    <Fragment>
      <Banner text="Set Password" />
      <PasswordForm onSubmit={setPassword} />
    </Fragment>
  );
};

export default SetPasswordForm;
