import React from 'react';
import { PrimaryButton, SecondaryButton, DangerButton } from '../Buttons';
import Loading from '../Spinner';
import styled from 'styled-components';

const ButtonRow = styled.div`
  width: 400px;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & * {
    text-align: center;
  }
`;

const ConfirmModal = ({
  danger,
  title,
  subtitle,
  className,
  onCancel,
  loading,
  onConfirm,
  FormWrapper,
  disabled,
}) => {
  const ButtonComponent = danger ? DangerButton : PrimaryButton;
  return (
    <div className={className}>
      <HeaderRow>
        <h3>{title}</h3>
        <h5>{subtitle}</h5>
      </HeaderRow>
      {FormWrapper && <FormWrapper />}
      <ButtonRow>
        <SecondaryButton onClick={onCancel} width="200px">
          Cancel
        </SecondaryButton>
        <ButtonComponent disabled={disabled} width="200px" onClick={onConfirm}>
          {loading ? <Loading small withOverlay={false} /> : 'Confirm'}
        </ButtonComponent>
      </ButtonRow>
    </div>
  );
};

export default styled(ConfirmModal)`
  height: 200px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
