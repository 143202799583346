import axios from 'axios';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useAlerts } from '../../../../';
import { Asset, eDeviceType } from './types';

const useDashCam = (asset: Asset) => {
  const [livestreamUrls, setLiveStreamUrls] = useState<string[]>([]);
  const [dashCamDevice, setDashCamDevice] = useState<any>();
  const [dashCamLoading, setDashCamLoading] = useState(false);
  const { onError } = useAlerts();
  const deviceId = asset.devices.find(
    (d) => d.type === eDeviceType.DASH_CAM,
  )?.deviceId;
  useEffect(() => {
    setLiveStreamUrls([]);
    setDashCamDevice(undefined);
    if (deviceId) {
      setDashCamLoading(true);
      const getAxios = async () => {
        try {
          const { data } = await axios.get(
            `https://tps.trackmatic.cloud/rest/v1/surfsight/videofeed/${deviceId}`,
          );
          const { data: deviceData } = await axios.get(
            `https://tps.trackmatic.cloud/rest/v1/surfsight/device/${deviceId}`,
          );
          setDashCamDevice((prev: any) => {
            if (prev?.status !== 'online' && deviceData.status === 'online') {
              setLiveStreamUrls(
                [1, 2].map(
                  (cameraId) =>
                    `https://${data.address}/${deviceId}/${cameraId}/${data.mediaToken}/playlist.m3u8`,
                ),
              );
            }
            if (deviceData.status !== 'online') {
              setLiveStreamUrls([]);
            }
            return deviceData;
          });
        } catch (e: any) {
          onError({ message: get(e, 'response.data.error', '') });
        } finally {
          setDashCamLoading(false);
        }
      };
      getAxios();
      const interval = setInterval(getAxios, 10000);
      return () => {
        clearInterval(interval);
      };
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);
  return {
    livestreamUrls,
    dashCamDevice,
    dashCamLoading,
    hasDashCam: !!deviceId,
  };
};

export { useDashCam };
