import { useContext, useEffect } from 'react';
import { LoadingContext } from '../context';

const useLoading = (loading) => {
  const { setLoading } = useContext(LoadingContext);
  useEffect(() => {
    setLoading(loading);
    return () => {
      setLoading(false);
    };
  }, [loading, setLoading]);
};

export default useLoading;
