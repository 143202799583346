import { gql } from '@apollo/client';

const GET_TRANSACTION = gql`
  query getTransaction($id: ID!) {
    getLinkFulfilmentTransaction(id: $id) {
      id
      articleNo
      entityName
      siteReference
      site {
        name
      }
      planDeliveryDate
      receiptDate
      penalty
      status
      poQty
      purchaseOrderReference
      grvQty
      audits {
        reason
        comment
        status
        previousStatus
        timestamp
        user
      }
      documents {
        name
        path
      }
    }
  }
`;

export { GET_TRANSACTION };
