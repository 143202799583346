import styled from 'styled-components';
import { OverflowContainer } from '../../styles';

const Container = styled(OverflowContainer)`
  .PhoneInput {
    height: 40px;
  }
  .PhoneInputInput {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkGrey};
    height: 40px;
  }
`;
const Label = styled.label`
  font-size: 0.75rem;
`;
export { Container, Label };
