import * as yup from 'yup';

import { documentUpdateSchema } from '../../../';

const formikSchema = {
  validationSchema: yup.object({
    id: yup.string().required(),
    documents: yup.array(documentUpdateSchema.required()).notRequired(),
  }),
};

export default formikSchema;
