import { gql } from '@apollo/client';

const GET_LICENCES = gql`
  query getOperator($id: ID!) {
    getOperator(id: $id) {
      licences {
        documentNumber
        documentType
        status
        validFrom
        validTo
        createdByOperator {
          firstName
          lastName
        }
        createdAt
      }
    }
  }
`;

export { GET_LICENCES };
