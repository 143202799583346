import { useContext } from 'react';
import {
  CurrentEntityContext,
  CurrentLoggedInUserContext,
  CurrentModuleContext,
  CurrentPathContext,
  DashboardModeContext,
  LoadingContext,
  MapCenterContext,
  ModalContext,
  NotificationsContext,
  SideNavContext,
} from '../context';

const useCurrentEntity = () => {
  const {
    linkParams,
    currentContextLink,
    currentGroup,
    currentOrg,
    currentSite,
    currentEntity,
    currentOrgSettings,
  } = useContext(CurrentEntityContext);
  return {
    linkParams,
    currentContextLink,
    currentGroup,
    currentOrg,
    currentSite,
    currentEntity,
    currentOrgSettings,
  };
};

const useCurrentModule = () => {
  const { currentModule, moduleAllowed, modulePermissions } =
    useContext(CurrentModuleContext);

  return {
    currentModule,
    moduleAllowed,
    modulePermissions,
  };
};

const useCurrentPath = () => {
  const {
    pathArray,
    modulePath,
    groupsPath,
    groupPath,
    orgsPath,
    orgPath,
    sitesPath,
    sitePath,
  } = useContext(CurrentPathContext);
  return {
    pathArray,
    modulePath,
    groupsPath,
    groupPath,
    orgsPath,
    orgPath,
    sitesPath,
    sitePath,
  };
};

const useDashboardMode = () => {
  const {
    isDashboardMode,
    dashboardMenuItems,
    setDashboardMode,
    setDashboardMenuItems,
  } = useContext(DashboardModeContext);
  return {
    isDashboardMode,
    dashboardMenuItems,
    setDashboardMode,
    setDashboardMenuItems,
  };
};

const useLoadingContext = () => {
  const { loading, setLoading, progress, setProgress } =
    useContext(LoadingContext);
  return {
    loading,
    setLoading,
    progress,
    setProgress,
  };
};

const useMapCenter = () => {
  const { center, setCenter } = useContext(MapCenterContext);
  return {
    center,
    setCenter,
  };
};

const useModal = () => {
  const { toggleModal, setModalContent, setDimensions, clearModal } =
    useContext(ModalContext);
  return {
    toggleModal,
    setModalContent,
    setDimensions,
    clearModal,
  };
};

const useNotifications = () => {
  const { inlineAlert, addInlineAlert, addNotification } =
    useContext(NotificationsContext);
  return {
    inlineAlert,
    addInlineAlert,
    addNotification,
  };
};

const useSideNav = () => {
  const {
    isSideNavOpen,
    toggleSideNav,
    sideNavOptions,
    setSideNavOptions,
    setIsSideNavOpen,
  } = useContext(SideNavContext);
  return {
    isSideNavOpen,
    toggleSideNav,
    sideNavOptions,
    setSideNavOptions,
    setIsSideNavOpen,
  };
};

const useLoggedInUser = () => {
  const {
    fetchUser,
    currentUser,
    userType,
    assignedToStateLoading,
    userAuthValues,
    userPermissions,
  } = useContext(CurrentLoggedInUserContext);
  return {
    fetchUser,
    currentUser,
    userType,
    assignedToStateLoading,
    userAuthValues,
    userPermissions,
  };
};

export {
  useCurrentEntity,
  useCurrentModule,
  useCurrentPath,
  useDashboardMode,
  useLoadingContext,
  useMapCenter,
  useModal,
  useNotifications,
  useSideNav,
  useLoggedInUser,
};
