import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 400px;
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  grid-gap: 0.5rem;
  font-size: 0.75rem;
`;

const ActionBar = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
  grid-gap: 1rem;
  & input {
    border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
`;

const CoordLine = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr auto 2fr 1fr;
  grid-gap: 0.5rem;
  > p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
`;

export { Container, ActionBar, CoordLine };
