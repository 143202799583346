import styled from 'styled-components';

const Container = styled.div`
  font-size: 0.75rem;
`;
const Label = styled.div`
  padding-bottom: 0.5rem;
`;

const ToggleLineContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
  align-items: center;
`;

const ToggleContainer = styled.div`
  width: 2.3rem;
  height: 1rem;
  background: ${({ theme, toggleOn }) =>
    theme.colors[toggleOn ? 'primary' : 'mediumGrey']};
  border-radius: 100rem;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 0 0.2rem;
  cursor: pointer;
  justify-items: ${({ toggleOn }) => (toggleOn ? 'end' : 'start')};
  transition: all 200ms ease;
`;

const ToggleButton = styled.div`
  transition: all 200ms ease;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100px;
  font-size: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
`;

export { Container, Label, ToggleContainer, ToggleButton, ToggleLineContainer };
