import { ApolloError } from '@apollo/client';
import { Location32 } from '@carbon/icons-react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  & h1 {
    color: ${theme.colors.danger};
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
  }
  & path,
  circle {
    fill: ${theme.colors.danger};
  }
`;

const ErrorShow = styled.div`
  font-size: 0.7rem;
  margin: 1rem;
  border: 1px solid ${theme.colors.black};
  padding: 0.3rem;
  border-radius: 10px;
  cursor: pointer;
`;

const PageError: React.FC<{ error?: Error; show?: boolean }> = ({
  error,
  show,
}) => {
  const [blobError, setBlobError] = useState('');
  let message = error?.message;

  const blob = (error as any)?.response?.data as Blob;
  const reader = new FileReader();
  reader.onload = () => {
    const result = reader.result;
    if (typeof result === 'string') {
      setBlobError(result);
    }
  };
  blob && reader.readAsText(blob);

  if (error instanceof ApolloError) {
    const networkError = ((error.networkError || {}) as any).result?.errors
      ?.map((e: any) => e?.message)
      .join(', ');
    message = networkError || error?.message;
  }
  const [showMessage, setShowMessage] = useState(false);
  return show ? (
    <Wrapper>
      <Location32 />
      <h1>{blobError || message}</h1>
    </Wrapper>
  ) : (
    <Wrapper>
      <Location32 />
      <h1>We've Encountered an Issue...</h1>
      <br />
      <p>
        Our team has been notified of the problem and will provide a fix as soon
        as possible.
      </p>
      <p>
        For further queries, please contact Trackmatic Support. We are sorry for
        the inconvenience.
      </p>
      {showMessage ? (
        <ErrorShow>{blobError || message}</ErrorShow>
      ) : (
        <ErrorShow onClick={() => setShowMessage(true)}>Show Error</ErrorShow>
      )}
    </Wrapper>
  );
};

export default PageError;
