import { gql } from '@apollo/client';

const GET_GROUP = gql`
  query getGroup(
    $id: ID!
    $take: Int!
    $skip: Int!
    $query: QueryOrgs
    $order: [[String]]
  ) {
    getGroup(id: $id) {
      orgsPaged(
        pagination: { take: $take, skip: $skip }
        query: $query
        order: $order
      ) {
        data {
          id
          name
        }
        pagination {
          skip
          take
          total
        }
      }
    }
  }
`;
export { GET_GROUP };
