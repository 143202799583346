import { lighten } from 'polished';
import styled from 'styled-components';

const ReportViewContainer = styled.div`
  display: grid;
  grid-template-columns: 21% 78%;
  grid-column-gap: 1%;
  height: 100%;
  width: 100%;
  padding: 1rem;
`;

const FiltersContainer = styled.div`
  height: 100%;
  padding: 1rem;
  background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
`;

const Report = styled.div`
  height: 100%;
  background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Filters = styled.div`
  width: 100%;
  margin: 1rem 0;
  > div:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PDFContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  & iframe {
    width: 100%;
    height: 100%;
  }
`;

export {
  ReportViewContainer,
  FiltersContainer,
  Report,
  Filters,
  PDFContainer,
  HeaderWrapper,
};
