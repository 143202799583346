import * as yup from 'yup';

const validationSchema = yup.object({
  sites: yup
    .array(
      yup.object({
        site: yup.string().required(),
        apps: yup.array().min(1).required(),
      }),
    )
    .required(),
});

export default validationSchema;
