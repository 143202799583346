import * as yup from 'yup';
import {
  yupBooleanNullable,
  yupNumberNullable,
  yupStringArrayRequired,
  yupStringRequired,
} from '../../..';

const routingViolationSchema = yup.object({
  isRequired: yupBooleanNullable,
  durationInSeconds: yupNumberNullable,
});

const formikSchema = ({ existingSchema, orgId }) => ({
  initialValues: existingSchema || {
    orgId,
    useEntityDefaultMst: false,
    allowEditingActiveLoad: false,
    allowEditingPersistedTravelPlan: false,
    loadCancellationReasons: [],
    loadReinstantiationReasons: [],
    loadRequestDeclineReasons: [],
    loadRejectionReasons: [],
    loadForceCloseReasons: [],
    loadDeclineReasons: [],
    loadFailureReasons: [],
    loadAbortReasons: [],
    actionManagementFailureReasons: [],
    dropOffHandlingUnitConfiguration: {
      failureReasons: [],
      rejectReasons: [],
      endorsementReasons: [],
      endorsementNoDiscrepancyReasons: [],
      endorsementType: undefined,
    },
    pickupHandlingUnitConfiguration: {
      failureReasons: [],
      rejectReasons: [],
      endorsementReasons: [],
      endorsementNoDiscrepancyReasons: [],
      endorsementType: undefined,
    },
    routingViolations: {
      unknownStop: { isRequired: false, durationInSeconds: undefined },
      excessStop: { isRequired: false, durationInSeconds: undefined },
      unplannedStop: { isRequired: false, durationInSeconds: undefined },
      unattended: { isRequired: false, durationInSeconds: undefined },
    },
    trackingViolations: {
      maxSpeed: undefined,
      idleDurationInSeconds: undefined,
    },
  },
  validationSchema: yup.object({
    orgId: yupStringRequired,
    loadCancellationReasons: yupStringArrayRequired,
    loadDeclineReasons: yupStringArrayRequired,
    loadReinstantiationReasons: yupStringArrayRequired,
    loadRejectionReasons: yupStringArrayRequired,
    loadForceCloseReasons: yupStringArrayRequired,
    loadRequestDeclineReasons: yupStringArrayRequired,
    loadFailureReasons: yupStringArrayRequired,
    loadAbortReasons: yupStringArrayRequired,
    actionManagementFailureReasons: yupStringArrayRequired,
    routingViolations: yup.object({
      unknownStop: routingViolationSchema.required(),
      excessStop: routingViolationSchema.required(),
      unplannedStop: routingViolationSchema.required(),
      unattended: routingViolationSchema.required(),
    }),
    trackingViolations: yup.object({
      maxSpeed: yupNumberNullable,
      idleDurationInSeconds: yupNumberNullable,
    }),
  }),
});

export default formikSchema;
