import { gql } from '@apollo/client';

const UPDATE_APPS = gql`
  mutation manageOperatorSiteApps($input: OperatorSiteInput!) {
    manageOperatorSiteApps(input: $input)
  }
`;

const UPDATE_OPERATOR = gql`
  mutation updateOperator($input: OperatorUpdateInput!) {
    updateOperator(input: $input) {
      id
    }
  }
`;

const GET_OPERATOR = gql`
  query getOperator($id: ID!) {
    getOperator(id: $id) {
      firstName
      lastName
      mobile
      reference
      id
      sites {
        apps
        site {
          id
        }
      }
      licences {
        documentNumber
        status
        validFrom
        validTo
        createdByOperator {
          firstName
          lastName
        }
        createdAt
      }
    }
  }
`;

export { UPDATE_OPERATOR, UPDATE_APPS, GET_OPERATOR };
