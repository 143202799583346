import { PageContainer } from '../../../../../../';
import React from 'react';
import Site from './Site';
import Admin from './Admin';

const Apps = ({
  data,
  outcomeObject,
  setCurrentFocusIndex,
  childIndex,
  appsArray,
  siteId,
}) => {
  return (
    <PageContainer height={'70vh'} align="end" gridStyling={'auto'}>
      {siteId ? (
        <Site
          data={data}
          outcomeObject={outcomeObject}
          appsArray={appsArray}
          siteId={siteId}
          setCurrentFocusIndex={setCurrentFocusIndex}
          childIndex={childIndex}
        />
      ) : (
        <Admin
          data={data}
          outcomeObject={outcomeObject}
          setCurrentFocusIndex={setCurrentFocusIndex}
          childIndex={childIndex}
        />
      )}
    </PageContainer>
  );
};

export default Apps;
