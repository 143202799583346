import { gql } from '@apollo/client';

const FETCH_INITIAL_DASHBOARD = gql`
  query fetchInitialDashboard($id: ID!) {
    getOrg(id: $id) {
      carrierLoads(
        pagination: { take: 1000000, skip: 0 }
        query: { status: ACTIVE }
      ) {
        data {
          id
          name
          reference
          status
          org {
            id
            name
          }
          travelPlan {
            stops {
              id
              location {
                data {
                  id
                  name
                }
              }
              entities {
                activities {
                  id
                  direction
                }
              }
            }
          }
          allocation {
            driver {
              firstName
              lastName
            }
            vehicle {
              assetId
              asset {
                licenceNumber
                fleetNumber
                type
                currentPosition {
                  gps {
                    lat
                    lng
                    dateTime
                  }
                  location {
                    id
                    name
                    stopId
                    dateEntered
                  }
                  address
                  status
                }
              }
              licenceNo
            }
          }
          execution {
            id
            activities {
              activityId
              workflowExecutionId
              status
              started
              ended
            }
            trips {
              tripId
              state
              destination {
                locationName
                locationId
                stopId
              }
            }
          }
          extrapolation {
            stops {
              stopId
              locationName
              locationId
              position {
                lat
                lng
              }
              duration
              estimatedEnd
              estimatedStart
              estimatedDistance
              estimatedTravelTime
            }
          }
        }
        pagination {
          take
          skip
          total
        }
      }
    }
  }
`;

const FETCH_INITIAL_DASHBOARD_FOR_ORG = gql`
  query fetchInitialDashboard($id: ID!) {
    getOrg(id: $id) {
      allAssociatedLoads(
        pagination: { take: 1000000, skip: 0 }
        query: { status: ACTIVE }
      ) {
        data {
          id
          name
          reference
          status
          org {
            id
            name
          }
          travelPlan {
            stops {
              id
              location {
                data {
                  id
                  name
                }
              }
              entities {
                activities {
                  id
                  direction
                }
              }
            }
          }
          allocation {
            driver {
              firstName
              lastName
            }
            vehicle {
              assetId
              asset {
                licenceNumber
                fleetNumber
                type
                currentPosition {
                  gps {
                    lat
                    lng
                    dateTime
                  }
                  location {
                    id
                    name
                    stopId
                    dateEntered
                  }
                  address
                  status
                }
              }
              licenceNo
            }
          }
          execution {
            id
            activities {
              activityId
              workflowExecutionId
              status
              started
              ended
            }
            trips {
              tripId
              state
              destination {
                locationName
                locationId
                stopId
              }
            }
          }
          extrapolation {
            stops {
              stopId
              locationName
              locationId
              position {
                lat
                lng
              }
              duration
              estimatedEnd
              estimatedStart
              estimatedDistance
              estimatedTravelTime
            }
          }
        }
        pagination {
          take
          skip
          total
        }
      }
    }
  }
`;

export { FETCH_INITIAL_DASHBOARD, FETCH_INITIAL_DASHBOARD_FOR_ORG };
