import { useQuery } from '@apollo/client';
import { Field, Form as FormikForm, useFormikContext } from 'formik';
import { uniq } from 'ramda';
import React from 'react';
import {
  ActionButtons,
  CheckBoxFormField,
  Column,
  convertEnumObject,
  Dropdown,
  FormArea,
  FormItem,
  FormWrapper,
  listSortAndFormat,
  PageError,
  PageLoading,
  PhoneNumberField,
  TableDynamicInput,
  TextFormField,
} from '../../../';
import { GET_META, GET_META_FOR_ORG } from './gql';

const ContactForm = ({
  currentEntity,
  isEdit,
  type = 'ORG',
  isRoutes = false,
}) => {
  const { values } = useFormikContext();

  const { data, error } = useQuery(
    type === 'CARRIER' ? GET_META : GET_META_FOR_ORG,
    {
      variables: { orgId: currentEntity.id },
    },
  );

  if (error) {
    return <PageError error={error} />;
  }

  if (data) {
    const events = isRoutes
      ? [
          { value: 'LOAD_SCHEDULED', label: 'Route Scheduled' },
          { value: 'LOAD_ACTIVATED', label: 'Routed Activated' },
          { value: 'NOTIFY_NEXT', label: 'Notify Next' },
          { value: 'LOAD_LOCATION_ARRIVED', label: 'Location Arrival' },
          { value: 'LOAD_LOCATION_DEPARTED', label: 'Location Departure' },
          { value: 'LOAD_ACTIVITY_COMPLETED', label: 'Delivery Confirmation' },
          { value: 'LOAD_ACTIVITY_FAILED', label: 'Failed Visit' },
          { value: 'WORKFLOW_FAILURE', label: 'Failed Workflow' },
        ]
      : convertEnumObject({ __type: data.getCommEvents }, false);
    return (
      <FormWrapper>
        <FormikForm>
          <FormArea columns={2}>
            <Column>
              <FormItem>
                <Field
                  labelText={'First Name *'}
                  name={'firstName'}
                  component={TextFormField}
                />
              </FormItem>
              <FormItem>
                <Field
                  labelText={'Last Name *'}
                  name={'lastName'}
                  component={TextFormField}
                />
              </FormItem>
              <FormItem>
                <Field
                  labelText={'Email'}
                  name={'email'}
                  component={TextFormField}
                />
              </FormItem>
              <FormItem>
                <Field
                  labelText={'Mobile'}
                  name={'mobile'}
                  component={PhoneNumberField}
                />
              </FormItem>
              {type === 'CARRIER' && (
                <FormItem>
                  <Field
                    labelText={'Organisation'}
                    name={'orgId'}
                    component={Dropdown}
                    options={listSortAndFormat(
                      uniq(data.getCarrier.yardCarriers.data.map((d) => d.org)),
                      'name',
                      'id',
                    )}
                  />
                </FormItem>
              )}
              <FormItem>
                <Field
                  labelText={'Role'}
                  name={'position'}
                  component={TextFormField}
                />
              </FormItem>
            </Column>
            <Column style={{ width: '40rem' }}>
              <TableDynamicInput
                name="comsEvents"
                columns={[
                  {
                    key: 'event',
                    header: 'Event',
                    Component: Dropdown,
                    componentProps: {
                      options: events,
                    },
                    dependsOnSelf: true,
                    dependsOnFunction: (_, thisValue) => {
                      return {
                        options: events.filter(
                          (e) =>
                            !values.comsEvents
                              .map((ee) => ee.event)
                              .includes(e.value) || e.value === thisValue,
                        ),
                      };
                    },
                  },
                  {
                    key: 'email',
                    header: 'Email',
                    Component: CheckBoxFormField,
                  },
                  {
                    key: 'sms',
                    header: 'SMS',
                    Component: CheckBoxFormField,
                  },
                ]}
                headerText=""
                actionButtonText="Add Event"
                rows={values.comsEvents}
                newRow={{ event: '', email: false, sms: false }}
                disableNewRow={!values.comsEvents.every((d) => d.event)}
              />
            </Column>
          </FormArea>
        </FormikForm>
        <ActionButtons
          type="finalStep"
          primaryName={isEdit ? 'Save Changes' : 'Create'}
        />
      </FormWrapper>
    );
  }
  return <PageLoading />;
};

export default ContactForm;
