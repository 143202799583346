import styled from 'styled-components';

const DividerLine = styled.div`
  width: 1px;
  background: ${({ theme }) => theme.colors.mediumGrey};
  height: 80%;
  border-radius: 50px;
  margin: 0 1rem;
`;

export default DividerLine;
