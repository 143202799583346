import React from 'react';
import { formatDateTime } from '../../../../functions';
import { theme, PageContainer, Table, Th } from '../../../../styles';
import { orderBy } from 'lodash';
const {
  colors: { success, danger },
} = theme;
const Licences = ({ data }) => {
  return (
    <PageContainer height={'70vh'} align="end">
      <Table>
        <thead>
          <tr>
            <Th noHover>Licence Number</Th>
            <Th noHover>Expiry Date</Th>
            <Th noHover>Captured By</Th>
            <Th noHover>Date Captured</Th>
            <Th noHover>Status</Th>
          </tr>
        </thead>
        <tbody>
          {orderBy(data, [(d) => new Date(d.expiryDate)], ['desc']).map((d) => {
            return (
              <tr key={d.id}>
                <td>
                  <p>{d.registration}</p>
                </td>
                <td>
                  <p>{formatDateTime(d.expiryDate, null, false)}</p>
                </td>
                <td>
                  <p>
                    {d.createdBy
                      ? `${d.createdBy.firstName} ${d.createdBy.lastName}`
                      : ''}
                  </p>
                </td>
                <td>
                  <p>{formatDateTime(d.createdAt, null, false)}</p>
                </td>
                <td>
                  <p
                    style={{
                      fontWeight: 'bold',
                      color: d.status === 'ACTIVE' ? success : danger,
                    }}
                  >
                    {d.status}
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </PageContainer>
  );
};

export default Licences;
