import { gql } from '@apollo/client';

const GET_SITES = gql`
  query getOrg($orgId: ID!) {
    getOrg(id: $orgId) {
      sites {
        id
        name
      }
    }
  }
`;

export { GET_SITES };
