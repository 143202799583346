import * as yup from 'yup';

const validationSchema = (showSites) =>
  yup.object({
    licenceNumber: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z0-9]*$/, 'Licence Number Can Only be Alphanumeric')
      .max(9, 'Licence Number Cannot Exceed 9 Characters')
      .required('Please enter a Licence'),
    type: yup.string('Please enter a Type').required('Please enter a Type'),
    assetClassId: yup
      .string('Please enter an Asset Class')
      .required('Please enter an Asset Class'),
    sites: showSites
      ? yup
          .array()
          .of(
            yup.object({
              id: yup.string().required(),
              name: yup.string().required(),
            }),
          )
          .min(1)
          .required()
      : yup.array().of(
          yup
            .object({
              id: yup.string().required(),
              name: yup.string().required(),
            })
            .optional(),
        ),
    isRearTrailer: yup.boolean().nullable(),
  });

export default validationSchema;
