import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton } from '../Buttons';

let LogicFlowRedirect = ({ className, to, message, title, buttonText }) => {
  const history = useHistory();
  return (
    <Fragment>
      <div className={className}>
        <h1>{title}</h1>
        <p>{message}</p>
      </div>
      <PrimaryButton onClick={() => (to ? history.push(to) : history.goBack())}>
        {buttonText || 'Go Back'}
      </PrimaryButton>
    </Fragment>
  );
};

LogicFlowRedirect = styled(LogicFlowRedirect)`
  & h1,
  & p {
    margin-bottom: 20px;
  }
  color: ${({ theme }) => theme.colors.secondary};
`;

export default LogicFlowRedirect;
