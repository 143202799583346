import { Field, Form, Formik } from 'formik';
import { omit } from 'lodash';
import React from 'react';
import {
  ActionButtons,
  Column,
  Dropdown,
  FormArea,
  FormItem,
  listSortAndFormat,
  PageContainer,
  TextFormField,
} from '../../../../';
import initialValues from './initialValues';

const Dimension = ({ editAssetMutation, data, updatedData }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues(data, updatedData)}
      onSubmit={(values) => {
        editAssetMutation({
          variables: {
            input: omit(values, [
              'masterAsset',
              'tyreTransactions',
              'engineNumber',
              'vin',
            ]),
          },
        });
      }}
    >
      <PageContainer height={'70vh'} align="end">
        <Form>
          <FormArea columns={2}>
            <Column>
              <FormItem>
                <Field
                  unit={'kgs'}
                  type="number"
                  labelText="Weight"
                  name="vehicleMass"
                  component={TextFormField}
                />
              </FormItem>
              <FormItem>
                <Field
                  type="number"
                  labelText="Units"
                  name="units"
                  component={TextFormField}
                />
              </FormItem>
              <FormItem>
                <Field
                  unit={'cbm'}
                  type="number"
                  labelText="Volume"
                  name="volume"
                  component={TextFormField}
                />
              </FormItem>
              <FormItem>
                <Field
                  type="number"
                  labelText="Pallets"
                  name="pallets"
                  component={TextFormField}
                />
              </FormItem>
            </Column>
            <Column>
              <FormItem>
                <Field
                  unit={'Litres'}
                  type="number"
                  labelText="Litres"
                  name="litres"
                  component={TextFormField}
                />
              </FormItem>
              <FormItem>
                <Field
                  type="number"
                  labelText="Seals"
                  name="seals"
                  component={TextFormField}
                />
              </FormItem>
              <FormItem>
                <Field
                  type="number"
                  labelText="Compartments"
                  name="compartments"
                  component={TextFormField}
                />
              </FormItem>
              <FormItem>
                <Field
                  labelText="Tyres"
                  name="numTyres"
                  component={Dropdown}
                  options={listSortAndFormat(
                    [...Array(21)]
                      .map((_, idx) => idx)
                      .filter((n) => n % 2 === 0),
                    (a) => a,
                    (a) => a,
                  )}
                />
              </FormItem>
            </Column>
          </FormArea>
        </Form>

        <ActionButtons primaryName="Save & Continue" />
      </PageContainer>
    </Formik>
  );
};

export default Dimension;
