import setup from './setup';
import config from '../config';

const deleteFile = async (Key, isPublic = true) => {
  try {
    const s3 = setup();
    await s3
      .deleteObject({ Bucket: config.s3(isPublic).bucketName, Key })
      .promise();
    return { result: 'success' };
  } catch (e) {
    return { error: e };
  }
};

export default deleteFile;
