import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { EditLink } from '../../styles';

const FlexActions = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  > * {
    margin: 0 5px;
    font-size: 0.8rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    :hover {
      color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
    }
  }
`;

let InlineNotification = ({
  title,
  subtitle,
  link,
  linkText,
  className,
  handleClick,
  handleClickText,
  multipleActions,
}) => {
  return (
    <div className={className}>
      <div>
        <h5>{title}</h5>
        <p>{subtitle}</p>
      </div>
      {link && link === 'back' ? (
        <p onClick={() => window.history.back()}>Go Back</p>
      ) : handleClick ? (
        <p onClick={handleClick}>{handleClickText}</p>
      ) : multipleActions ? (
        <FlexActions>
          {multipleActions.map((action) =>
            action.link ? (
              <EditLink to={action.link}>{action.text}</EditLink>
            ) : (
              <p onClick={action.onclick}>{action.text}</p>
            ),
          )}
        </FlexActions>
      ) : (
        <EditLink to={link}>{linkText}</EditLink>
      )}
    </div>
  );
};

InlineNotification = styled(InlineNotification)`
  ${({ width }) => width && `width: ${width};`}
  min-width:450px;
  min-height: 40px;
  padding: 5px 20px;
  & div p {
    font-size: 0.8rem;
  }
  > p {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    :hover {
      color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
    }
  }
  background: ${({ kind, theme }) =>
    lighten(
      0.45,
      kind === 'info'
        ? theme.colors.primary
        : kind === 'success'
        ? theme.colors.success
        : kind === 'error'
        ? theme.colors.danger
        : theme.colors.lightGrey,
    )};
  border-left: 6px solid
    ${({ kind, theme }) =>
      kind === 'info'
        ? theme.colors.primary
        : kind === 'success'
        ? theme.colors.success
        : kind === 'error'
        ? theme.colors.danger
        : theme.colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default InlineNotification;
