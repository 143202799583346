import { gql } from '@apollo/client';

const DELETE_ASSET = gql`
  mutation softDeleteAsset($id: ID!) {
    softDeleteAsset(id: $id)
  }
`;

const GET_ORG = gql`
  query getOrg(
    $id: ID!
    $take: Int!
    $skip: Int!
    $query: QueryAssets
    $order: [[String]]
  ) {
    getOrg(id: $id) {
      assetsPaged(
        pagination: { take: $take, skip: $skip }
        query: $query
        order: $order
      ) {
        data {
          status
          id
          qrCode
          assetGroupCount
          siteCount
          licenceNumber
          fleetNumber
          type
          vin
          isRearTrailer
          masterAsset {
            vin
          }
          assetClassId
        }
        pagination {
          take
          skip
          total
        }
      }
    }
  }
`;

const GET_SITE = gql`
  query getSite(
    $id: ID!
    $take: Int!
    $skip: Int!
    $order: [[String]]
    $query: QueryAssets
  ) {
    getSite(id: $id) {
      assetsPaged(
        pagination: { take: $take, skip: $skip }
        query: $query
        order: $order
      ) {
        data {
          status
          id
          qrCode
          assetGroupCount
          siteCount
          licenceNumber
          fleetNumber
          type
          vin
          isRearTrailer
          masterAsset {
            vin
          }
          assetClassId
        }
        pagination {
          take
          skip
          total
        }
      }
    }
  }
`;

const GET_ASSET_CLASSES_GROUPS = gql`
  query getOrg($id: ID!) {
    getOrg: getOrg(id: $id) {
      assetGroupsPaged(pagination: { take: 10000000, skip: 0 }) {
        data {
          id
          name
        }
      }
      assetClasses {
        id
        name
        isLink
      }
      sites {
        id
        name
      }
    }
    assetStatuses: __type(name: "AssetStatusEnum") {
      enumValues {
        name
      }
    }
    assetTypes: __type(name: "AssetTypeEnums") {
      enumValues {
        name
      }
    }
  }
`;
const GET_ASSET_CLASSES_GROUPS_SITE_LEVEL = gql`
  query getSite($id: ID!) {
    getSite: getSite(id: $id) {
      org {
        assetGroupsPaged(pagination: { take: 10000000, skip: 0 }) {
          data {
            id
            name
          }
        }
        assetClasses {
          id
          name
          isLink
        }
        sites {
          id
          name
        }
      }
    }
    assetStatuses: __type(name: "AssetStatusEnum") {
      enumValues {
        name
      }
    }
    assetTypes: __type(name: "AssetTypeEnums") {
      enumValues {
        name
      }
    }
  }
`;

export {
  GET_ORG,
  GET_ASSET_CLASSES_GROUPS,
  GET_SITE,
  GET_ASSET_CLASSES_GROUPS_SITE_LEVEL,
  DELETE_ASSET,
};
