import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Dropdown from '../Dropdown';
import { Container, Info, ItemsPerPage, Left, Right } from './styles';
const Pagination = ({ page, pageSize, totalItems, pageSizes, onChange }) => {
  const theme = useContext(ThemeContext);
  const firstItem = (page - 1) * pageSize + 1;
  const lastItem = Math.min(totalItems, pageSize * page);
  const totalPages = Math.ceil(totalItems / pageSize);
  const totalPagesOptions =
    totalPages === 0
      ? [{ value: 0, label: 0 }]
      : [...Array(totalPages)].map((_, idx) => ({
          value: idx + 1,
          label: idx + 1,
        }));
  const styles = {
    menuList: () => ({ zIndex: 100000, overflow: 'auto' }),
    menu: () => ({
      zIndex: 100000,
      overflow: 'auto',
      position: 'absolute',
      background: theme.colors.lightGrey,
    }),
    indicatorsContainer: () => ({
      padding: '0 0.5rem',
    }),
    control: (origStyles) => ({
      ...origStyles,
      border: 'none',
      cursor: 'pointer',
      width: '80px',
    }),
  };
  return (
    <Container>
      <ItemsPerPage>
        <p>Items Per Page:</p>
        <Dropdown
          isClearable={false}
          options={pageSizes.map((pageSizeOption) => ({
            value: pageSizeOption,
            label: pageSizeOption,
          }))}
          value={pageSize}
          styles={styles}
          onChange={(d) => {
            onChange({ page, pageSize: d });
          }}
          noBorders
        />
      </ItemsPerPage>
      <Info>
        {firstItem} - {lastItem} of {totalItems} items
      </Info>
      <ItemsPerPage style={{ gridTemplateColumns: '2fr 3fr' }}>
        <Dropdown
          isClearable={false}
          options={totalPagesOptions}
          value={totalPages === 0 ? 0 : page}
          styles={styles}
          onChange={(d) => {
            onChange({ pageSize, page: d });
          }}
          noBorders
        />
        <p>of {totalPages} Pages</p>
      </ItemsPerPage>
      <Left
        disabled={page === 1}
        onClick={() => page !== 1 && onChange({ pageSize, page: page - 1 })}
      />
      <Right
        disabled={page === totalPages}
        onClick={() =>
          page !== totalPages && onChange({ pageSize, page: page + 1 })
        }
      />
    </Container>
  );
};

export default Pagination;
