import { getLoadAssetCurrentPosition, theme } from '../../../../';
import { determinePickupOrDropoff, getCurrentActivity } from './functions';

const STATUSES = [
  {
    status: 'Driving',
    color: theme.colors.tmPrimary,
    headers: [
      { name: 'drivingIndicator', header: '' },
      { name: 'reference', header: 'Load Reference' },
      { name: 'asset', header: 'Asset' },
      { name: 'driver', header: 'Driver' },
      { name: 'destination', header: 'Destination' },
      { name: 'distance', header: 'Distance' },
      { name: 'eta', header: 'ETA' },
      { name: 'due', header: 'Due' },
    ],
    loadDeterminationFunction: (load) => {
      const currentPosition = getLoadAssetCurrentPosition(load, true);
      return (
        ['MOVING', 'IDLE'].includes(currentPosition.status) &&
        !currentPosition.location
      );
    },
  },
  {
    status: 'At Pickup',
    color: theme.colors.loads.PICKUP,
    headers: [
      { name: 'drivingIndicator', header: '' },
      { name: 'reference', header: 'Load Reference' },
      { name: 'shipper', header: 'Shipper' },
      { name: 'driver', header: 'Driver' },
      { name: 'location', header: 'Location' },
      { name: 'arrivalTime', header: 'Arrival Time' },
      { name: 'duration', header: 'Duration' },
      { name: 'progress', header: '' },
    ],
    loadDeterminationFunction: (load) =>
      determinePickupOrDropoff({ load, type: 'PICKUP' }),
  },
  {
    status: 'Location',
    color: theme.colors.tmSecondary,
    headers: [
      { name: 'drivingIndicator', header: '' },
      { name: 'reference', header: 'Load Reference' },
      { name: 'asset', header: 'Asset' },
      { name: 'driver', header: 'Driver' },
      { name: 'location', header: 'Location' },
      { name: 'arrivalTime', header: 'Arrival Time' },
      { name: 'duration', header: 'Duration' },
    ],
    loadDeterminationFunction: (load) => {
      const currentActivity = getCurrentActivity(load);
      const currentPosition = getLoadAssetCurrentPosition(load, true);
      if (!currentActivity && currentPosition && currentPosition.location) {
        const {
          location: { id: currentLocationId },
        } = currentPosition;
        const allLocationsInTravelPlan = load.travelPlan.stops.map(
          (s) => s.location.data.id,
        );
        return (
          currentLocationId &&
          !allLocationsInTravelPlan.some((id) => id === currentLocationId)
        );
      }
      return !currentActivity;
    },
  },
  {
    status: 'At Dropoff',
    color: theme.colors.loads.DROPOFF,
    headers: [
      { name: 'drivingIndicator', header: '' },
      { name: 'reference', header: 'Load Reference' },
      { name: 'shipper', header: 'Shipper' },
      { name: 'driver', header: 'Driver' },
      { name: 'location', header: 'Location' },
      { name: 'arrivalTime', header: 'Arrival Time' },
      { name: 'duration', header: 'Duration' },
      { name: 'progress', header: '' },
    ],
    loadDeterminationFunction: (load) =>
      determinePickupOrDropoff({ load, type: 'DROPOFF' }),
  },
];

export { STATUSES };
