import { useQuery } from '@apollo/client';
import React, { createContext, useEffect, useState } from 'react';
import {
  localStorageKeys,
  PageError,
  PageLoading,
  setSentryContext,
} from '../../../';
import { determineUserType } from './functions';
import GET_CURRENT_USER from './gql';

const CurrentLoggedInUserContext = createContext();

const LoginProvider = ({ children }) => {
  const [usersGroups, setUsersGroups] = useState([]);
  const [usersOrgs, setUsersOrgs] = useState([]);
  const [usersSites, setUsersSites] = useState([]);
  const [usersFacilities, setUsersFacilities] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [userType, setUserType] = useState();
  const [userPermissions, setUserPermissions] = useState([]);
  const [assignedToStateLoading, setAssignedToStateLoading] = useState(false);
  const { error, refetch: fetchUser } = useQuery(GET_CURRENT_USER, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getCurrentUser }) => {
      setCurrentUser(getCurrentUser);
      if (getCurrentUser.isEnabled) {
        setUserPermissions(getCurrentUser.permissions);
        setAssignedToStateLoading(true);
        const { isTMUser, isGroupUser } = getCurrentUser;
        const type = determineUserType(
          isTMUser,
          isGroupUser,
          getCurrentUser.contextOrgs,
          getCurrentUser.contextSites,
        );
        setUserType(type);
        setUsersOrgs(getCurrentUser.contextOrgs);
        setUsersSites(getCurrentUser.contextSites);
      } else {
        /**
         * TODO
         * Redirect User // Logout
         */
      }
    },
  });

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      setSentryContext(currentUser);
    }
  }, [currentUser]);

  if (error) {
    localStorage.removeItem(localStorageKeys.idToken);
    return <PageError error={error} />;
  }
  if (currentUser.id) {
    if (!currentUser.isEnabled) {
      return (
        <PageError
          error={
            new Error(
              'Your user is disabled. Please contact Trackmatic Support',
            )
          }
          show
        />
      );
    }
    return (
      <CurrentLoggedInUserContext.Provider
        value={{
          fetchUser,
          currentUser,
          userType,
          assignedToStateLoading,
          userAuthValues: {
            usersGroups,
            usersOrgs,
            usersSites,
            usersFacilities,
          },
          userPermissions,
        }}
      >
        {children}
      </CurrentLoggedInUserContext.Provider>
    );
  }
  return <PageLoading fillScreen />;
};

export { CurrentLoggedInUserContext, LoginProvider };
