import setup from './setup';
import config from '../config';

const deleteFiles = async (keys, isPublic = true) => {
  const s3 = setup();
  try {
    const Objects = keys.map((Key) => ({ Key }));
    await s3
      .deleteObjects({
        Bucket: config.s3(isPublic).bucketName,
        Delete: { Objects },
      })
      .promise();
    return { result: 'success' };
  } catch (e) {
    return { error: e };
  }
};

export default deleteFiles;
