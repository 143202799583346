import styled, { css } from 'styled-components';
import { darken } from 'polished';

const StepContainer = styled.div`
  border-top: 2px solid
    ${({ theme, current, complete }) =>
      theme.colors[current || complete ? 'primary' : 'lightGrey']};
  padding: 0.7rem 0 1.2rem 0;
  width: 10rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
  > div {
    :first-child {
      position: relative;
      border: 1px solid
        ${({ theme, current, complete }) =>
          theme.colors[current || complete ? 'primary' : 'mediumGrey']};
      ${({ theme, complete }) =>
        complete &&
        css`
          ::after {
            position: absolute;
            content: '✔';
            font-size: 0.5rem;
            color: ${theme.colors.white};
            background: ${theme.colors.primary};
            border-radius: 100px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        `}
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 100px;
    }
    :last-child {
      > h3 {
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
        color: ${({ theme }) => darken(0.1, theme.colors.lightGrey)};
      }
      > h4 {
        font-size: 0.75rem;
      }
    }
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
`;

export { StepContainer, ProgressContainer };
