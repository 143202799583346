import * as yup from 'yup';

const validationSchema = yup.object({
  firstName: yup.string().min(1).required(),
  lastName: yup.string().min(1).required(),
  reference: yup.string().notRequired().nullable(),
  mobile: yup.string().min(9).required(),
});

export { validationSchema };
