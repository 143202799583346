import { useMutation, useQuery } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { dissoc, reject } from 'ramda';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  ActionButtons,
  CheckBoxFormField,
  Column,
  convertEnumObject,
  Dropdown,
  FormArea,
  FormItem,
  FormWrapper,
  generateLinkWithParams,
  NotificationsContext,
  PageContainer,
  PageError,
  PageHeading,
  PageLoading,
  SelectRemoveColumns,
  TextFormField,
  useLoading,
} from '../../../../';
import { getURLParam, listSortAndFormat } from '../../../../functions';
import { FormItemSplitter } from '../../../../styles';
import { checkLicenceNumberFormat } from '../functions';
import { ALLOCATE_SITES, CREATE_ASSET, GET_ORG, GET_SITE } from './gql';
import initialValues from './initialValues';
import validationSchema from './validationSchema';

const CreateAsset = withRouter(
  ({
    match,
    history,
    returnRoute,
    currentEntity = {},
    linkParams = {},
    showSites: ss = true,
  }) => {
    const orgId = getURLParam(match, 'orgId');
    const siteId = getURLParam(match, 'siteId');
    const showSites = ss && !siteId;

    const [loading, setLoading] = useState(false);
    const { addNotification, addInlineAlert } =
      useContext(NotificationsContext);
    const { data, error } = useQuery(siteId ? GET_SITE : GET_ORG, {
      variables: { id: currentEntity.id },
    });
    const [createAsset] = useMutation(CREATE_ASSET);
    const [allocateSites] = useMutation(ALLOCATE_SITES);

    const mutate = async (values) => {
      const createPayload = {
        input: {
          orgId,
          siteId,
          ...dissoc('sites', values),
          licenceNumber: values.licenceNumber.toUpperCase(),
        },
      };
      const {
        data: {
          createAsset: { id },
        },
      } = await createAsset({
        variables: createPayload,
      });
      if (values.sites.length) {
        const managePayload = {
          input: { id, add: values.sites.map((s) => s.id), remove: [] },
        };
        await allocateSites({ variables: managePayload });
      }
    };
    useLoading(loading);
    if (error) {
      return <PageError error={error} />;
    }
    if (data) {
      const assetClasses =
        data.meta.org?.assetClasses || data.meta.assetClasses;
      const sites = data.meta.sites || [];

      return (
        <PageContainer>
          <PageHeading
            heading={`${currentEntity.name} Assets`}
            description="Create asset"
          />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(showSites)}
            validateOnMount
            onSubmit={(values) => {
              setLoading(true);
              mutate(reject((v) => v === '', values))
                .then(() => {
                  addInlineAlert(
                    'success',
                    'Asset Created Successfully',
                    'Asset successfully created and added to the fleet',
                  );
                  history.push(generateLinkWithParams(returnRoute, linkParams));
                })
                .catch((err) => {
                  setLoading(false);
                  addNotification('error', 'Error', err.message);
                })
                .finally(() => setLoading(false));
            }}
          >
            {({ handleChange, values }) => {
              checkLicenceNumberFormat(values, handleChange);
              const assetClass = assetClasses.find(
                (ac) => ac.id === values.assetClassId,
              );
              return (
                <FormWrapper>
                  <Form>
                    <FormArea>
                      <Column>
                        <FormItem>
                          <Field
                            labelText="Licence Number *"
                            name="licenceNumber"
                            component={TextFormField}
                            light
                          />
                        </FormItem>
                        <FormItem>
                          <FormItemSplitter>
                            <Field
                              labelText="Asset Type *"
                              name="type"
                              component={Dropdown}
                              options={convertEnumObject({
                                __type: data.assetTypes,
                              })}
                              disabled={!!assetClass}
                            />
                            <Field
                              labelText="Asset Class *"
                              name="assetClassId"
                              component={Dropdown}
                              options={listSortAndFormat(
                                assetClasses.filter(
                                  (ac) => ac.type === values.type,
                                ),
                                'name',
                                'id',
                              )}
                              disabled={!values.type}
                            />
                          </FormItemSplitter>
                        </FormItem>
                        {assetClass?.isLink && (
                          <FormItem>
                            <Field
                              name="isRearTrailer"
                              component={CheckBoxFormField}
                              labelText="Rear Trailer"
                            />
                          </FormItem>
                        )}
                        {showSites && (
                          <FormItem>
                            <Field
                              name="sites"
                              component={SelectRemoveColumns}
                              instruction="Assign Sites to Asset"
                              leftHeading="All Sites"
                              rightHeading="Assigned Sites"
                              leftItems={sites}
                              rightItems={[]}
                              id="id"
                              display="name"
                              actionHandler={(value) =>
                                handleChange({
                                  target: { name: 'sites', value },
                                })
                              }
                              withinForm
                            />
                          </FormItem>
                        )}
                      </Column>
                    </FormArea>
                  </Form>
                  <ActionButtons type={'finalStep'} primaryName="Create" />
                </FormWrapper>
              );
            }}
          </Formik>
        </PageContainer>
      );
    }
    return <PageLoading />;
  },
);

export default CreateAsset;
