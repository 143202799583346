import styled, { css } from 'styled-components';

const TableIconWrapper = styled.div`
  ${({ theme, noColor }) =>
    !noColor &&
    css`
      & path,
      & circle {
        fill: ${theme.colors.primary};
      }
    `}
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default TableIconWrapper;
