import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  margin-top: 1rem;
`;

export { Container };
