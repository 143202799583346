import * as yup from 'yup';

const formikSchema = {
  initialValues: {
    firstName: '',
    lastName: '',
    mobile: '',
    reference: '',
    apps: [],
  },
  validationSchema: yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    reference: yup.string().notRequired().nullable(),
    mobile: yup.string().required(),
    apps: yup.array().of(yup.string().required()).required(),
  }),
};

export default formikSchema;
