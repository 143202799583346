import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import React, { useContext } from 'react';
import {
  ActionButtons,
  CurrentEntityContext,
  FormItem,
  FormWrapper,
  MultiSelect,
  PageHeading,
  useAlerts,
} from '../../../../../';
import { useLoading } from '../../../../../hooks';
import { ADD_OPERATOR } from '../../OrgLevel/CreateOperator/gql';
import { Container } from './styles';

const UpgradeModal = ({ row, reloadTableData, close }) => {
  const { currentEntity } = useContext(CurrentEntityContext);
  const { onError, alertSuccess } = useAlerts();

  const [addOperator, { loading }] = useMutation(ADD_OPERATOR, {
    onError,
    onCompleted: () => {
      alertSuccess('Operator Upgraded Successfully');
      close();
      reloadTableData();
    },
  });

  useLoading(loading);

  return (
    <Container>
      <PageHeading
        style={{ textAlign: 'center' }}
        heading={`Upgrade ${row.firstName} ${row.lastName} to Organisation`}
      />
      <Formik
        initialValues={{ apps: [] }}
        onSubmit={(values) => {
          addOperator({
            variables: {
              input: { ...values, orgId: currentEntity.id, operatorId: row.id },
            },
          });
        }}
      >
        <FormWrapper>
          <Form>
            <FormItem>
              <Field
                component={MultiSelect}
                options={[
                  { value: 'crew', label: 'Crew' },
                  { value: 'driver', label: 'Driver' },
                  { value: 'fleet', label: 'Fleet' },
                ]}
                name="apps"
                labelText="Apps"
              />
            </FormItem>
          </Form>
          <ActionButtons type="finalStep" primaryName="Upgrade" />
        </FormWrapper>
      </Formik>
    </Container>
  );
};

export default UpgradeModal;
