import { capitalize } from 'lodash';

const convertEnumString = (enumm = '', maintainCasing = false) => {
  enumm = (enumm || '').replace(/___/g, '_-_').replace(/__/g, '|');
  const finalEnum = enumm
    .split('_')
    .map((section) => {
      if (section.includes('|')) {
        return section.replace(/\|/g, '');
      }
      return maintainCasing ? section : capitalize(section);
    })
    .join(' ');
  return finalEnum;
};

export { convertEnumString };
