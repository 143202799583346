import { useLazyQuery } from '@apollo/client';
import * as msal from '@azure/msal-browser';
import { Field, Formik } from 'formik';
import { decode } from 'jsonwebtoken';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ActionButtons,
  Form,
  InlineNotification,
  OnClickLink,
  PasswordFormField,
  TextFormField,
  useLoading,
} from '../../../../';
import Banner from '../Banner';
import { formikSchema, formikSchemaForUsername } from './formikSchema';
import { ASSERT_USER_EMAIL_EXISTS, LOGIN } from './gql';
import { ForgotPassword, FormArea, FormItem, NoChrome } from './styles';

const LoginForm = ({
  setAssertMFA,
  updateSession,
  updateUsername,
  updateResetPassword,
  closeModalAndRedirect,
  setNotify,
  yardToken,
  username: globalUsername,
  portal,
}) => {
  const location = useLocation();
  const onError = useCallback(
    (err) =>
      setNotify(
        <InlineNotification
          kind="error"
          title="Error"
          subtitle={err.message}
        />,
      ),
    [setNotify],
  );
  const isUsingChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > 0;
  const [doesUserExist, setUserExists] = useState(false);
  const [oAuth, setOAuth] = useState();
  const [assertUserExists, { loading: userLoading }] = useLazyQuery(
    ASSERT_USER_EMAIL_EXISTS,
    {
      notifyOnNetworkStatusChange: true,
      onError,
      onCompleted: (d) => {
        if (d) {
          const {
            assertUserEmailExists: {
              doesExist,
              oAuthType,
              oAuthPayload,
              oAuthToken,
            },
          } = d;
          setUserExists(doesExist);
          oAuthType &&
            oAuthPayload &&
            setOAuth({ oAuthType, ...oAuthPayload, oAuthToken });
        }
      },
    },
  );

  const [login, { loading }] = useLazyQuery(LOGIN, {
    onError,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data) {
        setNotify(
          <InlineNotification
            kind="error"
            title="Error"
            subtitle="please make sure that you have entered a valid username and password"
          />,
        );
      } else if (data.login.passwordReset) {
        updateSession(data.login.session);
      } else if (data.login.assertMFA) {
        setAssertMFA(true);
      } else {
        closeModalAndRedirect(data.login.token);
      }
    },
  });

  useEffect(() => {
    if (oAuth) {
      const { clientId, oAuthType, oAuthToken, url, tenantId } = oAuth;
      switch (oAuthType) {
        case 'ADFS':
          const msalInstance = new msal.PublicClientApplication({
            auth: {
              clientId,
              authority: url,
              knownAuthorities: [url],
              redirectUri: window.location.origin,
            },
          });
          msalInstance
            .loginPopup({
              tokenQueryParameters: { client_secret: tenantId },
              scopes: ['openid', 'profile', 'offline_access'],
            })
            .then((res) => {
              const decoded = decode(res.accessToken);
              login({
                variables: {
                  username: decoded?.email || decoded?.upn?.toLowerCase?.(),
                  oAuthToken,
                },
              });
            })
            .catch((e) => {
              onError(e);
              setOAuth();
              setUserExists(false);
            });
          break;
        default:
          return;
      }
    }
  }, [oAuth, onError, login]);

  useLoading(loading || userLoading);

  useEffect(() => {
    const urlParams = new URLSearchParams(
      location.search?.replace(/\+/, '%2B'),
    );
    const username = urlParams.get('email');
    const password = urlParams.get('tempPassword');
    if (username && password) {
      login({ variables: { username, password } });
    }
    if (yardToken) {
      login({ variables: { yardToken } });
    }
  }, [login, yardToken, location]);

  const forgotPassword = (
    <ForgotPassword
      onClick={() => {
        updateResetPassword(true);
      }}
    >
      Forgot Password?
    </ForgotPassword>
  );

  const usernameField = (disabled) => (
    <FormItem>
      <Field
        labelText="Username"
        name="username"
        component={TextFormField}
        disabled={disabled}
      />
    </FormItem>
  );

  const chromeAlert = !isUsingChrome && (
    <NoChrome href="https://www.google.com/chrome/" target="_blank">
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Chrome_icon_%28September_2014%29.svg/2048px-Google_Chrome_icon_%28September_2014%29.svg.png"
        height={30}
        alt="chrome"
      />
      <p>Please use Chrome for optimal performance</p>
    </NoChrome>
  );

  const action = (
    <ActionButtons
      fillWidth
      ignoreDirty
      noMargin
      primaryName="Login"
      type="finalStep"
    />
  );

  return (
    <Fragment>
      <Banner
        text="Welcome Back"
        subtext={
          oAuth ? 'Redirecting to your login platform' : 'Login to Continue'
        }
      />
      {oAuth ? (
        <div />
      ) : doesUserExist ? (
        <Formik
          {...formikSchema(globalUsername)}
          onSubmit={(values) => {
            const { username, password } = values;
            login({ variables: { username, password: password.trim() } });
          }}
        >
          <Form>
            <FormArea style={{ display: 'flex', flexDirection: 'column' }}>
              {usernameField(true)}
              <OnClickLink
                style={{ alignSelf: 'end' }}
                onClick={() => setUserExists(false)}
              >
                Change
              </OnClickLink>
              <FormItem>
                <Field
                  labelText="Password"
                  showPasswordLabel="Show"
                  hidePasswordLabel="Hide"
                  name="password"
                  component={PasswordFormField}
                />
              </FormItem>
            </FormArea>
            {action}
            {forgotPassword}
            {chromeAlert}
          </Form>
        </Formik>
      ) : (
        <Formik
          {...formikSchemaForUsername}
          onSubmit={({ username: email }) => {
            updateUsername(email);
            assertUserExists({ variables: { email, portal } });
          }}
        >
          <Form>
            <FormArea>{usernameField()}</FormArea>
            {action}
            {forgotPassword}
            {chromeAlert}
          </Form>
        </Formik>
      )}
    </Fragment>
  );
};

export default LoginForm;
