import { curry } from 'ramda';
import { linkPlaceholders } from '../config';

const generateLinkWithParams = curry(
  (link: string, params: { [x: string]: string }) => {
    const newLink = link
      .split('/')
      .map((section) => {
        const possibleParam =
          Object.keys(linkPlaceholders).find((p) =>
            section.includes(linkPlaceholders[p]),
          ) || '';
        const replaceText = linkPlaceholders[possibleParam] || '';
        const replaceParam = params[replaceText] || '';
        const sectionReplacement = section.replace(replaceText, replaceParam);
        return sectionReplacement;
      })
      .join('/');
    return newLink;
  },
);

export default generateLinkWithParams;
