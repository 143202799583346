import styled from 'styled-components';

const RowFlex = styled.div<{ expand?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: ${({ expand }) => expand && 'space-between'};
`;

export { RowFlex };
