import { gql } from '@apollo/client';

const GET_LOAD = gql`
  query getLoad($id: ID!) {
    getLoad(id: $id) {
      name
      isRoute
      reference
      id
      plannedStart
      plannedEnd
      orgId
      carrier {
        surfsightId
        loadConfiguration {
          loadForceCloseReasons
        }
      }
      siteId
      carrierId
      status
      allocation {
        driver {
          id
          firstName
          lastName
        }
        crew {
          id
          firstName
          lastName
        }
        vehicle {
          assetId
          asset {
            devices {
              id
              imei
              deviceId
              type
            }
            currentPosition {
              address
              status
              batteryCharging
              batteryLevel
              location {
                id
                name
                stopId
                dateEntered
              }
              gps {
                lat
                lng
                direction
                speed
                dateTime
                accuracy
              }
            }
            type
            licenceNumber
            fleetNumber
          }
          licenceNo
          fleetNumber
        }
        assets {
          assetId
          asset {
            type
            licenceNumber
            fleetNumber
          }
          licenceNo
          fleetNumber
        }
      }
      geometry {
        stops {
          stopId
          order
          geometry {
            lat
            lng
          }
        }
      }
      trips {
        id
        computedValues {
          maxSpeed
          averageSpeed
          drivingTime
          idleTime
          totalDistance
          totalTime
        }
        endEvent {
          dateTime
          lat
          lng
        }
        startEvent {
          dateTime
          lat
          lng
        }
        snapshots {
          dateTime
          optional {
            alti
            sat
          }
          accuracy
          direction
          lat
          lng
          speed
        }
      }
      travelPlan {
        stops {
          type
          isLocked
          entities {
            id
            name
            reference
            contacts {
              id
              firstName
              lastName
              mobile
              email
            }
            activities {
              id
              entityId
              dueTime
              duration
              direction
              consignmentIds
              workflowId
            }
          }
          id
          description
          plannedEnd
          plannedStart
          path {
            distance
            estimatedTravelTime
            hasBeenSuppliedByUser
          }
          location {
            isAdhoc
            tags
            data {
              id
              name
              reference
              entrance {
                lat
                lng
              }
              shape {
                type
                data {
                  markers {
                    lat
                    lng
                  }
                  radius
                  center {
                    lat
                    lng
                  }
                }
              }
            }
          }
        }
      }
      execution {
        trips {
          computed {
            averageSpeed
            drivingTime
            idleTime
            maxSpeed
            totalDistance
            totalTime
          }
          destination {
            locationId
            locationName
            stopId
          }
          state
          tripId
        }
        id
        stopVisits {
          arrivedAt
          departedAt
          stopId
          locationId
        }
        activities {
          activityId
          started
          ended
          status
          workflowExecution {
            workflowOutcome {
              data {
                failComment
                failReason
                consignmentOutcomes {
                  id
                  status
                  reference
                  consignmentId
                  failure {
                    actionToTake
                    comment
                    reason
                    returns {
                      comment
                      description
                      unitId
                      total
                    }
                  }
                  handlingUnitOutcomes {
                    id
                    endorsementType
                    endorsement {
                      actualQuantity
                      comment
                      damagedUnits
                      excessSkuNumbers
                      missingSkuNumbers
                      reason
                      sellableUnits
                      status
                    }
                    comment
                    imageUrls
                    inputType
                    reason
                    status
                  }
                }
              }
              type
            }
            requirementsOutcomes {
              data {
                odometerReading
                comment
                employeeNumber
                imageUrl
                name
                isPositive
                pdfUrl
                imageUrls
                status
                reason
                seals
                numPallets
                endorsement {
                  id
                  customerReference
                  status
                  reason
                  comment
                  missingSkuNumbers {
                    barcode
                    quantity
                  }
                  excessSkuNumbers {
                    barcode
                    quantity
                  }
                }
                consignmentId
                consignmentReference
                isFailure
                failReason
                handlingUnitId
                quantity
                returns {
                  comment
                  handlingUnitId
                  qty
                  reason
                  reference
                }
              }
              type
            }
          }
        }
        started
        ended
      }
      extrapolation {
        stops {
          stopId
          locationName
          locationId
          position {
            lat
            lng
          }
          duration
          estimatedEnd
          estimatedStart
          estimatedDistance
          estimatedTravelTime
        }
      }
      assetEvents {
        id
        eventType
        assetLicenceNo
        timeCreated
        source
      }
      zone {
        id
        name
      }
      consignments {
        id
        direction
        reference
        dueAt
        consignee {
          name
          reference
        }
        consignor {
          name
          reference
        }
      }
      audits {
        timestamp
        eventType
        beforeUpdateValues
        afterUpdateValues
        username
        formattedChangeLog
      }
    }
  }
`;

const GET_LEAN_LOAD = gql`
  query getLoad($id: ID!) {
    getLoad(id: $id) {
      isRoute
      name
      reference
      id
      plannedStart
      plannedEnd
      orgId
      siteId
      carrierId
      status
      allocation {
        driver {
          id
          firstName
          lastName
        }
        crew {
          id
          firstName
          lastName
        }
        vehicle {
          assetId
          asset {
            currentPosition {
              address
              status
              batteryCharging
              batteryLevel
              location {
                id
                name
                stopId
                dateEntered
              }
              gps {
                lat
                lng
                direction
                speed
                dateTime
                accuracy
              }
            }
            type
            licenceNumber
            fleetNumber
          }
          licenceNo
          fleetNumber
        }
        assets {
          assetId
          asset {
            type
            licenceNumber
            fleetNumber
          }
          licenceNo
          fleetNumber
        }
      }
      travelPlan {
        stops {
          type
          isLocked
          entities {
            id
            name
            reference
            contacts {
              id
              firstName
              lastName
              mobile
              email
            }
            activities {
              id
              entityId
              dueTime
              duration
              direction
              consignmentIds
              workflowId
            }
          }
          id
          description
          plannedEnd
          plannedStart
          path {
            distance
            estimatedTravelTime
            hasBeenSuppliedByUser
          }
          location {
            isAdhoc
            tags
            data {
              id
              name
              reference
              entrance {
                lat
                lng
              }
              shape {
                type
                data {
                  markers {
                    lat
                    lng
                  }
                  radius
                  center {
                    lat
                    lng
                  }
                }
              }
            }
          }
        }
      }
      execution {
        trips {
          computed {
            averageSpeed
            drivingTime
            idleTime
            maxSpeed
            totalDistance
            totalTime
          }
          destination {
            locationId
            locationName
            stopId
          }
          state
          tripId
        }
        id
        stopVisits {
          arrivedAt
          departedAt
          stopId
          locationId
        }
        activities {
          activityId
          started
          ended
          status
          workflowExecution {
            workflowOutcome {
              data {
                failComment
                failReason
                consignmentOutcomes {
                  id
                  status
                  reference
                  consignmentId
                  failure {
                    actionToTake
                    comment
                    reason
                    returns {
                      comment
                      description
                      unitId
                      total
                    }
                  }
                  handlingUnitOutcomes {
                    id
                    endorsementType
                    endorsement {
                      actualQuantity
                      comment
                      damagedUnits
                      excessSkuNumbers
                      missingSkuNumbers
                      reason
                      sellableUnits
                      status
                    }
                    comment
                    imageUrls
                    inputType
                    reason
                    status
                  }
                }
              }
              type
            }
            requirementsOutcomes {
              data {
                odometerReading
                comment
                employeeNumber
                imageUrl
                name
                isPositive
                pdfUrl
                imageUrls
                status
                reason
                seals
                numPallets
                endorsement {
                  id
                  customerReference
                  status
                  reason
                  comment
                  missingSkuNumbers {
                    barcode
                    quantity
                  }
                  excessSkuNumbers {
                    barcode
                    quantity
                  }
                }
                consignmentId
                consignmentReference
                isFailure
                failReason
                handlingUnitId
                quantity
                returns {
                  comment
                  handlingUnitId
                  qty
                  reason
                  reference
                }
              }
              type
            }
          }
        }
        started
        ended
      }
      extrapolation {
        stops {
          stopId
          locationName
          locationId
          position {
            lat
            lng
          }
          duration
          estimatedEnd
          estimatedStart
          estimatedDistance
          estimatedTravelTime
        }
      }
      consignments {
        id
        reference
        dueAt
        direction
        consignee {
          name
          reference
        }
        consignor {
          name
          reference
        }
      }
      audits {
        timestamp
        eventType
        beforeUpdateValues
        afterUpdateValues
        username
        formattedChangeLog
      }
    }
  }
`;

export { GET_LOAD, GET_LEAN_LOAD };
