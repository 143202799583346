import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { omit } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  ActionButtons,
  Column,
  FormArea,
  FormItem,
  FormWrapper,
  PhoneNumberField,
  TextFormField,
  useLoading,
} from '../../../../../../';
import { UPDATE_OPERATOR } from './gql';
import validationSchema from './validationSchema';

const Details = withRouter(({ operator, outcomeObject }) => {
  const [updateOperatorMutation, { loading }] = useMutation(
    UPDATE_OPERATOR,
    outcomeObject,
  );

  useLoading(loading);

  return (
    <Formik
      initialValues={operator}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(values) => {
        updateOperatorMutation({
          variables: { input: omit(values, ['idNumber']) },
        });
      }}
    >
      {({ values, handleChange }) => {
        return (
          <FormWrapper>
            <Form>
              <FormArea columns={2}>
                <Column>
                  <FormItem>
                    <Field
                      labelText="First Name *"
                      name="firstName"
                      component={TextFormField}
                      light
                    />
                  </FormItem>
                  <FormItem>
                    <Field
                      labelText="Last Name *"
                      name="lastName"
                      component={TextFormField}
                      light
                    />
                  </FormItem>
                  <FormItem>
                    <Field
                      labelText="Mobile *"
                      name="mobile"
                      component={PhoneNumberField}
                      light
                    />
                  </FormItem>
                  <FormItem>
                    <Field
                      labelText="Reference"
                      name="reference"
                      component={TextFormField}
                      light
                    />
                  </FormItem>
                  <FormItem>
                    <Field
                      labelText="ID Number *"
                      name="idNumber"
                      component={TextFormField}
                      disabled
                    />
                  </FormItem>
                </Column>
              </FormArea>
            </Form>
            <ActionButtons type={'finalStep'} primaryName="Save Changes" />
          </FormWrapper>
        );
      }}
    </Formik>
  );
});

export default Details;
