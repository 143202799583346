import React from 'react';
import styled from 'styled-components';
import OverflowContainer from './OverflowContainer';

const SectionContainer = styled(OverflowContainer)<{
  noGap?: boolean;
  area?: string;
}>`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: ${({ noGap }) => !noGap && '1rem'};
  grid-area: ${({ area }) => area};
`;

const Header = styled.div`
  padding: 0.7rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.secondary};
  font-size: 0.8rem;
`;

const Section: React.FC<
  React.PropsWithChildren<{ area?: string; noGap?: boolean; header?: string }>
> = ({ children, header = '', area, noGap }) => {
  return (
    <SectionContainer area={area} noGap={noGap}>
      <Header>{header}</Header>
      {children}
    </SectionContainer>
  );
};

export { Section };
