import { gql } from '@apollo/client';

const CREATE_CARRIER = gql`
  mutation createCarrier($input: CarrierInput!) {
    createCarrier(input: $input) {
      id
    }
  }
`;

export { CREATE_CARRIER };
