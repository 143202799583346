import { useQuery } from '@apollo/client';
import { Field, Form as FormikForm, useFormikContext } from 'formik';
import React from 'react';
import {
  ActionButtons,
  CheckBoxFormField,
  Column,
  convertEnumObject,
  convertEnumString,
  FormArea,
  FormItem,
  FormWrapper,
  listSortAndFormat,
  PageContainer,
  PageError,
  PageHeading,
  PageLoading,
  SelectRemoveColumns,
  TextFormField,
} from '../../../../';
import { GET_ASSET_TYPES } from './gql';

const CarrierForm = ({ isEdit, currentEntity }) => {
  const { values, handleChange } = useFormikContext();

  const { data, error } = useQuery(GET_ASSET_TYPES);

  if (error) {
    return <PageError error={error} />;
  }

  if (data) {
    const types = convertEnumObject(data);
    return (
      <PageContainer>
        <PageHeading
          heading={`${currentEntity.name} Carriers`}
          description={`${isEdit ? 'Update' : 'Create'} Carrier`}
        />
        <FormWrapper>
          <FormikForm>
            <FormArea columns={2}>
              <Column>
                <FormItem>
                  <Field
                    labelText={'Name *'}
                    name={'name'}
                    component={TextFormField}
                    light
                  />
                </FormItem>
                <FormItem>
                  <Field
                    labelText={'VAT Number *'}
                    name={'vatNumber'}
                    component={TextFormField}
                    light
                  />
                </FormItem>
                <FormItem>
                  <Field
                    labelText={'Reference *'}
                    name={'reference'}
                    component={TextFormField}
                    light
                    disabled={isEdit}
                  />
                </FormItem>
                <FormItem>
                  <Field
                    labelText={'Fuel Enabled'}
                    name={'isFuelEnabled'}
                    component={CheckBoxFormField}
                  />
                </FormItem>
                <FormItem>
                  <Field
                    labelText="Dedicated"
                    name={'isDedicated'}
                    component={CheckBoxFormField}
                    disabled={values.sharesAssets}
                  />
                </FormItem>
                {values.isDedicated && (
                  <FormItem>
                    <Field
                      labelText="Shares Assets"
                      name={'sharesAssets'}
                      component={CheckBoxFormField}
                    />
                  </FormItem>
                )}
              </Column>
              {values.sharesAssets && (
                <Column>
                  <FormItem>
                    <SelectRemoveColumns
                      leftItems={types}
                      rightItems={listSortAndFormat(
                        values.sharedAssetTypes,
                        (l) => convertEnumString(l),
                        (l) => l,
                      )}
                      instruction="Assign Types"
                      leftHeading="Available Types"
                      rightHeading="Assigned Types"
                      actionHandler={(value) => {
                        handleChange({
                          target: {
                            name: 'sharedAssetTypes',
                            value: value.map((v) => v.value),
                          },
                        });
                      }}
                      withinForm
                    />
                  </FormItem>
                </Column>
              )}
            </FormArea>
          </FormikForm>
          <ActionButtons
            type="finalStep"
            primaryName={isEdit ? 'Save Changes' : 'Create'}
          />
        </FormWrapper>
      </PageContainer>
    );
  }

  return <PageLoading />;
};

export default CarrierForm;
