import { useLazyQuery } from '@apollo/client';
import { get, omit, orderBy } from 'lodash';
import { uniq } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { DeleteIconSmall, Spinner, useAlerts } from '../..';
import {
  Container,
  Input,
  InputContainer,
  Label,
  MultiCount,
  OptionsContainer,
  SpinnerContainer,
} from './styles';

const DropdownSearch = ({
  labelText = null,
  onChange,
  placeholder = 'Select...',
  returnFullObject = false,
  gql,
  gqlPath,
  labelKey = 'name',
  idKey = 'id',
  searchKey = 'search',
  field,
  multi,
  variables,
}) => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef();

  const fieldValue = field?.value;

  useEffect(() => {
    if (!fieldValue) {
      ref.current.value = '';
      setSearch('');
    }
  }, [fieldValue]);
  // const isTouched = form?.touched?.[field?.name] || form?.submitCount > 0;
  // const hasError = isTouched && form?.errors?.[field?.name];
  const { onError } = useAlerts();
  const [getData, { loading }] = useLazyQuery(gql, {
    onError,
    onCompleted: (d) => {
      const o = get(d, gqlPath) || [];
      setOptions(o);
      o.length > 0 && setShowOptions(true);
    },
  });

  useEffect(() => {
    setShowOptions(false);
    const timeout = setTimeout(() => {
      if (search.length > 2) {
        getData({ variables: { ...variables, [searchKey]: search } });
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [search, getData, setShowOptions, searchKey, variables]);

  const fieldValueArray = (
    Array.isArray(field?.value)
      ? field.value
      : typeof field?.value === 'object'
      ? [field?.value]
      : []
  )?.filter(Boolean);

  const optionsToShow = uniq(
    [...fieldValueArray, ...options].filter(Boolean).map((o) => {
      const canDelete = fieldValueArray.some((a) => a?.[idKey] === o[idKey]);
      return { ...o, canDelete };
    }),
  );

  const toggleOptions = () => setShowOptions((p) => !p);

  if (multi) {
    return (
      <Container>
        {labelText && <Label>{labelText}</Label>}
        <InputContainer onClick={toggleOptions}>
          <MultiCount>{fieldValueArray?.length}</MultiCount>
          <Input
            ref={ref}
            placeholder={placeholder}
            defaultValue={search}
            onChange={(v) => setSearch(v.target.value)}
          />
        </InputContainer>
        {loading && (
          <SpinnerContainer>
            <Spinner small />
          </SpinnerContainer>
        )}
        {showOptions && optionsToShow.length > 0 && (
          <OptionsContainer tabIndex={0}>
            {orderBy(
              optionsToShow,
              ['canDelete', (o) => o[labelKey]?.toLowerCase()],
              ['desc', 'asc'],
            ).map((value) => (
              <div key={value[idKey]}>
                <div>
                  <div
                    onMouseDownCapture={() => {
                      if (!value.canDelete) {
                        onChange?.(value);
                        field?.onChange({
                          target: {
                            value: uniq([
                              ...(field.value || []),
                              omit(value, ['canDelete']),
                            ]),
                            name: field.name,
                          },
                        });
                      }
                    }}
                  >
                    {value[labelKey]}
                  </div>
                  {value.canDelete && (
                    <DeleteIconSmall
                      onMouseDownCapture={() => {
                        field?.onChange({
                          target: {
                            value: (field.value || []).filter(
                              (f) => f[idKey] !== value[idKey],
                            ),
                            name: field.name,
                          },
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </OptionsContainer>
        )}
      </Container>
    );
  }

  return (
    <Container
      onBlur={() => setShowOptions(false)}
      onFocus={() => {
        setShowOptions(true);
      }}
    >
      {labelText && <Label>{labelText}</Label>}
      <Input
        ref={ref}
        placeholder={placeholder}
        defaultValue={search}
        onChange={(v) => setSearch(v.target.value)}
      />
      {loading && (
        <SpinnerContainer>
          <Spinner small />
        </SpinnerContainer>
      )}
      {showOptions && optionsToShow.length > 0 && (
        <OptionsContainer tabIndex={0}>
          {orderBy(
            optionsToShow,
            [(o) => o[labelKey]?.toLowerCase()],
            ['asc'],
          ).map((o) => (
            <div
              key={o[idKey]}
              onMouseDown={() => {
                const value = o && (returnFullObject ? o : o[idKey]);
                onChange?.(value);
                field?.onChange({ target: { value, name: field.name } });
                ref.current.value = o[labelKey];
              }}
            >
              <div>{o[labelKey]}</div>
            </div>
          ))}
        </OptionsContainer>
      )}
    </Container>
  );
};
export default DropdownSearch;
