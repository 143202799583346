import { gql } from '@apollo/client';

const GET_CONTACTS_FOR_CARRIER = gql`
  query getOrg(
    $id: ID!
    $take: Int!
    $skip: Int!
    $query: QueryLoadContacts
    $order: [[String]]
  ) {
    getOrg(id: $id) {
      carrierLoadContacts(
        pagination: { take: $take, skip: $skip }
        query: $query
        order: $order
      ) {
        data {
          id
          firstName
          lastName
          mobile
          email
          comsEvents {
            event
          }
          org {
            name
          }
        }
        pagination {
          take
          skip
          total
        }
      }
    }
  }
`;

const GET_CONTACTS_FOR_ORG = gql`
  query getOrg(
    $id: ID!
    $take: Int!
    $skip: Int!
    $query: QueryLoadContacts
    $order: [[String]]
  ) {
    getOrg(id: $id) {
      loadContacts(
        pagination: { take: $take, skip: $skip }
        query: $query
        order: $order
      ) {
        data {
          id
          firstName
          lastName
          mobile
          email
          comsEvents {
            event
          }
          org {
            name
          }
        }
        pagination {
          take
          skip
          total
        }
      }
    }
  }
`;

const DELETE_CONTACT = gql`
  mutation deleteLoadContact($id: ID!) {
    deleteLoadContact(id: $id)
  }
`;

export { GET_CONTACTS_FOR_CARRIER, GET_CONTACTS_FOR_ORG, DELETE_CONTACT };
