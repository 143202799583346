import styled from 'styled-components';

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Top = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

export { Top, TopBar };
