import { Calendar20 } from '@carbon/icons-react';
import { endOfDay, startOfDay } from 'date-fns';
import { useFormikContext } from 'formik';
import React from 'react';
import DateSelector from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDateTime, formatISO } from '../../';
import { Container, DateInput, Label, Selectors } from './styles';

const DatePicker = ({
  className,
  labelText,
  field,
  form,
  disabled,
  value: v,
  onFromChange,
  onToChange,
  dateFormat,
  dateValueFormat,
  ...props
}) => {
  const value = v || field?.value;
  const handleChange = useFormikContext()?.handleChange;

  const CustomInput = ({ value: v, onClick, from }) => {
    const valueDisplay = v
      ? formatDateTime(v, null, false)
      : from
      ? 'From'
      : 'To';
    return (
      <DateInput
        disabled={disabled}
        labelText={labelText}
        hasValue={!!v}
        onClick={() => {
          onClick();
        }}
      >
        <Calendar20 />
        {valueDisplay}
      </DateInput>
    );
  };

  return (
    <Container className={className}>
      {labelText && <Label>{labelText}</Label>}
      <Selectors>
        <DateSelector
          customInput={<CustomInput from />}
          disabled={disabled}
          id={field?.name}
          selected={value?.from && new Date(value.from)}
          title={labelText}
          required
          onChange={(date) => {
            onFromChange?.(date);
            handleChange?.({
              target: {
                value: {
                  from: formatISO(startOfDay(date)),
                  to: formatISO(endOfDay(date)),
                },
                name: field.name,
              },
            });
          }}
          {...props}
        />
        <DateSelector
          customInput={<CustomInput />}
          disabled={disabled || !value?.from}
          id={field?.name}
          minDate={value?.from && new Date(value.from)}
          selected={value?.to && new Date(value.to)}
          title={labelText}
          required
          onChange={(date) => {
            onToChange?.(date);
            handleChange?.({
              target: {
                value: { from: value?.from, to: formatISO(endOfDay(date)) },
                name: field?.name,
              },
            });
          }}
          {...props}
        />
      </Selectors>
    </Container>
  );
};

export default DatePicker;
