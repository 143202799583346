import { gql } from '@apollo/client';

const REGISTER_CHAT_USER = gql`
  mutation ($input: LoadConversationRegistration) {
    registerChatUser(input: $input) {
      token
    }
  }
`;

export { REGISTER_CHAT_USER };
