import styled from 'styled-components';

const FormItemSplitter = styled.div`
  display: grid;
  grid-template-columns: ${({ split }) => `repeat(${split || 2},1fr)`};
  grid-gap: 0.5rem;
  align-items: center;
`;

export default FormItemSplitter;
