import React, {
  Children,
  cloneElement,
  Fragment,
  isValidElement,
  useState,
} from 'react';
import { TabContainer, TabsContainer } from './styles';

const Tabs = ({ children, withMargin, startIndex = 0 }) => {
  //Each Tab must have ONE child component to pass the props properly
  const [currentLabel, setCurrentLabel] = useState(
    children && (children[startIndex] || children).props.label,
  );
  const [currentFocusIndex, setCurrentFocusIndex] = useState(-1);

  const childrenWithProps = Children.map(children, (child, childIndex) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        currentLabel,
        setCurrentLabel,
        currentFocusIndex,
        childIndex,
      });
    }
    return child;
  });

  const currentChild = childrenWithProps.find(
    (child) => child.props.label === currentLabel,
  );

  const currentChildChild = currentChild.props.children;

  const currentChildChildrenWithProps = isValidElement(currentChildChild)
    ? cloneElement(currentChildChild, {
        setCurrentFocusIndex,
        childIndex: currentChild.props.childIndex,
      })
    : currentChildChild;

  return (
    <Fragment>
      <TabsContainer withMargin={withMargin}>{childrenWithProps}</TabsContainer>
      {currentChildChildrenWithProps}
    </Fragment>
  );
};

const Tab = ({
  label,
  currentLabel,
  setCurrentLabel,
  currentFocusIndex,
  childIndex,
}) => {
  const disabled =
    currentFocusIndex < 0 ? false : currentFocusIndex !== childIndex;
  return (
    <TabContainer
      active={currentLabel === label}
      disabled={disabled}
      onClick={() => !disabled && setCurrentLabel(label)}
    >
      {label}
    </TabContainer>
  );
};

export { Tabs, Tab };
