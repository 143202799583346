import { Calendar20 } from '@carbon/icons-react';
import { endOfDay, startOfDay } from 'date-fns';
import { useFormikContext } from 'formik';
import { lighten } from 'polished';
import React from 'react';
import DateSelector from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled, { css } from 'styled-components';
import { formatDateTime, formatISO, formatTime } from '../../functions';

const NoTypeText = styled.div`
  background: ${({ theme, disabled }) =>
    disabled && lighten(0.12, theme.colors.lightGrey)};
  height: 40px;
  width: 100%;
  font-size: 0.75rem;
  color: ${({ theme, hasValue }) =>
    hasValue ? theme.colors.darkGrey : theme.colors.mediumGrey};
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;
`;

const Label = styled.div`
  font-size: 0.75rem;
  margin-bottom: 4px;
  color: ${({ theme, error }) => theme.colors[error ? 'danger' : 'darkGrey']};
`;

let DatePicker = ({
  className,
  labelText,
  field = {},
  form,
  minDate,
  disabled,
  onChange,
  dateFormat,
  dateValueFormat,
  noTypeIn,
  placeholder,
  interval,
  useTime = false,
  useTimeOnly = false,
  ...props
}) => {
  const isTouched = form?.touched?.[field?.name] || form?.submitCount > 0;
  const hasError = isTouched && form?.errors?.[field?.name];
  const name = field.name;
  const value = field.value || props.value;
  const { handleChange } = useFormikContext() || {};
  const NoTypeIn = ({ value: v, onClick }) => {
    const valueDisplay =
      useTimeOnly && value
        ? formatTime(value)
        : (useTime || props.showTimeSelect) && value
        ? formatDateTime(value)
        : v;
    return (
      <NoTypeText
        disabled={disabled}
        labelText={labelText}
        hasValue={!!v}
        onClick={() => {
          onClick();
        }}
      >
        <Calendar20 />
        {valueDisplay || placeholder || 'Click to Select a Date'}
      </NoTypeText>
    );
  };
  const noTypeInProp = noTypeIn ? { customInput: <NoTypeIn /> } : {};
  return (
    <div
      className={className}
      labelText={labelText}
      noBorders={props.noBorders}
    >
      {labelText && <Label error={hasError}>{labelText}</Label>}
      <DateSelector
        shouldCloseOnSelect={!useTime}
        disabled={disabled}
        showTimeSelect={!!interval}
        timeIntervals={interval}
        showTimeInput={!interval && (useTime || useTimeOnly)}
        showTimeSelectOnly={useTimeOnly}
        minDate={minDate}
        id={name}
        selected={
          value &&
          (name === 'dateRange' ? new Date(value.from) : new Date(value))
        }
        maxDate={new Date('2100-01-01')}
        placeholderText={'yyyy/mm/dd'}
        allowSameDay
        title={labelText}
        required
        dateFormat={
          dateFormat ||
          (useTimeOnly ? 'HH:mm' : useTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')
        }
        onChange={(date) => {
          const formatDate = (dateValue) =>
            !dateValue ? null : formatISO(dateValue);
          if (onChange) {
            onChange(formatDate(date));
            return;
          }
          if (name === 'dateRange') {
            handleChange({
              target: {
                value: date
                  ? {
                      from: formatDate(startOfDay(date)),
                      to: formatDate(endOfDay(date)),
                    }
                  : date,
                name,
              },
            });
          } else {
            handleChange({
              target: {
                value: date ? formatDate(date) : date,
                name,
              },
            });
          }
        }}
        {...props}
        {...noTypeInProp}
      />
    </div>
  );
};

DatePicker = styled(DatePicker)`
  border: none;
  ${({ noBorders, theme }) =>
    !noBorders &&
    css`
      border-bottom: 1px solid ${theme.colors.inputBottom};
    `}
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  & .react-datepicker-wrapper,
  & .react-datepicker__input-container {
    border: none;
    width: 100%;
  }
  & .react-datepicker__close-icon {
    ::after {
      background: ${({ theme }) => theme.colors.primary};
    }
  }
  & .react-datepicker-popper {
    z-index: 1000;
  }
  & input {
    width: 100%;
    border: none;
    background: none;
    padding: 5px;
    cursor: pointer;
    :disabled {
      cursor: not-allowed;
      background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
    }
  }
  ${({ labelText }) =>
    labelText &&
    css`
      display: grid;
      grid-template-rows: auto 1fr;
      & .react-datepicker__input-container {
        height: 100%;
      }
    `}
`;

export default DatePicker;
