import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import {
  ActionButtons,
  appsArray,
  convertEnumString,
  FormArea,
  FormWrapper,
  SelectRemoveColumns,
  useLoading,
} from '../../../../../../../';
import { UPDATE_APPS_ON_SITE_LEVEL } from './gql';
import { validationSchema } from './validationSchema';

const Site = ({
  outcomeObject,
  data: { sites, id },
  setCurrentFocusIndex,
  childIndex,
  appsArray: externalAppsArray,
  siteId,
}) => {
  const [manageApps, { loading }] = useMutation(
    UPDATE_APPS_ON_SITE_LEVEL,
    outcomeObject,
  );
  useLoading(loading);

  const { apps } = sites.find(({ site }) => site.id === siteId);
  const mappedApps = apps.map((a) => ({
    value: a,
    label: convertEnumString(a),
  }));

  return (
    <Formik
      initialValues={{
        apps,
      }}
      validationSchema={validationSchema}
      onSubmit={({ apps: chosenApps }) => {
        const variables = {
          input: {
            siteId,
            operatorId: id,
            apps: chosenApps,
          },
        };
        manageApps({ variables });
      }}
    >
      {({ handleChange, values, dirty }) => {
        setCurrentFocusIndex(dirty ? childIndex : -1);
        return (
          <FormWrapper>
            <Form>
              <FormArea>
                <SelectRemoveColumns
                  name="apps"
                  leftHeading="All Permissions"
                  rightHeading="Assigned Permissions"
                  leftItems={(externalAppsArray || appsArray).filter(
                    (a) => !values.apps.includes(a.value),
                  )}
                  rightItems={mappedApps}
                  id="value"
                  display="label"
                  withinForm
                  actionHandler={(appObj) => {
                    handleChange({
                      target: {
                        name: 'apps',
                        value: appObj.map((a) => a.value).sort(),
                      },
                    });
                  }}
                />
              </FormArea>
            </Form>
            <ActionButtons primaryName="Save & Continue" />
          </FormWrapper>
        );
      }}
    </Formik>
  );
};

export default Site;
