import styled from 'styled-components';
import { darken } from 'polished';
import Add from '@carbon/icons-react/lib/add/20';

import { PrimaryButton } from '../Buttons';
const ActionRow = styled.div`
  display: flex;
  justify-content: space-between;
  > p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.85rem;
    color: ${({ theme }) => theme.colors.mediumGrey};
    padding-left: 5px;
  }
`;

const ActionButton = styled(PrimaryButton)`
  border-radius: 0;
  height: 40px;
  display: flex;
  width: auto;
  padding: 0 20px;
`;

const AddIcon = styled(Add)`
  & path {
    fill: #ffffff;
  }
`;

const Delete = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & path {
    fill: ${({ theme }) => theme.colors.mediumGrey};
  }
  :hover {
    & path {
      fill: ${({ theme }) => darken(0.2, theme.colors.mediumGrey)};
    }
  }
`;

export { ActionRow, ActionButton, AddIcon, Delete };
