import { Form, Formik } from 'formik';
import React from 'react';
import {
  ActionButtons,
  FormArea,
  FormWrapper,
  listSortAndFormat,
  PageContainer,
  PageLoading,
  SelectRemoveColumns,
} from '../../../../';

const AssignedSites = ({ manageSitesMutation, chosenSites, id, allSites }) => {
  const chosenSiteIds = chosenSites.map((s) => s.id);
  const initialSites = listSortAndFormat(chosenSites, 'name');
  const rightItems = allSites.filter((s) => chosenSiteIds.includes(s.id));
  const leftItems = allSites.filter((site) => !chosenSiteIds.includes(site.id));
  if (initialSites) {
    return (
      <PageContainer height={'70vh'} align="end" gridStyling={'auto'}>
        <Formik
          enableReinitialize
          initialValues={{ sites: initialSites }}
          onSubmit={({ sites }) => {
            const siteIds = sites.map((s) => s.id);
            const add = siteIds.filter((i) => !chosenSiteIds.includes(i));
            const remove = chosenSiteIds.filter((i) => !siteIds.includes(i));
            const variables = {
              input: {
                id,
                add,
                remove,
              },
            };
            manageSitesMutation({ variables });
          }}
        >
          {({ handleChange }) => (
            <FormWrapper>
              <Form>
                <FormArea>
                  <SelectRemoveColumns
                    leftHeading="All Sites"
                    rightHeading="Assigned Sites"
                    leftItems={leftItems}
                    rightItems={rightItems}
                    id="id"
                    display="name"
                    withinForm
                    actionHandler={(value) => {
                      handleChange({
                        target: {
                          name: 'sites',
                          value: value.sort((a, b) =>
                            a.name >= b.name ? 1 : -1,
                          ),
                        },
                      });
                    }}
                  />
                </FormArea>
              </Form>
              <ActionButtons primaryName="Save & Continue" />
            </FormWrapper>
          )}
        </Formik>
      </PageContainer>
    );
  }
  return <PageLoading />;
};

export default AssignedSites;
