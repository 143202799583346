import { useQuery } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import {
  ActionButtons,
  Column,
  convertEnumObject,
  Dropdown,
  FormArea,
  FormItem,
  PageContainer,
  PageError,
  PageLoading,
  TextFormField,
} from '../../../../';
import { FETCH_FUEL_TYPES } from '../../gql';
import initialValues from './initialValues';

const Consumption = ({ editAssetMutation, data, assetId, updatedData }) => {
  const { data: fuelTypes, error } = useQuery(FETCH_FUEL_TYPES);

  if (error) {
    return <PageError error={error} />;
  }

  if (fuelTypes) {
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues(data, updatedData)}
        onSubmit={(values) => {
          delete values.vin;
          delete values.tyreTransactions;
          delete values.engineNumber;
          delete values.masterAsset;
          let newValues = {};
          Object.keys(values).forEach(
            (k) =>
              typeof values[k] !== 'boolean' &&
              (newValues[k] = values[k] || null),
          );
          const variables = {
            input: { id: assetId, ...newValues },
          };
          editAssetMutation({ variables });
        }}
      >
        {({ values }) => (
          <PageContainer height={'70vh'} align="end">
            <Form>
              <FormArea>
                <Column>
                  <FormItem>
                    <Field
                      unit={'Litres'}
                      type={'number'}
                      labelText="Tank Capacity"
                      name="tankCapacity"
                      component={TextFormField}
                    />
                  </FormItem>
                  <FormItem>
                    <Field
                      labelText="Fuel Type"
                      name="fuelType"
                      options={convertEnumObject(fuelTypes)}
                      component={Dropdown}
                    />
                  </FormItem>
                  <FormItem>
                    <Field
                      unit={'KM / L'}
                      type={'number'}
                      labelText="Benchmark"
                      name="benchmark"
                      component={TextFormField}
                    />
                  </FormItem>
                  {values.benchmark && values.benchmark !== 0 && (
                    <FormItem>
                      <div>{`(${(100 / +values.benchmark).toFixed(
                        3,
                      )} Litres / 100KM)`}</div>
                    </FormItem>
                  )}
                  <FormItem>
                    <Field
                      labelText="GL Reference"
                      name="glReference"
                      component={TextFormField}
                    />
                  </FormItem>
                </Column>
              </FormArea>
            </Form>
            <ActionButtons primaryName="Save & Continue" />
          </PageContainer>
        )}
      </Formik>
    );
  }

  return <PageLoading />;
};

export default Consumption;
