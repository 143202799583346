import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 2rem;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'overview audits'
    'overview documents';
  & h2 {
    width: 100%;
    border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
    padding: 0.5rem;
  }
`;

const Overview = styled.div`
  grid-area: overview;
`;

const OverviewDetails = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 1.5rem;
  padding: 0.5rem;
  font-size: 0.75rem;
`;

const Audits = styled.div`
  grid-area: audits;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 0.5rem;
`;

const Documents = styled.div`
  grid-area: documents;
`;

const Overflow = styled.div`
  overflow: auto;
`;

export { Container, Audits, Overview, Documents, OverviewDetails, Overflow };
