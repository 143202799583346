import {
  Document,
  Image,
  Page,
  pdf,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import QRdata from 'qrcode';
import React, { Fragment, useContext, useState } from 'react';
import { ModalContext, Spinner, theme, trackmaticLogo } from '../../';
import { PrimaryButton } from '../Buttons';
import { DivWrapper, DownloadIcon, LoadingOverlay, QrIcon } from './styles';

const Doc = ({
  desc,
  size,
  qrCodeSize,
  orientation,
  fontSize,
  textStyling,
  headers,
  padding = 20,
  qrUrl,
  smallHeader,
}) => {
  const styles = StyleSheet.create({
    page: { backgroundColor: 'white' },
    logo: {
      backgroundColor: theme.colors.secondary,
      padding: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '8%',
    },
    headers: {
      fontSize: 10,
      color: theme.colors.darkGrey,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      justifySelf: 'center',
      paddingTop: 4,
    },
    qrCode: {
      padding,
      // paddingTop: 2 * padding,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '92%',
      width: '100%',
    },
    desc: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  });

  return (
    <Document>
      <Page size={size || 'A4'} orientation={orientation || 'portrait'}>
        <View style={styles.logo}>
          <Image
            src={trackmaticLogo}
            style={{ width: smallHeader ? 70 : 140 }}
          />
        </View>
        <View style={styles.body}>
          {headers && (
            <View style={styles.headers}>
              {headers.map(({ label, val }) => (
                <View
                  key={val}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    margin: '2',
                  }}
                >
                  <Text
                    style={{
                      fontFamily: 'Helvetica-Bold',
                      marginRight: '5',
                    }}
                  >
                    {label}
                  </Text>
                  <Text>{val}</Text>
                </View>
              ))}
            </View>
          )}
          <View style={styles.qrCode}>
            <Image
              src={{ uri: qrUrl }}
              style={{ width: qrCodeSize || '90%' }}
            />
          </View>
          <View style={styles.desc}>
            {desc[0] ? (
              <Text
                style={{
                  fontSize: fontSize || 14,
                  ...(textStyling || {}),
                }}
              >
                {desc[0]}
              </Text>
            ) : null}
            {desc[1] ? (
              <Text
                style={{
                  fontSize: (fontSize && fontSize - 2) || 12,
                  ...(textStyling || {}),
                }}
              >
                {desc[1]}
              </Text>
            ) : null}
            {desc[2] ? (
              <Text
                style={{
                  fontSize: (fontSize && fontSize - 6) || 14,
                  ...(textStyling || {}),
                }}
              >
                {desc[2]}
              </Text>
            ) : null}
          </View>
        </View>
      </Page>
    </Document>
  );
};

const QRCode = ({
  row,
  rawData = [],
  size,
  qrCodeSize,
  orientation,
  fontSize,
  textStyling,
  descValues,
  padding,
  getQRValue = (r) => r.id,
  getHeaders = () => null,
  descriptionKeys = () => [],
  smallHeader,
  fileName,
}) => {
  const { toggleModal, setModalContent } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const [qrValue, setQrValue] = useState();

  const getLazyPdfProps = async () => {
    const data = rawData.find((d) => d.id === row.id);
    const value = getQRValue(row, data);
    const qrUrl = await QRdata.toDataURL(value, {
      width: 400,
      color: { dark: theme.colors.black },
      margin: 0,
    });
    const desc = descValues || descriptionKeys.map((k) => row[k]);
    const headers = getHeaders(row, data);
    return { headers, desc, qrUrl };
  };

  const downloadQR = async () => {
    setLoading(true);
    const props = qrValue || (await getLazyPdfProps());

    const doc = (
      <Doc
        textStyling={textStyling}
        fontSize={fontSize}
        key={row.id}
        size={size}
        orientation={orientation}
        qrCodeSize={qrCodeSize}
        padding={padding}
        smallHeader={smallHeader}
        {...props}
      />
    );
    const asPdf = pdf();
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, fileName || `qr_code_${row.id}`);
    setLoading(false);
  };

  return (
    <DivWrapper>
      {loading && (
        <LoadingOverlay>
          <Spinner small withOverlay={false} />
        </LoadingOverlay>
      )}
      <QrIcon
        onClick={async () => {
          const props = await getLazyPdfProps();
          setQrValue(props);
          setModalContent(
            <Fragment>
              <img src={props.qrUrl} alt="QR Code" />
              <PrimaryButton
                style={{
                  width: '400px',
                  padding: '30px',
                  marginTop: '10px',
                }}
                onClick={downloadQR}
              >
                Generate PDF
              </PrimaryButton>
            </Fragment>,
          );
          toggleModal();
        }}
      />
      <DownloadIcon onClick={downloadQR} />
    </DivWrapper>
  );
};

// important props - descrpitionKeys(opt)<String[]>;getValue(row,data);getheaders(row,data)
export default QRCode;
