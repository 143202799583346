import styled from 'styled-components';

const TooltipContainer = styled.div`
  color: white;
  font-size: 0.6rem;
  max-width: 500px;
  > h3 {
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    padding: 0.2rem 0;
  }
`;

const TooltipColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-gap: 1rem;
`;

const TooltipValuesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 0.2rem;
  width: 100%;
  height: 0%;
`;

export { TooltipContainer, TooltipValuesContainer, TooltipColumnsContainer };
