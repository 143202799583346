import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ hasLabel }) => (hasLabel ? 'space-between' : 'center')};
`;

const Label = styled.div`
  display: flex;
  font-size: 0.75rem;
  color: ${({ theme, error }) => theme.colors[error ? 'danger' : 'darkGrey']};
  margin-bottom: 5px;
`;

export { Container, Label };
