import { Code16, Download16, Edit20, View16 } from '@carbon/icons-react';
import styled from 'styled-components';

const CodeIcon = styled(Code16)`
  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;
const EditIcon = styled(Edit20)`
  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;
const ViewIcon = styled(View16)`
  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;
const DownloadIcon = styled(Download16)`
  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const DoubleFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin: 20px 0 0 0;
`;

const Tr = styled.tr`
  border-bottom: ${({ theme }) => `1px ${theme.colors.inputBottom} solid`};
  text-align: left;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.inputText};
  > th {
    padding-bottom: 10px;
    font-weight: bold;
  }
`;

const DivWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BodyTr = styled.tr`
  > td {
    padding: 5px 0;
    color: ${({ theme }) => theme.colors.darkGrey};
    :last-of-type {
      font-weight: bold;
      color: ${({ active, theme }) =>
        active ? theme.colors.success : theme.colors.danger};
    }
  }
`;

export {
  DoubleFieldWrapper,
  DivWrapper,
  CodeIcon,
  EditIcon,
  ViewIcon,
  DownloadIcon,
  Tr,
  BodyTr,
};
