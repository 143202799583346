import {
  CheckmarkFilled16 as Truedom,
  Login16,
  Misuse16 as Falsedom,
  TrashCan16 as Trash,
} from '@carbon/icons-react';
import { darken } from 'polished';
import styled from 'styled-components';

const True = styled(Truedom)`
  & path {
    fill: ${({ theme }) => theme.colors.success};
  }
`;
const Delete = styled(Trash)`
  & path {
    fill: ${({ theme }) => theme.colors.danger};
  }
  :hover {
    & path {
      fill: ${({ theme }) => darken(0.2, theme.colors.danger)};
    }
  }
`;

const False = styled(Falsedom)`
  & path {
    fill: ${({ theme }) => theme.colors.lightGrey};
  }
`;

const Enter = styled(Login16)`
  & path {
    fill: ${({ theme }) => theme.colors.secondary};
  }
  :hover {
    & path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const BooleanContainer = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ clickable }) => clickable && 'cursor:pointer;'}
`;

const NoData = styled.h4`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.secondary};
  padding: 5px 0;
`;

const Color = styled.td`
  padding: 5px;
  height: 100%;
  & div {
    width: 10px;
    height: 10px;
    margin: auto;
    background: ${({ color }) => color};
  }
`;

const CloseModal = styled.p`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: bold;
`;

export {
  True,
  False,
  BooleanContainer,
  NoData,
  Color,
  CloseModal,
  Enter,
  Delete,
};
