import * as yup from 'yup';

const formikSchema = {
  initialValues: {
    reason: '',
    comment: '',
  },
  validationSchema: yup.object({
    reason: yup.string().required(),
    comment: yup.string().notRequired().nullable(),
  }),
};

export default formikSchema;
