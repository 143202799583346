import { ChevronDown16, ChevronRight16, Menu24 } from '@carbon/icons-react';
import { darken, lighten } from 'polished';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Container = styled.div`
  background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
  padding: 0.5rem;
  width: ${({ isOpen }) => (isOpen ? '15rem' : '3rem')};
  transition: 100ms ease;
  font-size: 0.85rem;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  z-index: 10;
`;

const MenuIcon = styled(Menu24)`
  cursor: pointer;
`;

const MenuLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.darkGrey};
  padding: ${({ level }) => `0.5rem 0.5rem 0.5rem ${level * 0.5 + 0.5}rem`};
  :hover {
    background: ${({ theme }) => darken(0.1, theme.colors.lightGrey)};
  }
  ${({ active, theme }) =>
    active &&
    css`
      border-left: 5px solid ${theme.colors.primary};
    `}
`;

const MenuTopStyle = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.darkGrey};
  padding: ${({ level }) => `0.5rem 0.5rem 0.5rem ${level * 0.5 + 0.5}rem`};
  :hover {
    background: ${({ theme }) => darken(0.1, theme.colors.lightGrey)};
  }
  cursor: pointer;
  ${({ active, theme }) =>
    active &&
    css`
      border-left: 5px solid ${theme.colors.primary};
    `}
`;

const MenuBlock = styled.div`
  ${({ level }) =>
    level === 0 &&
    css`
      margin-bottom: 0.2rem;
    `}
`;

const MenuTop = ({ children, onClick, isOpen, level, active }) => (
  <MenuTopStyle onClick={onClick} level={level} active={active}>
    {children}
    {isOpen ? <ChevronDown16 /> : <ChevronRight16 />}
  </MenuTopStyle>
);

export { Container, MenuIcon, MenuLink, MenuTop, MenuBlock };
