import styled from 'styled-components';

const FilterOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  & .react-datepicker-popper {
    z-index: 1000;
  }
`;

const FilterItem = styled.div`
  width: ${({ width }) => `${width}px`};
`;

export { FilterOptionsContainer, FilterItem };
