import React, { createContext, useCallback, useState } from 'react';

const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const [inlineAlert, setInlineAlert] = useState();

  const closeAlert = useCallback(() => {
    setInlineAlert(null);
  }, [setInlineAlert]);

  const addInlineAlert = useCallback(
    (type, title, message) => {
      setInlineAlert({
        title,
        message,
        type,
      });
      setTimeout(() => {
        closeAlert();
      }, 5000);
    },
    [setInlineAlert, closeAlert],
  );

  return (
    <NotificationsContext.Provider
      value={{ inlineAlert, addInlineAlert, addNotification: addInlineAlert }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsProvider, NotificationsContext };
