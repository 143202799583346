const checkLicenceNumberFormat = (values, handleChange) => {
  if (
    values.licenceNumber !==
    values.licenceNumber.trim().toUpperCase().replace(/\W/g, '')
  ) {
    handleChange({
      target: {
        name: 'licenceNumber',
        value: values.licenceNumber.trim().toUpperCase().replace(/\W/g, ''),
      },
    });
  }
};

export { checkLicenceNumberFormat };
