import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { generateLinkWithParams, useAlerts, useLoading } from '../../../../';
import CarrierForm from '../CarrierForm';
import formikSchema from './formikSchema';
import { CREATE_CARRIER } from './gql';

const CreateCarrier = withRouter(
  ({ history, redirect, currentEntity, linkParams }) => {
    const { alertSuccess, onError } = useAlerts();
    const [createCarrierMutation, { loading }] = useMutation(CREATE_CARRIER, {
      onCompleted: () => {
        alertSuccess('Carrier has been successfully created');
        history.push(generateLinkWithParams(redirect, linkParams));
      },
      onError,
    });

    useLoading(loading);

    return (
      <Formik
        validateOnMount
        {...formikSchema}
        onSubmit={(values) => {
          createCarrierMutation({
            variables: {
              input: {
                orgId: currentEntity.id,
                ...values,
              },
            },
          });
        }}
      >
        <CarrierForm currentEntity={currentEntity} />
      </Formik>
    );
  },
);

export default CreateCarrier;
