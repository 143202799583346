import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Container, Label } from './styles';

const PhoneNumberField = ({
  field,
  labelText,
  placeholder,
  defaultCountry = 'ZA',
}) => {
  return (
    <Container>
      {labelText && <Label>{labelText}</Label>}
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        placeholder={placeholder}
        defaultCountry={defaultCountry}
        value={field.value || null}
        onChange={(value) => {
          field.onChange({ target: { name: field.name, value } });
        }}
      />
    </Container>
  );
};

export { PhoneNumberField };
