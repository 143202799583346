import React from 'react';
import {
  DownloadIconSmall,
  Table,
  TableBodyContainer,
  TableIconWrapper,
  Th,
  useDownloadS3File,
} from '../../../';

const Download = ({ v }) => {
  const download = useDownloadS3File(v.path, v.name);
  return (
    <tr key={v.name}>
      <td>
        <p>{v.name}</p>
      </td>
      <td>
        <TableIconWrapper>
          <DownloadIconSmall onClick={() => download()} />
        </TableIconWrapper>
      </td>
    </tr>
  );
};

const ViewModal = ({ docs = [] }) => {
  return (
    <TableBodyContainer style={{ height: 'auto' }}>
      <Table>
        <colgroup>
          <col width="90%" />
          <col width="10%" />
        </colgroup>
        <thead>
          <tr>
            <Th noHover>File Name</Th>
            <Th noHover />
          </tr>
        </thead>
        <tbody>
          {(docs || []).map((v) => (
            <Download v={v} key={v.name} />
          ))}
        </tbody>
      </Table>
    </TableBodyContainer>
  );
};

export default ViewModal;
