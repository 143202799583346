import { get } from 'lodash';
import React from 'react';
import {
  convertEnumString,
  formatDateTime,
  formatDuration,
  getOperatorName,
} from '../../../';
import { Tooltip as TooltipDisplay } from '../Tooltip';
import { ActivityTypeBubble } from './styles';

const SelectedActivityTooltip = ({ load, selectedActivity }) => {
  const {
    started,
    status,
    ended,
    stop: {
      location: {
        data: { name: location, entrance },
      },
    },
  } = selectedActivity;
  return (
    <TooltipDisplay
      tooltipContent={{
        addresses: [{ lat: entrance.lat, lng: entrance.lng }],
        header: (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gridGap: '0.4rem',
              alignItems: 'center',
            }}
          >
            <ActivityTypeBubble type={selectedActivity.direction}>
              {convertEnumString(selectedActivity.direction)}
            </ActivityTypeBubble>
            <div>{location}</div>
          </div>
        ),
        values: [
          {
            header: 'Operator',
            value: getOperatorName(get(load, 'allocation.driver')),
          },
          {
            header: 'Start',
            value: formatDateTime(started),
          },
          {
            header: 'End',
            value: formatDateTime(ended),
          },
          { header: 'Duration', value: formatDuration(started, ended) },
          {
            header: 'Outcome',
            value: convertEnumString(status),
          },
        ],
        noAbsolute: true,
      }}
    />
  );
};

export default SelectedActivityTooltip;
