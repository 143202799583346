import { gql } from '@apollo/client';

const FETCH_ASSET_TYPES = gql`
  query {
    __type(name: "AssetTypeEnums") {
      enumValues {
        name
      }
    }
  }
`;

const FETCH_FUEL_TYPES = gql`
  query {
    __type(name: "EFuelTypes") {
      enumValues {
        name
      }
    }
  }
`;

const FETCH_EQUIPMENT_TYPES = gql`
  query {
    __type(name: "EEquipmentTypes") {
      enumValues {
        name
      }
    }
  }
`;

const FETCH_ASSET_STATUSES = gql`
  query {
    __type(name: "AssetStatusEnum") {
      enumValues {
        name
      }
    }
  }
`;

const FETCH_TRANSACTION_TYPES = gql`
  query {
    __type(name: "EFleetTransactionTypes") {
      enumValues {
        name
      }
    }
  }
`;

const FETCH_TRANSACTION_TRANSACTION_TYPES = gql`
  query {
    __type(name: "EFleetTransactionTransactionTypes") {
      enumValues {
        name
      }
    }
  }
`;

export {
  FETCH_ASSET_TYPES,
  FETCH_ASSET_STATUSES,
  FETCH_FUEL_TYPES,
  FETCH_EQUIPMENT_TYPES,
  FETCH_TRANSACTION_TYPES,
  FETCH_TRANSACTION_TRANSACTION_TYPES,
};
