import { gql } from '@apollo/client';

const GET_GROUP = gql`
  query getGroupContext($id: ID!) {
    getGroup(id: $id) {
      id
      name
    }
  }
`;

const GET_ORG = gql`
  query getOrgContext($id: ID!) {
    getOrg(id: $id) {
      id
      name
      onroadId
      orgIntegrationConfiguration {
        linkPlanReferenceAutoGenerate
        linkBookingIntegrationEndpoint
        linkTransactionIntegrationEndpoint
        linkPlanIntegrationEndpoint
        linkIntegrationFileType
      }
      group {
        id
        name
      }
    }
  }
`;

const GET_SITE = gql`
  query getSiteContext($id: ID!) {
    getSite(id: $id) {
      id
      name
      configuration {
        linkInbound {
          disableSorting
        }
      }
      org {
        id
        name
        onroadId
        orgIntegrationConfiguration {
          linkPlanReferenceAutoGenerate
          linkBookingIntegrationEndpoint
          linkTransactionIntegrationEndpoint
          linkPlanIntegrationEndpoint
          linkIntegrationFileType
        }
        group {
          id
          name
        }
      }
    }
  }
`;

const GET_FACILITY = gql`
  query getFacilityContext($id: ID!) {
    getFacility(id: $id) {
      id
      name
      site {
        id
        name
        configuration {
          linkInbound {
            disableSorting
          }
        }
        org {
          id
          name
          onroadId
          orgIntegrationConfiguration {
            linkPlanReferenceAutoGenerate
            linkBookingIntegrationEndpoint
            linkTransactionIntegrationEndpoint
            linkPlanIntegrationEndpoint
            linkIntegrationFileType
          }
          group {
            id
            name
          }
        }
      }
    }
  }
`;

export { GET_GROUP, GET_ORG, GET_SITE, GET_FACILITY };
