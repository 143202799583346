import { useMutation } from '@apollo/client';
import { CheckmarkFilled16 } from '@carbon/icons-react';
import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import {
  ActionButtons,
  CurrentEntityContext,
  deleteS3File,
  FileUploadComponentSimple,
  FormWrapper,
  LoadingContext,
  NotificationsContext,
  TableDynamicInput,
  TextFormField,
  uploadS3File,
} from '../../../';
import styled from 'styled-components';
import formikSchema from './formikSchema';
import { UPDATE_PERMIT } from './gql';

const Checked = styled(CheckmarkFilled16)`
  & path {
    fill: ${({ theme }) => theme.colors.success};
  }
`;

const CheckedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditModal = ({ data, refetchAsset, clearModal }) => {
  const { id } = data;
  const { currentEntity } = useContext(CurrentEntityContext);
  const { addNotification, addInlineAlert } = useContext(NotificationsContext);
  const { setLoading } = useContext(LoadingContext);
  const [updateTransaction] = useMutation(UPDATE_PERMIT);

  const mutate = async (values, initialVals) => {
    const docsToWorkWith = values.documents.filter(
      (d) => (!d.path && d.file) || d.path,
    );
    const docsToDelete = initialVals.documents.filter(
      (d) => !docsToWorkWith.map((dd) => dd.path).includes(d.path),
    );
    const docsWithNoPathAndFile = docsToWorkWith.filter((d) => !d.path);
    const docsWithPathAndFile = docsToWorkWith.filter((d) => d.path && d.file);
    const docsWithPathAndNoFile = docsToWorkWith.filter(
      (d) => d.path && !d.file,
    );

    const newDocArray = [];

    for (const doc of docsToDelete) {
      await deleteS3File(doc.path, false);
    }

    for (const doc of docsWithNoPathAndFile) {
      const newPath = `orgs/${currentEntity.id}/fleet_permit_documents/${id}/${
        doc.name
      }.${doc.file.type.split('/')[1]}`;
      await uploadS3File(
        doc.file,
        newPath,
        {
          ContentType: doc.file.type,
        },
        false,
      );
      newDocArray.push({ name: doc.name, path: newPath });
    }

    for (const doc of docsWithPathAndFile) {
      await deleteS3File(doc.path, false);
      const newPath = `orgs/${currentEntity.id}/fleet_permit_documents/${id}/${
        doc.name
      }.${doc.file.type.split('/')[1]}`;
      await uploadS3File(
        doc.file,
        newPath,
        {
          ContentType: doc.file.type,
        },
        false,
      );
      newDocArray.push({ name: doc.name, path: newPath });
    }

    for (const doc of docsWithPathAndNoFile) {
      newDocArray.push({ name: doc.name, path: doc.path });
    }

    await updateTransaction({
      variables: { input: { ...values, documents: newDocArray } },
    });
  };

  const initialValues = {
    id: data.id,
    documents: data.documents || [],
  };
  return (
    <Formik
      initialValues={initialValues}
      {...formikSchema}
      validateOnMount
      onSubmit={(values) => {
        setLoading(true);
        mutate(values, initialValues)
          .then(() => {
            addInlineAlert(
              'success',
              'Permit Updated Successfully',
              'Permit successfully updated.',
            );
          })
          .catch((err) => addNotification('error', 'Error', err.message))
          .finally(() => {
            setLoading(false);
            clearModal();
            refetchAsset();
          });
      }}
    >
      {({ values }) => {
        return (
          <FormWrapper>
            <Form>
              <TableDynamicInput
                name="documents"
                columns={[
                  {
                    key: 'name',
                    header: 'Document Name',
                    Component: TextFormField,
                    componentProps: { light: true },
                  },
                  {
                    key: 'path',
                    header: 'Path',
                    Component: ({ field }) => {
                      return (
                        <CheckedWrapper>
                          {field.value && <Checked />}
                        </CheckedWrapper>
                      );
                    },
                    componentProps: { light: true },
                  },
                  {
                    key: 'file',
                    header: 'File',
                    Component: FileUploadComponentSimple,
                    componentProps: {
                      onForm: true,
                      allowedFileType: 'pdf',
                    },
                  },
                ]}
                headerText="Documents"
                actionButtonText="Add Document"
                rows={values.documents}
                newRow={{ name: '' }}
                disableNewRow={
                  !values.documents.every((d) => d.name && (d.path || d.file))
                }
              />
            </Form>
            <ActionButtons type={'finalStep'} primaryName="Update" />
          </FormWrapper>
        );
      }}
    </Formik>
  );
};

export default EditModal;
