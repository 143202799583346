import { Field, Form, Formik } from 'formik';
import React from 'react';
import {
  ActionButtons,
  Column,
  FormArea,
  FormItem,
  PageContainer,
  PhoneNumberField,
  TextFormField,
} from '../../../../../../';
import { validationSchema } from './validationSchema';

const OperatorDetails = ({
  editOperatorMutation,
  data,
  setCurrentFocusIndex,
  childIndex,
}) => {
  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const variables = {
          input: values,
        };
        editOperatorMutation({ variables });
      }}
    >
      {({ dirty, values, handleChange }) => {
        setCurrentFocusIndex(dirty ? childIndex : -1);
        return (
          <PageContainer height={'70vh'} align="end">
            <Form>
              <FormArea columns={1}>
                <Column>
                  <FormItem>
                    <Field
                      labelText="First Name *"
                      name="firstName"
                      component={TextFormField}
                    />
                  </FormItem>
                  <FormItem>
                    <Field
                      labelText="Surname *"
                      name="lastName"
                      component={TextFormField}
                    />
                  </FormItem>
                  <FormItem>
                    <Field
                      labelText="Mobile *"
                      name="mobile"
                      component={PhoneNumberField}
                    />
                  </FormItem>
                  <FormItem>
                    <Field
                      labelText="Reference"
                      name="reference"
                      component={TextFormField}
                    />
                  </FormItem>
                </Column>
              </FormArea>
            </Form>
            <ActionButtons type="finalStep" primaryName="Save & Continue" />
          </PageContainer>
        );
      }}
    </Formik>
  );
};

export default OperatorDetails;
