import { useContext } from 'react';
import { NotificationsContext, generateLinkWithParams } from '../../../';

const useOutcomeObject = ({ history, redirect, linkParams }) => {
  const { addInlineAlert } = useContext(NotificationsContext);
  return {
    onCompleted: () => {
      addInlineAlert(
        'success',
        'Operator Updated Successfully',
        'Your operator has successfully been updated',
      );
      history.push(generateLinkWithParams(redirect, linkParams));
    },
    onError: (err) =>
      addInlineAlert('error', 'Could Not Update Operator', err.message),
  };
};

export { useOutcomeObject };
