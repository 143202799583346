import config from '../config';
import setup from './setup';

const uploadFile = async (Body, Key, params = {}, isPublic = true) => {
  const s3 = setup();
  try {
    const result = await s3
      .upload({
        Bucket: config.s3(isPublic).bucketName,
        Key,
        Body,
        ...params,
      })
      .promise();
    return { result };
  } catch (e) {
    throw new Error(e);
  }
};

export default uploadFile;
