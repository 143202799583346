import { gql } from '@apollo/client';

const GET_META = gql`
  query getMeta($orgId: ID!) {
    org: getOrg(id: $orgId) {
      sites {
        id
        name
      }
      routebuilderZones(pagination: { take: 100000, skip: 0 }) {
        data {
          id
          siteId
          name
          reference
          color
          shape {
            type
            data {
              markers {
                lat
                lng
              }
              center {
                lat
                lng
              }
              radius
            }
          }
        }
      }
    }
    localities: __type(name: "ERoutebuilderZoneLocalities") {
      enumValues {
        name
      }
    }
    shapes: __type(name: "EGeofenceType") {
      enumValues {
        name
      }
    }
  }
`;

const GET_OVERLAPPING_SHAPES = gql`
  query getOverlappingShapes(
    $siteId: ID!
    $spatialPoly: [PointInput]
    $spatialCircle: SpatialCircleInput
  ) {
    site: getSite(id: $siteId) {
      routebuilderZones(
        pagination: { take: 100000, skip: 0 }
        query: { spatialPoly: $spatialPoly, spatialCircle: $spatialCircle }
      ) {
        data {
          id
          siteId
          name
          reference
          color
          shape {
            type
            data {
              markers {
                lat
                lng
              }
              center {
                lat
                lng
              }
              radius
            }
          }
        }
      }
    }
  }
`;

export { GET_META, GET_OVERLAPPING_SHAPES };
