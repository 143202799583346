import { omit } from 'lodash';
import { localStorageKeys } from '../config';

const clearAllLocalStorageKeys = () => {
  Object.values(omit(localStorageKeys, ['contextSearch'])).forEach((val) => {
    localStorage.removeItem(val);
  });
};

export default clearAllLocalStorageKeys;
