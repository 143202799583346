import { format } from 'date-fns';
import { generateLinkWithParams, getURLParam } from '../../../../';
import {
  ALL_OPERATORS,
  OPERATORS_ON_ORG_LEVEL,
  ORG_OPERATORS,
  SITE_OPERATORS,
} from './gql';
import { operatorHeadings } from './headings';

const filterOrgSites = (sites, orgSites) =>
  !orgSites
    ? sites
    : sites.filter((s) => orgSites.getOrg.sites.some((os) => os.id === s.id));

const shapeOperatorData = ({ data, operatorsPaged, allOperators }, orgSites) =>
  (operatorsPaged?.data || allOperators?.data || data).map(
    ({
      apps,
      operator,
      id,
      firstName,
      lastName,
      mobile,
      isEnabled,
      createdAt,
      idNumber,
      sites: assignedSites,
      isOrgOperator,
    }) => {
      if (!operator) {
        return {
          id,
          createdAt: format(new Date(createdAt || new Date()), 'yyyy-MM-dd'),
          idNumber,
          firstName,
          lastName,
          mobile,
          status: isEnabled ? 'Active' : 'Inactive',
          sitesCount: assignedSites.length,
        };
      } else {
        const sites = filterOrgSites(
          assignedSites ? assignedSites : operator.sites,
          orgSites,
        );
        return {
          id: operator.id,
          createdAt: format(
            new Date(operator?.createdAt || new Date()),
            'yyyy-MM-dd',
          ),
          idNumber: operator?.idNumber,
          firstName: operator?.firstName,
          lastName: operator?.lastName,
          mobile: operator?.mobile,
          status: operator?.isEnabled ? 'Active' : 'Inactive',
          apps: apps?.length,
          sitesCount:
            sites && sites.length === 1
              ? (sites[0].site || sites[0]).name
              : (sites || []).length,
          isOrgOperator,
        };
      }
    },
  );

const getCurrentContext = ({
  currentEntity,
  linkParams,
  match,
  create,
  navLinks = [],
  currentEntityType = '',
  isOrgLevel = false,
}) => {
  const commonPayload = {
    headings: operatorHeadings(currentEntityType, isOrgLevel),
    dataShapeFunction: shapeOperatorData,
    gqlVariables: isOrgLevel
      ? { id: getURLParam(match, 'orgId') }
      : !currentEntityType
      ? {}
      : { id: getURLParam(match, `${currentEntityType}Id`) },
    paginationObject: isOrgLevel
      ? 'allOperators'
      : currentEntity?.id
      ? 'operatorsPaged'
      : 'getOperatorsPaged',
    navLinks: isOrgLevel
      ? navLinks.map((n) => ({ ...n, display: (row) => row.isOrgOperator }))
      : navLinks,
    actionLink: generateLinkWithParams(create, linkParams),
  };
  if (isOrgLevel) {
    return {
      gql: OPERATORS_ON_ORG_LEVEL,
      queryName: 'getOrg',
      ...commonPayload,
    };
  } else if (!currentEntity?.id) {
    return {
      gql: ALL_OPERATORS,
      queryName: 'getOperatorsPaged',
      ...commonPayload,
    };
  } else if (currentEntityType === 'org') {
    return {
      gql: ORG_OPERATORS,
      queryName: 'getOrg',
      ...commonPayload,
    };
  } else if (currentEntityType === 'site') {
    return {
      gql: SITE_OPERATORS,
      queryName: 'getSite',
      ...commonPayload,
    };
  }
};

export { getCurrentContext, shapeOperatorData };
