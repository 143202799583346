import {
  Attachment24,
  Checkmark16,
  Download16,
  SendFilled24,
} from '@carbon/icons-react';
import { darken, lighten } from 'polished';
import styled, { keyframes } from 'styled-components';
import { CheckMarkProps, MessageContainerProps, StyleProps } from './types';

const Tick = styled(Checkmark16)<CheckMarkProps>`
  fill: ${({ theme, isRead }) =>
    isRead ? theme.colors.primary : theme.colors.grey};
  grid-area: ticks;
  align-self: end;
`;
const SendIcon = styled(SendFilled24)`
  fill: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;
const Attachment = styled(Attachment24)`
  fill: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  position: relative;
`;
const MessageContainer = styled.div<MessageContainerProps>`
  padding: 5px;
  border-radius: 5px;
  background-color: ${({ isOutgoing, theme }) =>
    isOutgoing ? theme.colors.secondary : theme.colors.white};
  display: grid;
  grid-template-columns: 5fr 1fr auto auto;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'name date download ticks' 'message message message message';
  align-self: ${({ isOutgoing }) => (isOutgoing ? 'end' : 'start')};
  width: 50%;
  margin-bottom: 0.5rem;
  min-width: 300px;
`;

const MessageText = styled.div<StyleProps>`
  grid-area: message;
  color: ${({ theme, isOutgoing }) =>
    isOutgoing ? theme.colors.white : theme.colors.black};
  font-size: 0.65rem;
`;

const Ticks = styled.div<StyleProps>`
  grid-area: ticks;
  color: ${({ theme, isOutgoing }) =>
    isOutgoing ? theme.colors.white : theme.colors.black};
`;
const Name = styled.div<StyleProps>`
  grid-area: name;
  color: ${({ nameColor }) => nameColor};
  margin-bottom: 5px;
  font-size: 0.65rem;
`;
const Date = styled.div<StyleProps>`
  grid-area: date;
  color: ${({ theme, isOutgoing }) =>
    isOutgoing ? theme.colors.white : theme.colors.black};
  white-space: nowrap;
  margin: 0 5px 0 5px;
  font-size: 0.65rem;
`;

const InputContainer = styled.form`
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-self: stretch;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
  padding: 10px;
`;

const Input = styled.input`
  cursor: pointer;
  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};
  font-size: 1rem;
  border-radius: 5px;
  height: 40px;
  padding: 10px;
  margin: 0 10px;
  align-self: end;
`;

const Button = styled.button`
  border: 1px solid blue;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  margin-right: 10px;
`;

const ConversationContainer = styled.div`
  width: 400px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 10px;
`;
const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  padding: 10px;
`;
const TicksWrapper = styled.div`
  grid-area: ticks;
  align-self: end;
  > * {
    padding: 0;
    margin: 0;
    align-items: center;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const ImageMessage = styled.img`
  max-height: 50px;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
`;
const DownloadIcon = styled(Download16)`
  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  grid-area: download;
  :hover {
    & path {
      fill: ${({ theme }) => lighten(0.1, theme.colors.primary)};
    }
    cursor: pointer;
  }
`;
const ChatHeading = styled.h3`
  background-color: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
  padding: 15px;
  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};
  font-weight: bold;
`;

const AttachmentInput = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 4;
  ::-webkit-file-upload-button {
    display: none;
  }
`;

const AttachmentWrapper = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
`;

const TypingContainer = styled.div`
  width: fit-content;
  height: 25px;
  position: relative;
  padding: 10px;
  margin-left: 5px;
  background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
  border-radius: 20px;
`;

const loadingFade = keyframes`
 0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
`;
const TypingDot = styled.div`
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: ${({ theme }) => darken(0.3, theme.colors.lightGrey)};
  border-radius: 50%;
  opacity: 0;
  animation: ${loadingFade} 1s infinite;
  :nth-child(1) {
    animation-delay: 0s;
  }
  :nth-child(2) {
    animation-delay: 0.2s;
  }
  :nth-child(3) {
    animation-delay: 0.4s;
  }
`;

export {
  Name,
  Date,
  MessageText,
  Ticks,
  MessageContainer,
  Button,
  Input,
  InputContainer,
  Tick,
  ConversationContainer,
  MessagesContainer,
  SendIcon,
  TicksWrapper,
  Image,
  ImageMessage,
  DownloadIcon,
  ChatHeading,
  Attachment as AttachmentIcon,
  AttachmentInput,
  AttachmentWrapper,
  TypingContainer,
  TypingDot,
};
