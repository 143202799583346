import * as yup from 'yup';

const editAddressValidation = yup
  .object({
    streetName: yup.string().required().min(2),
    suburb: yup.string().required().min(2),
    city: yup.string().required().min(2),
    streetNo: yup.string().required().min(1),
    postalCode: yup.string().notRequired().min(2),
    province: yup.string().notRequired().min(2),
  })
  .notRequired()
  .nullable();

export default editAddressValidation;
