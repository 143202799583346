import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { getOperatorName, listSortAndFormat } from '../../../functions';
import { useAlerts, useLoading } from '../../../hooks';
import { FormItem, FormWrapper } from '../../../styles';
import ActionButtons from '../../ActionButtons';
import { DangerButton } from '../../Buttons';
import Dropdown from '../../Dropdown';
import TextAreaField from '../../TextAreaField';
import { getVehicle } from '../functions';
import formikSchema from './formikSchema';
import { FORCE_COMPLETE_LOAD } from './gql';
import { ForceCloseContainer, LoadInfoModalContainer } from './styles';

const ForceClose = ({ load, close }) => {
  const { alertSuccess, onError } = useAlerts();
  const [forceClose, { loading }] = useMutation(FORCE_COMPLETE_LOAD, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      alertSuccess('Load Force Closed');
      close();
    },
    onError,
  });
  useLoading(loading);
  return (
    <ForceCloseContainer>
      <h3>Force Close Load</h3>
      <Formik
        {...formikSchema}
        onSubmit={(values) => {
          forceClose({
            variables: { id: load.id, forceInput: { ...values, id: load.id } },
          });
        }}
      >
        <FormWrapper>
          <Form>
            <FormItem>
              <Field
                name="reason"
                labelText="Reason *"
                component={Dropdown}
                options={listSortAndFormat(
                  load.carrier?.loadConfiguration.loadForceCloseReasons,
                  (l) => l,
                  (l) => l,
                )}
              />
            </FormItem>
            <FormItem>
              <Field
                name="comment"
                labelText="Comment"
                component={TextAreaField}
              />
            </FormItem>
          </Form>
          <ActionButtons
            primaryName="Decline Load"
            type="finalStep"
            fillWidth
          />
        </FormWrapper>
      </Formik>
    </ForceCloseContainer>
  );
};

const LoadInfoModal = ({ load, close, setModalContent }) => {
  const { allocation = {} } = load;
  if (!allocation) {
    return (
      <LoadInfoModalContainer>
        <div>No Allocations Yet</div>
      </LoadInfoModalContainer>
    );
  }
  const driver = getOperatorName(allocation.driver);
  const crew = (allocation.crew || []).map(getOperatorName).join(', ');
  const vehicle = getVehicle(allocation.vehicle);
  const trailers = (allocation.assets || []).map(getVehicle).join(', ');
  return (
    <LoadInfoModalContainer>
      <div>
        <h3>Load Information</h3>
        <p>
          <strong>Driver: </strong>
          {driver}
        </p>
        {crew && (
          <p>
            <strong>Crew: </strong>
            {crew}
          </p>
        )}
        <p>
          <strong>Asset: </strong>
          {vehicle}
        </p>
        {trailers && (
          <p>
            <strong>Trailers: </strong>
            {trailers}
          </p>
        )}
      </div>
      {load.status === 'ACTIVE' && (
        <DangerButton
          onClick={() => {
            setModalContent(<ForceClose load={load} close={close} />);
          }}
        >
          Force Close
        </DangerButton>
      )}
    </LoadInfoModalContainer>
  );
};

export default LoadInfoModal;
