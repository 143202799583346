import {
  Circle,
  GoogleMap,
  OverlayView,
  Polygon,
  Polyline,
} from '@react-google-maps/api';
import { get } from 'lodash';
import Tooltip from 'rc-tooltip';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  formatDateTime,
  getLoadAssetCurrentPosition,
  getOperatorName,
  googleMapsOptions,
  googleMapsStyles,
  MapCenterContext,
  marker,
  PageError,
  PageLoading,
  theme,
  useGoogleMapsLoader,
} from '../../../';
import { Tooltip as TooltipDisplay } from '../Tooltip';
import CurrentPositionTooltip from './CurrentPositionTooltip';
import SelectedActivityTooltip from './SelectedActivityTooltip';
import {
  Arrow,
  CurrentPosition,
  MapContainer,
  Marker,
  OrderIndicator,
  TripStartEndEvent,
} from './styles';

const getStops = (load) => {
  return get(load, 'travelPlan.stops', []).map((s) => ({
    ...s,
    ...(get(load, 'geometry.stops', [])?.find((st) => st.stopId === s.id) ||
      {}),
  }));
};

const isGoodLatLng = (lat, lng) => lat !== 0 && lng !== 0;

const extendBounds = (bounds, lat, lng) => {
  isGoodLatLng(lat, lng) && bounds.extend({ lat, lng });
};

const MapView = ({
  selectedActivity,
  livePosition,
  load,
  selectedTrips = [],
  showCurrentPosition = false,
}) => {
  const assetCurrentPosition =
    livePosition || getLoadAssetCurrentPosition(load);
  const showAssetCurrentPosition =
    (showCurrentPosition && !!assetCurrentPosition) ||
    (load.status === 'ACTIVE' && !!assetCurrentPosition);
  const [map, setMap] = useState();
  const { center } = useContext(MapCenterContext);
  const { isLoaded, loadError } = useGoogleMapsLoader();

  const fitMapBounds = useCallback(() => {
    const stops = getStops(load);
    const bounds = new window.google.maps.LatLngBounds();
    if (selectedTrips.length > 0) {
      for (const trip of selectedTrips) {
        const {
          startEvent: { lat: sLat, lng: sLng },
          endEvent: { lat: eLat, lng: eLng },
        } = trip;
        extendBounds(bounds, eLat, eLng);
        extendBounds(bounds, sLat, sLng);
        for (const { lat, lng } of trip.snapshots) {
          extendBounds(bounds, lat, lng);
        }
      }
    } else if (selectedActivity) {
      bounds.extend(selectedActivity.stop.location.data.entrance);
    } else {
      const points = stops.map((s) => s.location.data.entrance);
      for (const point of points) {
        bounds.extend(point);
      }
      if (showAssetCurrentPosition) {
        bounds.extend({
          lat: assetCurrentPosition.gps.lat,
          lng: assetCurrentPosition.gps.lng,
        });
      }
    }
    map.fitBounds(bounds);
  }, [
    assetCurrentPosition,
    load,
    map,
    selectedTrips,
    showAssetCurrentPosition,
    selectedActivity,
  ]);

  useEffect(() => {
    map && fitMapBounds();
  }, [selectedTrips, map, fitMapBounds]);

  const handleZoom = (position) => {
    if (map.getZoom() >= 15) {
      fitMapBounds();
    } else {
      map.setCenter(position);
      map.setZoom(17);
    }
  };

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      const points = getStops(load).map((s) => s.location.data.entrance);
      for (const point of points) {
        bounds.extend(point);
      }
      if (showAssetCurrentPosition) {
        bounds.extend({
          lat: assetCurrentPosition.gps.lat,
          lng: assetCurrentPosition.gps.lng,
        });
      }
      map.fitBounds(bounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAssetCurrentPosition, map]);

  if (loadError) {
    return <PageError error={loadError} />;
  }

  if (isLoaded) {
    const stops = getStops(load);
    const geometries = stops.map((s) => s.geometry).filter(Boolean);
    const fleetNumber = get(load, 'allocation.vehicle.fleetNumber');
    const vehicle = get(load, 'allocation.vehicle.licenceNo');
    const vehicleDisplay = `${vehicle} ${
      fleetNumber ? ` (${fleetNumber})` : ''
    }`;
    return (
      <MapContainer>
        <GoogleMap
          onLoad={(m) => setMap(m)}
          options={{ ...googleMapsOptions, styles: googleMapsStyles.grayscale }}
          mapContainerStyle={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
          zoom={14}
          center={center}
          tilt={0}
        >
          {stops.map((stop, stopIndex) => {
            const order = stopIndex + (load.isRoute || load.hasLoading ? 0 : 1);
            const isLoading = load.hasLoading && stopIndex === 0;
            const {
              location: { data: location },
            } = stop;
            const {
              entrance,
              shape: { type, data: shape },
            } = location;
            return (
              <Fragment key={stop.id}>
                {type === 'RADIUS' ? (
                  <Circle
                    center={shape.center}
                    radius={shape.radius}
                    options={{
                      fillColor: theme.colors.primary,
                      strokeColor: theme.colors.secondary,
                    }}
                  />
                ) : (
                  <Polygon
                    paths={shape.markers}
                    options={{
                      fillColor: theme.colors.primary,
                      strokeColor: theme.colors.secondary,
                    }}
                  />
                )}
                <OverlayView
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  position={entrance}
                >
                  <Tooltip placement="top" overlay={<p>{location.name}</p>}>
                    <OrderIndicator
                      onMap
                      isLoading={isLoading}
                      onClick={() => {
                        handleZoom(entrance);
                      }}
                    >
                      {isLoading
                        ? 'L'
                        : stop.type === 'START'
                        ? 'S'
                        : stop.type === 'END'
                        ? 'E'
                        : order}
                    </OrderIndicator>
                  </Tooltip>
                </OverlayView>
              </Fragment>
            );
          })}
          {geometries.map((path) => (
            <Polyline
              path={path}
              options={{
                strokeColor: theme.colors.purple,
                strokeOpacity: 0.7,
              }}
            />
          ))}
          {selectedTrips.map((trip) => {
            const {
              allocation: { driver },
            } = load;
            const { snapshots, startEvent, endEvent } = trip;
            return (
              <Fragment key={trip.id}>
                <Polyline
                  path={snapshots.map((s) => ({ lat: s.lat, lng: s.lng }))}
                  options={{
                    strokeColor: theme.colors.darkGrey,
                    strokeOpacity: 0.7,
                    strokeWeight: 10,
                  }}
                />
                {snapshots.map((s, idx) => {
                  const values = [
                    {
                      header: 'Registration',
                      value: vehicleDisplay,
                    },
                    {
                      header: 'Driver',
                      value: getOperatorName(driver),
                    },
                    {
                      header: 'GPS',
                      value: `${s.lat.toFixed(3)}, ${s.lng.toFixed(3)}`,
                    },
                    {
                      header: 'Speed',
                      value: `${s.speed.toFixed()} kph`,
                    },
                    {
                      header: 'Date',
                      value: formatDateTime(s.dateTime),
                    },
                    {
                      header: 'GPS Accuracy',
                      value: `${s.accuracy}`,
                    },
                  ];
                  return (
                    <OverlayView
                      key={idx}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      position={{ lat: s.lat, lng: s.lng }}
                    >
                      <Tooltip
                        destroyTooltipOnHide
                        placement="top"
                        overlay={
                          <TooltipDisplay
                            tooltipContent={{
                              header: vehicleDisplay,
                              values,
                              addresses: [
                                {
                                  lat: s.lat,
                                  lng: s.lng,
                                },
                              ],
                            }}
                          />
                        }
                      >
                        <Arrow direction={s.direction} />
                      </Tooltip>
                    </OverlayView>
                  );
                })}
                {[startEvent, endEvent].map(({ lat, lng, dateTime }, idx) => (
                  <OverlayView
                    key={trip.id + dateTime}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    position={{ lat, lng }}
                  >
                    <TripStartEndEvent isStart={idx === 0} />
                  </OverlayView>
                ))}
              </Fragment>
            );
          })}
          {showAssetCurrentPosition && (
            <OverlayView
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              position={{
                lat: assetCurrentPosition.gps.lat,
                lng: assetCurrentPosition.gps.lng,
              }}
            >
              <Tooltip
                destroyTooltipOnHide
                placement="top"
                overlay={
                  <CurrentPositionTooltip
                    load={load}
                    assetCurrentPosition={assetCurrentPosition}
                    vehicleDisplay={vehicleDisplay}
                  />
                }
              >
                <CurrentPosition
                  status={assetCurrentPosition.status}
                  direction={assetCurrentPosition.gps.direction}
                  onClick={() => {
                    handleZoom({
                      lat: assetCurrentPosition.gps.lat,
                      lng: assetCurrentPosition.gps.lng,
                    });
                  }}
                />
              </Tooltip>
            </OverlayView>
          )}
          {selectedActivity && (
            <OverlayView
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              position={{
                lat: selectedActivity.stop.location.data.entrance.lat,
                lng: selectedActivity.stop.location.data.entrance.lng,
              }}
            >
              <Tooltip
                destroyTooltipOnHide
                placement="top"
                overlay={
                  <SelectedActivityTooltip
                    selectedActivity={selectedActivity}
                    load={load}
                  />
                }
              >
                <Marker src={marker} height={24} width={24} />
              </Tooltip>
            </OverlayView>
          )}
        </GoogleMap>
      </MapContainer>
    );
  }

  return <PageLoading />;
};

export default MapView;
