import * as yup from 'yup';

const yupBooleanOptional = yup.boolean();
const yupBooleanNullable = yupBooleanOptional.nullable();
const yupBooleanRequired = yupBooleanOptional.required();

const yupStringOptional = yup.string();
const yupStringNullable = yupStringOptional.nullable();
const yupStringRequired = yupStringOptional.required();

const yupNumberOptional = yup.number();
const yupNumberNullable = yupNumberOptional.nullable();
const yupNumberRequired = yupNumberOptional.required();

const yupStringArrayOptional = yup.array().of(yupStringOptional);
const yupStringArrayRequired = yupStringArrayOptional.required();

const yupNumberArrayOptional = yup.array().of(yupNumberOptional);
const yupNumberArrayRequired = yupNumberArrayOptional.required();

export {
  yupBooleanNullable,
  yupBooleanOptional,
  yupBooleanRequired,
  yupNumberArrayOptional,
  yupNumberArrayRequired,
  yupNumberNullable,
  yupNumberOptional,
  yupNumberRequired,
  yupStringArrayOptional,
  yupStringArrayRequired,
  yupStringNullable,
  yupStringOptional,
  yupStringRequired,
};
