import { noop } from 'lodash';
import { lighten } from 'polished';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import styled, { css } from 'styled-components';
import { theme } from '../../styles';
import InfoBubble from '../InfoBubble';

const Label = styled.div`
  font-size: 0.75rem;
  margin-bottom: 5px;
  color: ${({ error, theme }) => error && theme.colors.danger};
`;
const PrefixContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;
  > p {
    font-size: 0.75rem;
  }
`;
const InputContainer = styled.div`
  position: relative;
  width: 100%;
  > p {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 0.75rem;
  }
`;
const InfoContainer = styled.div`
  position: relative;
  > p {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 0.75rem;
  }
`;
const TextUnitAndInputWrapper = styled.div`
  position: relative;
  > p {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 0.75rem;
  }
  > :last-child {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 0.75rem;
  }
`;

const NumericWrapper = styled.div`
  border-bottom: ${({ theme, noBorders }) =>
    !noBorders && `1px solid ${theme.colors.mediumGrey}`};
  height: 40px;
  display: flex;
  align-items: center;
  & input {
    width: 100%;
    border: none;
    height: 100%;
  }
`;

let TextFormField = ({
  infoText,
  noError,
  form,
  labelText,
  unit,
  onChange,
  value,
  field = {},
  onKeyPress = noop,
  prefix,
  disabled,
  max,
  ...props
}) => {
  const isNumberField = props.type === 'number';
  const isTouched = form?.touched?.[field?.name] || form?.submitCount > 0;
  const hasError = isTouched && form?.errors?.[field?.name];
  const inputBase = isNumberField ? (
    <NumericWrapper noBorders={props.noBorders}>
      <NumericFormat
        isAllowed={({ floatValue }) =>
          floatValue <= (typeof max === 'number' ? max : Infinity)
        }
        decimalScale={2}
        thousandSeparator=","
        displayType="input"
        value={value || field.value}
        style={{ borderColor: hasError && theme.colors.danger }}
        onValueChange={({ floatValue: value }) => {
          const changeFunction = onChange || field?.onChange;
          const e = { target: { name: field?.name, value } };
          changeFunction?.(e);
        }}
        onKeyPress={onKeyPress}
        disabled={disabled}
      />
    </NumericWrapper>
  ) : (
    <input
      type="text"
      id={field.name}
      {...field}
      {...props}
      disabled={disabled}
      value={value || field.value}
      style={{
        borderColor: hasError && theme.colors.danger,
        color: hasError && theme.colors.danger,
      }}
      onChange={onChange || field.onChange}
      onKeyPress={onKeyPress}
    />
  );
  const inputComponent = noError ? (
    inputBase
  ) : unit && infoText ? (
    <TextUnitAndInputWrapper>
      {inputBase}
      <p style={{ color: props.disabled ? '#ccc' : '#333' }}>{unit}</p>
      <InfoBubble text={infoText} />
    </TextUnitAndInputWrapper>
  ) : unit && prefix ? (
    <PrefixContainer>
      <p>{prefix}</p>
      <InputContainer>
        {inputBase}
        <p style={{ color: props.disabled ? '#ccc' : '#333' }}>{unit}</p>
      </InputContainer>
    </PrefixContainer>
  ) : unit ? (
    <InputContainer>
      {inputBase}
      <p style={{ color: props.disabled ? '#ccc' : '#333' }}>{unit}</p>
    </InputContainer>
  ) : infoText ? (
    <InfoContainer>
      {inputBase}
      <InfoBubble text={infoText} />
    </InfoContainer>
  ) : prefix ? (
    <PrefixContainer>
      <p>{prefix}</p>
      {inputBase}
    </PrefixContainer>
  ) : (
    inputBase
  );
  return (
    <div>
      {labelText && <Label error={hasError}>{labelText}</Label>}
      {inputComponent}
    </div>
  );
};

TextFormField = styled(TextFormField)`
  height: 40px;
  font-size: 0.75rem;
  border: ${({ block, theme }) =>
    block ? `1px solid ${theme.colors.mediumGrey}` : 'none'};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  ${({ noBorders }) =>
    noBorders &&
    css`
      border: none;
    `}
  width: 100%;
  padding: 0.5rem;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
  :disabled {
    background: ${({ displayAsNormal }) => displayAsNormal && '#fff'};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
`;
export default TextFormField;
