import { useFormikContext } from 'formik';
import { noop } from 'lodash';
import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components';

const Label = styled.div`
  font-size: 0.75rem;
  margin-bottom: 5px;
`;

let TextAreaField = ({
  infoText,
  noError,
  form,
  labelText,
  unit,
  onChange,
  value,
  field = {},
  onKeyPress = noop,
  onKeyDown = noop,
  ...props
}) => {
  const { submitForm } = useFormikContext();
  return (
    <div>
      {labelText && <Label>{labelText}</Label>}
      <textarea
        id={field.name}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onKeyDown={(e) => {
          onKeyDown(e);
          if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
            submitForm?.();
          }
        }}
        value={value}
        {...field}
        rows={5}
        cols={50}
        {...props}
      />
    </div>
  );
};

TextAreaField = styled(TextAreaField)`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  resize: none;
  border: none;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
`;
export default TextAreaField;
