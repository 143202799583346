import * as yup from 'yup';

export default yup.object({
  licenceNumber: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Licence Number Can Only be Alphanumeric')
    .max(9, 'Licence Number Cannot Exceed 9 Characters')
    .required('Please enter a Licence'),
  type: yup.string('Please enter a Type').required('Please enter a Type'),
  assetClassId: yup
    .string('Please enter an Asset Class')
    .required('Please enter an Asset Class'),
});
