import styled from 'styled-components';

const TopBar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  align-items: center;
  & p {
    font-size: 0.75rem;
  }
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 3px solid ${({ theme }) => theme.colors.lightGrey};
`;

const InputAndButtonContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  grid-column-gap: 1rem;
`;

const VerifiedContainer = styled.p`
  padding: 1rem;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  text-align: center;
`;

const Unverified = styled(VerifiedContainer)`
  background: ${({ theme }) => theme.colors.danger};
`;

const Verified = styled(VerifiedContainer)`
  background: ${({ theme }) => theme.colors.success};
`;

export { InputAndButtonContainer, TopBar, Unverified, Verified };
