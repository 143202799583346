import { gql } from '@apollo/client';

const CREATE_ADN = gql`
  mutation createADN($input: ADNCreateInput!) {
    createADN(input: $input) {
      id
    }
  }
`;

export { CREATE_ADN };
