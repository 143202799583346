import { Camera24, Chat24, Information24, Menu24 } from '@carbon/icons-react';
import { lighten } from 'polished';
import styled, { css } from 'styled-components';
import { generatePrimaryIcon } from '../../../';

const ExecutionFlow = styled.div`
  grid-area: execution;
  height: ${({ height }) => height};
  overflow-y: auto;
  font-size: 0.75rem;
`;

const LoadInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto auto auto auto;
  grid-gap: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
`;

const [RepressIcon, ChatIcon, InfoIcon, CameraIcon] = [
  Menu24,
  Chat24,
  Information24,
  Camera24,
].map((Icon) => generatePrimaryIcon(Icon));

const InfoBox = styled.div`
  font-weight: bold;
  padding: 0.5rem;
  ${({ theme, status }) => {
    const color = status
      ? theme.colors.loads.statuses[status]
      : theme.colors.tmPrimary;
    const lighterColor = lighten(0.3, color);
    return css`
      background: ${lighterColor};
      color: ${status ? color : theme.colors.black};
      border: 2px solid ${color};
    `;
  }}
`;

const StopExecution = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  > h3 {
    display: flex;
    align-items: center;
  }
  padding-bottom: 1rem;
`;

const StopExecutionDetails = styled.div`
  & * {
    font-size: 0.6rem !important;
  }
  & td,
  th {
    padding: 0.2rem;
    & p {
      padding: 0;
    }
  }
  padding-left: 2.6rem;
  :not(:last-child) {
    padding-bottom: 1rem;
  }
`;

const OrderIndicator = styled.div`
  z-index: 2;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.colors.tmPrimary};
  color: ${({ theme }) => theme.colors.tmPrimary};
  background: ${({ theme }) => theme.colors.white};
  :hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.tmPrimary};
  }
  font-weight: bold;
  width: 1.7rem;
  height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  align-items: center;
  justify-items: center;
`;

const StopExecutionFlow = styled.div`
  padding: 1rem 0;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    left: 0.8rem;
    top: 0;
    height: 100%;
    width: 2px;
    background: ${({ theme }) => theme.colors.tmPrimary};
    z-index: 1;
  }
`;

const ActivityType = styled.p`
  width: 60px;
`;

const AbsoluteActivityType = styled(ActivityType)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-left: 10px solid ${({ theme, type }) => theme.colors.loads[type]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActivityTypeDot = styled.div`
  position: absolute;
  left: -2.2rem;
  top: 25%;
  width: 20%;
  height: 50%;
  background: ${({ theme, type }) => theme.colors.loads[type]};
  border-radius: 100px;
`;

const ActivityTypeLine = styled.div`
  position: absolute;
  left: -2.2rem;
  top: 48%;
  width: 2.2rem;
  height: 4%;
  background: ${({ theme, type }) => theme.colors.loads[type]};
  border-radius: 100px;
`;

const LoadInfoModalContainer = styled.div`
  width: 100%;
  height: 100%;
  & h3 {
    margin-bottom: 2rem;
    text-align: center;
  }
  & p {
    margin-bottom: 0.5rem;
  }
  & strong {
    margin-right: 2rem;
  }
  display: grid;
  grid-template-rows: 1fr auto;
  grid-gap: 1rem;
  justify-items: center;
`;

const ForceCloseContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr;
  grid-gap: 1rem;
`;

export {
  AbsoluteActivityType,
  ActivityType,
  ActivityTypeDot,
  ActivityTypeLine,
  CameraIcon,
  ChatIcon,
  ExecutionFlow,
  ForceCloseContainer,
  InfoBox,
  InfoIcon,
  LoadInfo,
  LoadInfoModalContainer,
  OrderIndicator,
  RepressIcon,
  StopExecution,
  StopExecutionDetails,
  StopExecutionFlow,
};
