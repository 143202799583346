import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import React from 'react';
import {
  convertEnumString,
  formatDateTime,
  PageError,
  PageLoading,
  Table,
  Td,
  Th,
  theme,
} from '../../../../../';
import { GET_LICENCES } from './gql';
import { Container } from './styles';

const LicenceModal = ({ row }) => {
  const { data, error } = useQuery(GET_LICENCES, { variables: { id: row.id } });

  if (error) {
    return <PageError error={error} />;
  }

  if (data) {
    const {
      getOperator: { licences },
    } = data;
    if (licences.length === 0) {
      return (
        <Container>
          <h3>This operator currently does not have licences</h3>
        </Container>
      );
    }
    return (
      <Container>
        <Table>
          <thead>
            <tr>
              <Th noHover>Type</Th>
              <Th noHover>Licence Number</Th>
              <Th noHover>Status</Th>
              <Th noHover>Valid From</Th>
              <Th noHover>Valid To</Th>
              <Th noHover>Added By</Th>
              <Th noHover>Date Added</Th>
            </tr>
          </thead>
          <tbody>
            {orderBy(licences, [(l) => new Date(l.validTo)], ['desc']).map(
              (licence) => {
                const addedBy = licence.createdByOperator
                  ? `${licence.createdByOperator.firstName} ${licence.createdByOperator.lastName}`
                  : 'System';

                const TD = ({ children }) => (
                  <Td
                    style={{
                      background:
                        theme.colors[
                          licence.status === 'VALID' ? 'success' : 'danger'
                        ],
                    }}
                  >
                    {children}
                  </Td>
                );
                return (
                  <tr key={licence.documentNumber}>
                    <TD>{(licence.documentType || '').replace(/_/g, ' ')}</TD>
                    <TD>{licence.documentNumber}</TD>
                    <TD>{convertEnumString(licence.status)}</TD>
                    <TD>{formatDateTime(licence.validFrom, null, false)}</TD>
                    <TD>{formatDateTime(licence.validTo, null, false)}</TD>
                    <TD>{addedBy}</TD>
                    <TD>{formatDateTime(licence.createdAt, null, false)}</TD>
                  </tr>
                );
              },
            )}
          </tbody>
        </Table>
      </Container>
    );
  }

  return <PageLoading />;
};

export default LicenceModal;
