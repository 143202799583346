import { lighten } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
  border: none;
  & .react-datepicker-wrapper,
  & .react-datepicker__input-container {
    border: none;
    width: 100%;
  }
  & input {
    font-size: 0.75rem;
    width: 100%;
    border: none;
    background: none;
    padding: 10px;
    cursor: pointer;
    :disabled {
      cursor: not-allowed;
      background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
    }
  }
`;

const DateInput = styled.div`
  background: ${({ theme, disabled }) =>
    disabled && lighten(0.12, theme.colors.lightGrey)};
  height: 40px;
  width: 100%;
  font-size: 0.75rem;
  color: ${({ theme, hasValue }) =>
    hasValue ? theme.colors.darkGrey : theme.colors.mediumGrey};
  cursor: pointer;
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 1rem;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
`;

const Label = styled.div`
  font-size: 0.75rem;
  margin-bottom: 5px;
`;

const Selectors = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;

export { Container, Label, DateInput, Selectors };
