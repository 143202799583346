import React from 'react';
import { QRCode } from '../../../../';

const headings = [
  {
    key: 'licenceNumber',
    header: 'Licence Number',
    allowOrder: true,
    auditPath: 'getAsset',
  },
  {
    key: 'fleetNumber',
    header: 'Fleet Number',
    allowOrder: true,
  },
  {
    key: 'vin',
    header: 'VIN',
  },
  {
    key: 'type',
    header: 'Asset Type',
    isEnum: true,
  },
  {
    key: 'class',
    header: 'Asset Class',
  },
  { key: 'position', header: 'Link' },
  {
    key: 'isVerified',
    header: 'Verified',
    isCount: true,
  },
  {
    key: 'isActive',
    header: 'Active',
    isCount: true,
  },
  {
    key: 'siteCount',
    header: 'Sites',
    isCount: true,
  },
  {
    key: 'assetGroupCount',
    header: 'Asset Groups',
    isCount: true,
  },
  {
    key: 'qrCode',
    header: 'QR Code',
    isCount: true,
    qrcode: true,
    QrCode: ({ row, rawData }) => {
      const getHeaders = (r, d) => [
        { label: 'VIN', val: d.masterAsset?.vin },
        { label: 'AssetID', val: r.id },
      ];
      const keys = ['licenceNumber', 'class', 'fleetNumber'];
      const getValue = (r, d) =>
        d.masterAsset ? `VIN:${d.masterAsset.vin}:VIN` : `TMA:${r.id}:TMA`;
      return (
        <QRCode
          row={row}
          rawData={(rawData.org || rawData).assetsPaged.data}
          getQRValue={getValue}
          descriptionKeys={keys}
          getHeaders={getHeaders}
          size={{ height: 295, width: 250 }}
          orientation="landscape"
          qrCodeSize={100}
          padding={10}
          smallHeader
        />
      );
    },
  },
];

export default headings;
