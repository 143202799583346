import { get } from 'lodash';
import { append, clone } from 'ramda';
import { convertEnumString } from './convertEnumString';

const getLoadAssetCurrentPosition = (load, returnEmptyObject = false) => {
  const pos = get(load, 'allocation.vehicle.asset.currentPosition');
  return pos || (returnEmptyObject && {});
};

const updateLoadAssetCurrentPosition = ({ load, payload }) => {
  const newLoad = clone(load);
  if (get(newLoad, 'allocation.vehicle.asset')) {
    newLoad.allocation.vehicle.asset.currentPosition = payload;
  }
  return newLoad;
};

const updateLoadExecution = ({ load, payload = {} }) => {
  const newLoad = clone(load);
  newLoad.execution = { ...(load.execution || {}), ...payload };
  return newLoad;
};

const updateLoadExtrapolation = ({ load, payload = {} }) => {
  const newLoad = clone(load);
  newLoad.extrapolation = payload;
  return newLoad;
};

const updateLoadStatus = ({ load, payload }) => {
  const newLoad = clone(load);
  newLoad.status = payload.status;
  return newLoad;
};

const updateLoadTrips = ({ load, payload }) => {
  const newLoad = clone(load);
  newLoad.trips = append(payload, newLoad.trips || []);
  return newLoad;
};

const getOperatorName = (operator) =>
  operator ? `${operator.firstName} ${operator.lastName}` : '';

const getAssetLabel = (asset, withType = true, assetClasses = []) => {
  if (!asset) return '';
  const { fleetNumber, licenceNumber, licenceNo, type, assetClassId } = asset;
  const assetClass = assetClasses?.find((ac) => ac.id === assetClassId)?.name;
  return `${licenceNumber || licenceNo}${
    fleetNumber ? ` (${fleetNumber})` : ''
  } ${withType ? `(${convertEnumString(type)})` : ''} ${
    assetClass ? ` - ${assetClass}` : ''
  }`;
};

const getLoadOrRouteDescription = (isRoute, isPlural) =>
  (isRoute ? 'Route' : 'Load') + (isPlural ? 's' : '');

export {
  getLoadAssetCurrentPosition,
  getOperatorName,
  getLoadOrRouteDescription,
  updateLoadAssetCurrentPosition,
  updateLoadExecution,
  updateLoadExtrapolation,
  updateLoadStatus,
  updateLoadTrips,
  getAssetLabel,
};
