import { orderBy } from 'lodash';
import { convertEnumString } from './convertEnumString';

const convertEnumObject = (
  emunPayload,
  sort = true,
  maintainCasing = false,
) => {
  const convertedEnums = emunPayload.__type.enumValues.map(({ name }) => ({
    value: name,
    label: convertEnumString(name, maintainCasing),
  }));

  return sort
    ? orderBy(convertedEnums, (v) => v.value.toLowerCase(), ['asc'])
    : convertedEnums;
};

export { convertEnumObject };
