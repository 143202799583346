import { gql } from '@apollo/client';

const UPDATE_PERMIT = gql`
  mutation updateFleetPermit($input: FleetPermitUpdateInput!) {
    updateFleetPermit(input: $input) {
      id
    }
  }
`;

export { UPDATE_PERMIT };
