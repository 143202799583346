import { useLazyQuery } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import React, { Fragment } from 'react';
import {
  ActionButtons,
  FormItem,
  InlineNotification,
  SecondaryButton,
  TextFormField,
  useLoading,
} from '../../../../';
import Banner from '../Banner';
import { FormArea } from '../LoginForm/styles';
import formikSchema from './formikSchema';
import { ASSERT_MFA } from './gql';

const MFAForm = ({ username, setNotify, closeModalAndRedirect }) => {
  const [assertMFA, { loading }] = useLazyQuery(ASSERT_MFA, {
    onCompleted: (d) => {
      if (d) {
        closeModalAndRedirect(d.assertMFA.token);
      }
    },
    onError: (e) =>
      setNotify(
        <InlineNotification kind="error" title="Error" subtitle={e.message} />,
      ),
  });

  useLoading(loading);

  return (
    <Fragment>
      <Banner
        text="Enter One Time Pin"
        subtext={`Please enter the OTP that has been sent to ${username}`}
      />
      <FormArea columns="1" fitWidth>
        <Formik
          {...formikSchema}
          onSubmit={({ otp }) => {
            assertMFA({ variables: { id: username, otp } });
          }}
        >
          <Fragment>
            <Form>
              <FormItem>
                <Field
                  placeholder="One-time PIN"
                  name="otp"
                  type="text"
                  component={TextFormField}
                  light
                />
              </FormItem>
              <div style={{ marginBottom: '150px' }} />
            </Form>
            <ActionButtons fillWidth primaryName="Login" />
          </Fragment>
        </Formik>
        <SecondaryButton fillWidth onClick={window.location.reload}>
          Cancel
        </SecondaryButton>
      </FormArea>
    </Fragment>
  );
};

export default MFAForm;
