const initialValues = {
  licenceNumber: '',
  type: '',
  assetClassId: '',
  sites: [],
  isRearTrailer: false,
  hasTrailers: false,
};

export default initialValues;
