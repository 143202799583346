import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Spinner, theme } from '../../';
import {
  AddBtn,
  CheckBox,
  Container,
  EmptyCheckBox,
  Header,
  ImageContainer,
  IncorrectCheckBox,
  InputContainer,
  LoadingOverlay,
  Remove,
  Restart,
  UploadBtn,
  UploadInput,
  VerifyLine,
} from './styles';

const PhotoContainer = ({
  field: { name, value },
  form,
  labelText,
  className,
  ...props
}) => {
  const [lessThan500kb, setLessThan500kb] = useState(true);
  const [loading, setLoading] = useState(false);
  const { handleChange } = useFormikContext();

  const uploadHandler = (e) => {
    setLoading(true);
    if (e.target.files[0]) {
      const size = e.target.files[0].size;
      if (size > 500000) {
        setLessThan500kb(false);
        value.data ? cancelHandler(e.target.files[0].size) : setLoading(false);
      } else {
        if (value.data) {
          cancelHandler();
        }
        const file = e.target.files[0];
        setLessThan500kb(true);

        const reader = new FileReader();
        reader.onload = (event) => {
          handleChange({
            target: {
              name,
              value: {
                data: event.target.result,
                fileType: file.type,
              },
            },
          });
          setLoading(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const cancelHandler = (size) => {
    handleChange({
      target: {
        name,
        value: {
          data: '',
          fileType: '',
        },
      },
    });
    size && setLoading(false);
  };
  return (
    <Container dimensions={props.dimensions || '300px'}>
      <Header>
        <p style={{ fontSize: '0.75rem' }}>{labelText}</p>
        {value.data && (
          <Remove type="button" onClick={cancelHandler}>
            Remove
          </Remove>
        )}
      </Header>
      <ImageContainer
        value={value.data}
        dimensions={props.dimensions || '300px'}
      >
        {loading && (
          <LoadingOverlay dimensions={props.dimensions || '300px'}>
            <Spinner />
          </LoadingOverlay>
        )}

        <InputContainer>
          <UploadBtn type={'button'}>
            {value.data ? <Restart /> : <AddBtn />}
          </UploadBtn>
          <UploadInput
            className={className}
            type="file"
            accept="image/jpeg,image/png,image/jpg"
            name={name}
            onChange={uploadHandler}
          />
        </InputContainer>
      </ImageContainer>
      <VerifyLine>Images must be:</VerifyLine>
      <VerifyLine color={!lessThan500kb && theme.colors.danger}>
        {value ? (
          <CheckBox />
        ) : !lessThan500kb ? (
          <IncorrectCheckBox />
        ) : (
          <EmptyCheckBox />
        )}
        Smaller than 500kb
      </VerifyLine>
      <VerifyLine>
        {value ? <CheckBox /> : <EmptyCheckBox />}
        JPG, JPEG or PNG format
      </VerifyLine>
    </Container>
  );
};

export default PhotoContainer;
