import { gql } from '@apollo/client';

const CHECK_IF_CAN_RESET = gql`
  query checkIfUserCanResetPassword($email: String!) {
    checkIfUserCanResetPassword(email: $email)
  }
`;

const SET_NEW_RESET_DATE = gql`
  mutation updateUserPasswordResetLastDate($email: String!) {
    updateUserPasswordResetLastDate(email: $email)
  }
`;

export { CHECK_IF_CAN_RESET, SET_NEW_RESET_DATE };
