import { gql } from '@apollo/client';

const LOGIN = gql`
  query login($username: String!, $password: String, $oAuthToken: String) {
    login(username: $username, password: $password, oAuthToken: $oAuthToken) {
      token
      error
      session
      passwordReset
      assertMFA
    }
  }
`;

const ASSERT_USER_EMAIL_EXISTS = gql`
  query assertUserEmailExists($email: String!, $portal: EOAuthPortals) {
    assertUserEmailExists(email: $email, portal: $portal) {
      doesExist
      oAuthType
      oAuthPayload {
        portal
        clientId
        tenantId
        url
      }
      oAuthToken
    }
  }
`;

export { LOGIN, ASSERT_USER_EMAIL_EXISTS };
