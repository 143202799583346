import * as Sentry from '@sentry/react';

const initiateSentry = () => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      ignoreErrors: [/m.target.className.indexOf is not a function/],
    });
  }
};

const setSentryContext = (currentUser = {}) => {
  const { email, firstName, lastName, id } = currentUser;
  const name = `${firstName} ${lastName}`;
  Sentry.setUser({ id, email, name });
  Sentry.setTag('email', email);
  Sentry.setTag('full_name', name);
  Sentry.setTag('id', id);
};

const checkEnv = () => {
  const [isDev, isUat] = ['dev', 'uat'].map((v) =>
    (process.env.REACT_APP_WSS || '').includes(v),
  );
  const isProd = !isDev && !isUat;
  return { isDev, isUat, isProd };
};

const setDocumentTitle = (name = 'Cloud') => {
  const { isDev, isUat } = checkEnv();
  const env = isUat ? 'UAT' : isDev ? 'DEV' : '';
  const envPart = env ? `(${env}) ` : '';
  const trackmaticName = 'Trackmatic';
  const namePart = name ? ` - ${name || ''}` : '';
  document.title = `${envPart}${trackmaticName}${namePart}`;
};

const resolveGoogleTranslate = () =>
  // hack for google translate: https://github.com/facebook/react/issues/11538#issuecomment-417504600
  {
    if (typeof Node === 'function' && Node.prototype) {
      const originalRemoveChild = Node.prototype.removeChild;
      Node.prototype.removeChild = function (child) {
        if (child.parentNode !== this) {
          if (console) {
            console.error(
              'Cannot remove a child from a different parent',
              child,
              this,
            );
          }
          return child;
        }
        return originalRemoveChild.apply(this, arguments);
      };

      const originalInsertBefore = Node.prototype.insertBefore;
      Node.prototype.insertBefore = function (newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
          if (console) {
            console.error(
              'Cannot insert before a reference node from a different parent',
              referenceNode,
              this,
            );
          }
          return newNode;
        }
        return originalInsertBefore.apply(this, arguments);
      };
    }
  };
// end hack

export {
  checkEnv,
  initiateSentry,
  setDocumentTitle,
  setSentryContext,
  resolveGoogleTranslate,
};
