import styled from 'styled-components';

const ColorPickerContainer = styled.div`
  width: 225px;
`;

const ColorBubble = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 14px;
  left: 8px;
  bottom: 6px;
  background: ${({ color }) => color};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 10px;
  > p {
    font-size: 0.75rem;
  }
`;

export { ColorBubble, ColorPickerContainer, HeaderContainer };
