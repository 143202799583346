import {
  CheckmarkOutline20,
  RadioButton20,
  Warning20,
} from '@carbon/icons-react';
import { lighten } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: inline-flex;
  min-width: 500px;
  min-height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: ${({ noFile, submitted, success, errorMsg, theme }) =>
    success
      ? `solid 2px ${theme.colors.success}`
      : errorMsg
      ? `solid 2px ${theme.colors.danger}`
      : noFile
      ? `dotted 2px ${theme.colors.lightGrey}`
      : `solid 2px ${theme.colors.lightGrey}`};
`;
const DropInput = styled.input`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
`;
const UploadBtn = styled.button`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  font-size: 1rem;
  border: none;
  padding: 5px;
  z-index: 3;
  color: #fff;
  background: ${({ theme }) => theme.colors.primary};
  :hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.primary)};
    cursor: pointer;
  }
`;

const TryAgain = styled.button`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  font-size: 1rem;
  border: none;
  padding: 5px;
  z-index: 3;
  background-color: inherit;
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
    cursor: pointer;
  }
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 4;
  ::-webkit-file-upload-button {
    display: none;
  }
`;
const FileIcon = styled.div`
  background-color: inherit;
  padding: 10px;
`;
const ClearBtn = styled.button`
  border: none;
  text-align: center;
  padding: 5px;
  background: none;
  position: absolute;
  top: -30px;
  right: 0;
  color: ${({ theme }) => theme.colors.primary};
  :hover {
    cursor: pointer;
    color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
  }
  :focus {
    outline: none;
  }
`;

const VerifyLine = styled.p`
  margin-bottom: 2px;
  font-size: 0.75rem;
  color: ${({ color, theme }) =>
    color ? theme.colors.danger : theme.colors.darkGrey};
  display: flex;
  align-items: center;
`;

const EmptyCheckBox = styled(RadioButton20)`
  padding-right: 3px;
`;
const CheckBox = styled(CheckmarkOutline20)`
  fill: ${({ theme }) => theme.colors.primary};
  padding-right: 3px;
`;
const IncorrectCheckBox = styled(Warning20)`
  fill: ${({ theme }) => theme.colors.danger};
  padding-right: 3px;
`;

const ValidationContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: -100px;
`;

const CompleteUploadMsg = styled.div`
  z-index: 3;
  color: ${({ success, theme }) =>
    success ? theme.colors.success : theme.colors.danger};
`;
export {
  Container,
  CompleteUploadMsg,
  DropInput,
  UploadBtn,
  Input,
  FileIcon,
  ClearBtn,
  VerifyLine,
  EmptyCheckBox,
  CheckBox,
  IncorrectCheckBox,
  ValidationContainer,
  TryAgain,
};
