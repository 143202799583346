import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { dissoc } from 'ramda';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  generateLinkWithParams,
  getURLParam,
  PageContainer,
  PageError,
  PageHeading,
  PageLoading,
  useAlerts,
  useLoading,
} from '../../../../';
import ContactForm from '../ContactForm';
import validationSchema from '../validationSchema';
import { GET_CONTACT, UPDATE_LOAD_CONTACT } from './gql';

const UpdateLoadContact = withRouter(
  ({
    history,
    match,
    currentEntity,
    linkParams,
    returnRoute,
    type = 'ORG',
    contactId = '',
    onSubmit,
    isRoutes = false,
    token,
  }) => {
    const display = isRoutes ? 'Routes' : 'Loads';
    const { alertSuccess, onError } = useAlerts();

    const { data, error } = useQuery(GET_CONTACT, {
      variables: {
        id: contactId || getURLParam(match, 'loadContactId'),
        token,
      },
    });

    const [updateLoadContactMutation, { loading }] = useMutation(
      UPDATE_LOAD_CONTACT,
      {
        onCompleted: (d) => {
          alertSuccess(`${display} Contact has been successfully updated`);
          onSubmit
            ? onSubmit(d)
            : history.push(generateLinkWithParams(returnRoute, linkParams));
        },
        onError,
      },
    );

    useLoading(loading);

    if (error) {
      return <PageError error={error} />;
    }

    if (data) {
      const initialValues = dissoc('carrier', data.getLoadContact);

      return (
        <PageContainer>
          <PageHeading
            heading={`${currentEntity.name} ${display} Contacts`}
            description={`Update ${display} Contact`}
          />
          <Formik
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(input) => {
              updateLoadContactMutation({
                variables: {
                  input,
                  token,
                },
              });
            }}
          >
            <ContactForm
              currentEntity={currentEntity}
              isEdit
              type={type}
              isRoutes={isRoutes}
            />
          </Formik>
        </PageContainer>
      );
    }
    return <PageLoading />;
  },
);

export default UpdateLoadContact;
