import { gql } from '@apollo/client';

const GET_OPERATOR = gql`
  query getOperator($id: ID!) {
    getOperator(id: $id) {
      id
      firstName
      lastName
      mobile
      idNumber
      reference
      orgs {
        org {
          id
        }
        apps
      }
      licences {
        documentNumber
        documentType
        status
        validFrom
        validTo
        createdByOperator {
          firstName
          lastName
        }
        createdAt
      }
    }
  }
`;

export { GET_OPERATOR };
