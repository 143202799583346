import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { GET_ADDRESS } from '../gql';
import {
  TooltipColumnsContainer,
  TooltipContainer,
  TooltipValuesContainer,
} from './styles';

const getDefaultAddresses = (addresses) => {
  const value = 'Loading...';
  if (addresses) {
    if (addresses.length === 1) {
      return [{ header: 'Address', value }];
    }
    return [
      { header: 'Start Address', value },
      { header: 'End Address', value },
    ];
  }
  return [];
};

const Tooltip = ({ tooltipContent }) => {
  const { values = [], header, addresses, valuesColumn2 = [] } = tooltipContent;
  const stringAddresses = addresses && JSON.stringify(addresses);

  const [fetchAddress] = useLazyQuery(GET_ADDRESS);
  const [addressData, setAddressData] = useState(
    getDefaultAddresses(addresses),
  );

  useEffect(() => {
    const parsedAddresses = stringAddresses && JSON.parse(stringAddresses);
    if (parsedAddresses) {
      const addressCount = parsedAddresses.length;
      const getAddresses = async () => {
        const [startAddress, endAddress] = (
          await Promise.all(
            parsedAddresses.map((input) =>
              fetchAddress({ variables: { input } }),
            ),
          )
        ).map(({ data }) => get(data, 'getPositionAddress') || 'Unknown');
        setAddressData(
          addressCount === 1
            ? [{ header: 'Address', value: startAddress }]
            : [
                { header: 'Start Address', value: startAddress },
                { header: 'End Address', value: endAddress },
              ],
        );
      };
      getAddresses();
    }
  }, [stringAddresses, fetchAddress]);

  if (!tooltipContent) return null;
  const allValues = [...values, ...addressData];
  return (
    <TooltipContainer>
      {header && <h3>{header}</h3>}
      <TooltipColumnsContainer columns={valuesColumn2?.length > 0 ? 2 : 1}>
        {allValues?.length > 0 && (
          <TooltipValuesContainer>
            {allValues.map(({ header: tHeader, value }) => (
              <Fragment>
                <h3>{tHeader}</h3>
                <div>{value}</div>
              </Fragment>
            ))}
          </TooltipValuesContainer>
        )}
        {valuesColumn2?.length > 0 && (
          <TooltipValuesContainer>
            {valuesColumn2.map(({ header: tHeader, value }) => (
              <Fragment>
                <h3>{tHeader}</h3>
                <div>{value}</div>
              </Fragment>
            ))}
          </TooltipValuesContainer>
        )}
      </TooltipColumnsContainer>
    </TooltipContainer>
  );
};

export * from './tooltipFactory';
export { Tooltip };
