import { dissoc } from 'ramda';

const mapValuesForHandling = (originalValues, newValues) => {
  const oldFormData = dissoc('sites', originalValues);
  const newFormData = dissoc('sites', newValues);

  const { sites } = newValues;
  const { sites: orgSites } = originalValues;

  const originalSiteIds = orgSites.map((site) => site.site);
  const newSiteIds = sites.map((site) => site.site);

  const sitesToRemove = originalSiteIds.filter(
    (id) => !newSiteIds.includes(id),
  );
  const sitesToAdd = sites.filter(
    (site) => !originalSiteIds.includes(site.site),
  );
  const idsOfSitesToAdd = sitesToAdd.map((site) => site.site);
  const idsOfSitesToRemove = sitesToRemove.map((site) => site.site);
  const appsHaveChanged = sites.filter((site) => {
    if (
      !idsOfSitesToAdd.includes(site.site) &&
      !idsOfSitesToRemove.includes(site.site)
    ) {
      const appsOfOrginalSite = orgSites.find(
        (s) => s.site === site.site,
      )?.apps;
      return (
        JSON.stringify(appsOfOrginalSite.slice().sort()) !==
        JSON.stringify(site.apps.slice().sort())
      );
    } else {
      return false;
    }
  });
  const changeOfDetails =
    JSON.stringify(oldFormData) !== JSON.stringify(newFormData)
      ? dissoc('idNumber', newFormData)
      : null;
  return {
    sitesToAdd,
    sitesToRemove,
    changeOfDetails,
    appsHaveChanged,
  };
};

export { mapValuesForHandling };
