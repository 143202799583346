import { Copy16 } from '@carbon/icons-react';
import { lighten } from 'polished';
import React, { useState } from 'react';
import Highlight from 'react-highlight';
import styled from 'styled-components';
import { ModalWrapper } from './styles';

const CopyContainer = styled.div`
  position: absolute;
  top: 2rem;
  right: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Copy = styled(Copy16)`
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const CopyText = styled.p`
  font-size: 0.75rem;
  padding: 0 1rem;
  font-weight: bold;
  opacity: ${({ copied }) => (copied ? '1' : '0')};
  ${({ transition }) => transition && 'transition : 500ms ease-out;'}
  color:${({ theme }) => theme.colors.success};
`;

const Wrapper = styled(ModalWrapper)`
  height: 600px;
  width: 100%;
  overflow: auto;
  padding: 1rem;
  background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
  & span {
    white-space: normal;
    word-break: break-all;
  }
`;

const RawPayloadModal = ({ data }) => {
  const [copied, setCopied] = useState(false);
  let display;
  try {
    display = JSON.stringify(
      typeof data === 'object' ? data : JSON.parse(data),
      null,
      2,
    );
  } catch {
    display = data;
  }
  return (
    <Wrapper>
      <CopyContainer>
        <CopyText copied={copied} transition={!copied}>
          Copied to Clipboard
        </CopyText>

        <Copy
          onClick={() => {
            if (!copied) {
              navigator.clipboard.writeText(display);
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 2000);
            }
          }}
        />
      </CopyContainer>
      <Highlight language="json">{display}</Highlight>
    </Wrapper>
  );
};

export { RawPayloadModal };
