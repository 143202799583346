import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  ActionButtons,
  Column,
  FormArea,
  FormItem,
  FormWrapper,
  generateLinkWithParams,
  LoadingContext,
  NotificationsContext,
  PhoneNumberField,
  SelectRemoveColumns,
  TextFormField,
} from '../../../../..';
import formikSchema from './formikSchema';
import { CREATE_OPERATOR } from './gql';

const CreateOperator = withRouter(
  ({
    history,
    idNumber,
    returnRoute = '',
    currentEntity = {},
    linkParams = {},
    permissionsList = [],
  }) => {
    const { addInlineAlert } = useContext(NotificationsContext);
    const { setLoading } = useContext(LoadingContext);

    const [createOperatorMutation, { loading }] = useMutation(CREATE_OPERATOR, {
      onCompleted: () => {
        addInlineAlert(
          'success',
          'Operator Created Successfully',
          'Your operator has successfully been created',
        );
        history.push(generateLinkWithParams(returnRoute, linkParams));
      },
      onError: (err) => addInlineAlert('error', 'Error', err.message),
    });

    useEffect(() => {
      setLoading(loading);
    }, [loading, setLoading]);
    return (
      <Formik
        {...formikSchema}
        validateOnMount
        onSubmit={(values) => {
          createOperatorMutation({
            variables: {
              input: {
                idNumber,
                ...values,
                orgId: currentEntity.id,
              },
            },
          });
        }}
      >
        {({ handleChange, values }) => {
          return (
            <FormWrapper>
              <Form>
                <FormArea columns={2}>
                  <Column>
                    <FormItem>
                      <Field
                        labelText="First Name *"
                        name="firstName"
                        component={TextFormField}
                        light
                      />
                    </FormItem>
                    <FormItem>
                      <Field
                        labelText="Last Name *"
                        name="lastName"
                        component={TextFormField}
                        light
                      />
                    </FormItem>
                    <FormItem>
                      <Field
                        labelText="Mobile *"
                        name="mobile"
                        component={PhoneNumberField}
                        light
                      />
                    </FormItem>
                    <FormItem>
                      <Field
                        labelText="Reference"
                        name="reference"
                        component={TextFormField}
                        light
                      />
                    </FormItem>
                  </Column>
                  <SelectRemoveColumns
                    leftItems={permissionsList}
                    rightItems={values.apps}
                    instruction="Assign Permissions"
                    leftHeading="Available Permissions"
                    rightHeading="Assigned Permissions"
                    actionHandler={(value) => {
                      handleChange({
                        target: {
                          name: 'apps',
                          value: value.map((v) => v.value),
                        },
                      });
                    }}
                    withinForm
                  />
                </FormArea>
              </Form>
              <ActionButtons type={'finalStep'} primaryName="Create" />
            </FormWrapper>
          );
        }}
      </Formik>
    );
  },
);

export default CreateOperator;
