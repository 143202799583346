import React from 'react';
import { TableIconWrapper, RawPayloadModal } from '../../../../../';
import { Location16, Code16 } from '@carbon/icons-react';
import PointViewModal from './PointViewModal';

const headings = [
  {
    key: 'timeCreated',
    header: 'Timestamp',
    allowOrder: true,
    isDateTime: true,
  },
  {
    key: 'tpsPulseType',
    header: 'Pulse',
    allowOrder: true,
    isEnum: true,
  },
  {
    key: 'source',
    header: 'Source',
    allowOrder: true,
  },
  {
    key: 'eventType',
    header: 'Event',
    allowOrder: true,
    isEnum: true,
  },
  {
    key: 'geolocation',
    header: '',
    isCount: true,
    componentType: 'modal',
    tooltip: 'Location',
    ComponentWrapper: ({ onClick }) => (
      <TableIconWrapper>
        <Location16 onClick={onClick} />
      </TableIconWrapper>
    ),
    Component: PointViewModal,
  },
  {
    key: 'rawData',
    header: '',
    isCount: true,
    componentType: 'modal',
    tooltip: 'Raw Data',
    ComponentWrapper: ({ onClick }) => (
      <TableIconWrapper>
        <Code16 onClick={onClick} />
      </TableIconWrapper>
    ),
    Component: ({ value }) => <RawPayloadModal data={value} />,
  },
];

export default headings;
