import { getIn, useFormikContext } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';

const NumberInput = styled.input`
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  border: none;
  ${({ noLine, theme }) =>
    !noLine &&
    css`
      border-bottom: 1px solid ${theme.colors.mediumGrey};
    `};
`;
const Label = styled.label`
  font-size: 0.75rem;
`;

const NumberFormField = ({ field, form, label, className, ...props }) => {
  const { handleChange } = useFormikContext();
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);
  const errorMessage = errorText ? errorText.replace(field.name, label) : null;

  return (
    <div>
      {label && <Label>{label}</Label>}
      <NumberInput
        className={className}
        type="number"
        id={field.name}
        invalid={!!errorText}
        invalidText={errorMessage}
        label={label}
        {...field}
        {...props}
        onChange={(e) => {
          handleChange({
            target: { name: field.name, value: +e.target.value },
          });
        }}
      />
    </div>
  );
};

export default NumberFormField;
