import { Information20 as Info } from '@carbon/icons-react';
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  border-radius: 50%;
  height: 20px;
  background-color: inherit;
  font-size: 100%;
  font-weight: 100;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  display: inline-flex;
  align-items: center;
  position: relative;
  :hover {
    > div {
      display: inline-block;
      z-index: 100;
    }
  }
`;

const Information = styled(Info)`
  fill: ${({ theme }) => theme.colors.mediumGrey};
`;
const InnerBubble = styled.div`
  margin-left: 10px;
  padding: 5px;
  line-height: 1rem;
  font-size: 0.75rem;
  position: absolute;
  top: 0;
  right: -320px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  width: ${({ width }) => width || '300px'};
  display: none;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InfoBubble = ({ text }) => {
  return (
    <IconWrapper>
      <Information />
      <InnerBubble>{text}</InnerBubble>
    </IconWrapper>
  );
};

export default InfoBubble;
