import {
  CarbonIconType,
  DeliveryTruck32 as LoadsIcon,
  GasStation32 as FuelIcon,
  IbmSecurity32 as GatekeeperIcon,
  Link32 as LinkIcon,
  Receipt32 as AdminIcon,
  Ticket32 as VortexIcon,
  Tools32 as FleetIcon,
} from '@carbon/icons-react';
import styled from 'styled-components';

const fillPrimary = (Icon: CarbonIconType) => styled(Icon)<{
  dark?: boolean;
  active?: boolean;
  module?: string;
}>`
  & path,
  & circle {
    fill: ${({ theme, dark, active, module }) =>
      module
        ? theme.colors.modules[module]
        : theme.colors[active ? 'white' : dark ? 'secondary' : 'primary']};
  }
`;

const globalSections = {
  groups: 'groups',
  orgs: 'organisations',
  sites: 'sites',
  stores: 'stores',
  facilities: 'facilities',
};

const modules = {
  admin: {
    activated: true,
    name: 'Admin',
    desc: 'Administrative Management',
    root: '/admin',
    icon: fillPrimary(AdminIcon),
  },
  crm: {
    activated: true,
    name: 'CRM',
    desc: 'Customer Management',
    root: '/crm',
    icon: fillPrimary(AdminIcon),
  },
  fleet: {
    activated: true,
    name: 'Fleet',
    desc: 'Service Maintenance',
    icon: fillPrimary(FleetIcon),
    root: '/fleet',
  },
  fuel: {
    activated: true,
    name: 'Fuel',
    desc: 'Fuel Management',
    icon: fillPrimary(FuelIcon),
    root: '/fuel',
  },
  gatekeeper: {
    activated: true,
    name: 'Gatekeeper',
    desc: 'Gate & Security Management',
    icon: fillPrimary(GatekeeperIcon),
    root: '/gatekeeper',
  },
  link: {
    activated: true,
    name: 'Link',
    desc: 'Link Facilities Portal',
    icon: fillPrimary(LinkIcon),
    root: '/link',
  },
  linkLite: {
    activated: true,
    name: 'Link Retail',
    desc: 'Link Retail',
    icon: fillPrimary(LinkIcon),
    root: '/link_retail',
  },
  live: {
    activated: true,
    name: 'Live',
    desc: 'Third Party System Data',
    root: '/live',
    icon: fillPrimary(LoadsIcon),
  },
  loads: {
    activated: true,
    name: 'Loads',
    desc: 'Primary Transport',
    icon: fillPrimary(LoadsIcon),
    root: '/loads',
  },
  routes: {
    activated: true,
    name: 'Routes',
    desc: 'Secondary Transport',
    icon: fillPrimary(LoadsIcon),
    root: '/routes',
  },
  vortex: {
    activated: true,
    name: 'Vortex',
    desc: 'Exception Management',
    icon: fillPrimary(VortexIcon),
    root: '/vortex',
  },
};

const localStorageKeys = {
  tempAdminToken: 'TM_CLOUD_TEMP_ADMIN_TOKEN',
  idToken: 'TM_CLOUD_ID_TOKEN',
  currentUser: 'TM_CLOUD_CURRENT_USER',
  rememberMe: 'TM_CLOUD_REMEMBER_ME',
  rememberMeCounter: 'TM_CLOUD_REMEMBER_ME_COUNTER',
  contextSearch: 'TM_CLOUD_CONTEXT_SEARCH',
  tableFilters: 'TM_CLOUD_TABLE_FILTERS',
};

const linkPlaceholders = {
  adnId: ':adnId',
  apiKeyId: ':apiKeyId',
  assetClassId: ':assetClassId',
  assetGroupId: ':assetGroupId',
  assetId: ':assetId',
  automatedReportId: ':automatedReportId',
  carrierId: ':carrierId',
  ccid: ':ccid',
  chainId: ':chainId',
  consignmentId: ':consignmentId',
  contactId: ':contactId',
  controlRoomId: ':controlRoomId',
  cpCarrierGroupId: ':cpCarrierGroupId',
  cpCarrierId: ':cpCarrierId',
  cpUserId: ':cpUserId',
  customerId: ':customerId',
  customerPortalCustomerId: ':customerPortalCustomerId',
  customerPortalUserId: ':customerPortalUserId',
  deviceId: ':deviceId',
  deviceManufacturerId: ':deviceManufacturerId',
  deviceModelId: ':deviceModelId',
  emergencyContactId: ':emergencyContactId',
  equipmentId: ':equipmentId',
  facilityId: ':facilityId',
  fleetManufacturerId: ':fleetManufacturerId',
  fleetModelId: ':fleetModelId',
  fleetPartId: ':fleetPartId',
  fleetPermitId: ':fleetPermitId',
  fleetSupplierId: ':fleetSupplierId',
  fleetTransactionCategoryId: ':fleetTransactionCategoryId',
  fleetTransactionId: ':fleetTransactionId',
  fuelBowserId: ':bowserId',
  fuelBowserSessionId: ':bowserSessionId',
  fuelExternalTransactionId: ':fuelExternalTransactionId',
  fuelInflowTransactionId: ':inflowTransactionId',
  fuelPumpId: ':pumpId',
  fuelRFSChainId: ':rfsChainId',
  fuelRFSId: ':rfsId',
  fuelTankId: ':tankId',
  fuelTransactionId: ':transactionId',
  gantryId: ':gantryId',
  gateId: ':gateId',
  gatekeeperEntityId: ':gatekeeperEntityId',
  gatekeeperTransactionId: ':gatekeeperTransactionId',
  gatekeeperWatchlistReasonId: ':gatekeeperWatchlistReasonId',
  gatekeeperWatchlistRecordId: ':gatekeeperWatchlistRecordId',
  gatekeeperWatchlistSubscriptionId: ':gatekeeperWatchlistSubscriptionId',
  gateLaneId: ':gateLaneId',
  globalLocationId: ':globalLocationId',
  groupId: ':groupId',
  linkAdhocLoadReasonId: ':linkAdhocLoadReasonId',
  linkBayId: ':linkBayId',
  linkBookingId: ':linkBookingId',
  linkBusinessUnitId: ':linkBusinessUnitId',
  linkCancellationReasonId: ':linkCancellationReasonId',
  linkCommodityId: ':linkCommodityId',
  linkEntityId: ':linkEntityId',
  linkFulfilmentTransactionId: ':linkFulfilmentTransactionId',
  linkFulfilmentTransactionReasonId: ':linkFulfilmentTransactionReasonId',
  linkOutboundLoadId: ':linkOutboundLoadId',
  linkOutboundLoadWaveId: ':linkOutboundLoadWaveId',
  linkOutboundPlanId: ':linkOutboundPlanId',
  linkTransactionId: ':linkTransactionId',
  linkZoneClassificationId: ':linkZoneClassificationId',
  linkZoneId: ':linkZoneId',
  loadCommodityId: ':loadCommodityId',
  loadConsignmentId: ':loadConsignmentId',
  loadContactId: ':loadContactId',
  loadContractId: ':loadContractId',
  loadDebtorId: ':loadDebtorId',
  loadId: ':loadId',
  loadLaneId: ':loadLaneId',
  loadLaneRateId: ':loadLaneRateId',
  loadNotificationId: ':loadNotificationId',
  loadPlanId: ':loadPlanId',
  loadRequestId: ':loadRequestId',
  loadTemplateId: ':loadTemplateId',
  loadWorkflowId: ':loadWorkflowId',
  masterAssetId: ':masterAssetId',
  merchantId: ':merchantId',
  mheItemId: ':mheItemId',
  mhePurchaseId: ':mhePurchaseId',
  notificationId: ':notificationId',
  operatorId: ':operatorId',
  orgId: ':orgId',
  purposeId: ':purposeId',
  simId: ':simId',
  siteId: ':siteId',
  siteTagId: ':siteTagId',
  spSupplierGroupId: ':spSupplierGroupId',
  spSupplierId: ':spSupplierId',
  spUserId: ':spUserId',
  teamBillingItemId: ':teamBillingItemId',
  teamContractorId: ':teamContractorId',
  teamCreditNoteId: ':teamCreditNoteId',
  teamDebtorId: ':teamDebtorId',
  teamInstallerId: ':teamInstallerId',
  teamInvoiceId: ':teamInvoiceId',
  teamJobId: ':teamJobId',
  teamPurchaseOrderId: ':teamPurchaseOrderId',
  teamPurchaseOrderLineId: ':teamPurchaseOrderLineId',
  teamQuoteId: ':teamQuoteId',
  teamQuoteLineId: ':teamQuoteLineId',
  teamSupplierId: ':teamSupplierId',
  teamSupplierProductId: ':teamSupplierProductId',
  templateId: ':templateId',
  userId: ':userId',
  vortexControlRoomId: ':vortexControlRoomId',
  vortexControlRoomUserId: ':vortexControlRoomUserId',
  vortexTicketId: ':vortexTicketId',
  yardCarrierId: ':yardCarrierId',
  yardSupplierId: ':yardSupplierId',
  zoneId: ':zoneId',
};

const userTypes = {
  tmUser: 'TM User',
  groupUser: 'Group User',
  orgUser: 'Org User',
  siteUser: 'Site User',
};

const commonLinks = {
  toGroups: `/${globalSections.groups}`,
  toGroup: `/${globalSections.groups}/${linkPlaceholders.groupId}`,
  toOrgs: `/${globalSections.groups}/${linkPlaceholders.groupId}/${globalSections.orgs}`,
  toOrg: `/${globalSections.groups}/${linkPlaceholders.groupId}/${globalSections.orgs}/${linkPlaceholders.orgId}`,
  toSites: `/${globalSections.groups}/${linkPlaceholders.groupId}/${globalSections.orgs}/${linkPlaceholders.orgId}/${globalSections.sites}`,
  toSite: `/${globalSections.groups}/${linkPlaceholders.groupId}/${globalSections.orgs}/${linkPlaceholders.orgId}/${globalSections.sites}/${linkPlaceholders.siteId}`,
  toFacilities: `/${globalSections.groups}/${linkPlaceholders.groupId}/${globalSections.orgs}/${linkPlaceholders.orgId}/${globalSections.sites}/${linkPlaceholders.siteId}/${globalSections.facilities}`,
  toFacility: `/${globalSections.groups}/${linkPlaceholders.groupId}/${globalSections.orgs}/${linkPlaceholders.orgId}/${globalSections.sites}/${linkPlaceholders.siteId}/${globalSections.facilities}/${linkPlaceholders.facilityId}`,
};

export * from './googleMaps';
export {
  globalSections,
  commonLinks,
  modules,
  userTypes,
  localStorageKeys,
  linkPlaceholders,
};
