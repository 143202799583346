import { orderBy } from 'lodash';
import React from 'react';
import {
  convertEnumString,
  formatDateTime,
  Table,
  Td,
  Th,
  theme,
} from '../../../../';
import { Container } from './styles';

const LicenceModal = ({ licences }) => {
  if (licences.length === 0) {
    return (
      <Container>
        <h3>This operator currently does not have licences</h3>
      </Container>
    );
  }
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <Th noHover>Licence Number</Th>
            <Th noHover>Licence Type</Th>
            <Th noHover>Status</Th>
            <Th noHover>Valid From</Th>
            <Th noHover>Valid To</Th>
            <Th noHover>Added By</Th>
            <Th noHover>Date Added</Th>
          </tr>
        </thead>
        <tbody>
          {orderBy(licences, [(l) => new Date(l.validTo)], ['desc']).map(
            (licence) => {
              const addedBy = licence.createdByOperator
                ? `${licence.createdByOperator.firstName} ${licence.createdByOperator.lastName}`
                : 'System';

              const TD = ({ children }) => (
                <Td
                  style={{
                    background:
                      theme.colors[
                        licence.status === 'VALID' ? 'success' : 'danger'
                      ],
                  }}
                >
                  {children}
                </Td>
              );
              return (
                <tr key={licence.documentNumber}>
                  <TD>{licence.documentNumber}</TD>
                  <TD>{licence.documentType}</TD>
                  <TD>{convertEnumString(licence.status)}</TD>
                  <TD>{formatDateTime(licence.validFrom, null, false)}</TD>
                  <TD>{formatDateTime(licence.validTo, null, false)}</TD>
                  <TD>{addedBy}</TD>
                  <TD>{formatDateTime(licence.createdAt, null, false)}</TD>
                </tr>
              );
            },
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default LicenceModal;
