import { CheckmarkOutline16, Timer16 } from '@carbon/icons-react';
import { differenceInSeconds } from 'date-fns';
import { get } from 'lodash';
import { lighten } from 'polished';
import Tooltip from 'rc-tooltip';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  convertEnumString,
  formatDateTime,
  Table as CommonTable,
  theme as mainTheme,
} from '../../../../';

const Table = styled(CommonTable)`
  & * {
    font-size: 0.65rem !important;
  }
  & th {
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  }
`;

const CircleIndicator = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  background: ${({ color }) => color};
  border-radius: 100px;
  margin: auto;
`;

const CurrentPosition = ({ currentPosition }) => {
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const currentPositionTimestamp = get(
    currentPosition,
    'gps.dateTime',
    '2020-01-01',
  );

  const isCurrentPositionStale =
    differenceInSeconds(date, new Date(currentPositionTimestamp)) > 15 * 60;

  return (
    <Tooltip
      overlay={`${convertEnumString(
        currentPosition.status,
      )} (last updated ${formatDateTime(currentPositionTimestamp)})`}
      placement="top"
      destroyTooltipOnHide
    >
      <CircleIndicator
        color={
          mainTheme.colors[
            isCurrentPositionStale
              ? 'mediumGrey'
              : currentPosition.status === 'MOVING'
              ? 'success'
              : currentPosition.status === 'PARKED'
              ? 'danger'
              : 'yellow'
          ]
        }
      />
    </Tooltip>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.5rem;
  width: 100%;
  height: 100%;
  font-size: 0.75rem;
`;

const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
`;

const Header = styled.div`
  font-weight: bold;
  width: 100%;
  padding: 0.4rem 0.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.lightGrey};
  border-left: 10px solid ${({ color }) => color};
`;

const TablePart = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ProgressBusy = styled(Timer16)`
  margin: auto;
  & path {
    fill: ${({ theme }) => theme.colors.warning};
  }
`;

const ProgressComplete = styled(CheckmarkOutline16)`
  margin: auto;
  & path {
    fill: ${({ theme }) => theme.colors.success};
  }
`;

const UnknownStreetViewLink = styled.a`
  color: ${({ theme }) => theme.colors.danger};
`;

export {
  CircleIndicator,
  Container,
  CurrentPosition,
  Header,
  ProgressBusy,
  ProgressComplete,
  Table,
  TableContainer,
  TablePart,
  UnknownStreetViewLink,
};
