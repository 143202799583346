import { get } from 'lodash';
import { append, clone, flatten } from 'ramda';
import { convertEnumString } from '../../';

const getLoadAssetCurrentPosition = (load, returnEmptyObject = false) => {
  const pos = get(load, 'allocation.vehicle.asset.currentPosition');
  return pos || (returnEmptyObject && {});
};

const updateLoadAssetCurrentPosition = ({ load, payload }) => {
  const newLoad = clone(load);
  if (get(newLoad, 'allocation.vehicle.asset')) {
    newLoad.allocation.vehicle.asset.currentPosition = payload;
  }
  return newLoad;
};

const updateLoadExecution = ({ load, payload = {} }) => {
  const newLoad = clone(load);
  newLoad.execution = { ...(load.execution || {}), ...payload };
  return newLoad;
};

const updateLoadStatus = ({ load, payload }) => {
  const newLoad = clone(load);
  newLoad.status = payload.status;
  return newLoad;
};

const updateLoadTrips = ({ load, payload }) => {
  const newLoad = clone(load);
  newLoad.trips = append(payload, newLoad.trips || []);
  return newLoad;
};

const getVehicle = (v) => {
  if (!v) {
    return '';
  }
  if (v.asset) {
    const fleetNumber = v.asset.fleetNumber ? `(${v.asset.fleetNumber})` : '';
    return `${v.asset.licenceNumber} ${fleetNumber} - ${convertEnumString(
      v.asset.type,
    )}`;
  }
  const fleetNo = v.fleetNumber ? `(${v.fleetNumber})` : '';
  return `${v.licenceNo} ${fleetNo}`;
};

const getStopActivities = (stop, execution = { activities: [] }) =>
  flatten(
    stop.entities.map((e) =>
      e.activities.map((a) => {
        return {
          stop,
          ...a,
          execution: (get(execution, 'activities', []) || []).filter(
            (act) => act.activityId === a.id,
          ),
        };
      }),
    ),
  );

export {
  getLoadAssetCurrentPosition,
  updateLoadAssetCurrentPosition,
  updateLoadExecution,
  updateLoadStatus,
  updateLoadTrips,
  getVehicle,
  getStopActivities,
};
