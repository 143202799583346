import React from 'react';
import { useDownloadS3File } from '../../hooks';
import {
  DownloadIconSmall,
  Table,
  TableBodyContainer,
  TableIconWrapper,
  Th,
} from '../../styles';

const Download = ({ v, isDirectLink, bucket }) => {
  const download = useDownloadS3File(v.path, v.name, isDirectLink, bucket);
  return (
    <tr key={v.name}>
      <td>
        <p>{v.name}</p>
      </td>
      <td>
        <TableIconWrapper>
          <DownloadIconSmall onClick={() => download()} />
        </TableIconWrapper>
      </td>
    </tr>
  );
};

const ViewModal = ({ docs = [], isDirectLink = false, bucket }) => {
  return (
    <TableBodyContainer style={{ height: 'auto' }}>
      <Table>
        <colgroup>
          <col width="90%" />
          <col width="10%" />
        </colgroup>
        <thead>
          <tr>
            <Th noHover>File Name</Th>
            <Th noHover />
          </tr>
        </thead>
        <tbody>
          {(docs || []).map((v) => (
            <Download
              v={v}
              key={v.name}
              isDirectLink={isDirectLink}
              bucket={bucket}
            />
          ))}
        </tbody>
      </Table>
    </TableBodyContainer>
  );
};

export default ViewModal;
