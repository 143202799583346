import React, { createContext, useState } from 'react';

const SideNavContext = createContext();

const SideNavProvider = ({ children }) => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [sideNavOptions, setSideNavOptions] = useState({});
  const toggleSideNav = () => {
    setIsSideNavOpen((prev) => !prev);
  };
  return (
    <SideNavContext.Provider
      value={{
        isSideNavOpen,
        toggleSideNav,
        sideNavOptions,
        setSideNavOptions,
        setIsSideNavOpen,
      }}
    >
      {children}
    </SideNavContext.Provider>
  );
};

export { SideNavContext, SideNavProvider };
