import { gql } from '@apollo/client';

const GET_DECLINE_REASONS = gql`
  query getOrg($id: ID!) {
    getOrg(id: $id) {
      loadConfiguration {
        loadDeclineReasons
      }
    }
  }
`;

const CONFIRM_LOAD = gql`
  mutation confirmLoad($id: ID!) {
    confirmLoad(id: $id) {
      id
    }
  }
`;

const DECLINE_LOAD = gql`
  mutation declineLoad($input: LoadReasonCommentInput!) {
    declineLoad(input: $input) {
      id
    }
  }
`;

export { CONFIRM_LOAD, DECLINE_LOAD, GET_DECLINE_REASONS };
