import { useMutation, useQuery } from '@apollo/client';
import { Field, Form as FormikForm, Formik } from 'formik';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  ActionButtons,
  CheckBoxFormField,
  Column,
  Dropdown,
  FormArea,
  FormItem,
  FormWrapper,
  generateLinkWithParams,
  getURLParam,
  listSortAndFormat,
  PageContainer,
  PageError,
  PageHeading,
  PageLoading,
  useAlerts,
  useLoading,
} from '../../../../';
import formikSchema from './formikSchema';
import { GET_CARRIER, GET_MASTER_CARRIERS, LINK_MASTER_CARRIER } from './gql';

const LinkMasterCarrier = withRouter(
  ({ match, history, redirect, currentEntity, linkParams }) => {
    const { alertSuccess, onError } = useAlerts();
    const id = getURLParam(match, 'carrierId');
    const { data, error } = useQuery(GET_CARRIER, {
      variables: { id },
    });

    const { data: masterCarrierData, error: masterCarrierError } =
      useQuery(GET_MASTER_CARRIERS);

    const [linkMasterCarrierMutation, { loading }] = useMutation(
      LINK_MASTER_CARRIER,
      {
        onCompleted: () => {
          alertSuccess('Carrier successfully linked');
          history.push(generateLinkWithParams(redirect, linkParams));
        },
        onError,
      },
    );

    useLoading(loading);

    const hasError = error || masterCarrierError;

    if (hasError) {
      return <PageError error={hasError} />;
    }

    if (data && masterCarrierData) {
      const {
        getCarrier: { isMasterCarrierLinkEnabled, vatNumber, masterCarrier },
      } = data;
      const masterCarriers = listSortAndFormat(
        masterCarrierData.getOrgsPaged.data,
        'name',
        'id',
      );
      const alreadyLinked = !!masterCarrier;
      const initialValues = alreadyLinked
        ? {
            masterCarrierId: masterCarrier.id,
            isMasterCarrierLinkEnabled,
          }
        : { masterCarrierId: vatNumber, isMasterCarrierLinkEnabled: true };
      return (
        <PageContainer>
          <PageHeading
            heading={`${currentEntity.name} Carriers`}
            description={'Link Master Carrier'}
          />
          <Formik
            validateOnMount
            {...formikSchema}
            initialValues={initialValues}
            onSubmit={(values) => {
              const variables = {
                input: {
                  id,
                  ...values,
                },
              };
              linkMasterCarrierMutation({
                variables,
              });
            }}
          >
            <FormWrapper>
              <FormikForm>
                <FormArea>
                  <Column>
                    {alreadyLinked && (
                      <FormItem>
                        This carrier is already linked. You can only enable /
                        disable.
                      </FormItem>
                    )}
                    <FormItem>
                      <Field
                        labelText={'Master Carrier'}
                        name={'masterCarrierId'}
                        component={Dropdown}
                        options={masterCarriers}
                        disabled={alreadyLinked}
                      />
                    </FormItem>
                    {alreadyLinked && (
                      <FormItem>
                        <Field
                          labelText={'Enabled'}
                          name={'isMasterCarrierLinkEnabled'}
                          component={CheckBoxFormField}
                          light
                        />
                      </FormItem>
                    )}
                  </Column>
                </FormArea>
              </FormikForm>
              <ActionButtons
                disabled={!alreadyLinked ? false : null}
                primaryName={'Save & Continue'}
              />
            </FormWrapper>
          </Formik>
        </PageContainer>
      );
    }

    return <PageLoading />;
  },
);

export default LinkMasterCarrier;
