import { useQuery } from '@apollo/client';
import { omit } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  appsArray,
  getURLParam,
  PageContainer,
  PageError,
  PageHeading,
  PageLoading,
  Tab,
  Tabs,
} from '../../../../..';
import { useOutcomeObject } from '../../common';
import Licences from '../../Licences';
import Apps from './Apps';
import Details from './Details';
import { GET_OPERATOR } from './gql';

const EditOperator = withRouter(
  ({
    history,
    match,
    returnRoute = '',
    currentEntity = {},
    linkParams = {},
    permissionsList = appsArray.filter((v) =>
      ['driver', 'crew', 'fleet'].includes(v.value),
    ),
  }) => {
    const id = getURLParam(match, 'operatorId');

    const { error, data } = useQuery(GET_OPERATOR, {
      variables: { id },
    });

    const outcomeObject = useOutcomeObject({
      history,
      redirect: returnRoute,
      linkParams,
    });

    if (error) {
      return <PageError error={error} />;
    }

    if (data) {
      const {
        getOperator: { orgs, ...rest },
      } = data;
      const operator = {
        ...rest,
        apps: orgs.find((mc) => mc.org.id === currentEntity.id).apps,
      };
      return (
        <PageContainer style={{ gridTemplateRows: 'auto auto 1fr' }}>
          <PageHeading
            heading={`${currentEntity.name} Operators`}
            description="Update operator"
          />
          <Tabs style={{ marginBottom: '1rem' }}>
            <Tab label="Operator Details">
              <Details
                operator={omit(operator, ['apps', 'licences'])}
                outcomeObject={outcomeObject}
              />
            </Tab>
            <Tab label="Permissions">
              <Apps
                operator={{
                  operatorId: operator.id,
                  orgId: currentEntity.id,
                  apps: operator.apps,
                }}
                outcomeObject={outcomeObject}
                permissionsList={permissionsList}
              />
            </Tab>
            <Tab label="Licences">
              <Licences licences={rest.licences} />
            </Tab>
          </Tabs>
        </PageContainer>
      );
    }

    return <PageLoading />;
  },
);

export default EditOperator;
