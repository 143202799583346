import { Field, Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import { mergeAll } from 'ramda';
import React, { Fragment, useCallback } from 'react';
import {
  CheckBoxFormField,
  DatePicker,
  DateRangePicker,
  Dropdown,
  DropdownSearch,
  MultiSelect,
  TextFormField,
} from '../../../components';
import { ActionButton, ActionButtonSecondary } from '../ActionRow/styles';
import { FilterItem, FilterOptionsContainer } from './styles';

const deepReject = (data, prevObjKey = null) => {
  if (Array.isArray(data)) {
    const mappedArray = data
      .map((el) => deepReject(el))
      .filter((el) => el !== undefined);
    return prevObjKey ? { [prevObjKey]: mappedArray } : mappedArray;
  }
  if (data && typeof data === 'object') {
    const goodKeys = Object.keys(data)
      .map((key) => deepReject(data[key], key))
      .filter((gk) => gk !== undefined);
    const returnObject = mergeAll(goodKeys);
    return prevObjKey ? { [prevObjKey]: returnObject } : returnObject;
  }
  const shapedData = typeof data === 'boolean' ? data : data || undefined;
  if (prevObjKey) {
    return shapedData !== undefined ? { [prevObjKey]: shapedData } : undefined;
  }
  return shapedData;
};

const formatFilterOptions = (fo, memoryFilter) =>
  fo.map(({ name, type, options, initialValue }) => ({
    payload: { [name]: memoryFilter[name] || initialValue },
    type,
    options,
  }));

const FilterOptions = ({
  filterOptions,
  query,
  refetch,
  setQuery = () => 0,
  setSkip = () => 0,
  onSearch = () => 0,
  hideSearch = false,
  memoryFilter = {},
}) => {
  const filters = formatFilterOptions(filterOptions, memoryFilter);
  const initialValues = mergeAll(filters.map(({ payload }) => payload));

  const fetchData = useCallback(
    (values) => {
      setSkip(0);
      setQuery(deepReject(values));
      isEqual(query, deepReject(values)) && refetch();
    },
    [query, refetch, setQuery, setSkip],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSearch(values);
        fetchData(values);
      }}
    >
      {({ values, handleChange }) => {
        return (
          <Form>
            <FilterOptionsContainer>
              {filterOptions.map((filter) => {
                const Component =
                  filter.type === 'dropdown'
                    ? Dropdown
                    : filter.type === 'dropdownsearch'
                    ? DropdownSearch
                    : filter.type === 'multiselect'
                    ? MultiSelect
                    : filter.type === 'daterange'
                    ? DateRangePicker
                    : filter.type === 'date'
                    ? DatePicker
                    : filter.type === 'checkbox'
                    ? CheckBoxFormField
                    : TextFormField;
                return (
                  <FilterItem key={filter.name} width={150}>
                    <Field {...filter} component={Component} />
                  </FilterItem>
                );
              })}
              {!hideSearch && (
                <Fragment>
                  <ActionButton>Search</ActionButton>
                  <ActionButtonSecondary
                    type="button"
                    onClick={() => {
                      onSearch({});
                      setSkip(0);
                      setQuery({});
                      Object.keys(values).forEach((name) => {
                        handleChange({ target: { name, value: undefined } });
                      });
                    }}
                  >
                    Clear
                  </ActionButtonSecondary>
                </Fragment>
              )}
            </FilterOptionsContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FilterOptions;
