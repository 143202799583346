import { gql } from '@apollo/client';

const FORCE_COMPLETE_LOAD = gql`
  mutation forceCompleteLoad($id: ID!, $forceInput: LoadReasonCommentInput) {
    completeLoad(id: $id, forceInput: $forceInput) {
      id
    }
  }
`;

export { FORCE_COMPLETE_LOAD };
