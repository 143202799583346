import styled from 'styled-components';

const Heading = styled.h4`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 3px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

const HeadingDescription = styled.p`
  text-transform: capitalize;
  font-size: 14px;
  margin: 0;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export { Heading, HeadingDescription };
