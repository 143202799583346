import { gql } from '@apollo/client';

const GET_META = gql`
  query getMeta {
    orgs: getOrgsPaged(pagination: { take: 100000, skip: 0 }) {
      data {
        id
        name
        hasCarrierPortal
      }
    }
    entities: getLinkEntities(
      pagination: { take: 10000000, skip: 0 }
      query: { entityType: PRINCIPAL }
    ) {
      data {
        id
        name
        orgId
      }
    }
    eventTypes: __type(name: "ELoadADNCommunicationEvent") {
      enumValues {
        name
      }
    }
    types: __type(name: "EADNType") {
      enumValues {
        name
      }
    }
  }
`;

const GET_MASTER_TEMPLATE = gql`
  query getADNMasterTemplate(
    $event: ELoadADNCommunicationEvent!
    $type: EADNType!
  ) {
    getADNMasterTemplate(event: $event, type: $type) {
      emailTemplate
    }
  }
`;

export { GET_META, GET_MASTER_TEMPLATE };
