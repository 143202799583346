import { useCallback, useContext } from 'react';
import { NotificationsContext } from '../context';

const useAlerts = () => {
  const { addInlineAlert } = useContext(NotificationsContext);
  const alertSuccess = useCallback(
    (message = '') => addInlineAlert('success', 'Success', message),
    [addInlineAlert],
  );
  const alertError = useCallback(
    (message = '') => addInlineAlert('error', 'Error', message),
    [addInlineAlert],
  );
  const alertInfo = useCallback(
    (message = '') => addInlineAlert('info', 'Info', message),
    [addInlineAlert],
  );
  const onError = useCallback(
    (error) => alertError(error.message),
    [alertError],
  );
  return { alertSuccess, alertError, onError, alertInfo };
};

export default useAlerts;
