import { lighten } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr 1fr auto;
  grid-gap: 0.5rem;
  grid-template-areas:
    'execution map allocation'
    'execution map allocation'
    'execution timeline allocation';
  width: 100%;
  height: 100%;
  font-size: 0.75rem;
  > div:first-child {
    border: 5px solid ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
    padding: 0.3rem;
  }
`;

const AllocationContainer = styled.div`
  grid-area: allocation;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 0.5rem;
`;

const AssignContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-gap: 1rem;
`;

export { AllocationContainer, Container, AssignContainer };
