import * as yup from 'yup';

const validationSchema = yup.object({
  type: yup.string().required(),
  carrierId: yup.string().nullable(),
  orgId: yup.string().nullable(),
  principalId: yup.string().nullable(),
  subject: yup.string().required(),
  from: yup.string().required(),
  event: yup.string().required(),
  emailTemplate: yup.string().notRequired().nullable(),
  smsTemplate: yup.string().notRequired().nullable(),
});

export default validationSchema;
