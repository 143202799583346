import { gql } from '@apollo/client';

const GET_SITES = gql`
  query getSites($id: ID!) {
    getOrg(id: $id) {
      sites {
        id
        name
      }
    }
  }
`;

const GET_ORG_SITES = gql`
  query {
    orgs: getOrgs {
      id
      name
    }
    sites: getSites {
      id
      name
      orgId
    }
  }
`;

const ALL_OPERATORS = gql`
  query getOperatorsPaged(
    $take: Int!
    $skip: Int!
    $query: QueryOperators
    $order: [[String]]
  ) {
    getOperatorsPaged(
      pagination: { take: $take, skip: $skip }
      query: $query
      order: $order
    ) {
      data {
        sites {
          site {
            id
            name
          }
          apps
        }
        createdAt
        id
        idNumber
        firstName
        lastName
        mobile
        isEnabled
      }

      pagination {
        skip
        take
        total
      }
    }
  }
`;

const ORG_OPERATORS = gql`
  query getOrg(
    $id: ID!
    $take: Int!
    $skip: Int!
    $query: QueryOperators
    $order: [[String]]
  ) {
    getOrg(id: $id) {
      operatorsPaged(
        pagination: { take: $take, skip: $skip }
        query: $query
        order: $order
      ) {
        data {
          sites {
            site {
              name
            }
            apps
          }
          createdAt
          id
          idNumber
          firstName
          lastName
          mobile
          isEnabled
        }
        pagination {
          skip
          take
          total
        }
      }
    }
  }
`;

const SITE_OPERATORS = gql`
  query getSite(
    $id: ID!
    $take: Int!
    $skip: Int!
    $query: QueryOperators
    $order: [[String]]
  ) {
    getSite(id: $id) {
      operatorsPaged(
        pagination: { take: $take, skip: $skip }
        query: $query
        order: $order
      ) {
        data {
          operator {
            sites {
              site {
                name
              }
            }
            createdAt
            id
            idNumber
            firstName
            lastName
            mobile
            isEnabled
          }
          apps
        }
        pagination {
          skip
          take
          total
        }
      }
    }
  }
`;

const OPERATORS_ON_ORG_LEVEL = gql`
  query getOrg(
    $id: ID!
    $take: Int!
    $skip: Int!
    $query: QueryOperators
    $order: [[String]]
  ) {
    getOrg(id: $id) {
      allOperators(
        pagination: { take: $take, skip: $skip }
        query: $query
        order: $order
      ) {
        data {
          isOrgOperator
          sites {
            id
            name
          }
          operator {
            id
            firstName
            lastName
            mobile
            createdAt
            idNumber
            isEnabled
          }
          apps
        }
        pagination {
          skip
          take
          total
        }
      }
    }
  }
`;

const RESEND_PIN = gql`
  mutation changeOperatorPin($id: ID!) {
    changeOperatorPin(id: $id) {
      id
    }
  }
`;

const MANAGE_OPERATOR_SITES = gql`
  mutation manageOperatorSites($id: ID!, $siteId: String) {
    manageOperatorSites(input: { id: $id, add: [], remove: [$siteId] })
  }
`;

export {
  SITE_OPERATORS,
  ORG_OPERATORS,
  ALL_OPERATORS,
  RESEND_PIN,
  OPERATORS_ON_ORG_LEVEL,
  GET_SITES,
  MANAGE_OPERATOR_SITES,
  GET_ORG_SITES,
};
