import { useLazyQuery, useQuery } from '@apollo/client';
import { Field, Form, useFormikContext } from 'formik';
import React, { useState } from 'react';
import {
  ActionButtons,
  CheckBoxFormField,
  Column,
  convertEnumObject,
  Dropdown,
  FormArea,
  FormItem,
  FormItemSplitter,
  FormWrapper,
  HtmlEdit,
  HtmlPreview,
  listSortAndFormat,
  PageError,
  PageLoading,
  PhotoUploadContainer,
  PrimaryButton,
  SecondaryButton,
  TextAreaField,
  TextFormField,
  useAlerts,
  useLoading,
} from '../../../';
import { GET_MASTER_TEMPLATE, GET_META } from './gql';

const ADNForm = ({ isEdit, isRoute }) => {
  const [editHtml, setEditHtml] = useState(false);
  const { handleChange, values } = useFormikContext();
  const { data, error } = useQuery(GET_META);
  const { onError } = useAlerts();

  const [getMasterTemplate, { loading }] = useLazyQuery(GET_MASTER_TEMPLATE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      handleChange({
        target: {
          name: 'emailTemplate',
          value: d?.getADNMasterTemplate.emailTemplate || '',
        },
      });
    },
    onError,
  });

  useLoading(loading);

  if (editHtml) {
    return (
      <HtmlEdit
        heading="Updating Template HTML"
        html={values.emailTemplate}
        onSave={(value) => {
          handleChange({ target: { name: 'emailTemplate', value } });
          setEditHtml(false);
        }}
        onCancel={() => setEditHtml(false)}
      />
    );
  }

  if (error) return <PageError error={error} />;
  if (!data) return <PageLoading />;

  const {
    orgs: { data: orgs },
    eventTypes,
    types,
    entities: { data: entities },
  } = data;
  const carriers = orgs.filter((o) => o.hasCarrierPortal);
  return (
    <FormWrapper>
      <Form>
        <FormArea
          columns={2}
          style={{ width: '100%', gridTemplateColumns: 'auto 1fr' }}
        >
          <Column>
            <FormItem>
              <FormItemSplitter>
                <Field
                  labelText={'Event *'}
                  name={'event'}
                  component={Dropdown}
                  options={convertEnumObject({
                    __type: eventTypes,
                  })}
                />
                <Field
                  labelText="Active"
                  name="isActive"
                  component={CheckBoxFormField}
                />
              </FormItemSplitter>
            </FormItem>
            <FormItem>
              <FormItemSplitter>
                <Field
                  labelText="Type *"
                  name="type"
                  component={Dropdown}
                  options={convertEnumObject({ __type: types }).filter((e) =>
                    isRoute ? e.value !== 'CARRIER' : true,
                  )}
                  disabled={
                    values.orgId || values.carrierId || values.principalId
                  }
                />
                {values.type === 'ORG' && (
                  <Field
                    labelText="Organisation"
                    name="orgId"
                    component={Dropdown}
                    searchOnly
                    options={listSortAndFormat(orgs, 'name', 'id')}
                    onChange={(v) => {
                      if (v) {
                        handleChange({
                          target: {
                            name: 'from',
                            value: orgs.find((c) => c.id === v).name,
                          },
                        });
                      }
                    }}
                  />
                )}
                {values.type === 'CARRIER' && (
                  <Field
                    labelText="Carrier"
                    name="carrierId"
                    component={Dropdown}
                    options={listSortAndFormat(carriers, 'name', 'id')}
                    onChange={(v) => {
                      if (v) {
                        handleChange({
                          target: {
                            name: 'from',
                            value: carriers.find((c) => c.id === v).name,
                          },
                        });
                      }
                    }}
                  />
                )}
                {values.type === 'PRINCIPAL' && (
                  <Field
                    labelText="Principal"
                    name="principalId"
                    component={Dropdown}
                    options={listSortAndFormat(
                      entities.map((e) => ({
                        ...e,
                        org: orgs.find((o) => o.id === e.orgId)?.name,
                      })),
                      (e) => `${e.org} - ${e.name}`,
                      'id',
                    )}
                    onChange={(v) => {
                      if (v) {
                        handleChange({
                          target: {
                            name: 'from',
                            value: entities.find((c) => c.id === v).name,
                          },
                        });
                      }
                    }}
                  />
                )}
              </FormItemSplitter>
            </FormItem>
            <FormItem>
              <FormItemSplitter>
                <Field
                  labelText={'Subject *'}
                  name={'subject'}
                  component={TextFormField}
                />
                <Field
                  labelText={'From *'}
                  name={'from'}
                  component={TextFormField}
                />
              </FormItemSplitter>
            </FormItem>
            <FormItem>
              <Field
                labelText="Banner Image"
                name="bannerImage"
                component={PhotoUploadContainer}
                dimensions="150px"
              />
            </FormItem>
            <FormItem>
              <FormItemSplitter>
                {values.event &&
                  values.type &&
                  (values.orgId || values.carrierId || values.principalId) && (
                    <SecondaryButton
                      fillWidth
                      type="button"
                      onClick={() => {
                        getMasterTemplate({
                          variables: { event: values.event, type: values.type },
                        });
                      }}
                    >
                      Copy HTML from Master Template
                    </SecondaryButton>
                  )}
                <PrimaryButton fillWidth onClick={() => setEditHtml(true)}>
                  Edit HTML (Blank Inherits from Master)
                </PrimaryButton>
              </FormItemSplitter>
            </FormItem>
            <FormItem>
              <Field
                labelText={'SMS Template'}
                name={'smsTemplate'}
                component={TextAreaField}
                rows={5}
              />
            </FormItem>
          </Column>
          <FormItem>
            <h4>HTML Preview</h4>
            <HtmlPreview html={values.emailTemplate} />
          </FormItem>
        </FormArea>
      </Form>
      <ActionButtons
        type="finalStep"
        primaryName={isEdit ? 'Save Changes' : 'Create'}
      />
    </FormWrapper>
  );
};

export default ADNForm;
