import { useMutation } from '@apollo/client';
import { Document16, Password16, Upgrade16 } from '@carbon/icons-react';
import { capitalize } from 'lodash';
import React, { useContext } from 'react';
import {
  ConfirmModal,
  DeleteIconLarge,
  NotificationsContext,
  TableIconWrapper,
  useAlerts,
  useCurrentEntity,
} from '../../../../';
import { MANAGE_OPERATOR_SITES, RESEND_PIN } from './gql';
import LicenceModal from './LicenceModal';
import UpgradeModal from './UpgradeModal';

const operatorHeadings = (currentEntityType, isOrgLevel) => [
  {
    key: 'firstName',
    header: 'First Name',
    allowOrder: true,
  },
  {
    key: 'lastName',
    header: 'Last Name',
    allowOrder: true,
  },
  { key: 'idNumber', header: 'ID Number', encrypt: true, allowOrder: true },
  {
    key: 'mobile',
    header: 'Mobile Number',
  },
  {
    key: 'createdAt',
    header: 'Date Created',
  },
  {
    key: 'status',
    header: 'Status',
  },
  { key: 'sitesCount', header: 'Sites', isCount: true },
  {
    ...(currentEntityType === 'site' || isOrgLevel
      ? {
          key: 'apps',
          header: 'Permissions',
          isCount: true,
        }
      : {}),
  },
  {
    key: 'resetPin',
    header: '',
    tooltip: 'Reset PIN',
    componentType: 'modal',
    dimensions: { height: 30 },
    ComponentWrapper: ({ onClick }) => (
      <TableIconWrapper onClick={onClick}>
        <Password16 />
      </TableIconWrapper>
    ),
    Component: ({ row, close }) => {
      const firstName = row.firstName;
      const lastName = row.lastName;
      const mobile = row.mobile;
      const { addNotification, addInlineAlert } =
        useContext(NotificationsContext);
      const [resendPin, { loading }] = useMutation(RESEND_PIN, {
        onCompleted: () => {
          addInlineAlert(
            'success',
            'PIN Reset',
            `SMS successfully sent to ${mobile}`,
          );
          close();
        },
        onError: (err) => {
          addNotification('error', 'Error', err.message);
        },
      });
      return (
        <ConfirmModal
          title={`Reset PIN for ${firstName} ${lastName} (${mobile})`}
          subtitle="Are you sure?"
          onCancel={close}
          onConfirm={() => resendPin({ variables: { id: row.id } })}
          loading={loading}
        />
      );
    },
  },
  {
    key: 'licences',
    header: '',
    tooltip: 'View Licences / PDPs',
    componentType: 'modal',
    dimensions: { height: 75, width: 75 },
    ComponentWrapper: ({ onClick }) => (
      <TableIconWrapper onClick={onClick}>
        <Document16 />
      </TableIconWrapper>
    ),
    Component: LicenceModal,
  },
  ...(isOrgLevel
    ? [
        {
          key: 'upgrade',
          header: '',
          tooltip: 'Upgrade to Organisation Level',
          componentType: 'modal',
          dimensions: { height: 75, width: 75 },
          ComponentWrapper: ({ onClick }) => (
            <TableIconWrapper onClick={onClick}>
              <Upgrade16 />
            </TableIconWrapper>
          ),
          Component: UpgradeModal,
          condition: (row) => !row.isOrgOperator,
        },
      ]
    : []),
  ...(['site'].includes(currentEntityType)
    ? [
        {
          key: 'delete',
          header: '',
          tooltip: `Remove From ${capitalize(currentEntityType)}`,
          componentType: 'modal',
          dimensions: { height: 30 },
          ComponentWrapper: ({ onClick }) => (
            <DeleteIconLarge onClick={onClick} />
          ),
          Component: ({ row, close, reloadTableData }) => {
            const { firstName, lastName } = row;
            const { alertSuccess, onError } = useAlerts();
            const { currentEntity } = useCurrentEntity();
            const [removeOperator, { loading }] = useMutation(
              MANAGE_OPERATOR_SITES,
              {
                onCompleted: () => {
                  alertSuccess('Operator Removed from Site');
                  close();
                  reloadTableData();
                },
                onError,
              },
            );
            return (
              <ConfirmModal
                title={`Remove ${firstName} ${lastName} from Site`}
                subtitle="Are you sure?"
                danger
                onCancel={close}
                onConfirm={() =>
                  removeOperator({
                    variables: { id: row.id, siteId: currentEntity.id },
                  })
                }
                loading={loading}
              />
            );
          },
        },
      ]
    : []),
];

export { operatorHeadings };
