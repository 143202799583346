import styled from 'styled-components';

const LogoutModalContainer = styled.div`
  width: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

export { LogoutModalContainer };
