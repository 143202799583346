import { gql } from '@apollo/client';

const UPDATE_ZONE = gql`
  mutation updateZone($input: RoutebuilderZoneUpdateInput!) {
    updateRoutebuilderZone(input: $input) {
      id
    }
  }
`;

const GET_ZONE = gql`
  query getZone($id: ID!) {
    getRoutebuilderZone(id: $id) {
      id
      name
      reference
      siteId
      startTime
      isDefaultTime
      color
      locality
      shape {
        type
        data {
          markers {
            lat
            lng
          }
          center {
            lat
            lng
          }
          radius
        }
      }
    }
  }
`;

export { UPDATE_ZONE, GET_ZONE };
