interface Asset {
  id: string;
  licenceNumber: string;
  fleetNumber?: string;
  devices: Device[];
}

enum eDeviceType {
  GATEKEEPER = 'GATEKEEPER',
  B_B_RADAR = 'B_B_RADAR',
  MOBILE = 'MOBILE',
  TELEMATICS = 'TELEMATICS',
  DASH_CAM = 'DASH_CAM',
}

interface TpiEvent {
  id?: string;
  created: string;
  deviceId: string;
  deviceSerial?: string;
  lat?: number;
  lng?: number;
  locationId?: string;
  locationName?: string;
  processed?: string;
  sent?: string;
  category: eDeviceType;
  source: string;
  type: string;
  vin?: string;
}

interface TpiEventPayload {
  data: TpiEvent[];
}
interface Device {
  id: string;
  imei: string;
  type: eDeviceType;
  tpiEvents: TpiEventPayload;
  currentPosition: DeviceCurrentPosition;
  deviceId: string;
}

interface DeviceCurrentPosition {
  hdop?: number;
  state?: number;
  svn?: number;
  altitude?: number;
  identifier: string;
  lat: number;
  lng: number;
  batteryState?: boolean;
  door?: boolean;
  locationId?: string;
  locationName?: string;
  timestamp: string;
}

export type { Asset };
export { eDeviceType };
