const cognito = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID,
};

const http = {
  uri:
    process.env.REACT_APP_SERVER_URL &&
    process.env.REACT_APP_SERVER_URL.replace(/'/g, ''),
  // uri: 'http://0.0.0.0:80/graphql', //UNCOMMENT THIS WHEN TESTING LOCALLY
};

export { cognito, http };
