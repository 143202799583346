import { gql } from '@apollo/client';

const GET_ORGS = gql`
  query getOrgs {
    orgs: getOrgsSimple {
      id
      name
    }
  }
`;

export { GET_ORGS };
