import styled from 'styled-components';

const TextSearch = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  margin-bottom: 1rem;
  grid-gap: 1rem;
  border-bottom: 5px solid ${({ theme }) => theme.colors.lightGrey};
  padding: 0.5rem;
`;

export { TextSearch };
