import { gql } from '@apollo/client';

export default gql`
  query getCurrentUser {
    getCurrentUser {
      id
      firstName
      lastName
      email
      isYardUser
      isCarrierPortalUser
      isCustomerPortalUser
      isSupplierPortalUser
      group {
        id
        name
      }
      contextOrgs {
        id
        name
        onroadId
        orgIntegrationConfiguration {
          linkPlanReferenceAutoGenerate
          linkBookingIntegrationEndpoint
          linkTransactionIntegrationEndpoint
          linkPlanIntegrationEndpoint
          linkIntegrationFileType
        }
        group {
          id
          name
        }
      }
      contextSites {
        id
        name
        configuration {
          linkInbound {
            disableSorting
          }
        }
        org {
          id
          name
          onroadId
          orgIntegrationConfiguration {
            linkPlanReferenceAutoGenerate
            linkBookingIntegrationEndpoint
            linkTransactionIntegrationEndpoint
            linkPlanIntegrationEndpoint
            linkIntegrationFileType
          }
          group {
            id
            name
          }
        }
      }
      isTMUser
      isGroupUser
      isEnabled
      permissions {
        module
        userType
      }
    }
  }
`;
