import { gql } from '@apollo/client';

const GET_OPERATOR = gql`
  query getOperatorByIdNumber($idNumber: String!) {
    getOperatorByIdNumber(idNumber: $idNumber) {
      id
      idNumber
      lastName
      reference
      orgs {
        org {
          id
        }
        apps
      }
    }
  }
`;

const CREATE_OPERATOR = gql`
  mutation addOrgOperator($input: OrgOperatorInput!) {
    addOrgOperator(input: $input) {
      id
    }
  }
`;

const ADD_OPERATOR = gql`
  mutation addOperatortoOrg($input: OperatorOrgInput!) {
    addOperatortoOrg(input: $input)
  }
`;

export { CREATE_OPERATOR, GET_OPERATOR, ADD_OPERATOR };
