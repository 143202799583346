import {
  CheckmarkFilled16,
  Document16,
  Document20,
  Download16,
  Download20,
  Edit16,
  Edit20,
  Misuse16,
  Misuse20,
  TrashCan16,
  TrashCan20,
} from '@carbon/icons-react';
import { darken } from 'polished';
import styled from 'styled-components';

const iconStyleFactory = (Icon) => styled(Icon)`
  cursor: pointer;
  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  & circle {
    fill: ${({ theme }) => theme.colors.primary};
  }
  :hover {
    & path {
      fill: ${({ theme }) => theme.colors.secondary};
    }
    & circle {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const iconStyleFactoryDanger = (Icon) => styled(Icon)`
  cursor: pointer;
  & path {
    fill: ${({ theme }) => theme.colors.danger};
  }
  & circle {
    fill: ${({ theme }) => theme.colors.danger};
  }
  :hover {
    & path {
      fill: ${({ theme }) => darken(0.1, theme.colors.danger)};
    }
    & circle {
      fill: ${({ theme }) => darken(0.1, theme.colors.danger)};
    }
  }
`;

const iconStyleFactoryInverted = (Icon) => styled(Icon)`
  cursor: pointer;
  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
  & circle {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const DownloadIconSmall = iconStyleFactory(Download16);
const DownloadIconLarge = iconStyleFactory(Download20);
const CancelWhiteSmall = iconStyleFactoryInverted(Misuse16);
const CancelWhiteLarge = iconStyleFactoryInverted(Misuse20);
const DocumentIconSmall = iconStyleFactoryInverted(Document16);
const DocumentIconLarge = iconStyleFactoryInverted(Document20);
const DocumentIconNormalSmall = iconStyleFactory(Document16);
const DocumentIconNormalLarge = iconStyleFactory(Document20);
const EditIconNormalSmall = iconStyleFactory(Edit16);
const EditIconNormalLarge = iconStyleFactory(Edit20);
const DeleteIconSmall = iconStyleFactoryDanger(TrashCan16);
const DeleteIconLarge = iconStyleFactoryDanger(TrashCan20);

const TrueIconSmall = styled(CheckmarkFilled16)`
  & path {
    fill: ${({ theme }) => theme.colors.success};
  }
`;

const FalseIconSmall = styled(Misuse16)`
  & path {
    fill: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export {
  CancelWhiteLarge,
  CancelWhiteSmall,
  DeleteIconLarge,
  DeleteIconSmall,
  DocumentIconLarge,
  DocumentIconNormalLarge,
  DocumentIconNormalSmall,
  DocumentIconSmall,
  DownloadIconLarge,
  DownloadIconSmall,
  EditIconNormalLarge,
  EditIconNormalSmall,
  FalseIconSmall,
  iconStyleFactory,
  iconStyleFactoryDanger,
  TrueIconSmall,
};
