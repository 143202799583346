import styled from 'styled-components';
import { photoBlank as defaultImg } from '../assets';

const PhotoContainer = styled.div`
  margin-right: 20px;
  width: ${({ dimensions }) => dimensions || '300px'};
  height: ${({ dimensions }) => dimensions || '300px'};
  outline: 3px solid ${({ theme }) => theme.colors.lightGrey};
  background: ${({ src }) =>
    `url(${
      src && src !== 'n/a' ? src : defaultImg
    }) no-repeat center center / cover`};
`;

export default PhotoContainer;
