import styled from 'styled-components';
import { lighten } from 'polished';

const ListItem = styled.li`
  padding: 10px 5px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & * {
    font-size: 0.8rem;
  }
  border-bottom: 2px solid
    ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
`;

export default ListItem;
