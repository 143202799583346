import React, { createContext, useContext, useState } from 'react';
import { InlineNotification, Modal } from '../';
import { NotificationsContext } from './Notifications';
const ModalContext = createContext({
  toggleModal: () => {},
  setModalContent: (element) => {},
  setDimensions: ({ width, height }) => {},
  clearModal: () => {},
});

const ModalProvider = ({ children }) => {
  const [isModalOpen, toggleModalHandler] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [dimensions, setDimensions] = useState({});
  const { inlineAlert } = useContext(NotificationsContext) || {};
  const toggleModal = () => toggleModalHandler((prev) => !prev);
  const clearModal = () => {
    setDimensions({});
    toggleModalHandler(false);
    setModalContent(null);
  };
  return (
    <ModalContext.Provider
      value={{ toggleModal, setModalContent, setDimensions, clearModal }}
    >
      <Modal {...dimensions} open={isModalOpen} close={clearModal}>
        {inlineAlert && (
          <InlineNotification
            width="100%"
            kind={inlineAlert.type}
            title={inlineAlert.title}
            subtitle={inlineAlert.message}
          />
        )}
        {modalContent}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
