import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ hasLabel }) => (hasLabel ? 'space-between' : 'center')};
  position: relative;
`;

const Label = styled.div`
  display: flex;
  font-size: 0.75rem;
  color: ${({ theme, error }) => theme.colors[error ? 'danger' : 'darkGrey']};
  margin-bottom: 5px;
`;

const InputContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-gap: 0.3rem;
  width: 100%;
`;

const Input = styled.input`
  padding: 0.5rem;
  height: 40px;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
`;

const SpinnerContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 30%;
`;

const MultiCount = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.8rem;
`;

const OptionsContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  max-height: 150px;
  overflow: auto;
  background: ${({ theme }) => theme.colors.white};
  transform: translate(0, 100%);
  z-index: 100000;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  font-size: 0.7rem;
  > div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
    height: 30px;
    display: flex;
    align-items: center;
    :hover {
      background: ${({ theme }) => theme.colors.lightGrey};
    }
    > div {
      margin: 0 0.5rem;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      width: 100%;
      > div {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
`;

export {
  Container,
  Label,
  Input,
  SpinnerContainer,
  OptionsContainer,
  MultiCount,
  InputContainer,
};
