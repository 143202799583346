import styled from 'styled-components';

const DashboardCapsule = styled.div`
  display: inline-block;
  width: 6px;
  height: 10px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  margin-right: 2px;
`;

export default DashboardCapsule;
