import { gql } from '@apollo/client';

const CREATE_ZONE = gql`
  mutation createZone($input: RoutebuilderZoneCreateInput!) {
    createRoutebuilderZone(input: $input) {
      id
    }
  }
`;

export { CREATE_ZONE };
