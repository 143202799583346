import { gql } from '@apollo/client';

const GET_ASSET_TYPES = gql`
  query {
    __type(name: "AssetTypeEnums") {
      enumValues {
        name
      }
    }
  }
`;

export { GET_ASSET_TYPES };
