import { gql } from '@apollo/client';

const CREATE_LOAD_CONTACT = gql`
  mutation createLoadContact($input: LoadContactCreateInput!, $token: String) {
    createLoadContact(input: $input, token: $token) {
      id
    }
  }
`;

export { CREATE_LOAD_CONTACT };
