import { gql } from '@apollo/client';

const GET_CANCEL_REASONS = gql`
  query getOrg($id: ID!) {
    getOrg(id: $id) {
      loadConfiguration {
        loadCancellationReasons
      }
    }
  }
`;

export { GET_CANCEL_REASONS };
