import styled from 'styled-components';

const FormWrapper = styled.div`
  height: 100%;
  display: inline-grid;
  align-items: ${({ align }) => align || null};
  grid-template-rows: ${({ gridStyling }) => gridStyling || '1fr auto'};
  grid-gap: 1rem;
`;

export default FormWrapper;
