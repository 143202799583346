import { OverflowContainer } from '@trackmatic/yard-ui-common';
import styled from 'styled-components';

const Container = styled(OverflowContainer)`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 0.5rem;
`;

const Left = styled(OverflowContainer)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 0.2rem;
`;

const Right = styled(OverflowContainer)`
  overflow: hidden;
`;

export { Container, Left, Right };
