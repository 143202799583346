import { useFormikContext } from 'formik';
import React from 'react';
import {
  Container,
  Label,
  ToggleButton,
  ToggleContainer,
  ToggleLineContainer,
} from './styles';

const ToggleBtn = ({
  field,
  labelA,
  labelB,
  labelText,
  onToggle,
  defaultToggled,
}) => {
  const valueToUse =
    typeof defaultToggled === 'boolean' ? defaultToggled : field.value;
  const { handleChange } = useFormikContext();
  return (
    <Container>
      <Label>{labelText}</Label>
      <ToggleLineContainer>
        <ToggleContainer
          toggleOn={valueToUse}
          onClick={() =>
            onToggle
              ? onToggle(!valueToUse)
              : handleChange({
                  target: {
                    value: !valueToUse,
                    name: field.name,
                  },
                })
          }
        >
          <ToggleButton>{valueToUse && '✔'}</ToggleButton>
        </ToggleContainer>
        {valueToUse ? labelB : labelA}
      </ToggleLineContainer>
    </Container>
  );
};

export default ToggleBtn;
