import { gql } from '@apollo/client';

const GET_ORG = gql`
  query getOrg(
    $id: ID!
    $take: Int!
    $skip: Int!
    $query: QueryAssetClasses
    $order: [[String]]
  ) {
    getOrg(id: $id) {
      assetClassesPaged(
        pagination: { take: $take, skip: $skip }
        query: $query
        order: $order
      ) {
        data {
          id
          name
          type
          isLink
          siteAllocations {
            allocation
          }
        }
        pagination {
          take
          skip
          total
        }
      }
    }
  }
`;

const GET_META = gql`
  query getMeta($id: ID!) {
    org: getOrg(id: $id) {
      assets {
        assetClassId
        status
      }
      sites {
        id
        name
      }
    }
    types: __type(name: "AssetTypeEnums") {
      enumValues {
        name
      }
    }
  }
`;

const DELETE = gql`
  mutation delete($id: ID!) {
    deleteAssetClass(id: $id)
  }
`;

export { GET_META, GET_ORG, DELETE };
