import React, { Fragment } from 'react';
import { ModalBackdrop, ModalContainer, Exit } from './styles';

const Modal = ({ open, children, height, width, close }) => {
  if (!open) {
    return null;
  }
  return (
    <Fragment>
      <ModalBackdrop onClick={close} />
      <ModalContainer height={height} width={width}>
        <Exit onClick={close} />
        {children}
      </ModalContainer>
    </Fragment>
  );
};

export default Modal;
