import { useQuery } from '@apollo/client';
import React from 'react';
import {
  convertEnumObject,
  PageError,
  PageLoading,
  TableView,
} from '../../../../../';
import { shapeData } from './functions';
import { GET_META, GET_STREAM } from './gql';
import headings from './headings';

const TPSEventStreamList = ({ id }) => {
  const { data, error } = useQuery(GET_META);

  if (error) {
    return <PageError error={error} />;
  }
  if (data) {
    const filterOptions = [
      {
        name: 'source',
        type: 'dropdown',
        placeholder: 'Source',
        options: convertEnumObject({
          __type: data.sources,
        }),
      },
      {
        name: 'eventType',
        type: 'dropdown',
        placeholder: 'Event Type',
        options: convertEnumObject({
          __type: data.types,
        }),
      },
      {
        name: 'dateRange',
        type: 'date',
        placeholder: 'Date',
        noTypeIn: true,
      },
    ];
    return (
      <TableView
        gql={GET_STREAM}
        gqlVariables={{ id }}
        queryName="getAsset"
        paginationObject="thirdPartyStreamEvents"
        filterOptions={filterOptions}
        initialOrder={[['timestamp', 'DESC']]}
        headings={headings}
        dataShapeFunction={shapeData}
        heading={`Assets`}
        description="stream"
        noActions
        noHeader
      />
    );
  }
  return <PageLoading />;
};

export default TPSEventStreamList;
