import { gql } from '@apollo/client';

const GET_GLOBAL_LOCATIONS = gql`
  query getLocations {
    getGlobalLocations(
      pagination: { take: 100000000, skip: 0 }
      order: [["name", "ASC"]]
    ) {
      data {
        id
        name
        reference
        entrance {
          lat
          lng
        }
      }
    }
  }
`;

export { GET_GLOBAL_LOCATIONS };
