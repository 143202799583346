import styled from 'styled-components';

const FormArea = styled.div`
  display: grid;
  align-items: ${({ align }) => align || null};
  width: ${({ fitWidth, width, wide, auto }) =>
    fitWidth ? '100%' : width || auto ? 'auto' : wide ? '75vw' : '52rem'};
  grid-template-columns: ${({ columns, auto, gridStyling }) =>
    gridStyling ||
    (columns
      ? [...Array(columns)].map(() => (auto ? 'auto' : '1fr')).join(' ')
      : auto
      ? 'auto'
      : '1fr')};
  grid-column-gap: 20px;
  display: ${({ display }) => display || null};
`;

export default FormArea;
