import { lighten } from 'polished';
import styled, { css } from 'styled-components';
import { TOP_BAR } from '../config';

const TimelineTooltipPoint = styled.div`
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  position: absolute;
  ${({ top, left }) => css`
    top: ${top - 20}px;
    left: ${left}px;
  `}
`;

const TimelineContainer = styled.div`
  grid-area: timeline;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  font-size: 0.7rem;
  position: relative;
  > div {
    > svg {
      cursor: move;
      width: 100%;
      height: 100%;
      & rect,
      circle,
      text {
        cursor: pointer;
      }
    }
  }
`;

const TimelineOptions = styled.div`
  width: 100%;
  height: ${TOP_BAR}px;
  background: ${({ theme }) => theme.colors.tmPrimary};
  display: flex;
`;

const TimelineOption = styled.div`
  cursor: pointer;
  width: 100px;
  border-right: 1px solid ${({ theme }) => theme.colors.white};
  padding: 0 0.3rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.2rem;
  font-size: 0.6rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  & svg {
    width: 14px;
    height: 14px;
  }
  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
  align-items: center;
  :hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.tmPrimary)};
  }
  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ theme }) => lighten(0.1, theme.colors.tmPrimary)};
    `}
`;

export {
  TimelineContainer,
  TimelineOption,
  TimelineOptions,
  TimelineTooltipPoint,
};
