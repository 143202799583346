import styled from 'styled-components';
import { lighten } from 'polished';

const ListSectionHeader = styled.li`
  font-weight: bold;
  font-size: 1rem;
  padding: 10px;
  background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
`;

export default ListSectionHeader;
