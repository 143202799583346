import { gql } from '@apollo/client';

const GET_ORG = gql`
  query getOrg(
    $id: ID!
    $take: Int!
    $skip: Int!
    $query: QueryCarriers
    $order: [[String]]
  ) {
    getOrg(id: $id) {
      carriersPaged(
        pagination: { take: $take, skip: $skip }
        query: $query
        order: $order
      ) {
        data {
          id
          name
          vatNumber
          reference
          masterCarrierId
          isMasterCarrierLinkEnabled
          isFuelEnabled
        }
        pagination {
          skip
          take
          total
        }
      }
    }
  }
`;
export { GET_ORG };
