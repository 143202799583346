import styled from 'styled-components';

const Line = styled.div`
  position: absolute;
  height: 3px;
  width: ${({ width }) => width || '100%'};
  top: ${({ top }) => `${top < 100 && top > 0 ? top : -100}%`};
  background-color: ${({ theme }) => theme.colors.success};
  z-index: 100;
  opacity: 0.7;
`;
export { Line };
