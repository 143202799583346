import * as yup from 'yup';

const validationSchema = yup.object({
  siteId: yup.string().required(),
  name: yup.string().required(),
  reference: yup.string().required(),
  locality: yup.string().required(),
  startTime: yup.date().required(),
  isDefaultTime: yup.boolean().required(),
  shape: yup.object({
    type: yup.string().required(),
    data: yup.object().required(),
  }),
  color: yup.string().required(),
});

export default validationSchema;
