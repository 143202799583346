import React from 'react';
import { ActionButton, ActionRowContainer, Link } from './styles';

const ActionRow = ({
  actionLink,
  actionLinkName,
  ActionModal,
  actionModalDimensions,
  noActions,
  toggleModal,
  setModalContent,
  setDimensions,
  clearModal,
  refetch,
}) => {
  return (
    <ActionRowContainer>
      {!noActions &&
        (ActionModal ? (
          <ActionButton
            onClick={() => {
              actionModalDimensions && setDimensions(actionModalDimensions);
              setModalContent(
                <ActionModal close={clearModal} refetch={refetch} />,
              );
              toggleModal();
            }}
          >
            {actionLinkName}
          </ActionButton>
        ) : (
          <Link to={actionLink}>
            <ActionButton>{actionLinkName}</ActionButton>
          </Link>
        ))}
    </ActionRowContainer>
  );
};

export default ActionRow;
