import { useFormikContext } from 'formik';
import Tooltip from 'rc-tooltip';
import React, { useCallback } from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import { CancelWhiteSmall, RowFlex, theme } from '../../styles';

const Value = styled.div`
  cursor: pointer;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  font-weight: bold;
  padding: 0.2rem;
  display: flex;
  font-size: 0.65rem;
  align-items: center;
  margin-right: 0.3rem;
  > svg {
    margin-left: 0.3rem;
  }
`;

const Count = styled(Value)`
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 100px;
  justify-content: center;
`;

const Label = styled.div`
  font-size: 0.75rem;
  color: ${theme.colors.darkGrey};
  margin-bottom: 5px;
`;

let MultiSelect = ({
  display,
  id,
  field,
  labelText,
  returnFullObject,
  noMoreItemsText,
  options,
  value,
  onChange,
  noBorders,
  placeholder = 'Select...',
  disabled,
  ...props
}) => {
  const ValueContainer = useCallback(
    ({ children, getValue, setValue, ...valProps }) => {
      const values = getValue();
      const removeValue = (value) =>
        setValue(values.filter((val) => val.value !== value));
      const valueLength = values.length;
      const showValues = valueLength <= 1;
      return (
        <components.ValueContainer {...valProps}>
          {!valProps.selectProps.inputValue &&
            (valueLength === 0 ? (
              placeholder
            ) : showValues ? (
              values.map((v) => (
                <Value
                  key={v.value}
                  onClick={() => {
                    removeValue(v.value);
                  }}
                >
                  {v.label}
                  <CancelWhiteSmall />
                </Value>
              ))
            ) : (
              <Tooltip
                overlayStyle={{ zIndex: 10000000000 }}
                destroyTooltipOnHide
                overlay={
                  <div>
                    {values.map((v) => (
                      <RowFlex expand>
                        <p key={v.value}>{v.label}</p>
                        <CancelWhiteSmall
                          onClick={(e) => {
                            e.preventDefault();
                            removeValue(v.value);
                          }}
                        />
                      </RowFlex>
                    ))}
                  </div>
                }
              >
                <Count>{valueLength}</Count>
              </Tooltip>
            ))}
          {React.Children.map(children, (child) => {
            return child && child.type === components.Input ? child : null;
          })}
        </components.ValueContainer>
      );
    },
    [placeholder],
  );
  const implementStyling = (provided) => ({ ...provided, fontSize: '0.75rem' });
  const styles = {
    control: (provided) => {
      return {
        ...provided,
        height: '40px',
        boxShadow: 'none',
        border: 'none',
        borderRadius: 0,
        borderBottom: noBorders
          ? 'none'
          : `1px solid ${theme.colors.mediumGrey}`,
        '&:hover': {
          borderBottom: noBorders
            ? 'none'
            : `1px solid ${theme.colors.mediumGrey}`,
        },
      };
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        display: 'none',
      };
    },
    placeholder: implementStyling,
    valueContainer: implementStyling,
    singleValue: implementStyling,
    option: (provided, state) => {
      return {
        ...provided,
        cursor: 'pointer',
        fontSize: '0.75rem',
        background: state.isSelected ? theme.colors.primary : 'none',
        '&:hover': {
          background: state.isSelected
            ? theme.colors.primary
            : theme.colors.lightGrey,
        },
      };
    },
    menu: (provided) => {
      return { ...provided, zIndex: 10000000 };
    },
    container: (provided) => ({ ...provided, width: '100%' }),
  };
  const realValue = options.filter((i) =>
    ((field ? field.value : value) || []).includes(i.value),
  );
  const formikContext = useFormikContext();

  const selectComponent = field ? (
    <Select
      isMulti={true}
      closeMenuOnSelect={false}
      noOptionsMessage={() => noMoreItemsText}
      onChange={(v) => {
        const value = returnFullObject
          ? (v || []).map((item) => ({
              [display || 'label']: item.label,
              [id || 'value']: item.value,
            }))
          : (v || []).map((item) => item.value);
        onChange?.(value);
        formikContext.handleChange({
          target: {
            value,
            name: field.name,
          },
        });
      }}
      value={realValue}
      options={options}
      components={{ ValueContainer }}
      styles={styles}
      isDisabled={disabled}
      {...props}
    />
  ) : (
    <Select
      isMulti={true}
      closeMenuOnSelect={false}
      noOptionsMessage={() => noMoreItemsText}
      onChange={(v) => onChange(returnFullObject ? v : v.map((vv) => vv.value))}
      value={realValue}
      styles={styles}
      options={options}
      components={{ ValueContainer }}
      isDisabled={disabled}
      {...props}
    />
  );

  return (
    <div>
      {labelText && <Label>{labelText}</Label>}
      {selectComponent}
    </div>
  );
};

MultiSelect = styled(MultiSelect)`
  .css-12jo7m5 {
    font-size: 0.875rem;
  }
`;

export default MultiSelect;
