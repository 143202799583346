import { flatten } from 'ramda';
import React, { useContext } from 'react';
import {
  convertEnumString,
  DocumentViewModal,
  EditLink,
  formatCurrency,
  formatDateTime,
  formatNumber,
  generateLinkWithParams,
  linkPlaceholders,
  ModalContext,
  PageContainer,
  Table,
  Th,
} from '../../../../../';
import { DivWrapper, ViewIcon } from '../styles';

const TyreTransactions = ({ data, route, linkParams, style = {} }) => {
  const { setModalContent, toggleModal } = useContext(ModalContext);
  return (
    <PageContainer height={'70vh'} align="end" {...style}>
      <Table>
        <thead>
          <tr>
            <Th noHover>Installed</Th>
            <Th noHover>Type</Th>
            <Th noHover>Position</Th>
            <Th noHover>Brand</Th>
            <Th noHover>Model</Th>
            <Th noHover>Cost</Th>
            <Th noHover>Odometer</Th>
            <Th noHover>Documents</Th>
            <Th noHover />
          </tr>
        </thead>
        <tbody>
          {flatten(
            data.map((d) => {
              return d.tyres.map((tyre, tIdx) => {
                return (
                  <tr key={d.id + tIdx}>
                    <td>
                      <p>{formatDateTime(d.transactionDate, null, false)}</p>
                    </td>
                    <td>
                      <p>{convertEnumString(tyre.transactionType)}</p>
                    </td>
                    <td>
                      <p>{tyre.position}</p>
                    </td>
                    <td>
                      <p>{tyre.brand}</p>
                    </td>
                    <td>
                      <p>{tyre.model}</p>
                    </td>
                    <td>
                      <p>{formatCurrency(d.invoiceAmountExcl)}</p>
                    </td>
                    <td>
                      <p>{formatNumber(d.quantity)}</p>
                    </td>
                    <td style={{ width: '20px' }}>
                      <DivWrapper
                        onClick={() => {
                          toggleModal();
                          setModalContent(
                            <DocumentViewModal docs={d.documents} />,
                          );
                        }}
                      >
                        <ViewIcon />
                      </DivWrapper>
                    </td>
                    <td style={{ width: '20px' }}>
                      <p>
                        {route && (
                          <EditLink
                            to={generateLinkWithParams(route, {
                              ...linkParams,
                              [linkPlaceholders.fleetTransactionId]: d.id,
                            })}
                            target="_blank"
                          >
                            Edit
                          </EditLink>
                        )}
                      </p>
                    </td>
                  </tr>
                );
              });
            }),
          )}
        </tbody>
      </Table>
    </PageContainer>
  );
};

export default TyreTransactions;
