import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  deleteS3Files,
  getURLParam,
  PageContainer,
  PageError,
  PageHeading,
  PageLoading,
  uploadS3File,
  useAlerts,
  useLoading,
  useLoadingContext,
} from '../../../../';
import ADNForm from '../ADNForm';
import validationSchema from '../validationSchema';
import { GET_DATA, UPDATE_ADN } from './gql';

const UpdateADN = withRouter(({ match, isRoute }) => {
  const id = getURLParam(match, 'adnId');
  const { alertSuccess, onError } = useAlerts();
  const { setLoading } = useLoadingContext();

  const { data, error, refetch } = useQuery(GET_DATA, { variables: { id } });

  const [updateADNMutation, { loading }] = useMutation(UPDATE_ADN);

  useLoading(loading);

  const mutate = async (values, initialImage) => {
    const image = values.bannerImage;
    const id = values.orgId || values.carrierId || values.principalId;
    const input = { ...values, bannerImage: image.data };
    if (image.fileType) {
      const buf = Buffer.from(
        image.data.replace(/^data:image\/\w+;base64,/, ''),
        'base64',
      );
      const imgName = `orgs/${id}/identity/adn_banners/${values.event}.${
        image.fileType.split('/')[1]
      }`;
      await uploadS3File(buf, imgName, {
        ContentEncoding: 'base64',
        ContentType: image.fileType,
      });
      input.bannerImage = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${imgName}`;
    }
    if (!image.data && initialImage) {
      await deleteS3Files([
        `orgs/${id}/identity/adn_banners/${values.event}.png`,
        `orgs/${id}/identity/adn_banners/${values.event}.jpg`,
        `orgs/${id}/identity/adn_banners/${values.event}.jpeg`,
      ]);
    }
    await updateADNMutation({ variables: { input } });
  };

  if (error) return <PageError error={error} />;
  if (!data) return <PageLoading />;

  const { getADN } = data;
  const initialValues = {
    ...getADN,
    bannerImage: { data: getADN.bannerImage, fileType: '' },
  };
  const formikSchema = {
    initialValues,
    validationSchema,
  };

  return (
    <PageContainer>
      <PageHeading heading={`ADNs`} description={'Update ADN'} />
      <Formik
        enableReinitialize
        validateOnMount
        {...formikSchema}
        onSubmit={(values) => {
          setLoading(true);
          mutate(values, getADN.bannerImage)
            .then(() => {
              alertSuccess('ADN has been successfully updated');
              refetch();
            })
            .catch(onError)
            .finally(() => setLoading(false));
        }}
      >
        <ADNForm isEdit isRoute={isRoute} />
      </Formik>
    </PageContainer>
  );
});

export default UpdateADN;
