import * as yup from 'yup';

const communicationEventSchema = yup.object({
  event: yup.string().required(),
  email: yup.string().required(),
  sms: yup.string().required(),
});

const validationSchema = yup.object({
  orgId: yup.string().notRequired().nullable(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().nullable(),
  mobile: yup.string().when('email', {
    is: (m) => !m,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
  comsEvents: yup.array().of(communicationEventSchema).required(),
});

export default validationSchema;
