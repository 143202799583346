import {
  Add32,
  CheckmarkOutline20,
  RadioButton20,
  Restart32,
  Warning20,
} from '@carbon/icons-react';
import { lighten } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
  width: ${({ dimensions }) => dimensions};
`;

const CheckBox = styled(CheckmarkOutline20)`
  fill: ${({ theme }) => theme.colors.primary};
  padding-right: 3px;
`;
const IncorrectCheckBox = styled(Warning20)`
  fill: ${({ theme }) => theme.colors.danger};
  padding-right: 3px;
`;
const UploadBtn = styled.button`
  border-radius: 50%;
  color: white;
  width: 100%;
  height: 100%;
  font-size: 100%;
  font-weight: 100;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  &:hover {
    background-color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
  }
`;
const AddBtn = styled(Add32)`
  fill: white;
`;
const Restart = styled(Restart32)`
  fill: white;
`;
const EmptyCheckBox = styled(RadioButton20)`
  padding-right: 3px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  margin-bottom: 10px;
`;

const ImageContainer = styled.div`
  width: ${({ dimensions }) => dimensions};
  height: ${({ dimensions }) => dimensions};
  position: relative;
  background: ${({ value, theme }) =>
    value
      ? `url(${value}) no-repeat center center / cover`
      : lighten(0.1, theme.colors.lightGrey)};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingOverlay = styled.div`
  background-color: #ccc;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ dimensions }) => dimensions};
  height: ${({ dimensions }) => dimensions};
  z-index: 10;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const Remove = styled.button`
  background: none;
  border: none;
  font-weight: 800;
  font-size: 0.75rem;
  cursor: pointer;
  outline: none;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
  }
  ::-webkit-file-upload-button {
    display: none;
  }
`;

const UploadInput = styled.input`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  opacity: 0;
  z-index: 4;
  ::-webkit-file-upload-button {
    display: none;
  }
`;

const InputContainer = styled.div`
  border-radius: 50%;
  width: 20%;
  height: 20%;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    & button {
      background: ${({ theme }) => lighten(0.1, theme.colors.primary)};
    }
  }
`;

const VerifyLine = styled.p`
  margin-bottom: 2px;
  font-size: 0.75rem;
  color: ${({ color, theme }) => color || theme.colors.darkGrey};
  display: flex;
  align-items: center;
`;

export {
  LoadingOverlay,
  CheckBox,
  Container,
  Header,
  UploadInput,
  InputContainer,
  ImageContainer,
  UploadBtn,
  Remove,
  Restart,
  EmptyCheckBox,
  AddBtn,
  IncorrectCheckBox,
  VerifyLine,
};
