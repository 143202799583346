import { orderBy } from 'lodash';

const appsArray = orderBy(
  [
    { value: 'gatekeeper', label: 'Gatekeeper', disabled: true },
    { value: 'fleet', label: 'Fleet', disabled: true },
    { value: 'fuel', label: 'Fuel', disabled: true },
    { value: 'link_yard', label: 'Link - Yard', disabled: true },
    { value: 'link_outbound', label: 'Link - Outbound', disabled: true },
    { value: 'link_inbound', label: 'Link - Inbound', disabled: true },
    { value: 'driver', label: 'Driver', disabled: false },
    { value: 'crew', label: 'Crew', disabled: false },
    { value: 'loader', label: 'Loader', disabled: false },
  ],
  'label',
  'asc',
);

const adminApps = [
  'gatekeeper',
  'fleet',
  'fuel',
  'link_yard',
  'link_outbound',
  'link_inbound',
];
const fleetApps = [
  { value: 'driver', label: 'Driver' },
  { value: 'crew', label: 'Crew' },
  { value: 'loader', label: 'Loader' },
];
export { appsArray, fleetApps, adminApps };
