import styled from 'styled-components';

const ToolTipItemContainer = styled.div`
  display: flex;
  max-width: 500px;
  flex-direction: column;
`;

const ToolTipItemRows = styled.div`
  display: flex;
  flex-direction: row;
`;

const ToolTipRowHeader = styled.h6`
  width: 100px;
  font-size: 0.75rem;
`;

const ToolTipRowValue = styled.div`
  display: inline-block;
  font-size: 0.75rem;
  word-wrap: break-word;
`;

export {
  ToolTipItemContainer,
  ToolTipItemRows,
  ToolTipRowHeader,
  ToolTipRowValue,
};
