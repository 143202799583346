import { gql } from '@apollo/client';
const UPDATE_ASSET = gql`
  mutation updateAsset($input: AssetUpdateInput) {
    updateAsset(input: $input) {
      id
      onroadId
      isRearTrailer
      type
      assetClassId
      year
      serviceInterval
      fleetNumber
      engineNo
      status
      hasTrailers
      licenceNumber
      registrationNumber
      make
      model
      colour
      vin
      glReference
      compartments
      vehicleMass
      serial
      fuelType
      tankCapacity
      volume
      units
      pallets
      litres
      seals
      numTyres
      benchmark
    }
  }
`;

const ALLOCATE_SITES = gql`
  mutation manageAssetSites($input: IdAddRemove!) {
    manageAssetSites(input: $input) {
      id
      sites {
        id
        name
      }
    }
  }
`;

const ALLOCATE_ASSET_GROUPS = gql`
  mutation manageAssetGroups($input: IdAddRemove!) {
    manageAssetGroups(input: $input) {
      id
      assetGroups {
        id
        name
      }
    }
  }
`;

const GET_ASSET = gql`
  query getAsset($id: ID!) {
    getAsset(id: $id) {
      onroadId
      glReference
      org {
        assetClasses {
          id
          name
          isLink
          type
        }
        assetGroupsPaged(pagination: { take: 1000000, skip: 0 }) {
          data {
            id
            name
          }
        }
        sites {
          id
          name
        }
      }
      isRearTrailer
      fleetPermits(pagination: { take: 1000, skip: 0 }) {
        data {
          id
          permitNumber
          country
          validFrom
          expiryDate
          documents {
            name
            path
          }
          createdOn
          createdBy {
            firstName
            lastName
          }
          rawData
        }
      }
      id
      status
      vin
      hasTrailers
      vehicleMass
      serial
      licenceNumber
      fuelType
      tankCapacity
      assetClassId
      type
      make
      model
      colour
      year
      volume
      units
      pallets
      litres
      seals
      engineNo
      fleetNumber
      numTyres
      serviceInterval
      benchmark
      tyreTransactions {
        id
        transactionDate
        quantity
        tyres {
          serialNumber
          transactionType
          position
          brand
          model
          size
          tread
        }
        documents {
          name
          path
        }
      }
      masterAsset {
        vin
        licenceNumber
        colour
        make
        model
        registrationNumber
        engineNumber
      }
      compartments
      sites {
        id
        name
      }
      licences {
        status
        expiryDate
        licenseNo
        registration
        createdAt
        createdBy {
          firstName
          lastName
        }
      }
      assetGroups {
        id
        name
      }
    }
  }
`;

const GET_ORG = gql`
  query getOrg($id: ID!) {
    getOrg(id: $id) {
      sites {
        id
        name
      }
    }
  }
`;
export {
  ALLOCATE_SITES,
  GET_ASSET,
  GET_ORG,
  UPDATE_ASSET,
  ALLOCATE_ASSET_GROUPS,
};
