import React from 'react';
import { Heading, HeadingDescription } from './styles';

const PageHeading = ({ heading, description, style }) => (
  <div style={style}>
    <Heading>{heading}</Heading>
    <HeadingDescription>{description}</HeadingDescription>
  </div>
);

export default PageHeading;
