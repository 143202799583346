import { NetworkStatus, useQuery } from '@apollo/client';
import { noop } from 'lodash';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { localStorageKeys } from '../../config';
import { ModalContext } from '../../context';
import { PaginationContainer } from '../../styles';
import PageError from '../PageError';
import PageHeading from '../PageHeading';
import PageLoading from '../PageLoading';
import Pagination from '../Pagination';
import ActionRow from './ActionRow';
import FilterOptions from './FilterOptions';
import { Top, TopBar } from './styles';
import Table from './Table';

const getMemoryFilters = () => {
  return JSON.parse(
    localStorage.getItem(localStorageKeys.tableFilters) || '{}',
  );
};

const getMemoryFilter = (filterKey = '') => getMemoryFilters()[filterKey] || {};

const TableViewForGql = ({
  actionLink,
  actionLinkName,
  ActionModal,
  actionModalDimensions,
  additionalLinkParam,
  additionalPageSizes,
  dataShapeFunction,
  defaultTake,
  deleteCondition,
  deleteDescription,
  deleteHeader,
  deleteRow,
  description,
  enter,
  EntityContext,
  filterOptions = [],
  fixedQueries = {},
  gql,
  gqlVariables,
  heading,
  headings,
  initialLinkParamObject,
  initialOrder,
  keepLoading,
  navLinks,
  noActions,
  noHeader = false,
  onCompleted = noop,
  paginationObject,
  queryName,
  filterMemoryKey,
}) => {
  const { toggleModal, setModalContent, setDimensions, clearModal } =
    useContext(ModalContext);

  const [data, setData] = useState();
  const [take, setTake] = useState(defaultTake || 50);
  const [skip, setSkip] = useState(0);
  const memoryFilter = getMemoryFilter(filterMemoryKey);
  const [query, setQuery] = useState(() => {
    return filterOptions.reduce((acc, fo) => {
      const value = memoryFilter[fo.name] || fo.initialValue;
      if (value) {
        acc[fo.name] = value;
      }
      return acc;
    }, {});
  });

  useEffect(() => {
    if (filterMemoryKey) {
      const memoryFilters = getMemoryFilters();
      memoryFilters[filterMemoryKey] = query || {};
      localStorage.setItem(
        localStorageKeys.tableFilters,
        JSON.stringify(memoryFilters),
      );
    }
  }, [filterMemoryKey, query]);

  const [order, setOrder] = useState(initialOrder || []);

  const { loading, error, refetch, networkStatus } = useQuery(gql, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...(gqlVariables || {}),
      take,
      skip,
      query: { ...fixedQueries, ...query },
      order,
    },
    onCompleted: (d) => {
      setData(d);
      onCompleted(d);
    },
  });

  useEffect(() => {
    const tableRefreshInterval = setInterval(refetch, 600000);
    return () => clearInterval(tableRefreshInterval);
  }, [refetch]);

  if (error) {
    return <PageError error={error} />;
  }
  if (keepLoading) {
    return <PageLoading />;
  }
  if (data && data[queryName]) {
    const payload =
      !paginationObject || paginationObject === queryName
        ? data[queryName]
        : data[queryName][paginationObject];
    if (payload) {
      const shapedData = dataShapeFunction(data[queryName]);
      const showFilterOptionsOnSameLine =
        noActions && filterOptions && filterOptions.length <= 3;
      const showFilterOptionsOnSeparateLine =
        (!noActions && filterOptions) ||
        (filterOptions && filterOptions.length > 3);
      const totalItems = !paginationObject ? 0 : payload.pagination.total;
      const actionRow = (
        <ActionRow
          actionLink={actionLink}
          actionLinkName={actionLinkName}
          ActionModal={ActionModal}
          actionModalDimensions={actionModalDimensions}
          noActions={noActions}
          toggleModal={toggleModal}
          setModalContent={setModalContent}
          setDimensions={setDimensions}
          clearModal={clearModal}
          refetch={refetch}
        />
      );
      const filterRow = (
        <FilterOptions
          refetch={refetch}
          filterOptions={filterOptions}
          query={query}
          setQuery={setQuery}
          setSkip={setSkip}
          memoryFilter={memoryFilter}
        />
      );
      return (
        <Fragment>
          <Top>
            <TopBar>
              {!noHeader && (
                <PageHeading
                  heading={
                    typeof heading === 'function'
                      ? heading(data[queryName])
                      : heading
                  }
                  description={description}
                />
              )}
              {!filterOptions && actionRow}
              {showFilterOptionsOnSameLine && filterRow}
            </TopBar>
            {showFilterOptionsOnSeparateLine && (
              <TopBar>
                {filterRow} {!noActions && actionRow}
              </TopBar>
            )}
          </Top>
          {paginationObject && totalItems > 0 && (
            <PaginationContainer>
              <Pagination
                page={(take + skip) / take}
                pageSize={take}
                totalItems={totalItems}
                pageSizes={[10, 20, 30, 40, 50].concat(
                  additionalPageSizes || [],
                )}
                onChange={({ page, pageSize }) => {
                  setTake(pageSize);
                  setSkip((page - 1) * pageSize);
                }}
              />
            </PaginationContainer>
          )}
          <Table
            enter={enter}
            order={order}
            setOrder={setOrder}
            setSkip={setSkip}
            loading={loading || networkStatus === NetworkStatus.refetch}
            rows={shapedData}
            allData={shapedData}
            headers={headings}
            navLinks={navLinks || []}
            initialLinkParamObject={initialLinkParamObject}
            additionalLinkParam={additionalLinkParam}
            refetch={refetch}
            deleteRow={deleteRow}
            deleteCondition={deleteCondition}
            deleteDescription={deleteDescription}
            deleteHeader={deleteHeader}
            rawData={data[queryName]}
            toggleModal={toggleModal}
            setModalContent={setModalContent}
            setDimensions={setDimensions}
            clearModal={clearModal}
            EntityContext={EntityContext}
          />
        </Fragment>
      );
    }
  }
  return <PageLoading />;
};

export default TableViewForGql;
