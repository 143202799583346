import styled from 'styled-components';

const Heading5 = styled.h5`
  font-size: 0.9rem;
  font-weight: 600;
  margin: 5px 0;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export default Heading5;
