import { gql } from '@apollo/client';

const MANAGE_OPERATOR_SITE_APPS = gql`
  mutation manageOperatorSiteApps($input: OperatorSiteInput!) {
    manageOperatorSiteApps(input: $input)
  }
`;

const MANAGE_OPERATOR_SITES = gql`
  mutation manageOperatorSites($input: OperatorSiteAddRemoveInput!) {
    manageOperatorSites(input: $input)
  }
`;

const GET_ALL_SITES = gql`
  query getGroups {
    getSites: getSites {
      id
      name
      orgId
    }
    getOrgs: getOrgs {
      id
      name
    }
  }
`;

export { MANAGE_OPERATOR_SITE_APPS, MANAGE_OPERATOR_SITES, GET_ALL_SITES };
