import { get } from 'lodash';
import { clone, flatten } from 'ramda';
import React from 'react';
import {
  DurationRefresher,
  EditLink,
  formatDateTime,
  formatNumber,
  formatTimeFromSeconds,
  generateLinkWithParams,
  getLoadAssetCurrentPosition,
  getOperatorName,
  linkPlaceholders,
  updateLoadAssetCurrentPosition,
  updateLoadExtrapolation,
} from '../../../../';
import { STATUSES } from './static';
import {
  CurrentPosition,
  ProgressBusy,
  ProgressComplete,
  UnknownStreetViewLink,
} from './styles';

const getVehicle = (v) => {
  if (!v) {
    return '';
  }
  const fleetNumber = v.fleetNumber ? `(${v.fleetNumber})` : '';
  return v ? `${v.licenceNumber} ${fleetNumber}` : '';
};

const shapeLoads = (allLoads = [], linkParams = {}, viewLoadLink = '') => {
  return STATUSES.map((statusPayload) => {
    const loads = allLoads
      .filter(statusPayload.loadDeterminationFunction)
      .map((load) => {
        const currentPosition = getLoadAssetCurrentPosition(load, true);
        const currentActivity = getCurrentActivity(load);
        const currentTrip = getCurrentTrip(load);
        const nextStopId = get(currentTrip, 'destination.stopId');
        const nextStopExtrapolation = get(load, 'extrapolation.stops', []).find(
          (s) => s.stopId === nextStopId,
        );
        const distance =
          nextStopExtrapolation &&
          `${formatNumber(
            Math.round(nextStopExtrapolation.estimatedDistance),
          )} km`;
        const eta =
          nextStopExtrapolation &&
          formatTimeFromSeconds(
            nextStopExtrapolation.estimatedTravelTime / 1000,
            true,
            false,
            true,
          );
        const due =
          nextStopExtrapolation &&
          formatDateTime(
            nextStopExtrapolation.estimatedStart,
            undefined,
            false,
            'dd-MM HH:mm',
          );
        const driver = getOperatorName(get(load, 'allocation.driver'));
        const asset = getVehicle(get(load, 'allocation.vehicle.asset'));
        const currentStopId = get(currentPosition, 'location.stopId');
        const loadActivities = flatten(
          get(load, 'travelPlan.stops', []).map((stop) =>
            stop.entities.map((e) =>
              e.activities.map((a) => ({ ...a, stopId: stop.id })),
            ),
          ),
        );
        const activityIdsOfCurrentStop = loadActivities
          .filter((a) => a.stopId === currentStopId)
          .map((a) => a.id);
        const executedActivities = get(load, 'execution.activities', []).filter(
          (a) => activityIdsOfCurrentStop.includes(a.activityId),
        );
        const progress = currentActivity ? (
          <p>
            <ProgressBusy />
          </p>
        ) : currentStopId && executedActivities.length > 0 ? (
          <ProgressComplete />
        ) : null;
        const drivingIndicator = (
          <CurrentPosition currentPosition={currentPosition} />
        );
        const destination = get(load, 'execution.trips', []).find(
          (trip) => trip.state === 'IN_PROGRESS',
        )?.destination?.locationName;
        const location = get(
          currentActivity,
          'plan.stop.location.data.name',
          get(
            currentPosition,
            'location.name',
            get(currentPosition, 'gps') ? (
              <UnknownStreetViewLink
                href={`https://www.google.com/maps?q&layer=c&cbll=${currentPosition.gps.lat},${currentPosition.gps.lng}&cbp=8,0,0,0,0`}
                target="_blank"
              >
                Unknown
              </UnknownStreetViewLink>
            ) : (
              <p>Unknown</p>
            ),
          ),
        );
        const arrivalTimestamp = get(
          currentActivity,
          'execution.started',
          get(currentPosition, 'location.dateEntered'),
        );
        const arrivalTime = formatDateTime(
          arrivalTimestamp,
          undefined,
          false,
          'dd-MM HH:mm',
        );
        const duration = arrivalTimestamp && (
          <DurationRefresher
            initialTime={new Date(arrivalTimestamp)}
            overTimeConfig={(mins) => mins / 60 > 5}
          />
        );
        const shipper = load?.org?.name;
        const reference = (
          <EditLink
            to={generateLinkWithParams(viewLoadLink, {
              ...linkParams,
              [linkPlaceholders.loadId]: load.id,
            })}
            target="_blank"
          >
            {load.reference}
          </EditLink>
        );
        return {
          ...load,
          driver,
          asset,
          destination,
          drivingIndicator,
          location,
          arrivalTime,
          shipper,
          duration,
          progress,
          reference,
          distance,
          due,
          eta,
        };
      });
    return { ...statusPayload, loads };
  });
};

const handleLoadStatusUpdateEvent = ({ payload, getLoad, setLoads }) => {
  const { loadId, status } = payload;
  if (status === 'ACTIVE') {
    getLoad({ variables: { id: loadId } });
  }
  if (status === 'COMPLETED') {
    setLoads((prev) => prev.filter((l) => l.id !== loadId));
  }
};

const handleLoadExecutionEvent = ({ payload, setLoads }) => {
  setLoads((prev) =>
    prev.map((load) => {
      if (load.id === payload.loadId) {
        const newLoad = clone(load);
        newLoad.execution = payload;
        return newLoad;
      }
      return load;
    }),
  );
};

const handleLoadTripSnapshotEvent = ({ payload, setLoads }) => {
  const { loadId } = payload.context;
  setLoads((prev) =>
    prev.map((load) => {
      if (load.id === loadId) {
        return updateLoadAssetCurrentPosition({ load, payload });
      }
      return load;
    }),
  );
};

const handleLoadExtrapolationEvent = ({ payload, setLoads }) => {
  const { loadId } = payload;
  setLoads((prev) =>
    prev.map((load) => {
      if (load.id === loadId) {
        return updateLoadExtrapolation({ load, payload });
      }
      return load;
    }),
  );
};

const getCurrentActivity = (load) => {
  const currentActivity = get(load, 'execution.activities', []).find(
    (a) => a.status === 'STARTED',
  );
  if (currentActivity) {
    const plans = flatten(
      load.travelPlan.stops.map((stop) =>
        stop.entities.map((e) => e.activities.map((a) => ({ stop, ...a }))),
      ),
    );
    const currentActivityPlan = plans.find(
      (a) => a.id === currentActivity.activityId,
    );
    return {
      plan: currentActivityPlan,
      execution: currentActivity,
    };
  }
};

const getCurrentTrip = (load) =>
  get(load, 'execution.trips', []).find((a) => a.state === 'IN_PROGRESS');

const determinePickupOrDropoff = ({ load, type }) => {
  const currentActivity = getCurrentActivity(load);
  if (currentActivity?.plan?.direction === type) {
    return true;
  }
  const currentPosition = getLoadAssetCurrentPosition(load, true);
  if (currentPosition && currentPosition.location) {
    const {
      location: { id: currentLocationId },
    } = currentPosition;
    const allStops = load.travelPlan.stops;
    const currentLocation = allStops.find(
      (stop) => stop.location.data.id === currentLocationId,
    );
    if (currentLocation) {
      const isOfType = currentLocation.entities.some((e) =>
        e.activities.some((a) => a?.direction === type),
      );
      return isOfType;
    }
  }
};

export {
  shapeLoads,
  getVehicle,
  handleLoadStatusUpdateEvent,
  handleLoadExecutionEvent,
  handleLoadTripSnapshotEvent,
  handleLoadExtrapolationEvent,
  determinePickupOrDropoff,
  getCurrentActivity,
};
