const isEven = (num: number) => num % 2 === 0;

const encrypt = (sensitiveData: string = '') => {
  const length = sensitiveData.length;
  let maskedItemCount = Math.ceil(length / 2);
  const slicePoints = Math.floor(maskedItemCount / 2);
  isEven(length) && !isEven(maskedItemCount)
    ? (maskedItemCount += 1)
    : !isEven(length) && isEven(maskedItemCount) && (maskedItemCount -= 1);
  return (
    sensitiveData.slice(0, slicePoints) +
    '*'.repeat(maskedItemCount) +
    sensitiveData.slice(-slicePoints)
  );
};

export { encrypt };
