import React, { Fragment } from 'react';
import styled from 'styled-components';
import InfoBubble from '../InfoBubble';

const Header = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  padding-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
`;

const CompositeFormField = ({ header, children, infoText }) => {
  return (
    <Fragment>
      <HeaderContainer>
        <Header>{header}</Header>
        {infoText && <InfoBubble text={infoText}></InfoBubble>}
      </HeaderContainer>
      <Container>{children}</Container>
    </Fragment>
  );
};

export default CompositeFormField;
