import styled from 'styled-components';
import {
  CheckmarkOutline16,
  RadioButton16,
  CloseOutline16,
} from '@carbon/icons-react';

const CheckBox = styled(CheckmarkOutline16)`
  fill: ${({ theme }) => theme.colors.primary};
  padding-right: 3px;
`;
const IncorrectCheckBox = styled(CloseOutline16)`
  fill: ${({ theme }) => theme.colors.danger};
  padding-right: 3px;
`;
const EmptyCheckBox = styled(RadioButton16)`
  padding-right: 3px;
`;

export { CheckBox, IncorrectCheckBox, EmptyCheckBox };
