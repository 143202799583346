import {
  AddAlt20 as Right,
  DotMark20 as Stuck,
  Search20 as Search,
  SubtractAlt20 as Left,
} from '@carbon/icons-react';
import { concat, equals, prepend, reject } from 'ramda';
import React, { Fragment, useEffect, useState } from 'react';
import { OverflowContainer, theme } from '../../styles';
import { PrimaryButton } from '../Buttons';
import {
  AreaHeader,
  ClearFilter,
  Column,
  Container,
  Heading,
  IconContainer,
  Input,
  InputContainer,
  Item,
  ItemArea,
} from './styles';

const SelectRemoveColumns = ({
  instruction,
  leftHeading,
  rightHeading,
  leftItems,
  rightItems,
  actionHandler,
  withinForm,
  display = 'label',
  id = 'value',
  disableFunction = () => false,
  field = {},
  form = {},
}) => {
  const [filter, setFilter] = useState('');
  const [right, setRight] = useState(rightItems);
  const isTouched = form?.touched?.[field?.name] || form?.submitCount > 0;
  const hasError = isTouched && form?.errors?.[field?.name];
  useEffect(() => {
    if (withinForm) {
      actionHandler(right);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [right]);
  const filteredLeft = leftItems.filter((item) => {
    const filtered = filter
      ? (item[display] || '')
          .toLowerCase()
          .includes(filter.trim().toLowerCase())
      : true;
    const notInRight = !right.some((r) => r[id] === item[id]);
    return filtered && notInRight;
  });

  return (
    <Fragment>
      <div>
        <p
          style={{
            paddingBottom: '20px',
            color: hasError && theme.colors.danger,
          }}
        >
          {instruction}
        </p>
        <Container>
          <Column>
            <AreaHeader>
              <Heading>{leftHeading}</Heading>
              <p
                onClick={() => {
                  setRight(concat(right, filteredLeft));
                }}
              >
                Add All
              </p>
            </AreaHeader>

            <ItemArea withFilter>
              <InputContainer>
                <Search />
                <Input
                  placeholder="Search..."
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
                <ClearFilter onClick={() => setFilter('')} />
              </InputContainer>
              <OverflowContainer>
                {filteredLeft.map((item) => {
                  return (
                    <Item
                      initial={rightItems.some((i) => i[id] === item[id])}
                      key={item[id]}
                      onClick={() => {
                        setRight(prepend(item, right));
                      }}
                    >
                      <IconContainer>
                        <Right />
                      </IconContainer>
                      <p>
                        {item[display] === null
                          ? 'Name Missing'
                          : item[display]}
                      </p>
                    </Item>
                  );
                })}
              </OverflowContainer>
            </ItemArea>
          </Column>
          <Column>
            <AreaHeader>
              <Heading>{rightHeading}</Heading>
              <p
                onClick={() => {
                  const disabledRight = right.filter((r) =>
                    disableFunction(r[id]),
                  );
                  setRight(disabledRight);
                }}
              >
                Remove All
              </p>
            </AreaHeader>
            <ItemArea>
              {right.map((item) => {
                const isDisabled = disableFunction(item[id]);
                return (
                  <Item
                    isDisabled={isDisabled}
                    initial={rightItems.some((i) => i[id] === item[id])}
                    key={item[id]}
                    onClick={() => {
                      if (!isDisabled) {
                        setRight(
                          reject((i) => i[id] === item[id]),
                          right,
                        );
                      }
                    }}
                  >
                    <IconContainer>
                      {!isDisabled ? <Left /> : <Stuck />}
                    </IconContainer>
                    <p>
                      {item[display] === null ? 'Name Missing' : item[display]}
                    </p>
                  </Item>
                );
              })}
            </ItemArea>
          </Column>
        </Container>
      </div>
      {!withinForm && (
        <PrimaryButton
          onClick={() => actionHandler(right)}
          style={{ width: '280px' }}
          disabled={equals(rightItems, right)}
        >
          Allocate
        </PrimaryButton>
      )}
    </Fragment>
  );
};

export default SelectRemoveColumns;
