import styled from 'styled-components';

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${({ withMargin }) => withMargin && '1rem'};
`;

const TabContainer = styled.div`
  cursor: pointer;
  width: 150px;
  height: 3rem;
  border-bottom: 2px solid
    ${({ theme, active }) => theme.colors[active ? 'primary' : 'lightGrey']};
  font-size: 0.75rem;
  padding: 0.5rem 1rem 0.5rem 0;
  color: ${({ theme, disabled }) => disabled && theme.colors.lightGrey};
  display: flex;
  align-items: center;
`;

export { TabsContainer, TabContainer };
