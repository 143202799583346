import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1rem;
`;

const Left = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 1rem;
`;

const ZoneLabel = styled.div`
  padding: 0.5rem;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  font-weight: bold;
`;

export { Container, Left, ZoneLabel };
