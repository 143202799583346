import Tooltip from 'rc-tooltip';
import React, { Fragment, useContext } from 'react';
import {
  CurrentEntityContext,
  CurrentLoggedInUserContext,
  NotificationsContext,
} from '../../../context';
import {
  convertEnumString,
  encrypt,
  formatCurrency,
  formatDateTime,
  formatNumber,
  generateLinkWithParams,
} from '../../../functions';
import {
  EditLink,
  EmailLink,
  Link,
  OnClickLink,
  Table,
  TableBodyContainer,
  Th,
} from '../../../styles';
import Loading from '../../Spinner';
import AuditList from './AuditList';
import DeleteRowModal from './Delete';
import {
  BooleanContainer,
  Color,
  Delete,
  Enter,
  False,
  NoData,
  True,
} from './styles';
import TableHeader from './TableHeader';

const TDWrapper = ({ children, tooltip, row = {} }) => {
  const tooltipDisplay = typeof tooltip === 'function' ? tooltip(row) : tooltip;
  if (tooltipDisplay) {
    return (
      <Tooltip
        placement="left"
        overlay={tooltipDisplay}
        destroyTooltipOnHide
        overlayStyle={{ zIndex: '99999999999' }}
      >
        <td>{children}</td>
      </Tooltip>
    );
  }
  return <td>{children}</td>;
};

const TableComponent = ({
  additionalLinkParam = '',
  clearModal,
  deleteCondition = () => true,
  deleteDescription,
  deleteHeader,
  deleteRow,
  enter,
  EntityContext = CurrentEntityContext,
  headers = [],
  initialLinkParamObject = {},
  loading,
  navLinks = [],
  order,
  rawData,
  refetch,
  rows = [],
  setDimensions,
  setModalContent,
  setOrder,
  setSkip,
  toggleModal,
}) => {
  const notificationsContext = useContext(NotificationsContext);
  const { currentUser } = useContext(CurrentLoggedInUserContext) || {};
  const { currentEntity } = useContext(EntityContext) || {};
  return (
    <TableBodyContainer>
      <Table>
        <colgroup>
          {enter && <col width="2%" />}
          {headers.map((header, idx) => (
            <col key={idx} width={header.isCount ? '7.5%' : 'auto'} />
          ))}
          {navLinks.map((_, idx) => (
            <col key={idx} width={`2%`} />
          ))}
          {deleteRow && <col width={`2%`} />}
        </colgroup>
        <thead>
          <tr>
            {enter && <Th noHover />}
            {headers.map((header) =>
              header.allowOrder ? (
                <TableHeader
                  order={order}
                  setOrder={setOrder}
                  setSkip={setSkip}
                  heading={header.header}
                  key={header.key}
                  id={header.key}
                />
              ) : (
                <Th
                  noHover
                  key={header.header}
                  style={{
                    textAlign: header.isCount ? 'center' : 'left',
                  }}
                >
                  {header.header}
                </Th>
              ),
            )}
            {navLinks.map(() => (
              <Th noHover />
            ))}
            {deleteRow && <Th noHover />}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <NoData
              style={{
                width: '200px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Loading Data... <Loading small />
            </NoData>
          ) : rows.length > 0 ? (
            rows.map((row) => {
              const linkParam = { ...initialLinkParamObject };
              if (Array.isArray(additionalLinkParam)) {
                const allRowData = rows.find((r) => r.id === row.id);
                additionalLinkParam.forEach(({ param, value }) => {
                  linkParam[param] = allRowData[value];
                });
              } else {
                linkParam[additionalLinkParam] = row.id;
              }
              return (
                <tr key={row.id}>
                  {enter && (
                    <td>
                      <EditLink to={generateLinkWithParams(enter, linkParam)}>
                        <BooleanContainer clickable>
                          <Enter />
                        </BooleanContainer>
                      </EditLink>
                    </td>
                  )}
                  {headers.map((header) => {
                    if (header.auditPath) {
                      return (
                        <TDWrapper tooltip="View Audits" key={header.key}>
                          <OnClickLink
                            onClick={() => {
                              setDimensions({ width: 90, height: 70 });
                              setModalContent(
                                <AuditList
                                  id={row.id}
                                  auditGqlQuery={header.auditPath}
                                  auditGqlPath={header.auditGqlPath}
                                />,
                              );
                              toggleModal();
                            }}
                          >
                            {row[header.key]}
                          </OnClickLink>
                        </TDWrapper>
                      );
                    }
                    if (header.OnClickComponent) {
                      return (
                        <TDWrapper
                          tooltip={header.tooltip}
                          key={header.key}
                          row={row}
                        >
                          {(header.condition
                            ? header.condition(row[header.key])
                            : true) && (
                            <header.OnClickComponent value={row[header.key]} />
                          )}
                        </TDWrapper>
                      );
                    }
                    if (header.QrCode) {
                      return (
                        <TDWrapper
                          tooltip={header.tooltip}
                          key={header.key}
                          row={row}
                        >
                          <header.QrCode
                            rawData={rawData}
                            row={row}
                            value={row[header.key]}
                          />
                        </TDWrapper>
                      );
                    }
                    if (header.componentType) {
                      const displayModalOption = header.condition
                        ? header.condition(row, rawData, currentUser)
                        : true;
                      if (header.componentType === 'modal') {
                        return (
                          <TDWrapper
                            tooltip={displayModalOption && header.tooltip}
                            key={header.key}
                            row={row}
                          >
                            {displayModalOption && (
                              <header.ComponentWrapper
                                value={row[header.key]}
                                rawData={rawData}
                                row={row}
                                onClick={() => {
                                  header.dimensions &&
                                    setDimensions(header.dimensions);

                                  setModalContent(
                                    <Fragment>
                                      <header.Component
                                        value={row[header.key]}
                                        row={row}
                                        close={clearModal}
                                        reloadTableData={refetch}
                                        rawData={rawData}
                                        currentUser={currentUser}
                                        currentEntity={currentEntity}
                                        notificationsContext={
                                          notificationsContext
                                        }
                                      />
                                    </Fragment>,
                                  );
                                  toggleModal();
                                }}
                              ></header.ComponentWrapper>
                            )}
                          </TDWrapper>
                        );
                      }

                      if (header.componentType === 'color') {
                        return (
                          <Color key={header.key} color={row[header.key]}>
                            <div />
                          </Color>
                        );
                      }

                      if (header.componentType === 'link') {
                        return (
                          <TDWrapper
                            tooltip={displayModalOption && header.tooltip}
                            key={header.key}
                            row={row}
                          >
                            {displayModalOption && (
                              <Link
                                to={generateLinkWithParams(
                                  header.componentLink,
                                  linkParam,
                                )}
                                target={!header.sameTab && '_blank'}
                              >
                                <header.ComponentWrapper />
                              </Link>
                            )}
                          </TDWrapper>
                        );
                      }
                      if (header.componentType === 'hover') {
                        return (
                          <TDWrapper
                            tooltip={header.tooltip}
                            key={header.key}
                            row={row}
                          >
                            <header.Component
                              row={row}
                              value={row[header.key]}
                            />
                          </TDWrapper>
                        );
                      }
                    }
                    if (header.encrypt) {
                      return (
                        <TDWrapper
                          tooltip={header.tooltip}
                          key={header.key}
                          row={row}
                        >
                          <p>{encrypt(row[header.key])}</p>
                        </TDWrapper>
                      );
                    }
                    if (typeof row[header.key] === 'boolean') {
                      return (
                        <TDWrapper
                          tooltip={header.tooltip}
                          key={header.key}
                          row={row}
                        >
                          <BooleanContainer
                            style={{
                              display: 'flex',
                            }}
                          >
                            {row[header.key] ? <True /> : <False />}
                          </BooleanContainer>
                        </TDWrapper>
                      );
                    }
                    return (
                      <TDWrapper
                        tooltip={header.tooltip}
                        key={header.key}
                        row={row}
                      >
                        {header.key === 'email' ? (
                          <EmailLink
                            href={`mailto:${row[header.key]}`}
                            target="_blank"
                          >
                            {row[header.key]}
                          </EmailLink>
                        ) : (
                          <p
                            style={{
                              textAlign: header.isCount ? 'center' : 'left',
                              color: header.color && header.color(row, rawData),
                              justifyContent: header.isCount && 'center',
                            }}
                          >
                            {header.isEnum
                              ? convertEnumString(row[header.key])
                              : header.isDateTime
                              ? formatDateTime(row[header.key])
                              : header.isDate
                              ? formatDateTime(row[header.key], null, false)
                              : header.isCurrency
                              ? formatCurrency(row[header.key])
                              : header.isNumber
                              ? formatNumber(row[header.key])
                              : row[header.key]}
                          </p>
                        )}
                      </TDWrapper>
                    );
                  })}
                  {navLinks.map(
                    (
                      {
                        name,
                        link,
                        display,
                        newTab,
                        passDataToDetermineLink,
                        tooltip,
                      },
                      idx,
                    ) => {
                      return (
                        <TDWrapper
                          tooltip={tooltip}
                          key={`navlink${idx}`}
                          row={row}
                        >
                          {(!display || display(row, currentEntity)) && (
                            <EditLink
                              style={{ justifyContent: 'center' }}
                              to={
                                passDataToDetermineLink
                                  ? link(row, linkParam)
                                  : link(linkParam)
                              }
                              target={newTab ? '_blank' : ''}
                            >
                              {typeof name === 'function' ? name(row) : name}
                            </EditLink>
                          )}
                        </TDWrapper>
                      );
                    },
                  )}
                  {deleteRow && (
                    <TDWrapper>
                      {deleteCondition(row) && (
                        <BooleanContainer clickable>
                          <Delete
                            onClick={() => {
                              setModalContent(
                                <Fragment>
                                  <DeleteRowModal
                                    row={row}
                                    close={clearModal}
                                    deleteRow={deleteRow}
                                    refetch={refetch}
                                    deleteDescription={deleteDescription}
                                    deleteHeader={deleteHeader}
                                  />
                                </Fragment>,
                              );
                              toggleModal();
                              setDimensions({ height: 30 });
                            }}
                          />
                        </BooleanContainer>
                      )}
                    </TDWrapper>
                  )}
                </tr>
              );
            })
          ) : (
            <NoData>No data yet...</NoData>
          )}
        </tbody>
      </Table>
    </TableBodyContainer>
  );
};

export { TableComponent as default, TableHeader };
