import React, { createContext, useState } from 'react';
import { PageLoading } from '../components';

const LoadingContext = createContext();
const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState();

  return (
    <LoadingContext.Provider value={{ loading, setLoading, setProgress }}>
      {loading && <PageLoading overlay progress={progress} />}
      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingProvider };
