const config = {
  aws: {
    region: process.env.REACT_APP_AWS_S3_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
  s3: (isPublic = true) => ({
    region: process.env.REACT_APP_AWS_S3_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    bucketName: isPublic
      ? process.env.REACT_APP_AWS_S3_BUCKET_PUBLIC
      : process.env.REACT_APP_AWS_S3_BUCKET_PRIVATE,
  }),
};

export default config;
