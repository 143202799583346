import { gql } from '@apollo/client';

const GET_META = gql`
  query getMeta($id: ID!) {
    org: getOrg(id: $id) {
      sites {
        id
        name
      }
    }
    localities: __type(name: "ERoutebuilderZoneLocalities") {
      enumValues {
        name
      }
    }
  }
`;

const GET_ZONES = gql`
  query getOrg(
    $id: ID!
    $take: Int!
    $skip: Int!
    $query: QueryRoutebuilderZones
    $order: [[String]]
  ) {
    getOrg(id: $id) {
      id
      name
      routebuilderZones(
        pagination: { take: $take, skip: $skip }
        query: $query
        order: $order
      ) {
        data {
          id
          siteId
          name
          reference
          locality
          color
          shape {
            type
          }
        }
        pagination {
          take
          skip
          total
        }
      }
    }
  }
`;

const DELETE_ZONE = gql`
  mutation deleteZone($id: ID!) {
    deleteRoutebuilderZone(id: $id)
  }
`;

export { GET_ZONES, GET_META, DELETE_ZONE };
