import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Circle,
  DrawingManager,
  GoogleMap,
  Polygon,
} from '@react-google-maps/api';
import { kinks, polygon } from '@turf/turf';
import { Field, Form, useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';
import {
  ActionButtons,
  CheckBoxFormField,
  ColorPickerFormField,
  convertEnumObject,
  DatePicker,
  Dropdown,
  FormItem,
  FormItemSplitter,
  FormWrapper,
  googleMapsOptions,
  googleMapsStyles,
  listSortAndFormat,
  MapCenterContext,
  PageError,
  PageHeading,
  PageLoading,
  TextFormField,
  theme,
  useAlerts,
  useGoogleMapsDisplayShapes,
} from '../../../';
import { GET_META, GET_OVERLAPPING_SHAPES } from './gql';
import { Container, Left } from './styles';

const ZoneForm = ({ initialZone, currentEntity }) => {
  const { onError } = useAlerts();
  const [overlappingData, setOverlappingData] = useState();
  const [isShapeIntersecting, setIsShapeIntersecting] = useState(false);
  const [getOverlaps] = useLazyQuery(GET_OVERLAPPING_SHAPES, {
    onError,
    onCompleted: (d) => {
      if (d) {
        setOverlappingData(d);
      }
    },
    notifyOnNetworkStatusChange: true,
  });
  const [zones, setZones] = useState([]);
  const [shape, setShape] = useState();
  const { center } = useContext(MapCenterContext);
  const { values, handleChange, dirty } = useFormikContext();
  const { data, error } = useQuery(GET_META, {
    variables: { orgId: currentEntity.id },
    onCompleted: (d) =>
      setZones(
        d.org.routebuilderZones.data.filter((z) =>
          initialZone ? z.id !== initialZone.id : true,
        ),
      ),
  });
  const { shapesComponent } = useGoogleMapsDisplayShapes(
    zones.filter((z) => z.siteId === values.siteId),
    undefined,
    true,
  );

  const hasOverlap =
    overlappingData &&
    overlappingData.site.routebuilderZones.data.some((d) => d.id !== values.id);
  const isPolygonIntersecting =
    values.shape.type === 'POLYGON' && isShapeIntersecting;

  const detectPolygonSelfIntersect = (poly) => {
    const markers = poly
      .getPath()
      .getArray()
      .map((latlng) => latlng.toJSON());
    const turfPoly = polygon([
      [...markers.map((m) => [m.lng, m.lat]), [markers[0].lng, markers[0].lat]],
    ]);
    const hasIntersect = kinks(turfPoly).features.length > 0;
    setIsShapeIntersecting(hasIntersect);
    return hasIntersect;
  };

  const handleCircleChange = () => {
    if (shape) {
      const spatialCircle = {
        center: shape.getCenter().toJSON(),
        radius: shape.getRadius(),
      };
      handleChange({
        target: {
          name: 'shape.data',
          value: spatialCircle,
        },
      });
      getOverlaps({
        variables: {
          siteId: values.siteId,
          spatialCircle: {
            ...spatialCircle,
            radius: spatialCircle.radius / 1000,
          },
        },
      });
    }
  };

  const handlePolygonChange = () => {
    if (shape) {
      const markers = shape
        .getPath()
        .getArray()
        .map((latlng) => latlng.toJSON());
      handleChange({
        target: {
          name: 'shape.data',
          value: {
            markers,
          },
        },
      });
      const hasIntersect = detectPolygonSelfIntersect(shape);
      !hasIntersect &&
        getOverlaps({
          variables: { siteId: values.siteId, spatialPoly: markers },
        });
    }
  };

  if (error) {
    return <PageError error={error} />;
  }

  const submit = (
    <ActionButtons
      fillWidth
      type="finalStep"
      primaryName={initialZone ? 'Save Changes' : 'Create'}
    />
  );

  if (data) {
    const options = {
      fillColor:
        isPolygonIntersecting || hasOverlap
          ? theme.colors.warning
          : values.color,
      strokeColor:
        isPolygonIntersecting || hasOverlap
          ? theme.colors.warning
          : values.color,
      zIndex: 100000,
    };
    return (
      <Container>
        <Left>
          <PageHeading
            heading={`${currentEntity.name} Zones`}
            description={initialZone ? 'Update Zone' : 'Create Zone'}
          />
          <FormWrapper>
            <Form>
              <FormItem>
                <FormItemSplitter>
                  <Field
                    name="name"
                    component={TextFormField}
                    labelText="Zone Name *"
                  />
                  <Field
                    name="reference"
                    component={TextFormField}
                    labelText="Reference *"
                  />
                </FormItemSplitter>
              </FormItem>
              <FormItem>
                <FormItemSplitter>
                  <Field
                    name="siteId"
                    component={Dropdown}
                    options={listSortAndFormat(data.org.sites, 'name', 'id')}
                    labelText="Site *"
                    disabled={!!values.shape?.type}
                  />
                  <Field
                    name="locality"
                    component={Dropdown}
                    options={convertEnumObject({ __type: data.localities })}
                    labelText="Locality *"
                  />
                </FormItemSplitter>
              </FormItem>
              <FormItem>
                <FormItemSplitter>
                  <Field
                    name="isDefaultTime"
                    component={CheckBoxFormField}
                    labelText="Set Start Time"
                  />
                  {values.isDefaultTime && (
                    <Field
                      name="startTime"
                      component={DatePicker}
                      useTime
                      useTimeOnly
                      noTypeIn
                      labelText="Start Time *"
                    />
                  )}
                </FormItemSplitter>
              </FormItem>
              <FormItem>
                <FormItemSplitter>
                  <Field
                    name="shape.type"
                    disabled={!values.siteId}
                    component={Dropdown}
                    options={convertEnumObject({ __type: data.shapes })}
                    labelText="Shape *"
                    onChange={() => {
                      setShape();
                      handleChange({
                        target: { name: 'shape.data', value: null },
                      });
                    }}
                  />
                </FormItemSplitter>
              </FormItem>
              <FormItem>
                <Field
                  name="color"
                  component={ColorPickerFormField}
                  labelText="Colour *"
                  toggle
                />
              </FormItem>
              {!values.shape.data && (
                <FormItem>
                  <div
                    style={{ color: theme.colors.danger, fontWeight: 'bold' }}
                  >
                    Please Draw a Shape!
                  </div>
                </FormItem>
              )}
            </Form>
            {hasOverlap ? (
              <div>
                <div style={{ color: theme.colors.warning }}>
                  Shape May overlap other Zones.
                </div>
                <ActionButtons
                  fillWidth
                  type="finalStep"
                  primaryName={initialZone ? 'Save Changes' : 'Create'}
                />
              </div>
            ) : isPolygonIntersecting ? (
              <div style={{ color: theme.colors.danger }}>
                Polygon is Self-Intersecting. Please Adjust
              </div>
            ) : (
              submit
            )}
          </FormWrapper>
        </Left>
        <GoogleMap
          options={{ ...googleMapsOptions, styles: googleMapsStyles.grayscale }}
          center={
            initialZone && initialZone.shape.type === 'RADIUS' && !dirty
              ? initialZone.shape.data.center
              : center
          }
          zoom={10}
          onLoad={(map) => {
            if (initialZone && values.shape.type === 'POLYGON') {
              const bounds = new window.google.maps.LatLngBounds();
              values.shape.data.markers.forEach((point) =>
                bounds.extend(point),
              );
              map.fitBounds(bounds);
            }
          }}
        >
          {!values.shape.data && values.shape.type && (
            <DrawingManager
              drawingMode={
                values.shape.type === 'POLYGON' ? 'polygon' : 'circle'
              }
              options={{ drawingControl: false }}
              onPolygonComplete={(p) => {
                const markers = p
                  .getPath()
                  .getArray()
                  .map((latlng) => latlng.toJSON());
                handleChange({
                  target: {
                    name: 'shape.data',
                    value: {
                      markers,
                    },
                  },
                });
                p.setVisible(false);
                const hasIntersect = detectPolygonSelfIntersect(p);
                !hasIntersect &&
                  getOverlaps({
                    variables: { siteId: values.siteId, spatialPoly: markers },
                  });
              }}
              onCircleComplete={(c) => {
                const spatialCircle = {
                  center: c.getCenter().toJSON(),
                  radius: c.getRadius(),
                };
                handleChange({
                  target: {
                    name: 'shape.data',
                    value: spatialCircle,
                  },
                });
                getOverlaps({
                  variables: {
                    siteId: values.siteId,
                    spatialCircle: {
                      ...spatialCircle,
                      radius: spatialCircle.radius / 1000,
                    },
                  },
                });
                c.setVisible(false);
              }}
            />
          )}
          {values.shape.type === 'RADIUS' && values.shape.data && (
            <Circle
              onLoad={(c) => setShape(c)}
              center={values.shape.data.center}
              radius={values.shape.data.radius}
              draggable
              editable
              onDragEnd={handleCircleChange}
              onRadiusChanged={handleCircleChange}
              onMouseUp={handleCircleChange}
              options={options}
            />
          )}
          {values.shape.type === 'POLYGON' && values.shape.data && (
            <Polygon
              onLoad={(p) => setShape(p)}
              path={values.shape.data.markers}
              draggable
              editable
              onDragEnd={handlePolygonChange}
              onMouseUp={handlePolygonChange}
              options={options}
            />
          )}
          {shapesComponent}
        </GoogleMap>
      </Container>
    );
  }

  return <PageLoading />;
};

export default ZoneForm;
