import { Form, Formik } from 'formik';
import React from 'react';
import {
  ActionButtons,
  FormArea,
  FormWrapper,
  listSortAndFormat,
  PageContainer,
  PageLoading,
  SelectRemoveColumns,
} from '../../../../';

const AssignedAssetGroups = ({
  manageAssetGroupsMutation,
  chosenAssetGroups,
  id,
  allAssetGroups,
}) => {
  const chosenAssetGroupIds = chosenAssetGroups.map((s) => s.id);

  const initialAssetGroups = listSortAndFormat(chosenAssetGroups, 'name');
  const rightItems = allAssetGroups.filter((s) =>
    chosenAssetGroupIds.includes(s.id),
  );
  const leftItems = allAssetGroups.filter(
    (site) => !chosenAssetGroupIds.includes(site.id),
  );
  if (initialAssetGroups) {
    return (
      <PageContainer height={'70vh'} align="end" gridStyling={'auto'}>
        <Formik
          enableReinitialize
          initialValues={{ sites: initialAssetGroups }}
          onSubmit={({ sites }) => {
            const siteIds = sites.map((s) => s.id);
            const add = siteIds.filter((i) => !chosenAssetGroupIds.includes(i));
            const remove = chosenAssetGroupIds.filter(
              (i) => !siteIds.includes(i),
            );
            const variables = {
              input: {
                id,
                add,
                remove,
              },
            };
            manageAssetGroupsMutation({ variables });
          }}
        >
          {({ handleChange }) => (
            <FormWrapper>
              <Form>
                <FormArea>
                  <SelectRemoveColumns
                    leftHeading="All Asset Groups"
                    rightHeading="Assigned Asset Groups"
                    leftItems={leftItems}
                    rightItems={rightItems}
                    id="id"
                    display="name"
                    withinForm
                    actionHandler={(value) => {
                      handleChange({
                        target: {
                          name: 'sites',
                          value: value.sort((a, b) =>
                            a.name >= b.name ? 1 : -1,
                          ),
                        },
                      });
                    }}
                  />
                </FormArea>
              </Form>
              <ActionButtons primaryName="Save & Continue" />
            </FormWrapper>
          )}
        </Formik>
      </PageContainer>
    );
  }
  return <PageLoading />;
};

export default AssignedAssetGroups;
