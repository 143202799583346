import { gql } from '@apollo/client';

const GET_BOOKING = gql`
  query getLinkBooking($id: ID!) {
    getLinkBooking(id: $id) {
      status
    }
  }
`;

export { GET_BOOKING };
