import Download from '@carbon/icons-react/lib/download/20';
import { lighten } from 'polished';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledClickToSeeMore = styled.p`
  font-size: 0.75rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.danger};
  text-decoration: underline;
  z-index: 3;
  :hover {
    color: ${({ theme }) => lighten(0.1, theme.colors.danger)};
    cursor: pointer;
  }
`;

const ErrModalWrapper = styled.div`
  padding: 0 10px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  > h2 {
    color: ${({ theme }) => theme.colors.danger};
    font-weight: bold;
    font-size: 1.5rem;
    cursor: pointer;
    padding-bottom: 20px;
  }
`;

const CloseModal = styled.p`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: bold;
`;

const DownloadIcon = styled(Download)`
  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  :hover {
    & path {
      fill: ${({ theme }) => lighten(0.1, theme.colors.primary)};
    }
    cursor: pointer;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
`;

export {
  StyledClickToSeeMore,
  ModalWrapper,
  CloseModal,
  ErrModalWrapper,
  DownloadIcon,
  LoadingOverlay,
};
