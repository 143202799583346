import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { dissoc } from 'ramda';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  generateLinkWithParams,
  getURLParam,
  PageError,
  PageLoading,
  useAlerts,
  useLoading,
} from '../../../../';
import CarrierForm from '../CarrierForm';
import validationSchema from '../validationSchema';
import { EDIT_CARRIER, GET_CARRIER } from './gql';

const EditCarrier = withRouter(
  ({ history, redirect, currentEntity, linkParams, match }) => {
    const { data, error } = useQuery(GET_CARRIER, {
      variables: { id: getURLParam(match, 'carrierId') },
    });
    const { alertSuccess, onError } = useAlerts();
    const [editCarrierMutation, { loading }] = useMutation(EDIT_CARRIER, {
      onCompleted: () => {
        alertSuccess('Carrier successfully updated');
        history.push(generateLinkWithParams(redirect, linkParams));
      },
      onError,
    });

    useLoading(loading);

    if (error) {
      return <PageError error={error} />;
    }

    if (data) {
      return (
        <Formik
          validateOnMount
          validationSchema={validationSchema}
          initialValues={data.getCarrier}
          onSubmit={(values) => {
            editCarrierMutation({
              variables: { input: dissoc('reference', values) },
            });
          }}
        >
          <CarrierForm isEdit currentEntity={currentEntity} />
        </Formik>
      );
    }
    return <PageLoading />;
  },
);

export default EditCarrier;
