import { gql } from '@apollo/client';

const GET_ASSET_CLASSES_GROUPS = gql`
  query getOrg($orgId: ID!) {
    getOrg(id: $orgId) {
      assetClasses {
        id
        name
      }
      sites {
        id
        name
      }
      assetGroupsPaged(pagination: { take: 10000000, skip: 0 }) {
        data {
          id
          name
        }
      }
    }
  }
`;
const GET_SITE_ASSET_CLASSES_GROUPS = gql`
  query getSite($siteId: ID!) {
    getSite(id: $siteId) {
      org {
        assetGroupsPaged(pagination: { take: 10000000, skip: 0 }) {
          data {
            id
            name
          }
        }
        assetClasses {
          id
          name
        }
      }
    }
  }
`;

export { GET_ASSET_CLASSES_GROUPS, GET_SITE_ASSET_CLASSES_GROUPS };
