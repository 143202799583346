import * as yup from 'yup';

const formikSchema = (username) => ({
  initialValues: {
    username,
    password: '',
  },
  validationSchema: yup.object({
    username: yup.string().email().required(),
    password: yup.string().required(),
  }),
});

const formikSchemaForUsername = {
  initialValues: {
    username: '',
  },
  validationSchema: yup.object({
    username: yup.string().email().required(),
  }),
};

export { formikSchema, formikSchemaForUsername };
