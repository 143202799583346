import Download from '@carbon/icons-react/lib/download/20';
import QRCodeIcon from '@carbon/icons-react/lib/qr-code/20';
import { lighten } from 'polished';
import styled from 'styled-components';

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
`;
const DivWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
`;
const QrIcon = styled(QRCodeIcon)`
  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  &:hover {
    & path {
      fill: ${({ theme }) => lighten(0.1, theme.colors.primary)};
    }
    cursor: pointer;
  }
`;
const DownloadIcon = styled(Download)`
  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  :hover {
    & path {
      fill: ${({ theme }) => lighten(0.1, theme.colors.primary)};
    }
    cursor: pointer;
  }
`;

const CloseModal = styled.p`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: bold;
`;

export { QrIcon, LoadingOverlay, DivWrapper, DownloadIcon, CloseModal };
