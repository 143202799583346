import {
  addSeconds,
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
  format,
} from 'date-fns';
import { mean, sum } from 'ramda';

const formatISO = (date?: string): string | undefined =>
  date && `${new Date(date).toISOString().slice(0, -5)}Z`;

const formatDurationPart = (t: number) => {
  const length = `${t}`.length;
  const numberOfZeroes = length === 1 ? 1 : 0;
  const slice = Math.max(length, 2);
  const zeroString = [...Array(numberOfZeroes)].map(() => '0').join();
  return `${zeroString}${t}`.slice(-slice);
};

const formatTimeFromSeconds = (
  duration?: number,
  noSec: boolean = false,
  absolute: boolean = false,
  humanize: boolean = false,
) => {
  if (!duration) {
    return '-';
  }

  const dur = absolute ? Math.abs(duration) : duration;
  const hoursUnrounded = dur / 3600;
  const hours = Math.floor(hoursUnrounded);
  const minutesUnrounded = (hoursUnrounded - hours) * 60;
  const minutes = Math.floor(minutesUnrounded);
  const seconds = Math.round((minutesUnrounded - minutes) * 60);

  const daysWhole = Math.floor(hours / 24);
  const laggingHours = hours % (daysWhole * 24);
  const laggingMinutes = Math.round((hoursUnrounded - hours) * 60);
  return humanize
    ? daysWhole
      ? `${daysWhole} days ${laggingHours} hrs`
      : hours >= 1
      ? `${hours} hrs ${laggingMinutes} mins`
      : `${minutes} mins`
    : `${formatDurationPart(hours)}:${formatDurationPart(
        seconds > 59 ? minutes + 1 : minutes,
      )}${noSec ? '' : `:${formatDurationPart(seconds > 59 ? 0 : seconds)}`}`;
};

interface OrgConfiguration {
  dateFormat?: string;
  timeFormat?: string;
}

const formatDateTime = (
  date?: Date,
  orgConfiguration?: OrgConfiguration,
  withTime: boolean = true,
  dateFormat?: string,
) =>
  !date
    ? '-'
    : format(
        new Date(date),
        `${orgConfiguration?.dateFormat || dateFormat || 'yyyy-MM-dd'}${
          withTime ? ' ' : ''
        }${withTime ? orgConfiguration?.timeFormat || 'HH:mm' : ''}`,
      );

const formatTime = (date?: Date) =>
  !date ? '-' : format(new Date(date), 'HH:mm');

const formatDashDateTime = (date?: Date) => {
  try {
    return !date ? '-' : format(new Date(date), 'dd MMM HH:mm');
  } catch {
    return '-';
  }
};

const formatDuration = (
  start?: string | Date,
  end?: string | Date,
  noSecs?: boolean,
  getValue?: boolean,
  humanize?: boolean,
) => {
  try {
    if (!start || !end) {
      if (getValue) {
        return {
          format: '-',
        };
      }
      return '-';
    }
    const valueInSeconds = differenceInSeconds(new Date(end), new Date(start));
    const valueInMinutes = Math.round(valueInSeconds / 60);
    const stringFormat = formatTimeFromSeconds(
      valueInSeconds,
      noSecs,
      false,
      humanize,
    );
    if (getValue) {
      return {
        valueInSeconds,
        valueInMinutes,
        format: stringFormat,
      };
    }
    return stringFormat;
  } catch {
    if (getValue) {
      return {
        valueInSeconds: 0,
        valueInMinutes: 0,
        format: '-',
      };
    }
    return '-';
  }
};

interface StartEnd {
  start?: string;
  end?: string;
}

const getAverageDuration = (startEnds: StartEnd[], noSec: boolean = false) => {
  const durations = startEnds
    .filter((s) => !!s.start && !!s.end)
    .map(({ start, end }) =>
      differenceInSeconds(new Date(end!), new Date(start!)),
    );
  return durations.length > 0
    ? formatTimeFromSeconds(mean(durations), noSec)
    : '-';
};

const getTotalDuration = (
  startEnds: StartEnd[],
  getValue: boolean = false,
  noSec: boolean = false,
) => {
  const durations = startEnds
    .filter((s) => s.start && s.end)
    .map(({ start, end }) =>
      differenceInSeconds(new Date(end!), new Date(start!)),
    );
  if (durations.length === 0) {
    if (getValue) {
      return {
        valueInSeconds: null,
        format: '-',
      };
    }
    return '-';
  }
  const stringFormat = formatTimeFromSeconds(sum(durations), noSec);
  if (getValue) {
    return {
      valueInSeconds: sum(durations),
      format: stringFormat,
    };
  }
  return stringFormat;
};

const getTimesFromDurationString = (durationString: string = '') => {
  if (!durationString) {
    return { hours: 0, minutes: 0, seconds: 0 };
  }
  const [hours, minutes, seconds] = durationString.split(':').map(Number);
  return { hours, minutes, seconds };
};

const addDurationStringToDate = (
  date: string = '',
  durationString: string = '',
  subtract = false,
) => {
  const { hours, minutes, seconds } =
    getTimesFromDurationString(durationString);
  return addSeconds(
    new Date(date),
    (hours * 3600 + minutes * 60 + seconds) * (subtract ? -1 : 1),
  );
};

const isWithin24HoursOf = (date?: string) =>
  !!date && differenceInHours(new Date(), new Date(date)) <= 24;
const isWithin30DaysOf = (date?: string) =>
  !!date && differenceInDays(new Date(), new Date(date)) <= 30;
const isMoreThan30DaysFrom = (date?: string) =>
  !!date && differenceInDays(new Date(), new Date(date)) > 30;

export {
  addDurationStringToDate,
  formatDashDateTime,
  formatDateTime,
  formatDuration,
  formatTime,
  formatTimeFromSeconds,
  getAverageDuration,
  getTimesFromDurationString,
  getTotalDuration,
  formatISO,
  isWithin24HoursOf,
  isWithin30DaysOf,
  isMoreThan30DaysFrom,
};
