import { orderBy } from 'lodash';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { SideNavContext, trackmaticLogoDark } from '../../';
import { Container, MenuBlock, MenuIcon, MenuLink, MenuTop } from './styles';

const SideNav = withRouter(
  ({ location: { pathname }, linkParams, showLogo }) => {
    const [openMenuOptions, setOpenMenuOptions] = useState([]);
    const { isSideNavOpen, toggleSideNav, sideNavOptions } =
      useContext(SideNavContext);

    const stringOptions = JSON.stringify(sideNavOptions);
    useEffect(() => {
      setOpenMenuOptions([]);
    }, [stringOptions]);

    const determineActive = (option) => {
      const link = option.link && option.link(linkParams);
      if (typeof link === 'string') {
        const isActive = !link.includes(pathname)
          ? false
          : pathname.includes(link);
        return isActive;
      }
      return Object.values(option.sub).some((v) => determineActive(v));
    };

    const mapOptions = (option, level = 0, prevOption = 0) => {
      const list = Object.values(option);
      const values =
        level === 0
          ? orderBy(list, (l) => [l.name?.toLowerCase()], ['asc'])
          : list;
      return values.map((o, idx) => {
        const openId = `L${level}-O${idx}-P${prevOption}`;
        const isOpen = openMenuOptions.some((omo) => omo === openId);
        const isActive = determineActive(o);
        return (
          <MenuBlock key={openId} level={level}>
            {o.link ? (
              <MenuLink
                level={level}
                to={o.link(linkParams)}
                onClick={() => {
                  o.closeOnClick && toggleSideNav();
                }}
                active={isActive}
              >
                {o.name}
              </MenuLink>
            ) : (
              <Fragment>
                <MenuTop
                  onClick={() => {
                    setOpenMenuOptions((prev) => {
                      if (prev.includes(openId)) {
                        return prev.filter((p) => p !== openId);
                      } else {
                        return [...prev, openId];
                      }
                    });
                  }}
                  isOpen={isOpen}
                  level={level}
                  active={isActive}
                >
                  {o.name}
                </MenuTop>
                {isOpen && mapOptions(o.sub, level + 1, idx)}
              </Fragment>
            )}
          </MenuBlock>
        );
      });
    };

    return (
      <Container isOpen={isSideNavOpen}>
        <MenuIcon onClick={toggleSideNav} />
        <div>
          {isSideNavOpen && sideNavOptions && mapOptions(sideNavOptions)}
        </div>
        {isSideNavOpen && showLogo && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={trackmaticLogoDark} alt="tm-logo" width="70%" />
          </div>
        )}
      </Container>
    );
  },
);

export default SideNav;
