import React, { useEffect, useState } from 'react';
import {
  CheckBox,
  EmptyCheckBox,
  FormItem,
  PasswordFormField,
  PrimaryButton,
  theme,
} from '../../../../';
import { FormArea, GridBox } from '../LoginForm/styles';

const SetPasswordForm = ({ onSubmit }) => {
  const [passwordInput, setPasswordInput] = useState('');
  const [passwordValid, setPasswordValid] = useState({
    uppercase: false,
    lowercase: false,
    special: false,
    number: false,
    goodLength: false,
  });

  useEffect(() => {
    const lowercase = /[a-z]/.test(passwordInput);
    const uppercase = /[A-Z]/.test(passwordInput);
    const special = /[!@#$%^&*+=._-]/.test(passwordInput);
    const number = /[\d]/.test(passwordInput);
    const goodLength = passwordInput.length >= 8;
    const validationPayload = {
      uppercase,
      lowercase,
      special,
      number,
      goodLength,
    };
    setPasswordValid(validationPayload);
  }, [passwordInput]);

  const disabled = !Object.values(passwordValid).every((v) => v);

  return (
    <FormArea columns="1" fitWidth>
      <FormItem>
        <PasswordFormField
          value={passwordInput}
          onChange={(e) => {
            setPasswordInput(e.target.value);
          }}
          labelText="Password"
          showPasswordLabel="Show"
          hidePasswordLabel="Hide"
          name="pw"
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !disabled) {
              onSubmit(passwordInput);
            }
          }}
        />
      </FormItem>
      <p style={{ paddingTop: '20px', textAlign: 'center' }}>
        Password must include at least one of each of the following:
      </p>
      <GridBox>
        {[
          'Uppercase Letter',
          'Lowercase Letter',
          'Special Character',
          'Number',
          'Length of 8 Characters',
        ].map((val, idx) => {
          const isValid = passwordValid[Object.keys(passwordValid)[idx]];
          return (
            <p
              key={idx}
              style={{
                color: isValid ? theme.colors.primary : theme.colors.darkGrey,
              }}
            >
              {isValid ? <CheckBox /> : <EmptyCheckBox />}
              {val}
            </p>
          );
        })}
      </GridBox>
      <PrimaryButton
        type="button"
        fillWidth
        disabled={disabled}
        onClick={() => onSubmit(passwordInput)}
      >
        Reset Password
      </PrimaryButton>
    </FormArea>
  );
};

export default SetPasswordForm;
