import React, { useState, createContext } from 'react';

const MapCenterContext = createContext();

const MapCenterProvider = ({ children }) => {
  const [center, setCenter] = useState({
    lat: -26.2041,
    lng: 28.0473,
  });
  return (
    <MapCenterContext.Provider value={{ center, setCenter }}>
      {children}
    </MapCenterContext.Provider>
  );
};

export { MapCenterContext, MapCenterProvider };
