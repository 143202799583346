import { useLazyQuery } from '@apollo/client';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { globalSections, linkPlaceholders, userTypes } from '../../config';
import { PageLoadingg } from '../../shared';
import { CurrentModuleContext } from '../CurrentModule';
import { CurrentPathContext } from '../CurrentPath';
import { CurrentLoggedInUserContext } from '../Login';
import { GET_FACILITY, GET_GROUP, GET_ORG, GET_SITE } from './gql';
import SelectOrgOrSite from './SelectOrgOrSite';

const [isGroupUser, isOrgUser, isSiteUser] = [
  userTypes.groupUser,
  userTypes.orgUser,
  userTypes.siteUser,
].map((globalType) => (userType) => userType === globalType);

const { groupId, orgId, siteId, facilityId } = linkPlaceholders;

const { groups, orgs, sites } = globalSections;

const setCurrentOrgSettings = (org) => {
  if (org) {
    const { orgConfiguration, orgIntegrationConfiguration } = org;
    return { orgConfiguration, orgIntegrationConfiguration };
  }
};

const setCurrentSiteSettings = (site) => {
  if (site) {
    const { configuration: siteConfiguration } = site;
    return { siteConfiguration };
  }
  return {};
};

const CurrentEntityContext = createContext();

const CurrentEntityProvider = ({ children }) => {
  //The global path will be of format:
  // /<module>/groups/<group>/orgs/<org>/sites/<site>/facilities/<facility>
  const { push } = useHistory();
  const [idFromMultipleSelect, setIdFromMultipleSelect] = useState();
  const { currentModule } = useContext(CurrentModuleContext);

  const {
    userType,
    currentUser,
    userAuthValues: { usersOrgs, usersSites },
  } = useContext(CurrentLoggedInUserContext);

  const splitPaths = useContext(CurrentPathContext);
  const {
    pathArray,
    groupPath,
    orgPath,
    sitePath,
    groupsPath,
    orgsPath,
    sitesPath,
  } = splitPaths;

  //CURRENT GROUP
  const [currentGroupLoading, setCurrentGroupLoading] = useState(true);
  const [currentGroup, setCurrentGroup] = useState();
  const [getCurrentGroup] = useLazyQuery(GET_GROUP, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      d && setCurrentGroup(d.getGroup);
      setCurrentGroupLoading(false);
    },
    onError: () => {
      setCurrentGroupLoading(false);
    },
  });

  useEffect(() => {
    setCurrentGroupLoading(true);
    if (groupPath) {
      currentUser.isTMUser || isGroupUser(userType)
        ? getCurrentGroup({ variables: { id: groupPath } })
        : setCurrentGroupLoading(false);
    } else {
      setCurrentGroup();
      setCurrentGroupLoading(false);
    }
  }, [groupPath, getCurrentGroup, currentUser, userType]);

  //CURRENT ORG
  const [currentOrgLoading, setCurrentOrgLoading] = useState(true);
  const [currentOrg, setCurrentOrg] = useState();
  const [getCurrentOrg] = useLazyQuery(GET_ORG, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      if (d) {
        setCurrentOrg(d.getOrg);
        setCurrentGroup(d.getOrg.group);
      }
      setCurrentOrgLoading(false);
    },
    onError: () => {
      setCurrentOrgLoading(false);
    },
  });

  useEffect(() => {
    setCurrentOrgLoading(true);
    if (orgPath) {
      currentUser.isTMUser || isGroupUser(userType) || isOrgUser(userType)
        ? getCurrentOrg({ variables: { id: orgPath } })
        : setCurrentOrgLoading(false);
    } else {
      setCurrentOrg();
      setCurrentOrgLoading(false);
    }
  }, [orgPath, getCurrentOrg, currentUser, userType]);

  //CURRENT SITE
  const [currentSiteLoading, setCurrentSiteLoading] = useState(true);
  const [currentSite, setCurrentSite] = useState();
  const [getCurrentSite] = useLazyQuery(GET_SITE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      if (d) {
        setCurrentSite(d?.getSite);
        setCurrentOrg(d?.getSite.org);
        setCurrentGroup(d?.getSite.org.group);
      }
      setCurrentSiteLoading(false);
    },
    onError: () => {
      setCurrentSiteLoading(false);
    },
  });

  useEffect(() => {
    setCurrentSiteLoading(true);
    if (sitePath) {
      getCurrentSite({ variables: { id: sitePath } });
    } else {
      setCurrentSite();
      setCurrentSiteLoading(false);
    }
  }, [sitePath, getCurrentSite]);

  //CURRENT FACILITY
  const facilityPath =
    pathArray[pathArray.findIndex((e) => e === 'facilities') + 1];
  const [currentFacilityLoading, setCurrentFacilityLoading] = useState(true);
  const [currentFacility, setCurrentFacility] = useState();
  const [getCurrentFacility] = useLazyQuery(GET_FACILITY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      if (d) {
        setCurrentFacility(d?.getFacility);
        setCurrentSite(d?.getFacility.site);
        setCurrentOrg(d?.getFacility.site.org);
        setCurrentGroup(d?.getFacility.site.org.group);
      }
      setCurrentFacilityLoading(false);
    },
    onError: () => {
      setCurrentFacilityLoading(false);
    },
  });

  useEffect(() => {
    setCurrentFacilityLoading(true);
    if (facilityPath) {
      getCurrentFacility({ variables: { id: facilityPath } });
    } else {
      setCurrentFacility();
      setCurrentFacilityLoading(false);
    }
  }, [facilityPath, getCurrentFacility]);

  const currentEntity = currentFacility
    ? {
        id: currentFacility.id,
        name: currentFacility.name,
        type: 'facility',
        parent: {
          id: currentFacility.site.id,
          name: currentFacility.site.name,
          type: 'site',
          parent: {
            id: currentFacility.site.org.id,
            name: currentFacility.site.org.name,
            onroadId: currentFacility.site.org.onroadId,
            type: 'org',
            parent: {
              id: currentFacility.site.org.group.id,
              name: currentFacility.site.org.group.name,
              type: 'group',
              parent: null,
            },
          },
        },
      }
    : currentSite
    ? {
        id: currentSite.id,
        name: currentSite.name,
        type: 'site',
        parent: {
          id: currentSite.org.id,
          name: currentSite.org.name,
          onroadId: currentSite.org.onroadId,
          type: 'org',
          parent: {
            id: currentSite.org.group.id,
            name: currentSite.org.group.name,
            type: 'group',
            parent: null,
          },
        },
      }
    : currentOrg
    ? {
        id: currentOrg.id,
        name: currentOrg.name,
        onroadId: currentOrg.onroadId,
        type: 'org',
        parent: {
          id: currentOrg.group.id,
          name: currentOrg.group.name,
          type: 'group',
          parent: null,
        },
      }
    : currentGroup
    ? {
        id: currentGroup.id,
        name: currentGroup.name,
        type: 'group',
        parent: null,
      }
    : {};

  const contextGroup =
    currentGroup || (isGroupUser(userType) && currentUser.group);
  const contextOrg =
    currentOrg ||
    usersOrgs.find((uo) => uo.id === idFromMultipleSelect) ||
    (isOrgUser(userType) && usersOrgs.length === 1 && usersOrgs[0]);
  const contextSite =
    currentSite ||
    usersSites.find((uo) => uo.id === idFromMultipleSelect) ||
    (isSiteUser(userType) && usersSites.length === 1 && usersSites[0]);

  const currentOrgSettings = setCurrentOrgSettings(contextOrg);
  const { siteConfiguration } = setCurrentSiteSettings(contextSite);

  const currentContextLink = contextSite
    ? `/${groups}/${contextSite.org.group.id}/${orgs}/${contextSite.org.id}/${sites}/${contextSite.id}`
    : contextOrg
    ? `/${groups}/${contextOrg.group.id}/${orgs}/${contextOrg.id}${
        sitesPath ? `/${sites}` : ''
      }`
    : contextGroup
    ? `/${groups}/${contextGroup.id}${orgsPath ? `/${orgs}` : ''}`
    : groupsPath
    ? `/${groups}`
    : '';

  const reselectContextIfMultiple = () => {
    setIdFromMultipleSelect();
    push(currentModule ? `/${currentModule}` : ``);
  };

  const linkParams = {
    [groupId]: currentGroup?.id,
    [orgId]: currentOrg?.id,
    [siteId]: currentSite?.id,
    [facilityId]: currentFacility?.id,
  };

  const mustSelectOrgOrSite =
    (isOrgUser(userType) && !contextOrg) ||
    (isSiteUser(userType) && !contextSite);

  const mustLoad =
    currentGroupLoading ||
    currentOrgLoading ||
    currentSiteLoading ||
    currentFacilityLoading;

  if (mustLoad) {
    return <PageLoadingg fillScreen />;
  }

  if (mustSelectOrgOrSite) {
    return (
      <SelectOrgOrSite
        list={isOrgUser(userType) ? usersOrgs : usersSites}
        type={isOrgUser(userType) ? 'an Organisation' : 'a Site'}
        changeId={(id) => {
          setIdFromMultipleSelect(id);
          const selectedContext = (
            isOrgUser(userType) ? usersOrgs : usersSites
          ).find((o) => o.id === id);
          const contextLink = isOrgUser(userType)
            ? `/${groups}/${selectedContext.group.id}/${orgs}/${
                selectedContext.id
              }${sitesPath ? `/${sites}` : ''}`
            : `/${groups}/${selectedContext.org.group.id}/${orgs}/${selectedContext.org.id}/${sites}/${selectedContext.id}`;
          push(
            `/${
              currentModule || currentUser.permissions[0]?.module
            }${contextLink}`,
          );
        }}
      />
    );
  }

  const isMultipleUser =
    (isOrgUser(userType) && usersOrgs.length > 1) ||
    (isSiteUser(userType) && usersSites.length > 1);

  return (
    <CurrentEntityContext.Provider
      value={{
        linkParams,
        currentContextLink,
        currentGroup,
        currentOrg,
        currentSite,
        currentEntity,
        currentOrgSettings,
        isMultipleUser,
        siteConfiguration,
        reselectContextIfMultiple,
      }}
    >
      {children}
    </CurrentEntityContext.Provider>
  );
};

export { CurrentEntityContext, CurrentEntityProvider };
