import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  updateLoadAssetCurrentPosition,
  updateLoadExecution,
  updateLoadStatus,
  updateLoadTrips,
  useAlerts,
  useChatApp,
  websocket,
} from '../../../';
import { loadWebsocketEvents } from '../config';
import { GET_LEAN_LOAD, GET_LOAD } from './gql';

const useLoad = ({ id, onCompleted = () => {} }, lean = false) =>
  useQuery(lean ? GET_LEAN_LOAD : GET_LOAD, { variables: { id }, onCompleted });

const useLazyLoad = (options = {}, lean = false) =>
  useLazyQuery(lean ? GET_LEAN_LOAD : GET_LOAD, {
    ...options,
    notifyOnNetworkStatusChange: true,
  });

const useLoadViewState = (currentUser) => {
  const [load, setLoad] = useState();
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState();
  const [isRepressed, setIsRepressed] = useState(false);
  const [isCamOpen, setIsCamOpen] = useState(false);
  const { alertInfo } = useAlerts();

  useEffect(() => {
    if (isCamOpen) {
      const timeout = setTimeout(() => {
        setIsCamOpen(false);
        alertInfo('Live Feed Closing Due to Inactivity');
      }, 30000);
      return () => clearTimeout(timeout);
    }
  }, [isCamOpen, setIsCamOpen, alertInfo]);

  const { initializeChatApp, chatIsOpen, currentConversation } = useChatApp({
    currentUser,
    chatId: load?.id,
  });

  return {
    chatIsOpen,
    currentConversation,
    initializeChatApp,
    isCamOpen,
    isRepressed,
    load,
    selectedActivity,
    selectedTrips,
    setIsCamOpen,
    setIsRepressed,
    setLoad,
    setSelectedActivity,
    setSelectedTrips,
  };
};

const useLoadEvents = (load, setLoad) => {
  const loadId = load?.id || '';

  useEffect(() => {
    if (loadId) {
      const loadUpdates = new WebSocket(websocket('loads', loadId));
      loadUpdates.onmessage = async (e) => {
        if (e.data) {
          const {
            payload,
            meta: { type: eventType },
          } = JSON.parse(e.data);
          switch (eventType) {
            case loadWebsocketEvents.STATUS_UPDATE:
              setLoad((prev) => updateLoadStatus({ load: prev, payload }));
              break;
            case loadWebsocketEvents.LOAD_EXECUTION:
              setLoad((prev) => updateLoadExecution({ load: prev, payload }));
              break;
            case loadWebsocketEvents.TRIP_SNAPSHOT:
              setLoad((prev) =>
                updateLoadAssetCurrentPosition({ load: prev, payload }),
              );
              break;
            case loadWebsocketEvents.TRIP_SUMMARY:
              setLoad((prev) => updateLoadTrips({ load: prev, payload }));
              break;
            default:
              break;
          }
        }
      };
      return () => {
        loadUpdates.close();
      };
    }
  }, [loadId, setLoad]);
};

export { useLoad, useLazyLoad, useLoadViewState, useLoadEvents, GET_LOAD };
