import { equals } from 'ramda';
import React, { useContext, useState } from 'react';
import Dropdown from 'react-select';
import { ThemeContext } from 'styled-components';
import { Container, Label } from './styles';

const DropdownBox = ({
  field = null,
  labelText = null,
  options = [],
  onChange,
  value,
  noBorders = false,
  placeholder = 'Select...',
  disabled = false,
  isClearable = true,
  returnFullObject = false,
  searchOnly = false,
  form = {},
}) => {
  const [search, setSearch] = useState('');
  const realValue = field?.value !== undefined ? field.value : value;
  const theme = useContext(ThemeContext);
  const searchedOptions = searchOnly
    ? options.filter(
        (option) =>
          option.value === realValue ||
          (search.length >= 3 &&
            option.label.toLowerCase().includes(search.toLowerCase())),
      )
    : options;

  const isTouched = form?.touched?.[field?.name] || form?.submitCount > 0;
  const hasError = isTouched && form?.errors?.[field?.name];

  const styles = {
    container: (provided) => {
      return {
        ...provided,
        width: '100%',
      };
    },
    control: (provided) => {
      return {
        ...provided,
        height: '40px',
        boxShadow: 'none',
        border: 'none',
        borderRadius: 0,
        borderBottom: noBorders
          ? 'none'
          : `1px solid ${theme.colors[hasError ? 'danger' : 'mediumGrey']}`,
        '&:hover': {
          borderBottom: noBorders
            ? 'none'
            : `1px solid ${theme.colors[hasError ? 'danger' : 'mediumGrey']}`,
        },
      };
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        display: 'none',
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        fontSize: '0.75rem',
        color: hasError && theme.colors.danger,
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        fontSize: '0.75rem',
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        cursor: 'pointer',
        fontSize: '0.75rem',
        background: state.isSelected ? theme.colors.primary : 'none',
        '&:hover': {
          background: state.isSelected
            ? theme.colors.primary
            : theme.colors.lightGrey,
        },
      };
    },
    menu: (provided) => {
      return { ...provided, zIndex: 10000000 };
    },
  };
  const noOptionsMessage = () =>
    searchOnly && search.length < 3 ? 'Type to Search...' : 'No Options';
  const handleInputChange = (v) => setSearch(v);
  if (field) {
    //ie is this component in a formik?
    const { name, value: formikValue } = field;
    return (
      <Container hasLabel={!!labelText}>
        {labelText && <Label error={hasError}>{labelText}</Label>}
        <Dropdown
          noOptionsMessage={noOptionsMessage}
          onInputChange={handleInputChange}
          inputValue={search}
          isClearable={isClearable}
          isDisabled={disabled}
          placeholder={placeholder}
          value={
            realValue !== undefined &&
            options &&
            options.find((i) => equals(formikValue, i.value))
          }
          options={searchedOptions}
          onChange={(v) => {
            onChange && onChange(v?.value);
            field.onChange({
              target: {
                value: v?.value,
                name,
              },
            });
          }}
          blurInputOnSelect
          styles={styles}
        />
      </Container>
    );
  }
  return (
    <Container>
      {labelText && <Label error={hasError}>{labelText}</Label>}
      <Dropdown
        noOptionsMessage={noOptionsMessage}
        onInputChange={handleInputChange}
        inputValue={search}
        isClearable={isClearable}
        isDisabled={disabled}
        placeholder={placeholder}
        value={realValue && options.find((i) => equals(i.value, realValue))}
        options={searchedOptions}
        onChange={(v) => {
          onChange(v && (returnFullObject ? v : v.value));
        }}
        blurInputOnSelect
        styles={styles}
      />
    </Container>
  );
};
export default DropdownBox;
