import { useQuery } from '@apollo/client';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  convertEnumObject,
  generateLinkWithParams,
  getURLParam,
  linkPlaceholders,
  listSortAndFormat,
  PageError,
  PageLoading,
  TableView,
} from '../../../../';
import { shapeData } from './functions';
import {
  DELETE_ASSET,
  GET_ASSET_CLASSES_GROUPS,
  GET_ASSET_CLASSES_GROUPS_SITE_LEVEL,
  GET_ORG,
  GET_SITE,
} from './gql';
import headings from './headings';

const AssetList = withRouter(
  ({
    match,
    navLinks = [],
    navLinksForSite = [],
    createRoute = '',
    createRouteForSite = '',
    linkParams = {},
    currentEntity = {},
    isTMUser = false,
  }) => {
    const orgId = getURLParam(match, 'orgId');
    const siteId = getURLParam(match, 'siteId');
    const { data, error } = useQuery(
      siteId ? GET_ASSET_CLASSES_GROUPS_SITE_LEVEL : GET_ASSET_CLASSES_GROUPS,
      {
        variables: {
          id: siteId || orgId,
        },
      },
    );

    if (error) {
      return <PageError error={error} />;
    }
    if (data) {
      const {
        assetClasses,
        assetGroupsPaged: { data: assetGroups },
        sites,
      } = siteId ? data.getSite.org : data.getOrg;

      const filterOptions = [
        { name: 'search', type: 'input', placeholder: 'Licence Number' },
        {
          name: 'status',
          type: 'dropdown',
          options: convertEnumObject({
            __type: data.assetStatuses,
          }),
          label: 'Status',
          placeholder: 'Status',
          initialValue: 'ACTIVE',
        },
        {
          name: 'type',
          type: 'dropdown',
          options: convertEnumObject({ __type: data.assetTypes }),
          label: 'Asset Type',
          placeholder: 'Asset Type',
        },
        {
          name: 'assetClassId',
          type: 'dropdown',
          options: listSortAndFormat(assetClasses, 'name', 'id'),
          placeholder: 'Asset Class',
        },
        {
          name: 'assetGroupId',
          type: 'multiselect',
          options: listSortAndFormat(assetGroups, 'name', 'id'),
          placeholder: 'Asset Group',
        },
      ];
      !siteId &&
        filterOptions.push({
          name: 'siteIds',
          type: 'dropdown',
          options: listSortAndFormat(sites, 'name', 'id'),
          placeholder: 'Sites',
        });
      return (
        <TableView
          gql={siteId ? GET_SITE : GET_ORG}
          gqlVariables={{ id: currentEntity.id }}
          queryName={siteId ? 'getSite' : 'getOrg'}
          paginationObject={'assetsPaged'}
          filterOptions={filterOptions}
          initialOrder={[['licenceNumber', 'ASC']]}
          navLinks={siteId ? navLinksForSite : navLinks}
          initialLinkParamObject={linkParams}
          additionalLinkParam={linkPlaceholders.assetId}
          headings={headings}
          dataShapeFunction={shapeData(assetClasses)}
          heading={`${currentEntity.name} Assets`}
          description="View, edit or create Asset"
          actionLink={generateLinkWithParams(
            siteId ? createRouteForSite : createRoute,
            linkParams,
          )}
          actionLinkName="Create"
          placeHolderText="Search Asset"
          initialSortBy="licenceNumber"
          deleteRow={isTMUser && DELETE_ASSET}
        />
      );
    }
    return <PageLoading />;
  },
);

export default AssetList;
