import React, { Fragment, useContext } from 'react';
import { ModalContext } from '../../';
import { CloseModal, ErrModalWrapper, StyledClickToSeeMore } from './styles';

const ErrorModal = ({ errors }) => {
  const { toggleModal, setModalContent, setDimensions } =
    useContext(ModalContext);
  const close = () => {
    toggleModal();
    setModalContent();
    setDimensions({});
  };
  return (
    <Fragment>
      {`${errors[0]}...`}
      <StyledClickToSeeMore
        onClick={() => {
          setDimensions({ height: 'auto', width: 'auto' });
          toggleModal();
          setModalContent(
            <Fragment>
              <CloseModal onClick={close}>X</CloseModal>
              <ErrModalWrapper>
                <h2>ERRORS</h2>
                <ul>
                  {errors
                    .filter((_, idx) => idx < 10)
                    .map((e) => (
                      <li>{e}</li>
                    ))}
                </ul>
              </ErrModalWrapper>
            </Fragment>,
          );
        }}
      >
        See More
      </StyledClickToSeeMore>
    </Fragment>
  );
};

export { ErrorModal };
