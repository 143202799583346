import styled from 'styled-components';
import { TrashCan16, AddAlt16, SortAscending20 } from '@carbon/icons-react';

const Container = styled.div`
  width: 100%;
`;

const SelectContainer = styled.div`
  width: 100%;
  height: 400px;
  overflow: auto;
  border: 2px solid ${({ theme }) => theme.colors.lightGrey};
`;

const Header = styled.div`
  font-size: 0.75rem;
  padding: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  background: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  padding: 0.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0.5rem;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;

const Clear = styled(TrashCan16)`
  cursor: pointer;
  & path {
    fill: ${({ theme }) => theme.colors.danger};
  }
`;
const Add = styled(AddAlt16)`
  cursor: pointer;
  & path {
    fill: ${({ theme }) => theme.colors.success};
  }
`;

const Input = styled.input`
  border: none;
  font-size: 0.6rem;
`;

const Sort = styled(SortAscending20)`
  cursor: pointer;
  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export {
  Container,
  InputContainer,
  Input,
  Clear,
  Add,
  Header,
  Sort,
  SelectContainer,
};
