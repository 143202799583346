import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const EditLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const EmailLink = styled.a`
  padding-right: 3px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const OnClickLink = styled.p`
  ${({ center }) =>
    center &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  font-size: 0.75rem;
  cursor: pointer;
  padding-right: 3px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export { EditLink, EmailLink, OnClickLink };
