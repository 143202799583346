import { gql } from '@apollo/client';

const UPDATE_OPERATOR = gql`
  mutation updateOperator($input: OperatorUpdateInput!) {
    updateOperator(input: $input) {
      id
    }
  }
`;

export { UPDATE_OPERATOR };
