import { useQuery } from '@apollo/client';
import { Field, Form as FormikForm, Formik } from 'formik';
import { mergeAll } from 'ramda';
import React from 'react';
import {
  ActionButtons,
  CheckBoxFormField,
  Column,
  convertEnumObject,
  Dropdown,
  FormArea,
  FormItem,
  FormItemSplitter,
  FormWrapper,
  listSortAndFormat,
  PageContainer,
  PageError,
  PageLoading,
  TextFormField,
  ToggleBtn,
} from '../../../../';
import { FETCH_ASSET_TYPES } from '../../gql';
import { checkLicenceNumberFormat } from '../functions';
import initialValues from './initialValues';
import validationSchema from './validationSchema';

const AssetDetails = ({
  editAssetMutation,
  data,
  assetId,
  availableAssetClasses,
  updatedData,
  currentUser,
}) => {
  const { data: assetTypes, error } = useQuery(FETCH_ASSET_TYPES);
  if (error) {
    return <PageError error={error} />;
  }
  if (assetTypes) {
    const isVerified = !!data.masterAsset;
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues(data, updatedData)}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          values.masterAsset && delete values.vin;
          delete values.masterAsset;
          delete values.tyreTransactions;
          if (!['VEHICLE', 'TRUCK', 'T_T', 'TRAILER'].includes(values.type)) {
            delete values.fleetNumber;
            !['TRUCK', 'VEHICLE'].includes(values.type) &&
              (values.hasTrailers = false);
          }
          values.type === 'TRAILER' && delete values.engineNo;
          delete values.engineNumber;
          const variables = {
            input: {
              id: assetId,
              ...mergeAll(
                Object.keys(values).map((key) => ({
                  [key]: values[key] === '' ? null : values[key],
                })),
              ),
              licenceNumber: values.licenceNumber.toUpperCase(),
            },
          };
          editAssetMutation({ variables });
        }}
      >
        {({ values, handleChange }) => {
          checkLicenceNumberFormat(values, handleChange);
          const assetClass = availableAssetClasses.find(
            (ac) => ac.id === values.assetClassId,
          );
          return (
            <PageContainer height={'70vh'} align="end" gridStyling={'auto'}>
              <FormWrapper>
                <FormikForm>
                  <FormArea columns={2}>
                    <Column>
                      <FormItem>
                        <Field
                          labelText="Asset Type *"
                          name="type"
                          options={convertEnumObject(assetTypes)}
                          component={Dropdown}
                          disabled={!!assetClass}
                        />
                      </FormItem>
                      <FormItem>
                        <Field
                          labelText="Asset Class *"
                          name="assetClassId"
                          options={listSortAndFormat(
                            availableAssetClasses.filter(
                              (ac) => ac.type === values.type,
                            ),
                            (a) => a.name,
                            'id',
                          )}
                          component={Dropdown}
                          disabled={!values.type}
                        />
                      </FormItem>
                      {assetClass?.isLink && (
                        <FormItem>
                          <Field
                            name="isRearTrailer"
                            component={CheckBoxFormField}
                            labelText="Rear Trailer"
                          />
                        </FormItem>
                      )}
                      <FormItem>
                        <FormItemSplitter>
                          <Field
                            labelText="Year Registered"
                            name="year"
                            component={TextFormField}
                            type="number"
                          />
                          <Field
                            labelText="Service Interval"
                            name="serviceInterval"
                            component={TextFormField}
                            type="number"
                            unit="km"
                          />
                        </FormItemSplitter>
                      </FormItem>
                      {['VEHICLE', 'TRUCK', 'T_T'].includes(values.type) && (
                        <FormItem>
                          <FormItemSplitter>
                            <Field
                              labelText="Fleet Number"
                              name="fleetNumber"
                              component={TextFormField}
                            />
                            <Field
                              labelText="Engine Number"
                              name={isVerified ? 'engineNumber' : 'engineNo'}
                              disabled={isVerified}
                              component={TextFormField}
                            />
                          </FormItemSplitter>
                        </FormItem>
                      )}
                      {values.type === 'TRAILER' && (
                        <FormItem>
                          <Field
                            labelText="Fleet Number"
                            name="fleetNumber"
                            component={TextFormField}
                          />
                        </FormItem>
                      )}
                      <FormItem>
                        <FormItemSplitter>
                          <Field
                            defaultToggled={
                              values.status === 'ACTIVE' ? true : false
                            }
                            onToggle={(value) => {
                              handleChange({
                                target: {
                                  value: value ? 'ACTIVE' : 'INACTIVE',
                                  name: 'status',
                                },
                              });
                            }}
                            labelA="Inactive"
                            labelB="Active"
                            labelText="Asset Status"
                            name="status"
                            component={ToggleBtn}
                          />
                          {['VEHICLE', 'TRUCK'].includes(values.type) && (
                            <Field
                              labelText="Has Trailers"
                              name="hasTrailers"
                              component={CheckBoxFormField}
                            />
                          )}
                        </FormItemSplitter>
                      </FormItem>
                      {currentUser.isTMUser && (
                        <FormItem>
                          <Field
                            labelText="Onroad ID"
                            name="onroadId"
                            component={TextFormField}
                          />
                        </FormItem>
                      )}
                    </Column>
                    <Column>
                      <FormItem>
                        <Field
                          labelText={`Licence Number *${
                            isVerified
                              ? ` (master: ${data.masterAsset.licenceNumber})`
                              : ''
                          }`}
                          name="licenceNumber"
                          component={TextFormField}
                        />
                      </FormItem>
                      <FormItem>
                        <Field
                          labelText="Registration Number"
                          name="registrationNumber"
                          component={TextFormField}
                          disabled={isVerified}
                        />
                      </FormItem>

                      <FormItem>
                        <Field
                          labelText="Make"
                          name="make"
                          component={TextFormField}
                          disabled={isVerified}
                        />
                      </FormItem>
                      <FormItem>
                        <Field
                          labelText="Model"
                          name="model"
                          component={TextFormField}
                          disabled={isVerified}
                        />
                      </FormItem>
                      <FormItem>
                        <Field
                          labelText="Colour"
                          name="colour"
                          component={TextFormField}
                          disabled={isVerified}
                        />
                      </FormItem>
                      <FormItem>
                        <Field
                          labelText="VIN Number"
                          name="vin"
                          component={TextFormField}
                          disabled={isVerified}
                        />
                      </FormItem>
                    </Column>
                  </FormArea>
                </FormikForm>
                <ActionButtons primaryName="Save & Continue" type="finalStep" />
              </FormWrapper>
            </PageContainer>
          );
        }}
      </Formik>
    );
  }
  return <PageLoading />;
};

export default AssetDetails;
