import { gql } from '@apollo/client';

const CREATE_LOAD_CONTACT = gql`
  mutation createLoadContact($input: LoadContactCreateInput!) {
    createLoadContact(input: $input) {
      id
    }
  }
`;

const GET_META = gql`
  query getMeta($orgId: ID!) {
    getCarrier: getOrg(id: $orgId) {
      yardCarriers(pagination: { take: 1000000, skip: 0 }) {
        data {
          org {
            id
            name
          }
        }
      }
    }
    getCommEvents: __type(name: "ELoadADNCommunicationEvent") {
      enumValues {
        name
      }
    }
  }
`;

const GET_META_FOR_ORG = gql`
  query getMeta {
    getCommEvents: __type(name: "ELoadADNCommunicationEvent") {
      enumValues {
        name
      }
    }
  }
`;

export { CREATE_LOAD_CONTACT, GET_META, GET_META_FOR_ORG };
