import { gql } from '@apollo/client';

const CREATE_ASSET = gql`
  mutation createAsset($input: AssetCreateInput!) {
    createAsset(input: $input) {
      id
    }
  }
`;
const ALLOCATE_SITES = gql`
  mutation manageAssetSites($input: IdAddRemove!) {
    manageAssetSites(input: $input) {
      id
    }
  }
`;

const GET_SITE = gql`
  query getSite($id: ID!) {
    meta: getSite(id: $id) {
      org {
        assetClasses {
          id
          name
          type
          isLink
        }
      }
    }
    assetTypes: __type(name: "AssetTypeEnums") {
      enumValues {
        name
      }
    }
  }
`;

const GET_ORG = gql`
  query getOrg($id: ID!) {
    meta: getOrg(id: $id) {
      assetClasses {
        id
        name
        type
        isLink
      }
      sites {
        id
        name
      }
    }
    assetTypes: __type(name: "AssetTypeEnums") {
      enumValues {
        name
      }
    }
  }
`;

export { CREATE_ASSET, GET_ORG, ALLOCATE_SITES, GET_SITE };
