import styled from 'styled-components';

const PrintViewContainer = styled.div`
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PDFContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  & iframe {
    width: 100%;
    height: 100%;
  }
`;

export { PrintViewContainer, PDFContainer };
