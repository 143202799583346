import { gql } from '@apollo/client';

const GET_META = gql`
  query getMeta {
    types: __type(name: "EAssetTPSEventTypes") {
      enumValues {
        name
      }
    }
    sources: __type(name: "EAssetTPSEventSources") {
      enumValues {
        name
      }
    }
  }
`;

const GET_STREAM = gql`
  query getAsset(
    $id: ID!
    $take: Int!
    $skip: Int!
    $query: QueryAssetTPSEvents
    $order: [[String]]
  ) {
    getAsset(id: $id) {
      thirdPartyStreamEvents(
        pagination: { take: $take, skip: $skip }
        query: $query
        order: $order
      ) {
        pagination {
          take
          skip
          total
        }
        data {
          id
          assetLicenceNo
          source
          eventType
          timeCreated
          tpsPulseType
          geolocation {
            latitude
            longitude
          }
          rawData
        }
      }
    }
  }
`;

export { GET_META, GET_STREAM };
