import { Close20 } from '@carbon/icons-react';
import { lighten } from 'polished';
import styled, { css } from 'styled-components';

const AreaHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & p {
    font-size: 11px;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }

  & p:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Column = styled.div`
  width: 320px;
  :not(:last-child) {
    margin-right: 50px;
  }
`;

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Heading = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

const IconContainer = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px;
  width: 100%;
  & p {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  color: ${({ theme }) => theme.colors.darkGrey};
  ${({ isDisabled }) =>
    !isDisabled
      ? css`
          :hover {
            background: ${({ theme }) => theme.colors.lightGrey};
            cursor: pointer;
          }
        `
      : css`
          background: ${({ theme }) => theme.colors.mediumGrey};
        `}
  & path {
    fill: ${({ theme, initial }) =>
      initial ? theme.colors.primary : theme.colors.darkGrey};
  }
  & svg {
    margin-right: 10px;
  }
`;

const ItemArea = styled.div`
  border-top: 1px solid ${({ theme }) => lighten(0.05, theme.colors.lightGrey)};
  border-top: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  height: 300px;
  width: 320px;
  overflow: auto;
  background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
  margin-top: 10px;
  transition: 300ms;
  > div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
  ${({ withFilter }) =>
    withFilter &&
    css`
      display: grid;
      grid-template-rows: auto 1fr;
      > div > div {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
      }
    `}
`;

const InputContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0.5rem;
  background: ${({ theme }) => theme.colors.white};
  padding: 0.5rem;
  align-items: center;
  font-size: 0.7rem;
`;

const ClearFilter = styled(Close20)`
  cursor: pointer;
  & path {
    fill: ${({ theme }) => theme.colors.secondary};
  }
  :hover {
    & path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const Input = styled.input`
  width: 100%;
  border: none;
`;

export {
  AreaHeader,
  Column,
  Container,
  Heading,
  IconContainer,
  ClearFilter,
  InputContainer,
  Input,
  Item,
  ItemArea,
};
